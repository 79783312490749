import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatesSetArg } from '@fullcalendar/core';
import * as _ from 'lodash';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { FilterCalendar } from 'src/app/pages/models/filter-calendar';
import { LocalSessionService } from 'src/app/pages/services/local-session.service';
import { SelectedFIlters } from 'src/app/shared/models/selected-filters';

//#endregion Boolean GetValues
const _ALL_DOCTORS = '_all_doctors'
const _ALL_SERVICIOS = '_all_servicios'
const _ALL_RECURSOS = '_all_recursos'
const _FORM_FILTER = '_form_filter_calendar'
//#endregion

@Component({
  selector: 'app-form-filter-calendar',
  templateUrl: './form-filter-calendar.component.html',
  styleUrls: ['./form-filter-calendar.component.scss']
})
export class FormFilterCalendarComponent implements OnInit  {

  @Input()
    _selectedDoctors:   SelectedFIlters[] = []
  @Input()
    _selectedServicios: SelectedFIlters[] = []
  @Input()
    _selectedRecursos:  SelectedFIlters[] = []

  @Input()
  dateRange: DatesSetArg;
  

  @Output()
    onEventEmmitFilters: EventEmitter<FilterCalendar> = new EventEmitter()

    _all_doctors:   boolean //= true;
    _all_servicios: boolean //= true;
    _all_recursos:  boolean //= true

    _isFilter: boolean;
    _FormFilter: FormGroup;

    doctorModel: string
    servicioModel: string
    recursoModel: string

    _calendarFilters: FilterCalendar = new FilterCalendar() 

    _isAdmin: boolean;
    _data_user: any
    _roleUser: string

    _dateRange: any;

    constructor (
      private fb: FormBuilder,
      private credentialService: CredentialsService,
      private date: DatePipe,
      private _local: LocalSessionService      
      ) { 
        const DATA_USER = this.credentialService._getdataUser()
        if(DATA_USER) {
          this._data_user = DATA_USER
        }
      }
    ngOnInit(): void {  

      if(this.dateRange) {
        this._dateRange = {
          from: this.dateRange.start,
          to: this.dateRange.end
        }
      }
      

      this.CheckBoolen()
      this.initFilters()    


      this.initForm()
      //this._isAdmin = this.credentialService.credentials?.roles.Administrador ?  this.credentialService.credentials.roles.Administrador : false;     
    }

    /**
     * 
     */
    private CheckBoolen () {

      const _all_doctors_ = this._local.getBooleanValue(_ALL_DOCTORS);     
      if(_all_doctors_ != null ) {
        this._all_doctors = _all_doctors_
      }else{
        this._all_doctors = true
      }

      const _all_servicios_ = this._local.getBooleanValue(_ALL_SERVICIOS);                  
      if(_all_servicios_ != null ) {
        this._all_servicios = _all_servicios_
      }else{
        this._all_servicios = true
      }

      const _all_recursos_ = this._local.getBooleanValue(_ALL_RECURSOS);   
      if(_all_recursos_ != null ) {
        this._all_recursos = _all_recursos_
      }else{
        this._all_recursos = true
      }     
      
    }

    private initFilters () {

      const _filters = this._local.getJsonValues(_FORM_FILTER)

      if(_filters) {

        const _filterDoc  = _filters.doctores
        //
        //       

        if(_filterDoc.length > 0 ) {
          _.map(this._selectedDoctors, (a) => {
            const _include = _filterDoc.includes(a.Id)
            if(!_include) {
              a.checked = false 
            }   
          })
        }

        const _filterRec  = _filters.recursos

        if(_filterRec.length > 0 ) {
          _.map(this._selectedRecursos, (a) => {
            const _include = _filterRec.includes(a.Id)
            if(!_include) {
              a.checked = false
            }
          })
        }


        const _filterServ = _filters.servicios    

        if(_filterServ.length > 0 ) {
          _.map(this._selectedServicios, (a) =>{
            const _include = _filterServ.includes(a.Id)
            if(!_include) {
              a.checked = false
            }
          })
        }


      }

      //console.log({_FORM_FILTER: _filters });
      

    }

    private initForm() {
      this._FormFilter = this.fb.group({
        doctores:   [''],
        recursos:   [''],
        servicios:  [''],
        inicio:     [null],
        final:      [null],
      })

    //  this.patchValue()
    }

    /**
     * 
     */
    private patchValue() {
    }

    /**
    * Evento Change de los Doctores
    * @param event 
    */
    changeDoctors(event: SelectedFIlters) {

     event.checked = !event.checked;   
     
     this.onEventEmmit()
 
    }
   
   /**
    * Evento Change all Doctores
    * @param event 
    */
   changeEvent(event: any) {
     let val = event.target.checked   

     this._local.setBooleanValue(_ALL_DOCTORS,val)
     
     _.map(this._selectedDoctors, (a) => {
       a.checked = val
     })

     this.onEventEmmit()
 
   }
 
   /**
    * Evento Check Servicios
    */
   ChangeServicios(event: SelectedFIlters) {

     event.checked = !event.checked;   

     this.onEventEmmit()
   }
 
   /**
    * Evento Change  all Servicios
    */
   changeEventServicios(event: any) {
     let val = event.target.checked
     this._local.setBooleanValue(_ALL_SERVICIOS, val)

     _.map(this._selectedServicios, (a) => {
       a.checked = val
     })

     this.onEventEmmit()
   }
 
   /**
    *  Evento Change all Recursos
    * @param event 
    */
   changeAllRecrusos(event:any) {
     let val = event.target.checked
     this._local.setBooleanValue(_ALL_RECURSOS, val)

     _.map(this._selectedRecursos, (a) => {
       a.checked = val
     })
     this.onEventEmmit()
    }
 
 
   /**
    * Evento Change CheckedRecurso
    * @param evento 
    */
   changeEventRecruso(evento:SelectedFIlters) {    

     evento.checked = !evento.checked  
     
     this.onEventEmmit()
   }


   onEventEmmit(){  
    
    let _doctores : any[] = []
    
    if(this._roleUser == 'Doctor') {      
      
      _doctores.push({
        Id: this._data_user.id_salesforce,
        Name: this._data_user.nombre,
        checked: true
      })
    }else {
      _doctores = _.filter(this._selectedDoctors,   (a: SelectedFIlters) => { return a.checked } )
    } 
    

    let _servicios = _.filter(this._selectedServicios, (a: SelectedFIlters) => { return a.checked } )      
    let _recursos  = _.filter(this._selectedRecursos,  (a: SelectedFIlters) => { return a.checked} )
 
    let _doctoresFilter:  any[] = []
    let _serviciosFilter: any[] = []
    let _recursosFilter : any[] = [] 
 
    _.map(_doctores,  (a) => {_doctoresFilter.push(a.Id) })
    _.map(_servicios, (a) => {_serviciosFilter.push(a.Id)})
    _.map(_recursos,  (a) => {_recursosFilter.push(a.Id)} )
 
    this._calendarFilters.doctores   = _doctoresFilter
    this._calendarFilters.servicios  = _serviciosFilter
    this._calendarFilters.recursos   = _recursosFilter

    const  inicio = this.date.transform(this._dateRange.from,'yyyy-MM-dd')
    const  final = this.date.transform(this._dateRange.to,'yyyy-MM-dd')

    this._calendarFilters.inicio  = inicio
    this._calendarFilters.final   = final

    this._local.savedJsonValues(_FORM_FILTER, this._calendarFilters)

    console.log({_CalendarFilters: this._calendarFilters});
    
    
    this.onEventEmmitFilters.emit(this._calendarFilters)  
    
   }


   _doctorsFilter(): boolean {

    if(this.credentialService.credentials?.roles.Administrador || this.credentialService.credentials?.roles.Administracion ) {
      return true
    }

    return false

   }

   _onModelChange() {
    if(this._dateRange && (this._dateRange.from && this._dateRange.to) ) {
      this.onEventEmmit()
    }
    
    
   }


}
