
<fieldset class="mt-4 border  border-opacity-25 border-primary">                                
    <div class="m-2">
        <span class="fw-bold text-primary">Crear cita subsecuente</span>                                     
        <div class=" d-flex justify-content-end ">
            <button class="btn btn-success w-sm flex-shrink-0" 
            (click)="_addNewCita()"> <i class=" ri-add-fill  align-bottom me-1"></i> 
                Registrar cita sub-secuente
             </button> 
        </div>
        <form [formGroup]="_formCitaNueva" (submit)="_onsaveNewCita()" class="mt-4" novalidate>
            <ngx-simplebar style="max-height: calc(100vh - 70px)">
                <div class="card card-body">
                    <div formArrayName="citas">
                        <div  *ngFor="let _cita of citas.controls; let i= index">
                            <div [formGroupName]="i">
                                <fieldset class="mt-4">
                                    <legend class="bg-light fs-16 fw-bold"> Cita # {{i +1 }} </legend>
                                    <div class="float-end">
                                        <button class="btn bt-sm btn-danger" (click)="removeControls(i)">Quitar</button>
                                    </div>
                                </fieldset>
                                <div class="row ">
                                    <div class="col-xl-4 col-lg-6">
                                        <div class="form-group">
                                        <label class="form-label">Tipo de cita</label>
                                        <ng-select
                                        [items]="_tipo_evento"
                                        bindLabel="name"
                                        bindValue="Id"              
                                        [clearable]="false"
                                        (change)="_onloadProcedimiento($event)"
                                        formControlName="cita"
                                        ></ng-select>
                                        </div>                                                                         
                                    </div>       
                                    <div class="col-xl-4 col-lg-6">
                                        <div class="form-group">
                                            <label class="form-label">Tipo de procedimiento</label>
                                            <ng-select
                                            [items]="_Servicios"
                                            bindLabel="Name"
                                            bindValue="Id"
                                            [clearable]="false"
                                            formControlName="procedimiento">    
                                            </ng-select>        
                                        </div>
                                    </div>
                                    <div class="col-12 mt-4">
                                        <label class="form-label">Detalles de cita</label>                                                      
                                        <textarea
                                        class="form-control"
                                        formControlName="detalles"
                                        [rows]="5"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>                                        
                </div>
            </ngx-simplebar>
            <div class="float-end m-2">
                <button type="submit" class="btn btn-success text-uppercase">guardar</button>
            </div>
        </form>
    </div>                          
</fieldset>

