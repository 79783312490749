import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { dataObjectImages } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  currentProfileImgSubject : BehaviorSubject<dataObjectImages> = new BehaviorSubject({} as dataObjectImages);
  public readonly currentProfile: Observable<dataObjectImages> = this.currentProfileImgSubject.asObservable();

  constructor() { }

  /**
   * 
   * @param _profileImg 
   */
  public setCurrentProfileImg(_profileImg: dataObjectImages) {

    this.currentProfileImgSubject.next(_profileImg)

  }
}
