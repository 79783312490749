import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { Servicio } from 'src/app/pages/models/servicios';
import { CreateCita } from 'src/app/shared/models/create-cita';
import { AlertsService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-cita-subsecuente',
  templateUrl: './cita-subsecuente.component.html',
  styleUrls: ['./cita-subsecuente.component.scss']
})
export class CitaSubsecuenteComponent  {

  @Input()
  _closeModal: boolean = false

  @Output()
  eventCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>()

  _formCitaNueva: FormGroup;    
  _Servicios    : Servicio[]  = []
  _tipo_evento  : any[] = []

  _cita_subsecuente: boolean = false
  _Cita: Cita
  _add_cita_seguimiento: boolean

  _CitasCalednario: CreateCita = new CreateCita();

  constructor(
    private formBuilder: FormBuilder,
    private calendar: CalendarService,
    private toastr: ToastrService,
    private credentials: CredentialsService,
    private alert: AlertsService
  ) {
    this._initformCitasNew()
    

    const _cita = this.credentials.getCitaJson() //this._session._get

    if(_cita) {
      this._Cita = _cita
    }

    this.loadCatalog()
  }


  get citas() {
    return this._formCitaNueva.get('citas') as FormArray
  }

  _addNewCita(): void {
    this._add_cita_seguimiento = true //! this._add_cita_seguimiento
    
    this.citas.push(this._addNewControlCita())
  }

  private _addNewControlCita():FormGroup{
    return this.formBuilder.group({
      cita:null,
      servicio:null,
      procedimiento:null,
      detalles:null,
      recurso:null,
      doctor: this._Cita.Empleado_Id,
      paciente: this._Cita.Paciente_Id,      
    })
  }


  private _initformCitasNew() {
    this._formCitaNueva = this.formBuilder.group({
      citas: this.formBuilder.array([])
    })
  }


  private loadCatalog() {
    this.calendar._getTipoEvento(this._Cita.Paciente_Id).subscribe({
      next: (response: any) => {

        if(response.codigo == 200) {
          
          this._tipo_evento= response.data[0].tipo_evento;
          
        }

      },
      error:(error) => {
        console.error({error});        
        this.toastr.error('Hubo un error al listar los tipos de evento','Lista Anestesiologos Error', {
          timeOut: 3200
        })
      }
    })
  }


  _onloadProcedimiento(item: string) {
    
    this.calendar._ConsultarTipoProcedimiento(item,this._Cita.Empleado_Id).subscribe({
      next:(value: any) =>{
        console.log({value});
        
        if(value.codigo == 200) {
          this._Servicios = value.data
        }
        
      },
      error(err) {
        console.error({err});
        
      },
    })
    
  }


 async _onsaveNewCita() {
    this.alert._showLoader('Guardando citas, espere...')
    _.map(this._formCitaNueva.controls, (a) => {
      const _values = a.value   

      _.map(_values, async (b) => {        
        this._CitasCalednario = new CreateCita()      

        this._CitasCalednario.generales = {      
          tipo_evento: b.cita,
          comentarios: b.detalles          
        }

        this._CitasCalednario.detalle_cita = {
          doctor: b.doctor,
          paciente: b.paciente,
          recurso: b.recurso,
          servicio: b.procedimiento,
        }     

       this._CitasCalednario.cita_sub_secuente = true

       console.log({_CitasCalednario: this._CitasCalednario});

      await this.calendar._CreateCitaPromie(this._CitasCalednario).then((resp) => {
          console.log({'_RESPONSE__': resp});     
          this.alert._hideLoader()
          
          this.toastr.success('Exito al crear la cita subsecuente','Exito!!', {
            timeOut: 3200
          })
          this._add_cita_seguimiento = false
          this.calendar._setValueLoadCitasSubsecuentes(true)
          if(this._closeModal) {
            this.eventCloseModal.next(true)
          }
          this.removeAllControls()

         


      }).catch((error) => {
        console.error({'_ERRPR': error}); 
        this.alert._hideLoader()
        this.toastr.error('Ocurrio un error al registrar la cita','Error!!',{
          timeOut: 3200
        })

                 
      })
      })                 
    }) 
  }

  private removeAllControls() {
    _.map(this._formCitaNueva.value.citas, (a, i: number) => {
      this.removeControls(i)      
    })
  }

  /**
   * 
   * @param i 
   */
  removeControls (i:number) {
      this.citas.removeAt(i)
  }
}
