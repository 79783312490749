

<div class="customizer-setting">
    <div class="page-title-box d-sm-flex align-items-center justify-content-end">                
        <button class="btn-primary rounded-pill shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
        data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas"  (click)="openEnd(filtetcontent)">
            <i class="mdi mdi-account-arrow-left-outline fs-22"></i>
        </button>      
                      
        </div>
</div>

<!-- Theme Settings -->
<ng-template #filtetcontent let-offcanvas>
    <div class="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
        <h5 class="m-0 me-2 text-white">Filtros Calendario</h5>
        <button type="button" class="btn-close btn-close-white ms-auto " data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body p-2">   
        <ngx-simplebar style="height: 90vh;">
          
          <app-form-filter-calendar
          [_selectedDoctors]="_selectedDoctors"
          [_selectedRecursos]="_selectedRecursos"
          [_selectedServicios]="_selectedServicios"
          (onEventEmmitFilters)="_OnEventEmmitFilters($event)"
          ></app-form-filter-calendar>
        
        </ngx-simplebar>    
   
         

    </div>
</ng-template>
  