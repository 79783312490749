import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { SessionPageService } from '../../services/session-page.service';
import { FormatsService } from 'src/app/shared/services/formats.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarService } from '../../calendar/services/calendar.service';
import { PatientsService } from '../../calendar/services/patients.service';
import { LocalSessionService } from '../../services/local-session.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from 'src/app/layouts/services/navigation.service';
import { Location } from '@angular/common';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { Cita } from '../../calendar/models/calendar';
import { VoiceRecognitionService } from 'src/app/shared/services/voice-recognition.service';
import { Subscription } from 'rxjs';
import { Recetas } from '../../models/medicamentos';
import { RecetasService } from '../../services/recetas.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CandidatosService } from '../../calendar/services/candidatos.service';
import { CalificacionCandidato, CalificacionVarices } from '../../calendar/models/candidatos';

const _LAYOUT_FORMATO = '_layout_template'


@Component({
  selector: 'app-layout-patients-format',
  templateUrl: './layout-patients-format.component.html',
  styleUrls: ['./layout-patients-format.component.scss']
})
export class LayoutPatientsFormatComponent implements OnInit, OnDestroy {

  @ViewChild('modalPatient') modalPatient !: TemplateRef<any>
  @ViewChild('modalCrearCita') modalCrearCita!: TemplateRef<any>
  @ViewChild('modalCrearReceta') modalCrearReceta!: TemplateRef<any>

  @ViewChild('modalBtns') modalBtns!: TemplateRef<any>;
  
  // bread crumb items
  breadCrumbItems!: Array<{}>;

  record: any
  _cita: any;
  _nombre_completo: string

  _pacientesFormatos: any[] = []
  _jsonCitas: any[] = []

  isCollapsed: boolean = true
  isCollapsedPacientes: boolean =  true
  isCollapsedOthers: boolean = true

  _formato: any;
  formato: any

  _formato_id: string
  _label_formato: string

  _clientes_espera: any[] = []

  _spinner = 'spinner'

  _ngModal: NgbModalRef;
  _ngbModal: NgbModalRef
  _ngbModalReceta: NgbModalRef
  _ngModalBtns: NgbModalRef

  _loadFromCita!: boolean
  _citasSubsecuentes: any[] = []
  _dataCita: any;
  _cita_sub_secuente_id: string;
  _citas_paciente: any[] = []
  _readyFormat: boolean;

  _voiceText: string = '_text_voice'

  $loadCitasSubsecuentes: Subscription

  _modalOption!: string 


  _recetas:Recetas[] = []
  _LaboratorioFiles: any[] = []

  _calificacionCandidato: CalificacionCandidato = new CalificacionCandidato()
  _calificacionVarices: CalificacionVarices = new CalificacionVarices()
  _prioridad: number = 0
  
  constructor (
    private credentials: CredentialsService,
    private sessionPage: SessionPageService,    
    private format: FormatsService,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private calendarService: CalendarService,
    private patientService: PatientsService,
    private modalService: NgbModal,
    private local_: LocalSessionService,
    private navigation: NavigationService,
    private location: Location,
    private alert: AlertsService,
    private receta: RecetasService,
    public voice: VoiceRecognitionService,
    private candidato: CandidatosService
  ) {   
    const _record = this.credentials.userInfoData

    if(_record && _record.data) {
      this.record =  _record.data.records[0]

      const especialidad: string = this.record.Especialidad__c

      if(especialidad === 'Proctología' || especialidad === 'Coloproctología') {
        this._getCandidaoResultado(this.record.Candidato__c)
      } 

      if( especialidad === 'Varices'|| especialidad === 'Várices'||    especialidad === 'Varices avanzadas'
      ) {
        this._getCalificacionVarices(this.record.Candidato__c)
      }         
      
    }
  }

  ngOnInit(): void {
    this._loadFromCita = false

    this._loadDataCita()


    this.$loadCitasSubsecuentes = this.calendarService.CurrentCitaSubsecuente.subscribe({
      next:(value) =>  {
        console.log({___VALUE____: value});
        
        if(value) {
            this._loadCitasSubsecuents(this.record)
          return
        }  
      },
      error(err) {
        console.error({err});
        
      },
    })


    if(this._cita && this.record) {
      this._nombre_completo = this._cita.Paciente_Nombre_Completo

      this._loadCitasSubsecuents(this.record)
      this._loadCitasPaciente(this.record)

      this.breadCrumbItems = [
        { label: this.record.Name, type:'paciente' },
        { label: this._cita.Cita_Id , type:'cita' }
      ];
    }  
    
    this.getPatientsFormatos()

    // Compose Model Hide/Show
     let isShowMenu = false;
     document.querySelectorAll(".file-menu-btn").forEach(function (item) {
       item.addEventListener("click", function (e) {
         e.preventDefault();
         isShowMenu = true;
         document.getElementById('menusidebar')?.classList.add("menubar-show");
       });
     });

     document.querySelector('.chat-wrapper')?.addEventListener('click', function () {
      if (document.querySelector(".file-manager-sidebar")?.classList.contains('menubar-show')) {
        if (!isShowMenu) {
          document.querySelector(".file-manager-sidebar")?.classList.remove("menubar-show");
        }
        isShowMenu = false;
      }
    });

    this.loadClientesEspera()
    console.log({_CITA____: this._cita, ____RECORD___: this.record });   
 
  }

  private _getCandidaoResultado(_candidato_id: string) {
    this.alert._showLoader('Consultando calificacion, paciente')
    this.candidato._consultarCalifPaciente(_candidato_id).subscribe({
      next:(value: any) => {           

        if(value && value.codigo === 200) {              
          this.alert._hideLoader()
          //value.data.candidato_id = this._candidato_id
          this._calificacionCandidato = value.data;       
          this._prioridad = this._calificacionCandidato.prioridad       
        }            
      },
      error:(err) => {
        this.alert._hideLoader()
        console.error({err});            
      },
    })
  }

  private _getCalificacionVarices(_cadidato_id: string) {
    this.alert._showLoader('Consultando calificacion, paciente')
    this.candidato._consultarCalificacionVarices(_cadidato_id).subscribe({
      next:(value: any) => {           

        if(value && value.codigo === 200) {              
          this.alert._hideLoader()
          //value.data.candidato_id = this._candidato_id
          this._calificacionVarices = value.data;            
          this._prioridad = this._calificacionVarices.prioridad  
        }            
      },
      error:(err) => {
        this.alert._hideLoader()
        console.error({err});            
      },
    })
  }

  /**
   * 
   * @param record 
   */
  private _loadCitasPaciente (record: any) {

    this.calendarService._GetCitasPaciente(record.Id).then((resp: any) => {
      if(resp.codigo === 200) {
        this._citas_paciente = resp.data
      }                
    }).catch((error) => {
      console.error({error});      
    })
  }

  /** */
  private getPatientsFormatos () : void {
    this._loadFromCita = true
    let _formatos_cita = this.local_.getJsonValues('_json_formatos_cita')

    if(_formatos_cita) {

      let _menu = this.navigation.getMenuItems(_formatos_cita)     
      //console.log({_menu});
      
      this._pacientesFormatos =  _menu[0].menu     
/*       const PacientesFormatos = _menu[0].menu
      console.log({___PACIENTES_FORMATOS: PacientesFormatos});
      

      _.map(PacientesFormatos, (a, i) => {
        _.map(a.subItems, (b: any, j) => {
          if(b.permision && !(b.permision.vista)) {
            console.log({subItems: b, _INDEX__: j});
            PacientesFormatos[i].subItems.splice(j, 1)            
          }
        })
      })
      console.log({_pacientesFormatos: this._pacientesFormatos, PacientesFormatos}); */
      
    }
  }

  private _loadDataCita() {


    //console.log({record_: this.record});
    
    
    this._cita = this.credentials.getCitaJson()    
    //const _dataUser = this.credentials._getdataUser();  
    
    const _jsonCitas = this.sessionPage._returnJSONSession()
    if(_jsonCitas) {
      this._jsonCitas = _jsonCitas
    }
  }

  private _loadCitasSubsecuents (_record: any) {
    //const _record = this.credentials.userInfoData
    this._citasSubsecuentes = []
    this.calendarService._GetCitasSubsecuentes(_record.Id).then((resp: any) => {      

      if(resp.codigo === 200){
        this._citasSubsecuentes = resp.data

      }

      
    }).catch((error) => {      
      console.error({error});      
    })
  }

  _onBack(): void {
    this.location.back()
  }

  ngOnDestroy(): void {
    this.record = null
    if(this.$loadCitasSubsecuentes) {
      this.$loadCitasSubsecuentes.unsubscribe()
    }
    //sessionStorage.removeItem('data_user')
  }

  _onEventEmmiterClick (item: any) {

  }

  private loadClientesEspera () {
    this._clientes_espera = []
    this.authService._getUserDetail().then((resp) => {  
      if(resp.codigo == 200) {
        const webSocket = resp.data.info.Websockets
        if(webSocket && webSocket.paciente_espera) {
          console.log({webSocket});
          this._clientes_espera  = webSocket.paciente_espera.Logs;

          
        }
        //= resp.data.info.Websockets.paciente_espera ? resp.data.info.Websockets.paciente_espera.Logs : []     
      }      
    })
  }


  /**
   * 
   * @param formato 
   */
  async _routerLink (formato: any) {
    this._formato = null   
    this._readyFormat = false

    console.log({formato});
    
    this.formato = formato

    this._formato_id = formato._sub_link
    this._label_formato = formato.label

    let _formato_id_ = formato._formato_id

    if(_formato_id_ == 'Hoja_Indicaciones' ) {
      _formato_id_ = 'Hoja_Indicaciones_Medicas'
    }

  /*   if(_formato_id_ == 'Enfermeria_Operatorios') {
      _formato_id_ = 'registro_enfermeria_preTrans_postOperatorio'
    } */
    
    let _formato_ = {
      _formato_id_,
      _cita_id:this._cita.Cita_Id,
      _formato_id: this._formato_id
    }    

    this.local_.savedJsonValues(_LAYOUT_FORMATO, _formato_)

    await this.format._getFormatoAsync(_formato_id_,this._cita.Cita_Id).then(async (response) => {

      this._formato = response.data.formato;
      this._readyFormat = true

    }).catch((error) => {
      console.error({'ERROR__': error});
    })
/*     .subscribe({
      next:(response) => {     
        if(response.codigo == 200 ) {
          this._formato = response.data.formato;
        }         
      },  
      error(err) {
        console.error({'ERROR__': err});
        
      },
    }) */
    


  }

  /**
   * 
   */
  OtherFormats(link: string) {

    this._formato = null

    this._formato_id = link

    this.format._getFormat(link,this._cita.Cita_Id).subscribe({
      next:(response) => {
        if(response.codigo == 200 ) {
          this._formato = response.data.formato;
        }   
      }
    })
  }


  _onloadHistory(event: any) {
    let _cita_id = event.data.cita_id

    let _find = _.find(this._jsonCitas, {Cita_Id: _cita_id })

    if(_find) {
      this.credentials.saveCitaJson(_find)
      this._getPatientDetalle(_find.Paciente_Id)
  
     }else {
  
      this.spinner.show(this._spinner)
  
      this.calendarService._getDetallesCita(event.data.cita_id).subscribe({
        next:(response: any) => {
          
          if(response.codigo == 200) {
              const _data = response.data[0];
              if(_data && _data.error) {
                console.log('AAAAAAAAAA');
              }else {
                console.log('BBBBBBBBBBBB'); 
              }  
              this.spinner.hide(this._spinner)
          }
          
        },
        error:(error: any) =>  {
          console.error({error});
  
          this.spinner.hide(this._spinner)
          
        }
      })
  
     }    
     console.log({event, _jsonCitas: this._jsonCitas, _find });   


  }

   /**
   * 
   * @param paciente_id 
   */
   _getPatientDetalle(paciente_id: string) {
    this.spinner.show(this._spinner)
    this.patientService._getPatientInfo(paciente_id).subscribe({
      next:(response: any) => {
       // 
       this.spinner.hide(this._spinner)
        this.credentials.saveUserinfo(response);
       // this.router.
       window.location.reload()
      },
      error: (error: any) => {
        this.spinner.hide(this._spinner)
        console.error({error});        
      }
    })   
  }


  /**
   * 
   * @param item 
   */
  _onOpenCita(item: any) : void {

    console.log({item});
    this._dataCita = []
    
    this._cita_sub_secuente_id = item.cita_sub_secuente_id

    this._dataCita = item.Datos_Cita
    
    this._ngModal  = this.modalService.open(this.modalPatient,{
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })

  }

  closeEventModal() {
    this._ngModal.close()
  }

  onClickEvent(event: boolean) {
    if(event) {
      this._ngModal.close()
    }
  }

  /**
   * 
   */
  onSettingsButtonClicked(){
    document.body.classList.toggle('right-bar-enabled');
    const rightBar = document.getElementById('theme-settings-offcanvas');
    if (rightBar != null) {
      rightBar.classList.toggle('show');
      rightBar.setAttribute('style', "visibility: visible;");
    }
  }

  /**
   * 
   * @param formato 
   */
  onSetIdFormato(formato: any) {        
    this._routerLink(formato)
  }

  /**
   * 
   * @param _cita 
   */
  _setCita(_cita: any) {
    console.log({_cita});
    this.alert._showLoader('Cargando, espere...')
    let _cita_: Cita;

    _cita_ = {
      Cita_Id: _cita.Name,
      Recurso: _cita.Recurso,
      Fecha_Inicio: _cita.Fecha_Inicio,
      Fecha_Final: _cita.Fecha_Final,
      Paciente_Id: _cita.Paciente_Id,
      Recurso_Id: _cita.Recurso_Id,
      Tipo_Cita: _cita.Tipo_Evento,
      Servicio_Id: _cita.Servicio_Id,
      Empleado_Id: _cita.Empleado_Id,
      Procedimiento: '',
      Estatus: _cita.Estatus,
      Candidato_Cirugia: this.record.Candidato_a_cirug_a__c,
      N_Expediente: this.record.Name,
      Paciente_Nombre_Completo: this.record.Nombre_completo__c,


    }

    this.format._getForamtoByCita(_cita.Name).then((resp) => {
 

/*       this.calendarService._getDetallesCita(_cita.Name).subscribe({
        next:(value) => {
            console.log({value});
            
        },
      }) */
      console.log({resp});
      this.local_.savedJsonValues('_json_formatos_cita',resp)   
      this.credentials.saveCitaJson(_cita_)  
      
      setTimeout(() => {
          window.location.reload()
      }, 300);

    }).catch((error) => {
      console.error({error});
      
    })
    
  }

  _onEventLoadRecord(event: boolean) {
    if(event) {
      this.record = null
      const _record = this.credentials.userInfoData
      if(_record && _record.data) {
        this.record =  _record.data.records[0]
      }
    }    
  }


  /**
   * 
   * @param value 
   */
  _onEventEmmiterValue(value: string) {
    console.log({________TEXT___________: this.voice.text});    
    console.log({TEXT___: value});    
  }

  /**
   * 
   */
  _openModal() {
    this._ngbModal = this.modalService.open(this.modalCrearCita, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })
  }

  /**
   * 
   */
  _openModal2() {
    this._ngbModalReceta = this.modalService.open(this.modalCrearReceta, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })
  }

  _onEventCloseModal(val: boolean) {
    if(val) {
      this._ngbModal.close()
    }
  }

  /**
   * 
   * @param value 
   */
  _onEventCloseModal2(value: boolean) {
    if(value) {
      this._ngbModalReceta.close()
    }
  }

  /**
   * 
   * @param option 
   */
  _openModalBtns(option: string) {

    this._modalOption = option

    if(option === 'recetas'){
      this._loadRecetas()
    }else if (option === 'estudios') {
      this._loadFilesImgaes()
    }

    this._ngModalBtns = this.modalService.open(this.modalBtns, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen: true
    }) 
  }

  /**
   * 
   */
  private _loadRecetas() {
    this._recetas = []
    this.receta.GetRecetaByCita(this._cita.Cita_Id).subscribe({
      next:(value: any) => {
        if(value.codigo === 200) {
          const data = value.data
          if(data) {            
            if(data && data.length >= 1 ) {
              const _type = typeof(data)
              if(_type === 'object') {
              this._recetas = data //[...data]
              }
            }            
          }
        }
               
      },
      error(err) {
        console.error({err});
        
      },
    })    
  }

  /**
   * 
   */
  private _loadFilesImgaes() {
    this.format._getListImages(this._cita.Cita_Id,this.record.Name).subscribe({
      next: (response) => {        
        if(response.codigo == 200) {
          console.log({response});
          const data = response.data
          if(data.formato && data.formato.archivos) { 
            const archivos = data.formato.archivos  
            this._LaboratorioFiles = archivos.Laboratorio ? archivos.Laboratorio : []              
          }   
        }        
      },
      error: (error: any) => {
        console.error({error});        
      }
    })
  }


  _onEventLoadRecetas(event: boolean) {
    if(event) {
      this._loadRecetas()
    }
  }


  getbgBadge(total: number): String {
    
    if(total >= 0 && total <= 1) {
      return 'bg-danger'
    }/* else if (total >=5 && total <= 9) {
      return 'bg-brown'
    } */else if (total=== 2 ) {
      return 'bg-warning'
    }else if (total === 3 ){
      return 'bg-yellow'
    }else if (total >=4 && total <= 10 ) {
      return 'bg-success'
    }

    return 'bg-light'
  }

  getLevel (total: number): number {

    if(total >= 0 && total <= 1) {
      return 5
    }else if (total=== 2 ) {
      return 3
    }else if (total === 3 ){
      return 2
    }else if (total >=4 && total <= 10 ) {
      return 1
    }

    return 0
  }


}
