
import { Injectable } from '@angular/core';

import { currentUser } from './../models/auth.models';

const TOKEN_KEY = 'token';
const USER_KEY = 'currentUser';
const USER_ROLE = '_role';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.localStorage.clear();
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem('token') //localStorage.getItem('token');
  }

  public saveUser(user: currentUser): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public saveRole(role: string): void {
    window.localStorage.removeItem(USER_ROLE)
    window.localStorage.setItem(USER_ROLE,role)
  }

  public getRole(): string {
    const _role = localStorage.getItem(USER_ROLE)
    if(_role) {
      return _role
    }
    return 'none'
  }

  public getUser(): currentUser {
    const user = localStorage.getItem(USER_KEY);    
    if (user) {
      return JSON.parse(user);
    }

    return {} as currentUser
  }
}
