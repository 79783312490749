import { Component, OnInit } from '@angular/core';
import { UpdateService } from './services/update.service';
import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'velzon';

  public readonly publicKey: string = 'BE1yv8t8-uuPR3QW_Wgzdzb3VSaB2TlEW-rY1aZoX1z2O0hHkAXs4Oq4hk84sZpFLvpkddXRFefZmiUuC1lWe-Q';

  constructor () {
    this.SwpSusbribeNotification()
   }

   SwpSusbribeNotification (): any {
    
  /*   this.swPush.requestSubscription({
      serverPublicKey: this.publicKey
    }).then(sub => {
      const token = JSON.parse(JSON.stringify(sub))
      console.log({sub});      
    }) */
  }
  
  ngOnInit(): void {
      //this.update.checkForUpdates()
  }
}
