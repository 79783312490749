import { Injectable } from '@angular/core';
import { dataInfo } from 'src/app/layouts/models/user-data';
import { DataResponseResultSearch } from '../models/response-search-result';


const TOGGLE_BAR = '_toggle_bar'
const TAB_ACTIVEID = '_tab_id'
const PACIENTES_FORMATOS = '_pacientes_data'
const SHOW_DAYS_CALENDAR = 'show_days_calendar'

const _JSON_CITAS = 'json_calendar'

const JSON_RESULTS = 'json_results'
const JSON_RESPONSE_RESULT = 'json_response_result'

@Injectable({
  providedIn: 'root'
})
export class SessionPageService {

  private _dataPacientes: any | null = null

  constructor() { 
    const dataPacientes = sessionStorage.getItem(PACIENTES_FORMATOS);

    if(dataPacientes) {
      this._dataPacientes = JSON.parse(dataPacientes)
    }
  }

  get pacientesFormatos(): any | null { 
    return this._dataPacientes
  }

  /**
   * 
   * @param toggle 
   */
  _saveToggle(toggle: boolean) {
    window.sessionStorage.removeItem(TOGGLE_BAR)
    window.sessionStorage.setItem(TOGGLE_BAR, `${toggle}`)
  }

  /**
   * 
   * @returns 
   */
  _getToggle(): boolean {
    const _val = sessionStorage.getItem(TOGGLE_BAR)
    if( _val && _val == 'true'){
      return true
    }
    return false    
  }


  /**
   * Guardamos el TAB Seleccionado
   * @param tabId 
   */
  _setActiveTab(tabId: number) {    
    const _val =  sessionStorage.getItem(TAB_ACTIVEID);
    if(_val){
      sessionStorage.removeItem(TAB_ACTIVEID)
    }
    sessionStorage.setItem(TAB_ACTIVEID,`${tabId}`)
  }

  /**
   * 
   * @returns 
   */
  _getTabId(): number {
    const _tadId = sessionStorage.getItem(TAB_ACTIVEID)
    if(_tadId) {
      return Number(_tadId)
    }
    
    return 1
  }

  /**
   * 
   * @param data 
   */
  _setPacientesData(data?: any) {

    this._dataPacientes = data || null;

    if(data) {
      sessionStorage.setItem(PACIENTES_FORMATOS,JSON.stringify(data));
    }else {
      sessionStorage.removeItem(PACIENTES_FORMATOS)
    }
  }

  /**
   * Guardamos la seleccion del Calendario
   * @param value 
   */
  _setShowDaysCalendar(value: boolean) {
    const _show_days = sessionStorage.getItem(SHOW_DAYS_CALENDAR);

    if(_show_days) {
      sessionStorage.removeItem(SHOW_DAYS_CALENDAR)
    }
    sessionStorage.setItem(SHOW_DAYS_CALENDAR,`${value}`)
  }

  /**
   * Obtenemos el Valor del Calendario
   * @returns 
   */
  _getShowDaysCalendar(): boolean {

    const _show_days = sessionStorage.getItem(SHOW_DAYS_CALENDAR);

    if(_show_days && _show_days == 'true') {
      return true
    }

    return false

  }


  //#region Guardamos los datos del calendario en session
  /**
   * 
   * @param param0 
   */
  _saveJSONtoSession(_body: any[]) {

   /*  if(_key){
      sessionStorage.setItem(`${_key}`, JSON.stringify(_body))
    }else { */
      sessionStorage.setItem(_JSON_CITAS, JSON.stringify(_body))
  //  }
  }

  /**
   * 
   * @param _key 
   */
  _returnJSONSession() {
 /*    if(_key) {
      const _val = sessionStorage.getItem(`${_key}`)
      if(_val){
        return JSON.parse(_val)
      }
      return null
    }else { */
      const _val = sessionStorage.getItem(_JSON_CITAS)
      if(_val){
        return JSON.parse(_val)
      }
      return null
    //}
  }

  /**
   * 
   * @param _body 
   */
  _saveResultSearch(_body: any[]) {
    sessionStorage.setItem(JSON_RESULTS, JSON.stringify(_body) )
  }

  _returnResultSearch() {
      const _result = sessionStorage.getItem(JSON_RESULTS)

      if(_result) {
        return JSON.parse(_result)
      }
      return null
  }


  /**
   * 
   * @param _data 
   */
  _saveSearchResponse(_data:DataResponseResultSearch[]) {

    window.sessionStorage.setItem(JSON_RESPONSE_RESULT, JSON.stringify(_data))

  }

  /**
   * 
   * @returns 
   */
  _getSearchResponse() {
    const _b = window.sessionStorage.getItem(JSON_RESPONSE_RESULT)

    if(_b) {
      return JSON.parse(_b)
    }
    return null
  }

  //#endregion
  
}
