
<ng-container *ngIf="!custom; else CustomTemplate">
    <div class="container-fluid" >
        <div class="">        
            <div class="table-responsive">
                <table class="table align-middle table-nowrap mb-0">
                    <thead class="table-light">
                        <tr>                        
                            <th scope="col">No. Expediente</th>
                            <th scope="col">Paciente</th>                        
                            <th scope="col">Evento</th>
                            <th scope="col">Estatus</th>
                            <th scope="col">Recruso</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of results"> 
                            <td> 
                                <a href="javascript:void(0);" class="link-secondary  text-decoration-underline link-underline-opacity-100-hover"  (click)="openCanvas(item)" > {{item.N_Expediente}} </a> 
                            </td>
                            <td> {{item.Paciente_Nombre_Completo}}  </td>                        
                            <td> {{item.Tipo_Cita}} </td>
                            <td> {{item.Estatus}}      </td>
                            <td>{{item.Recurso}} </td>
                            <td>
                                <button 
                                type="button" 
                                [disabled]="_disabledBtn"
                                (click)="sendWebsocket(item)"
                                class="btn btn-success btn-icon waves-effect waves-light"> 
                                <i class="ri-send-plane-fill align-middle"></i>
                                <span *ngIf="_disabledBtn" class="spinner-grow flex-shrink-0" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </button>
                            </td>
                        </tr>
                    </tbody>              
                </table>
                <!-- end table -->
            </div>
        </div>
    </div>
</ng-container>

<ng-template #CustomTemplate>

    <div class="container-fluid" >
        <div class="mt-4">  
            <ngx-simplebar style="max-height: calc(100vh - 70px)">      
                <div class="table-responsive">
                    <table class="table align-middle table-nowrap mb-0">
                        <thead class="table-light">
                            <tr>                        
                                <th scope="col" *ngFor="let item of _columns" >{{item}}</th>                       
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of results; let i = index">
                                <td *ngFor="let _col of _columns"> 
                                    
                                    <ng-cotainer *ngIf="_col == 'Fecha_Inicio' || _col == 'Fecha_Final' ; else textTemplate">
                                        {{ item[_col] | date:'yyyy-MM-dd hh:mm a' }}
                                    </ng-cotainer>

                                    <ng-template #textTemplate>
                                        <ng-container *ngIf="!_hideColumns.includes(_col)" >
                                            <ng-container *ngIf="_col == 'Name' || _col == 'Name_Paciente'; else _span_template  ">
                                                <a href="javascript:void(0);" class="link-primary link-offset-2 text-decoration-underline" (click)="_onEventClick(item,_col)"> {{ item[_col] }}   </a>
                                            </ng-container>
                                            
                                            <ng-template #_span_template>
                                                <span> {{item[_col]}} </span>
                                            </ng-template>
                                        
                                        </ng-container>                          
                                    </ng-template>   
                                </td>       
                            </tr>
                        </tbody>                
                    </table>
                    <!-- end table -->
                </div>
            </ngx-simplebar>
        </div>
    </div>
</ng-template>



<!-- Canvas -->
<ng-template #canvasDetail let-offcanvas>
    <app-canvas-detalles-citas [_detallesCita]="_detallesCita"
    (eventCanvasEmiiter)="_onEventEmmiter($event)"></app-canvas-detalles-citas>
</ng-template>

<ngx-spinner [name]="_spinner"  [fullScreen]="true" type="ball-scale-multiple" >  </ngx-spinner>
