<div class="card" *ngIf="_formato && _itsReady">
    
    
    <div class="card-body">

        <form [formGroup]="formEnfermeria" class="needs-validation createfile-form" autocomplete="off" novalidate>

            <div class="row g-2 m-4" *ngFor="let item of _controls">

                <ng-container *ngIf="item.type=='frame' || item.type == 'title' ; else otherTypes">

                    <ng-container *ngIf="item.type == 'frame'; else TemplateTitle ">
                        
                        <div class="col-12">
                            <div class="bg-primary">
                                <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                            </div>
                        </div>

                        <div class="row">
                            <div *ngFor="let obj of item.Objects; let i = index" [ngClass]="obj.type != 'textarea' ? 'col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2' :'col-12'" >

                                <div *ngIf="obj.type == 'date' ">
                                    <div class="form-floating">
                                        <input                        
                                        class="form-control flatpickr-input"                                             
                                        [id]="obj.name"     
                                        [type]="obj.type"          
                                        [value]="_getValue(obj.name) != 'N/D'  "                              
                                        [readonly]="obj.readonly" >
                                        <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                    </div>
                                </div>

                                <div *ngIf="obj.type == 'number' ">
                                    <div class="form-floating">
                                        <input 
                                        class="form-control"
                                        type="text"
                                        [name]="obj.name"
                                        [value]="_getValue(obj.name)"
                                        [readonly]="obj.readonly"                                
                                        [required]="obj.required"
                                        >
                                        <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                    </div>
                                </div>
                                
                                <div *ngIf="obj.type == 'time' ">
                                    <div class="form-floating">
                                        <input 
                                        type="text"
                                        [name]="obj.name"
                                        [readonly]="obj.readonly"
                                        [value]="_getValue(obj.name)"
                                        class="form-control">
                                        <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                    </div>
                            
                                </div>

                                <div *ngIf="obj.type =='text' ">
                                    <div class="form-floating">
                                        <input 
                                        class="form-control"
                                        [type]="obj.type"
                                        [name]="obj.name"
                                        [value]="_getValue(obj.name)"
                                        [readonly]="obj.readonly"                                
                                        [required]="obj.required"
                                        >
                                        <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                    </div>
                                </div>   
                                
                                <div *ngIf="obj.type =='textarea' ">
                                    <div class="row">
                                        <div class="col-12">
                                            <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                            <textarea
                                            [name]="obj.name"
                                            class="form-control"
                                            [readonly]="obj.readonly"
                                            [rows]="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </ng-container>

                    <ng-template #TemplateTitle>     
                        <div class="col-12">
                            <div class="bg-primary ">
                                <h4 class="fs-16 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                            </div>

                            <div class="row g-2">

                                <div class="col-md-6 col-lg-6 col-xl-6" *ngFor="let obj of item.Objects">     

                                    <ng-cotainer *ngIf="obj.type == 'subtitle'; else defaultTemplate" >
                                        <div class="bg-dark">
                                            <h4 class="fs-16 text-white bold text-uppercase text-center">{{obj.label}} - {{obj.name}} </h4>     
                                        </div>

                                        <div class="row">
                                            <div class="col-12" *ngFor="let _obj of obj.Objects">
                                                <fieldset class="mt-4" >
                                                    <legend class="fs-14 text-muted"> {{_obj.label}}   </legend>

                                                    <ng-container *ngIf="_obj.type == 'label'; else inputTemplate">
                                                        <div class="row">
                                                            <div *ngFor="let _objItems of _obj.Objects" [ngClass]="_objItems.type == 'checkbox' ? 'col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2' :'col-12'">
        
                                                                <div *ngIf="_objItems.type == 'checkbox' ">
                                                                    <div class="form-check  mb-3">
                                                                        <input
                                                                        class="form-check-input" 
                                                                        type="checkbox"
                                                                        [name]="_objItems.name"
                                                                        [formControlName]="_objItems.name"
                                                                        [checked]="_objItems.value == 'false' ? false : _objItems.value "
                                                                        (change)="_onCheckChange($event, _objItems.name)"
                                                                        [required]="_objItems.required"
                                                                        [readonly]="_objItems.readonly">
                                                                        <label class="form-check-label" for="{{_objItems.name}}">{{_objItems.label}} </label>
                                                                    </div>
                                                                </div>
        
                                                                <div *ngIf="_objItems.type == 'textarea' ">
                                                                    <textarea
                                                                    class="form-control"
                                                                    [name]="_objItems.name"
                                                                    [formControlName]="_objItems"
                                                                    [readonly]="_objItems.readonly"
                                                                    rows="2"
                                                                    ></textarea>
                                                                </div>
        
                                                                <div *ngIf="_objItems.type == 'text' || _objItems.type == 'time'  ">
                                                                    <div class="form-floating">
                                                                        <input 
                                                                        class="form-control"
                                                                        [type]="_objItems.type"
                                                                        [name]="_objItems.name"
                                                                        [readonly]="_objItems.readonly"     
                                                                        [formControlName]="_objItems.name"                          
                                                                        [required]="_objItems.required"
                                                                        >
                                                                        <label class="form-label" for="{{_objItems.name}}" >{{_objItems.label}} </label>
                                                                    </div>
                                                                </div>       
                                                                
                                                        
                                                            
        
                                                            </div>
                                                        </div>
                                                    </ng-container>                                             

                                                    <ng-template #inputTemplate>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div *ngIf="_obj.type == 'text' || _obj.type == 'time'  ">
                                                                    <div class="form-floating">
                                                                        <input 
                                                                        class="form-control"
                                                                        [type]="_obj.type"
                                                                        [name]="_obj.name"
                                                                        [readonly]="_obj.readonly"      
                                                                        [formControlName]="_obj.name"                          
                                                                        [required]="_obj.required"
                                                                        >
                                                                        <label class="form-label" for="{{_obj.name}}" >{{_obj.label}} </label>
                                                                    </div>
                                                                </div>   

                                                                <div *ngIf="_obj.type == 'textarea' ">
                                                                    <textarea
                                                                    class="form-control"
                                                                    [name]="_obj.name"
                                                                    [formControlName]="_obj.name"
                                                                    [readonly]="_obj.readonly"      
                                                                    rows="2"
                                                                    ></textarea>
                                                                </div>

                                                                <div *ngIf="_obj.type == 'checkbox' ">
                                                                    <div class="form-check  mb-3">
                                                                        <input
                                                                        class="form-check-input" 
                                                                        type="checkbox"
                                                                        [name]="_obj.name"
                                                                        [required]="_obj.required"
                                                                        [formControlName]="_obj.name"
                                                                        [checked]="_obj.name == 'false' ? false : _obj.name "
                                                                        (change)="_onCheckChange($event,_obj.name)"
                                                                        [readonly]="_obj.readonly">
                                                                        <label class="form-check-label" for="{{_obj.name}}">{{_obj.label}} </label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </ng-cotainer>

                                    <ng-template #defaultTemplate>
                                        <div class="bg-success">
                                            <h4 class="fs-16 text-white bold text-uppercase text-center">{{obj.label}} </h4>     
                                        </div>
                                        <div class="row">

                                            <ng-container *ngIf="obj.type == 'title'; else _inputTemplate " >

                                                <div class="col-12" *ngFor="let _objs of obj.Objects">

                                                    <fieldset class="mb-4" *ngIf="_objs.type != 'label' ">
                                                        
                                                        <div *ngIf="_objs.type == 'checkbox' ">
                                                            <div class="form-check  mb-3">
                                                                <input
                                                                class="form-check-input" 
                                                                type="checkbox"
                                                                [name]="_objs.name"
                                                                [required]="_objs.required"
                                                                [formControlName]="_objs.name"
                                                                [checked]="_objs.name == 'false' ? false : _objs.name "
                                                                (change)="_onCheckChange($event,_objs.name)"
                                                                [readonly]="_objs.readonly">
                                                                <label class="form-check-label" for="{{_objs.name}}">{{_objs.label}} </label>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="_objs.type == 'textarea' ">
                                                            <textarea
                                                            class="form-control"
                                                            [name]="_objs.name"
                                                            [formControlName]="_objs.name"
                                                            rows="2"
                                                            ></textarea>
                                                        </div>

                                                        <div *ngIf="_objs.type == 'text' || _objs.type == 'time'  ">
                                                            <div class="form-floating">
                                                                <input 
                                                                class="form-control"
                                                                [type]="_objs.type"
                                                                [name]="_objs.name"
                                                                [formControlName]="_objs.name"
                                                                [readonly]="_objs.readonly"                                
                                                                [required]="_objs.required"
                                                                >
                                                                <label class="form-label" for="{{_objs.name}}" >{{_objs.label}} </label>
                                                            </div>
                                                        </div>   
                                                    </fieldset>

                                                    <div *ngIf="_objs.type == 'label' ">

                                                        <fieldset>

                                                            <legend class="fs-14 text-muted"> {{_objs.label}}   </legend>

                                                            <div class="row">                                                       
                                                                <!-- class="" -->
                                                                <div  *ngFor="let _objs_ of _objs.Objects" [ngClass]="_objs_.type== 'textarea' ? 'col-12' : 'col-md-6 col-lg-4 col-xl-4'   " >                                                 
                                                                    <div *ngIf="_objs_.type == 'checkbox' ">
                                                                        <div class="form-check  mb-3">
                                                                            <input
                                                                            class="form-check-input" 
                                                                            type="checkbox"
                                                                            [name]="_objs_.name"
                                                                            [formControlName]="_objs_.name"
                                                                            [checked]="_objs_.name == 'false' ? false : _objs_.name "
                                                                            (change)="_onCheckChange($event,_objs_.name)"
                                                                            [required]="_objs_.required"
                                                                            [readonly]="_objs_.readonly">
                                                                            <label class="form-check-label" for="{{_objs_.name}}">{{_objs_.label}} </label>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="_objs_.type =='textarea' ">
                                                                    <fieldset>
                                                                        <legend class="fs-14 text-muted">{{_objs_.label}} </legend>
                                                                        <textarea
                                                                        class="form-control"
                                                                        [name]="_objs_.name"
                                                                        [formControlName]="_objs_.name"
                                                                        [required]="_objs_.required"
                                                                        [readonly]="_objs_.readonly"
                                                                        rows="2"
                                                                        ></textarea>
                                                                    </fieldset>
                                                                    </div>
                                                                </div>  
                                                            </div>


                                                        </fieldset>
                                                        
                                                
                                                    </div>
                                                </div>
                                                
                                            </ng-container>

                                            <ng-template #_inputTemplate>
                                                <div class="col-12">
                                                    <div *ngIf="obj.type == 'text' || obj.type == 'time' ">
                                                        <div class="form-floating">
                                                            <input 
                                                            class="form-control"
                                                            [type]="obj.type"
                                                            [name]="obj.name"
                                                            [formControlName]="obj.name"
                                                            [readonly]="obj.readonly"                                
                                                            [required]="obj.required">
                                                            <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="obj.type =='checkbox' ">
                                                        <div class="form-check  mb-3">
                                                            <input
                                                            class="form-check-input" 
                                                            type="checkbox"
                                                            [name]="obj.name"
                                                            [required]="obj.required"
                                                            [formControlName]="obj.name"
                                                            [readonly]="obj.readonly">
                                                            <label class="form-check-label" for="{{obj.name}}">{{obj.label}} </label>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="obj.type == 'textarea' ">
                                                        <textarea
                                                        class="form-control"
                                                        [name]="obj.name"
                                                        [formControlName]="obj.name"
                                                        rows="2"
                                                        ></textarea>
                                                    </div>
                                                    <div *ngIf="obj.type == 'title' ">
                                                        <div class="row">
                                                            <div class="col-12" *ngFor="let _obj of obj.Objects">
                                                                <div *ngIf="_obj.type == 'text' || _obj.type == 'time' ">
                                                                    <div class="form-floating">
                                                                        <input 
                                                                        class="form-control"
                                                                        [type]="_obj.type"
                                                                        [name]="_obj.name"
                                                                        [formControlName]="_obj.name"
                                                                        [readonly]="_obj.readonly"                                
                                                                        [required]="_obj.required">
                                                                        <label class="form-label" for="{{_obj.name}}" >{{_obj.label}} </label>
                                                                    </div>
                                                                </div>
                    
                                                                <div *ngIf="_obj.type =='checkbox' ">
                                                                    <div class="form-check  mb-3">
                                                                        <input
                                                                        class="form-check-input" 
                                                                        type="checkbox"
                                                                        [name]="_obj.name"
                                                                        [formControlName]="_obj.name"
                                                                        [required]="_obj.required"
                                                                        [readonly]="_obj.readonly">
                                                                        <label class="form-check-label" for="{{_obj.name}}">{{_obj.label}} </label>
                                                                    </div>
                                                                </div>
                    
                                                                <div *ngIf="_obj.type == 'textarea' ">
                                                                    <textarea
                                                                    class="form-control"
                                                                    [name]="_obj.name"
                                                                    [formControlName]="_obj.name"
                                                                    rows="2"
                                                                    ></textarea>
                                                                </div>
        
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </ng-template>

                                            <div *ngIf="obj.type =='label' ">
                                                <div class="col-12" *ngFor="let _obj2 of obj.Objects">                                                
                                                    <div *ngIf="_obj2.type =='checkbox' ">
                                                        <div class="form-check  mb-3">
                                                            <input
                                                            class="form-check-input" 
                                                            type="checkbox"
                                                            [name]="_obj2.name"
                                                            [required]="_obj2.required"
                                                            [formControlName]="_obj2.name"
                                                            [checked]="_obj2.value == 'false' ? false : _obj2.value "
                                                            (change)="_onCheckChange($event, _obj2.name)"
                                                            [readonly]="_obj2.readonly">
                                                            <label class="form-check-label" for="{{_obj2.name}}">{{_obj2.label}} </label>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="_obj2.type == 'textarea' ">
                                                        <fieldset>
                                                            <legend class="fs-14 text-muted"> {{_obj2.label}}   </legend>
                                                            <textarea
                                                            class="form-control"
                                                            [name]="_obj2.name"
                                                            [formControlName]="_obj2.name"
                                                            rows="2"
                                                            ></textarea>
                                                        </fieldset>
                                                    
                                                    </div>

                                                </div>
                                            </div>

                                        <!--     -->

                                        

                                        
                                        </div>
                                    </ng-template>
                                </div>
                            </div>    
                        </div>  
                    </ng-template> 

                </ng-container>

                <ng-template #otherTypes>
                    <div class="bg-light">
                        <h4 class="fs-4 text-black fw-bold text-uppercase text-center">{{item.label}} </h4>     
                    </div>
                    <div class="row g-2 m-1">
                        <!-- class=""  -->
                        <div *ngFor="let obj of item.Objects" [ngClass]="obj.Objects && obj.Objects.length ? 'col-xxl-4 col-lg-6 col-md-12': 'col-md-12 col-lg-6 col-xl-4' ">
                            <div class="bg-success">
                                <h4 class="fs-4 text-white bold text-uppercase text-center">{{obj.label}} </h4>     
                            </div>

                            <div *ngIf="obj.Objects && obj.Objects.length">
                                <div class="col-12" *ngFor="let _obj of obj.Objects">
                                    <div class="row g-2 m-1">
                                        <div class="col-12">
                                            <div *ngIf="_obj.type == 'text'" >
                                                <div class="form-floating">
                                                    <input 
                                                    class="form-control"
                                                    [type]="_obj.type"
                                                    [name]="_obj.name"
                                                    [readonly]="_obj.readonly"           
                                                    [formControlName]="_obj.name"                     
                                                    [required]="_obj.required"
                                                    >
                                                    <label class="form-label" for="{{_obj.name}}" >{{_obj.label}} </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'text' || obj.type == 'time' " >
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [readonly]="obj.readonly"           
                                    [formControlName]="obj.name"                     
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'radio'">          
                                                               
                                <div class="form-check form-check-right form-radio-primary mb-3">
                                    <input 
                                    class="form-check-input" 
                                    type="radio" 
                                    [name]="item.name"         
                                    [value]="obj.name"                                        
                                    id="{{obj.name}}" 
                                    [formControlName]="item.name"
                                    >
                                    <label class="form-check-label" for="{{obj.name}}">
                                    {{obj.label}}
                                    </label>
                                </div>  
                            </div>
                        </div>
                    </div>
                </ng-template>

            </div>

        </form>
    </div>

</div>
