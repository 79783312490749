import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, delay, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { Images } from 'src/app/shared/models/list-images';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormatsService } from 'src/app/shared/services/formats.service';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import * as moment from 'moment';

@Component({
  selector: 'app-consentimiento-procedimiento-diagnostico',
  templateUrl: './consentimiento-procedimiento-diagnostico.component.html',
  styleUrls: ['./consentimiento-procedimiento-diagnostico.component.scss']
})
export class ConsentimientoProcedimientoDiagnosticoComponent implements OnInit, OnDestroy {

  @Input()
  id: string
  
  @Input()
  _formatoData: any
  
  _controls: any[] = []
  _formato: any = {}
  record: any;

  _Cita: Cita;
  _label: string

  _itsReady: boolean

  _formControl: FormGroup = this.formBuilder.group({})
  $interval!: Subscription;
  _interval: number = 0;

  _firma: Images | null

  _firma_paciente_nombre_firma_consentimiento_informado_procedimiento_diagnostico: Images | null
  _firma_medico_nombre_firma_consentimiento_informado_procedimiento_diagnostico: Images | null
  _firma_testigo_1_nombre_firma_consentimiento_informado_procedimiento_diagnostico: Images | null
  _firma_testigo_2_nombre_firma_consentimiento_informado_procedimiento_diagnostico: Images | null

  _today = new Date()


  constructor (
    private formBuilder: FormBuilder,
    private formatService: FormatsService,
    private credentials: CredentialsService,
    private toastr: ToastrService,
    private alert: AlertsService
  ) {
    flatpickr.localize(Spanish)

    this.record = this.credentials.userInfoData.data.records[0]
    const cita = this.credentials.getCitaJson()
    const dataUser = this.credentials._getdataUser()
    
    if(dataUser) {
      /**
       * // TODO
       */
      //this.loadImagesByUser(dataUser)
    }


    if(cita) {
      this._Cita = cita
    }
  }

  ngOnInit(): void {

    if(this.$interval){
      this.$interval.unsubscribe()
    }

    if(this._formatoData) {
      this._formato = this._formatoData

      this._label = this._formato.label;
      this._controls = this._formato.form.controls;

      this._itsReady = true
      this.initForm();
      this.loadImages() 
    }    
  }

  ngOnDestroy(): void {
    if(this.$interval) {
      this.$interval.unsubscribe()
    }

    this._savePrevDestroy()
  }

  /**
   * 
   * @param user 
   */
  private loadImagesByUser (user: any) {
    this.formatService._getImageProfile(user.usuario).subscribe({
      next:(response) => {
        
        if(response.codigo === 200) {
          const data = response.data
          if(data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos  
            if(archivos) {
              this._firma = archivos.Firma[0]
            }
        
          }
          

         
        }          
      },error(err) {
        console.error({err});          
      },
    })
  }

  private loadImages () {
    this.formatService._getListImages(this._Cita.Cita_Id, this.record.Name).subscribe({
      next:(response) => {

        if(response.codigo === 200) {
          const data = response.data

          if(data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos  

            this._firma_paciente_nombre_firma_consentimiento_informado_procedimiento_diagnostico = archivos.firma_paciente_nombre_firma_consentimiento_informado_procedimiento_diagnostico ? archivos.firma_paciente_nombre_firma_consentimiento_informado_procedimiento_diagnostico[0]: null
            this._firma_medico_nombre_firma_consentimiento_informado_procedimiento_diagnostico = archivos.firma_medico_nombre_firma_consentimiento_informado_procedimiento_diagnostico ? archivos.firma_medico_nombre_firma_consentimiento_informado_procedimiento_diagnostico[0]: null
            this._firma_testigo_1_nombre_firma_consentimiento_informado_procedimiento_diagnostico = archivos.firma_testigo_1_nombre_firma_consentimiento_informado_procedimiento_diagnostico ? archivos.firma_testigo_1_nombre_firma_consentimiento_informado_procedimiento_diagnostico[0]: null
            this._firma_testigo_2_nombre_firma_consentimiento_informado_procedimiento_diagnostico = archivos.firma_testigo_2_nombre_firma_consentimiento_informado_procedimiento_diagnostico ? archivos.firma_testigo_2_nombre_firma_consentimiento_informado_procedimiento_diagnostico[0]: null
            
            
            



          }
        }
        
      },error:(err) => {

      },
    })
  }

  private _savePrevDestroy(): void {
    let _formValues = this._formControl.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  private initForm () {

    _.map(this._controls, (a) => {

      _.map(a.Objects, (b) => {
        let _value = b.value

        if(b.name === 'fecha_consentimiento_informado_procedimiento_diagnostico' && _value === '' ) {
          _value =  _value = this._today
        }

        if(b.name === 'hora_consentimiento_informado_procedimiento_diagnostico' && _value === null ) {
          _value = moment().format('H:mm')        
        }

        if(b.name === 'sexo_consentimiento_informado_procedimiento_diagnostico' ) {
          let _find = _.find(b.Objects, { label: this.record.P05_Sexo__c })
          if(_find) {
            _value = _find.name
          }
        }

        if(b.name === 'doy_mi_consentimiento_informado_procedimiento_diagnostico' && _value === null  ){          
          _value = 'si_acepto_doy_mi_consentimiento_informado_procedimiento_diagnostico' 
        }
        
        if(b.name === 'acepto_doctor_consentimiento_informado_procedimiento_diagnostico' && b.value === null ) {
          _value = this._Cita.Doctor
          b.readonly = true
        }

        if(b.name === 'comprendo_anterior_consentimiento_informado_procedimiento_diagnostico' && _value === null ) {
          _value = 'si_acepto_comprendo_anterior_consentimiento_informado_procedimiento_diagnostico'
        }

        if(b.name === 'completo_nombre_firma_consentimiento_informado_procedimiento_diagnostico' && b.value === '') {
          _value = this.record.Nombre_completo__c    
        }

        if(b.name === 'completo_medico_nombre_firma_consentimiento_informado_procedimiento_diagnostico' && b.value === '') {
          _value = this._Cita.Doctor
        }

        if(b.name === 'art_80_reglamento_general_consentimiento_informado_procedimiento_diagnostico' && _value === null ) {
          _value = 'si_acepto_art_80_reglamento_general_consentimiento_informado_procedimiento_diagnostico'
        }

        if(b.name === 'art_81_reglamento_general_consentimiento_informado_procedimiento_diagnostico' && _value === null ) {
          _value = 'si_acepto_art_81_reglamento_general_consentimiento_informado_procedimiento_diagnostico'
        }

        if(b.name === 'art_82_reglamento_general_consentimiento_informado_procedimiento_diagnostico' && _value === null ){
          _value = 'si_acepto_art_82_reglamento_general_consentimiento_informado_procedimiento_diagnostico'
        }

        if(b.name === 'art_83_reglamento_general_consentimiento_informado_procedimiento_diagnostico' && _value === null ){
          _value = 'si_acepto_art_83_reglamento_general_consentimiento_informado_procedimiento_diagnostico'
        }
        
        _.map(b.Objects, (c) => {

          let _value = c.value

          if(c.name === 'nombre_completo_consentimiento_informado_procedimiento_diagnostico' ) {
            _value = `${this.record.P00_Nombre__c} ${this.record.P05_Apellidos__c} `
          }         

          if(c.name === 'beneficio_consentimiento_informado_procedimiento_diagnostico' && _value === null  ) {   
            _value = 'si_acepto_beneficio_consentimiento_informado_procedimiento_diagnostico'
          }

          if(c.name === 'riesgo_procedimiento_consentimiento_informado_procedimiento_diagnostico'  && _value === null ) {
            _value = 'si_acepto_riesgo_procedimiento_consentimiento_informado_procedimiento_diagnostico'
          }

          if(c.name === 'fui_informado_consentimiento_informado_procedimiento_diagnostico' && _value === null ) {
            _value = 'si_acepto_fui_informado_consentimiento_informado_procedimiento_diagnostico'
          }
          this._formControl.addControl(c.name, this.formBuilder.control(_value))
        })
        
        //
        this._formControl.addControl(b.name, this.formBuilder.control(_value))
      })      
    })
    
    this.createInterval()

  }

  private createInterval () {
    const myObservable = interval(1000);
    this.$interval = myObservable.pipe(
      delay(500),

    ).subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();        
        this._newSaveFormat()            
      }           

      this._interval = integer            
    })
  }

  private _newSaveFormat() {
    
    let _formValues = this._formControl.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    console.log({_bodyForm});
    this._saveFormatValues(_bodyForm, true)   

  }

  private _saveFormatValues (_jsonObject: any, createInterval: boolean = false): void  {
    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información, correctamente','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        this.$interval.unsubscribe()

        if(createInterval) {     
          this.createInterval()
        }

      })
    }).catch((error) => {
      console.error({error});
      
    })

  }

  _onSaveSing(event: any, main_label: string) {  

    if(event) {
      this.alert._showLoader('Cargando, espere...')

      this.formatService.UploadFirmas(this._Cita.Cita_Id,this.record.Name, main_label, event).then((resp) => {  
        this.alert._hideLoader()
        this.loadImages()
        this.toastr.success('Firma, cargada exitosamente','Exito!!',{
          timeOut: 3200
        })
      }).catch((error) => {
        console.error({_ERROR_LOAD_FIRMA: error });
        
        this.alert._hideLoader()
        this.toastr.error('Error al cargar la firma','Error!!',{
          timeOut: 3200
        })
      })
    }    
  }

}
