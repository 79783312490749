<div class="row">
    <div class="col-xxl-6">        
        <div class="card">
            <div class="card-body">               
                <!-- Nav tabs -->  
                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-pills nav-justified card-footer-tabs fs-17">
                  <li *ngFor="let item of formControls; let i = index" [ngbNavItem]="i + 1" class="nav-item waves-effect waves-light" >
                      <a ngbNavLink (click)="_setObjects(item)">
                          <span class="text-uppercase fs-14">{{item.label ? item.label : item.name }}</span>
                      </a>
                      <ng-template ngbNavContent>
                          <ng-template [ngTemplateOutlet]="TabContent" ></ng-template>
                      </ng-template>
                  </li> 
                  <li [ngbNavItem]="6">
                    <a ngbNavLink>
                      Escala Aldrete
                    </a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="escala_aldrete" ></ng-template>
                    </ng-template>
                </li>           
                </ul>
                <!-- Tab panes -->
                <div class="tab-content  text-muted">
                  <div [ngbNavOutlet]="nav"></div>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!--end col-->
</div>

<!-- Template -->
<ng-template #TabContent>
    
    <ng-container *ngIf="_Objects ; else emptyTemplate">
        <div class="mt-4 p-2">
            <ngx-simplebar class="sidebar-menu-scroll" style="max-height: calc(100vh - 70px)">
                <div class="card">
                    <div class="card-body">
                        <h4 class="fs-6 text-primary bold text-uppercase text-center">{{_Objects.label ? _Objects.label : _Objects.name }} </h4>             

                        <div *ngIf="_Objects.name =='grafica_registro_anestesia' ">
                        <button 
                        type="button"
                        class="btn btn-outline-primary"      
                        (click)="collapse.toggle()"
                        [attr.aria-expanded]="!isCollapsed"     
                        aria-controls="collapseExample"
                        > Ver Historia grafica
                        </button>
                            <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                                <ng-template [ngTemplateOutlet]="graficas"></ng-template>
                            </div>  
                        </div>

                        <form [formGroup]="formAnestesiologo" class="needs-validation createfile-form" (ngSubmit)="_onSubmit()" autocomplete="off" novalidate>   
                            <ng-template [ngTemplateOutlet]="btnTemplate"></ng-template>
                            <div class="row g-2 m-2">
                                <div *ngFor="let _object of _Objects.Objects" [ngClass]="_object.type == 'text-area' || _object.Objects  ? 'col-12' : 'col-6' ">

                                    <ng-container *ngIf="_object.Objects; else _inputTemplate"> 

                                            <legend class="fs-14 text-muted"> {{_object.label}}   </legend>
                                            <div class="row g-2" >
                                                <div *ngFor="let _objects_ of _object.Objects; let i = index;" 
                                                [ngClass]="_objects_.type == 'text-area'   ? 'col-12' :  _object.name == 'riesgo_anestesia_num' ? 'col-4' : 'col-12' ">

                                                    <ng-container *ngIf="_objects_.type == 'text' && _objects_.label; else _templateInputs ">
                                                        <!-- ; else horaTemplate"    -->
                                                        <ng-container *ngIf="_objects_.label!= 'HORA'">
                                                            <div *ngIf="_objects_.type == 'text' ">
                                                                <div class="form-floating">
                                                                    <input
                                                                    [type]="_objects_.type"                                                
                                                                    [formControlName]="_objects_.name"
                                                                    class="form-control"
                                                                    [name]="_objects_.name">
                                                                    <label class="form-label bold text-uppercase"> {{_objects_.label}} </label>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    <!--     <ng-template #horaTemplate>
                                                            <div class="form-floating">
                                                                <input 
                                                                [type]="_objects_.type"                                                           
                                                                readonly
                                                                disabled                                                                
                                                                class="form-control text-center text_hora"
                                                                [name]="_objects_.name"
                                                                [value]="_hour_caputred | date:'H:mm'"    
                                                                >
                                                            </div>
                                                        
                                                        </ng-template>  --> 
                                                    </ng-container>

                                                    <ng-template #_templateInputs>
                                                        <div class="mb-4" *ngIf="_objects_.type =='text-area' ">
                                                            <label class="form-label">{{_objects_.label}}</label>
                                                            <textarea 
                                                            class="form-control"
                                                            [name]="_objects_.name"
                                                            [required]="_objects_.required"
                                                            [formControlName]="_objects_.name"
                                                            [readonly]="_objects_.readonly"
                                                            [rows]="4">
                                                            </textarea>
                                                        </div>  
    
                                                        <div *ngIf="_objects_.type == 'radio'">                                              
                                                            <div class="form-check form-check-right form-radio-primary mb-3">
                                                                <input 
                                                                class="form-check-input" 
                                                                type="radio" 
                                                                [name]="_object.name"         
                                                                [value]="_objects_.name"                                        
                                                                id="{{_objects_.name}}" 
                                                                [formControlName]="_object.name"
                                                                >
                                                                <label class="form-check-label" for="{{_objects_.name}}">
                                                                {{_objects_.label}}
                                                                </label>
                                                            </div>
                                                            
                                                        
                                                        </div>

                                                        <div *ngIf="_objects_.type == 'text' ">
                                                            <div class="form-floating">
                                                                <input
                                                                [type]="_objects_.type"                                                
                                                                [formControlName]="_objects_.name"
                                                                class="form-control"
                                                                [name]="_objects_.name">
                                                                <label class="form-label bold text-uppercase"> {{_objects_.label}} </label>
                                                            </div>
                                                        </div>

                                                  
                                                    </ng-template>

                                                </div>  
                                            </div>
                                    </ng-container>

                                    <ng-template #_inputTemplate>
                                        <div class="mb-4" *ngIf="_object.type =='text-area' ">
                                            <label class="form-label">{{_object.label}}</label>
                                            <textarea 
                                            class="form-control"
                                            [name]="_object.name"
                                            [required]="_object.required"
                                            [formControlName]="_object.name"
                                            [readonly]="_object.readonly"
                                            [rows]="4">
                                            </textarea>
                                        </div>

                                        <div *ngIf="_object.type == 'text' ">
                                            <div class="form-floating">
                                                <input
                                                [type]="_object.type"                                                
                                                [formControlName]="_object.name"
                                                class="form-control"
                                                [name]="_object.name">
                                                <label class="form-label bold text-uppercase"> {{_object.label}} </label>
                                            </div>
                                        </div>

                                    </ng-template>
                                </div>
                            </div>
                            <ng-template [ngTemplateOutlet]="btnTemplate"></ng-template>
                        </form>
                    </div>
                </div>
            </ngx-simplebar>
        </div>
    </ng-container>

    <ng-template #emptyTemplate>
       <small class="text-muted">No hay nada que mostrar</small>
    </ng-template>   
    
</ng-template>

<ng-template #btnTemplate>
    <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-success"> Guardar </button> 
    </div>
</ng-template>

<ng-template #graficas>
    <div class="card">
        <div class="card-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title mb-0">Basic Line Chart</h4>
                </div><!-- end card header -->
      
                <div class="card-body">
                  <apx-chart [series]="basicLineChart.series" [chart]="basicLineChart.chart"
                  [markers]="basicLineChart.markers" [dataLabels]="basicLineChart.dataLabels"
                  [stroke]="basicLineChart.stroke" [colors]="basicLineChart.colors" [title]="basicLineChart.title"
                  [xaxis]="basicLineChart.xaxis" dir="ltr"></apx-chart>
                </div><!-- end card-body -->
    
            </div><!-- end card -->
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title mb-0">Line with Data Labels</h4>
                </div><!-- end card header -->
      
                <div class="card-body">
                  <apx-chart [series]="lineWithDataLabelsChart.series" [chart]="lineWithDataLabelsChart.chart"
                  [xaxis]="lineWithDataLabelsChart.xaxis" [stroke]="lineWithDataLabelsChart.stroke"
                  [colors]="lineWithDataLabelsChart.colors" [dataLabels]="lineWithDataLabelsChart.dataLabels"
                  [legend]="lineWithDataLabelsChart.legend" [markers]="lineWithDataLabelsChart.markers"
                  [grid]="lineWithDataLabelsChart.grid" [yaxis]="lineWithDataLabelsChart.yaxis"
                  [title]="lineWithDataLabelsChart.title" dir="ltr"></apx-chart>
                </div><!-- end card-body -->
            </div><!-- end card -->
        </div>
    </div>
</ng-template>

<ng-template #escala_aldrete>
    <div class="row g-2 mt-4">
        <div class="col-12 border border-2">
            <div class="d-flex justify-content-center">
                <span class="text-uppercase fs-3 fw-bold text-muted m-2"> Escala de aldrete </span>
            </div>                      
        </div>
    </div>
    <div class="row p-2" *ngFor="let item of  ['actividad muscular','respiracion','circulacion','estado de conciencia','colaracion','alta a su piso* medico responsalbe']">
        <div class="col-4">
            <div class="row mt-2 border border-2" >
                <div class="col-12"> 
                    <div class="d-flex justify-content-center">
                        <span class="fw-bold fs-12 text-uppercase">{{item}}</span>
                    </div>    
                </div>

                <div class="col-12">
                    <div class="row mt-2" *ngFor="let _item of ['Movimientos voluntarios (4 Extremidades) = 2', 'tension arterial + - 50% de cifras control = 0', 'respiraciones amplias y capaz de toser = 1 ']; let isLast=last " >
                        <div class="col-8" >
                            <span class="fs-12">{{_item}} </span>
                        </div>
                        <div class="col-4" >
                            <div class="float-end">
                                <input type="text" class="form-control form-control-sm" placeholder="0">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8 border border-2">

        </div>
    </div>
</ng-template>