import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutsModule} from "./layouts/layouts.module";
import { PagesModule } from "./pages/pages.module";

import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap'

// Auth
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Language
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Pages404Component } from './errorPages/pages404/pages404.component';

//Ngx-Toastr
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store'
import { calendarReducer } from './shared/store/reducers/calendar.reducers';
import { EffectsModule } from '@ngrx/effects';
import { CalendarEffects } from './shared/store/effects/calendar.effects';

import { NgxSpinnerModule } from 'ngx-spinner'

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { LOCAL_STORAGE_ENGINE, NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { environment } from 'src/environments/environment';
import { CitasState } from './store/citas.state';


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    Pages404Component
  ],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    LayoutsModule,
    PagesModule,
    NgbToastModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
 /*    StoreModule.forRoot({ calendar: calendarReducer }, {}),
    EffectsModule.forRoot([ CalendarEffects ] ),
    StoreDevtoolsModule.instrument({maxAge:24, logOnly: !isDevMode}),
    NgxsModule.forRoot([CitasState],
      { developmentMode: !environment.production }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsStoragePluginModule.forRoot(({key: 'citas'}),) */
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
