import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, map, share, timer } from 'rxjs';
import { FormatsService } from 'src/app/shared/services/formats.service';
import { LocalSessionService } from '../../services/local-session.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CalendarService } from '../../calendar/services/calendar.service';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { FilterCalendar } from '../../models/filter-calendar';
import { InitWebSocketService } from 'src/app/services/init-web-socket.service';
import { WsAnestesiologo } from '../../models/anestesiologo';

const _ANESTESIOLOGO_VAL = '_json_anestesiologo'

interface _json_anestesiologo_items {
  hora: string

}

@Component({
  selector: 'app-index-antesiologo',
  templateUrl: './index-antesiologo.component.html',
  styleUrls: ['./index-antesiologo.component.scss']
})
export class IndexAntesiologoComponent implements OnInit, OnDestroy {


  public innerWidth: number;
  breadCrumbItems!: Array<{}>;

  basicLineChart: any;
  lineWithDataLabelsChart: any

  formControls: any[]

  _local_anestesiologo: _json_anestesiologo_items //| null
  public formAnestesiologo: FormGroup = this.formBuilder.group({})

  private readonly _formato_anestesia = 'registro_anestesia'

  eventSubjectSpinner: Subject<boolean> = new Subject<boolean>();

  
  _formato:       any = {}
  _controls:      any[] = []
  _formControls:  any[] = []

  _spinner_anestesia = 'anestesia_spinner'

  _show_template: boolean;

  /**
     * // TODO
     * Colocar ---- CITA DINAMICA !!
     */
  Cita_Id = "E-152007"  //'E-151822'
  Paciente_Id= "a0B8W00000Pn9N7UAJ"

  _filterCalendar: FilterCalendar = new FilterCalendar()

  private readonly _now = moment()//.utcOffset(0)  

  _time_now = this._now.format('HH:mm')

  _form_name: string

  _spinner_anestesiologo = '_spinner_anestesiologo'

  _grafica_anestesiologo = 'grafica_registro_anestesia'
  _Objects: any

  _ObjectsDates: any[] = []

  time = new Date();
  rxTime = new Date();
  rxTime_: any

  intervalId: any;
  subscription: Subscription;

  _hour_caputred: any

  _WsAnestesiolgo: WsAnestesiologo;
  _itenName: string


  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView()
  }
  constructor (
    private formatoService: FormatsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private _local: LocalSessionService,
    private calendar: CalendarService,
    private credentials: CredentialsService,
    private initWs: InitWebSocketService
  ) {}
  
  ngOnInit(): void {  

    this.initWS()

  //  this._Objects = this.formControls[0]

    this.initTimer()
    this.listTimer()       

    //console.log({_hour_caputred: this._hour_caputred});
    if(!this._hour_caputred) {

      const _first = moment().startOf('hour')     
      this._hour_caputred = _first      
    }
                    
    //console.log({dates, _hour_caputred: this._hour_caputred})    
    
    const _id_salesForce =  this.credentials.credentials?.id_salesforce

    if(_id_salesForce) {
      //console.log({_id_salesForce});
      
      this._filterCalendar.doctor = _id_salesForce
      this._filterCalendar.inicio = ''
      this._filterCalendar.final  = ''
      //this._filterCalendar.
    }

    this.loadCitas()
    
    let _hora_anesteiolgo = this._local.getJsonValues(_ANESTESIOLOGO_VAL)

    if(_hora_anesteiolgo) {
      this._local_anestesiologo = _hora_anesteiolgo
     // console.log('AAAAAAAAAAAAAAAAAAAAAAAAA');
      this._time_now = _hora_anesteiolgo.hora
      
    }else {

      this._local_anestesiologo = {
        hora: this._hour_caputred
      }

      this._local.savedJsonValues(_ANESTESIOLOGO_VAL,this._local_anestesiologo)
    }


    this.breadCrumbItems = [
      { 
        label: 'Dashboards',
        active: false

      },
      { 
        label: 'JOB Dashboard', 
        active: false 
      }
    ]

    this._show_template = false
    this.loadForamtoAnestesia()
    this.checkView()
    this._basicLineChart('["--vz-primary"]');
    this._lineWithDataLabelsChart('["--vz-primary", "--vz-info"]');

  }

  /**
   * 
   */
  initWS() : void {
    this.initWs.connectWsAnestesiologo()

  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe()
    }
  }

  get isMobile(): boolean {
    return this.innerWidth <= 1024;
  }
  
  private checkView(): void {    
    this.innerWidth = window.innerWidth;
  }
  
  /**
   * 
   */
  private listTimer() {
    let startDate = moment().startOf('h');

    let dates = [];
    let hourDivider = 2;
    let t = hourDivider * 24;

    while(t--) {      
      startDate.add(15, 'minute');
      let _added_minutes = moment(startDate)//.format('HH:mm')
      //console.log({_START_DATA: startDate,  });

      if(_added_minutes >= this._now ) {
        //console.log('AAAAAAAAAAA');
        dates.push(startDate.format('hh:mm'));
        
      }else{
     //   console.log({_added_minutes});
          this._hour_caputred = _added_minutes
      } 
    }   
  }

  private initTimer() {
      // Using Basic Interval
  /*     this.intervalId = setInterval(() => {
        this.time = new Date();
      }, 1000); */
  
      // Using RxJS Timer
      this.subscription = timer(0, 1000)
        .pipe(
          map(() => new Date()),
          share()
        )
        .subscribe({
          next:(resp) => {
            this.rxTime = resp

           // let _time = moment(resp).format('hh:mm a')

            //console.log({_time});
            

            //console.log({rxTime: this.time});
            

          }
        })
  }

  /**
   * Cargamos las Citas del anestesiologo
   */
  private loadCitas() {
   // console.log({FILTER: this._filterCalendar});
    
    this.calendar._getFilterCalendar(this._filterCalendar).subscribe({
      next:(response) => {
       // console.log({_RESPONSE_: response});
        
      },
      error:(error)=> {
       // console.error({_ERROR: error});
        
      }
    })

  }

  /**
   * 
   */
  private loadForamtoAnestesia() {

    this.formatoService._getFormat(this._formato_anestesia,this.Cita_Id).subscribe({
      next: (response) => {

        if(response.codigo == 200) {
          this._formato = response.data.formato;  
          
          if(this._formato.form) {
            console.log({FORMATO: this._formato});
            
            this._Objects = this._formato.form.controls[0]

            this._controls = this._formato.form.controls

            this.initForm(this._Objects)

         //   console.log({_Objects: this._Objects});
            

          //  console.log({CONTROLS: this._controls});

     

           
            this._show_template = true
          }


        }

      //  console.log({response});
        

      },
      error: (err) => {
        
      },
    })

  }

  private initForm(Objects: any) {

    if(Objects.type == 'subtitle' ) {
      console.log({AAAAAA_: Objects});

      this.formAnestesiologo.addControl(
        Objects.name, this.formBuilder.control(Objects.name)
      )
      
    }
/* 
    _.map(Objects, (a) => {
      console.log({______AAAAAAA__: a});
      
    }) */
    
    _.map(Objects.Objects ,(a) => {    

      
      
      
      /**
       * 
       */
      _.map(a.Objects, (b) => {       
        this.formAnestesiologo.addControl(
          b.name, this.formBuilder.control(b.value)
        )        
      })    

      /**
       * 
       */
      this.formAnestesiologo.addControl(
        a.name, this.formBuilder.control(a.value)
      )
      
    })
    console.log({ANESTESIOLOGO: this.formAnestesiologo});
  }

  get f() {
    return this.formAnestesiologo.controls;
  }

  // Chart Colors Set
  private getChartColorsArray(colors:any) {
    colors = JSON.parse(colors);
    return colors.map(function (value:any) {
        let newValue = value.replace(" ", "");
        if (newValue.indexOf(",") === -1) {
          let color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
              if (color) {
              color = color.replace(" ", "");
              return color;
              }
              else return newValue;;
          } else {
              let val = value.split(',');
              if (val.length == 2) {
                  let rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                  rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                  return rgbaColor;
              } else {
                  return newValue;
              }
          }
      });
  }

  /**
  * Basic Line Chart
  */
  private _basicLineChart(colors:any) {
      colors = this.getChartColorsArray(colors);
      this.basicLineChart  = {
        series: [{
          name: "STOCK ABC",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        colors: colors,
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            fontWeight: 500,
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
      };
  }


  private _lineWithDataLabelsChart(colors:any) {
      colors = this.getChartColorsArray(colors);
      this.lineWithDataLabelsChart = {
        chart: {
          height: 380,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: colors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [3, 3],
          curve: 'straight'
        },
        series: [{
          name: "High - 2018",
          data: [26, 24, 32, 36, 33, 31, 33]
        },
        {
          name: "Low - 2018",
          data: [14, 11, 16, 12, 17, 13, 12]
        }
        ],
        title: {
          text: 'Average High & Low Temperature',
          align: 'left',
          style: {
            fontWeight: 500,
          },
        },
        grid: {
          row: {
            colors: ['transparent', 'transparent'],
            opacity: 0.2
          },
          borderColor: '#f1f1f1'
        },
        markers: {
          size: 6
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          title: {
            text: 'Month'
          }
        },
        yaxis: {
          title: {
            text: 'Temperature'
          },
          min: 5,
          max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      };
  }

  /**
   * 
   * @param _body_json 
   */
  _onEventEmmiter(_body_json: any) {

    //console.log({_body_json});

    this._formato.form.controls = _body_json.form

    let _bodyForm = {
      Cita_Id: 'E-151822', //this._Cita.Cita_Id,
      label: 'Registro_Anestesia', //this.formControls.label,
      form: this._formato.form //_formato.form
    }

    //console.log({_bodyForm});
    

    this.formatoService._postFormato(_bodyForm).then((resp) => {
      this.toastr.success('Se ha guardado la información','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(()=> {
        this.eventSubjectSpinner.next(true)
      })
    }).catch((error) => {
      this.eventSubjectSpinner.next(true)
      this.toastr.error('Ocurrio un error al tratar de guardar, los datos de la anestesia','Error', {
        timeOut: 3200
      })
    })


   // this.eventSubjectSpinner.next(true)
    

  }

  /**
   * 
   */
  _onSubmit() {

    

    if(this._itenName === 'grafica_registro_anestesia') {

      const _val = this.formAnestesiologo.value
      //TODO
      /**
       * //hora_registro_anestesia_sato2 --> NO SALE
       */
    console.log({_VALIE_: _val});
    
      
      this._WsAnestesiolgo = {
        client: '0001',
        cita_id: this.Cita_Id,
        pacient: this.Paciente_Id,
        O2: _val.nombre_registro_anestesia_o2,
        N2O: _val.nombre_registro_anestesia_n2o,
        SEVOFLURANO: _val.nombre_registro_anestesia_sevoflurano,
        DESFLUORANO: _val.nombre_registro_anestesia_desfluorano,
        CO2EXP: _val.nombre_registro_anestesia_co2exp,
        TEMPERATURA: _val.nombre_registro_anestesia_temperatura,
        DIURESIS: _val.nombre_registro_anestesia_diuresis,
        PRESION_ARTERIAL_MEDIA: _val.nombre_registro_anestesia_presion_arterial_media,
        P_V_C: _val.nombre_registro_anestesia_pvc,
        PRESION_ARTERIAL_PULMONAR: _val.nombre_registro_anestesia_presion_arterial_pulmonar,
        PRESION_CANA: _val.nombre_registro_anestesia_presion_cuna,
        GASTO_CARDIACO: _val.nombre_registro_anestesia_gasto_cardiaco,
        PULSO: _val.nombre_registro_anestesia_pulso,
        PRESION_ARTERIAL: _val.nombre_registro_anestesia_presion_arterial,
        INICIO_ANESTESIA: _val.nombre_registro_anestesia_inicio_anestesia,
        FINAL_INDUCCION: _val.nombre_registro_anestesia_final_induccion,
        INICIO_CIRUGIA: _val.nombre_registro_anestesia_inicio_cirugia,
        FINAL_CIRUGIA: _val.nombre_registro_anestesia_final_cirugia,
        FINAL_ANESTESIA: _val.nombre_registro_anestesia_final_anestesia,
        RESPIRACION: _val.nombre_registro_anestesia_respiracion,
        ESPONTANEA: _val.nombre_registro_anestesia_espontanea,
        ASISTIDA: _val.nombre_registro_anestesia_asistida,
        CONTROLADA: _val.nombre_registro_anestesia_controlada
      }

      //console.log({_VALLL: this._WsAnestesiolgo});

    }  

  }

  /**
   * 
   */
 async _setObjects (item: any) {
    console.log({_ITEMS_: item});
    this._itenName = item.name
    this._Objects = item
    await this.removeControls()
    
    this.initForm(this._Objects);
  }

  private async removeControls (): Promise<void> {
    while(Object.keys(this.f).length){
      const toRemove = Object.keys(this.formAnestesiologo.controls)[0];
      this.formAnestesiologo.removeControl(toRemove)
    }    
   // console.log({ANESTESIOLOGO_CLEAR_FORM: this.formAnestesiologo});

  }

}
