import { Pipe, PipeTransform } from '@angular/core';
import { Doctor } from 'src/app/pages/models/doctores';

@Pipe({
  name: 'filterDoctorAyudante'
})
export class FilterDoctorAyudantePipe implements PipeTransform {

  transform(Collection: Doctor[],searchText: string ) {
    if (typeof searchText === typeof undefined) return Collection;

    return Collection.filter((item) => item.name.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()) )
    
    
  }

}
