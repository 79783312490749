import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CalendarService } from '../../calendar/services/calendar.service';
import { PatientsService } from '../../calendar/services/patients.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { Paciente } from '../../models/response-search-result';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-listado-pacientes',
  templateUrl: './listado-pacientes.component.html',
  styleUrls: ['./listado-pacientes.component.scss']
})
export class ListadoPacientesComponent {

  @ViewChild('modalPatient') modalPatient!: TemplateRef<any>;

  _listadoPacientes: any[] = []

  _ngModal: NgbModalRef;

  _paciente_id: string

  
  _spinner = '_spinner_'
  

  constructor (
    private patient: PatientsService,
    private alert: AlertsService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {
    this.getPacientes()
  }


  private getPacientes() {
    this.spinner.show(this._spinner)
    this.patient._getPatientInfo('true').subscribe({
      next:(resp: any) => {
        this.spinner.hide(this._spinner)
        if(resp && resp.codigo === 200) {
          this._listadoPacientes = resp.data.records

          console.log({_PACIENTE___: this._listadoPacientes});
          
        }
        },error:(error) => {
          console.error({error});
          
        this.spinner.hide(this._spinner)
      }
    })
  }


  /**
   * 
   * @param _paciente 
   */
  _onEdit(_paciente: any) {

    this._paciente_id = _paciente.Name;
    
    this._ngModal = this.modalService.open(this.modalPatient, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })

  }

  closeEventModal() {
    this._ngModal.close()
  }

  onClickEvent(event: boolean) {
    if(event) {
      this._ngModal.close()
    }
  }


  _onEventReload(event: boolean) {
    if(event) {
      this.closeEventModal()
      this.getPacientes()
    }
  }



}
