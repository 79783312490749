import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {

  @Input() title: string | undefined;
  @Input() value: any | undefined;
  @Input() icon: string | undefined;
  @Input() persantage: string | undefined;
  @Input() profit: string | undefined;
  @Input() desciption: string | undefined;

  @Input() 
    recursoId: string | undefined;
  @Input()
    DataCitas: any[] = []

  @Output()
    eventEmmitCitas: EventEmitter<any[]> = new EventEmitter()

  constructor () {}

  ngOnInit(): void {
    
  }

  /**
   * 
   */
  SetLoadData() {

    let _citasFilter = _.filter(this.DataCitas, (a) => { return a.Recurso_Id == this.recursoId } )
    
    this.eventEmmitCitas.emit(_citasFilter)
    

  }

}
