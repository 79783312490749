import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CandidatoData } from 'src/app/pages/calendar/models/candidatos';
import { CandidatosService } from 'src/app/pages/calendar/services/candidatos.service';

@Component({
  selector: 'app-lista-candidatos',
  templateUrl: './lista-candidatos.component.html',
  styleUrls: ['./lista-candidatos.component.scss']
})
export class ListaCandidatosComponent implements OnInit {

  _listadoCandidatos: CandidatoData[]

  _candidato: CandidatoData;

  constructor (
    private candiatos: CandidatosService,
    private modal: NgbModal
  ) {

  }

  ngOnInit(): void {
    this.loadCandidatos()
  }

  /**
   * 
   */
  private loadCandidatos(): void {
    this.candiatos.ObtenerListadoCanidatos('true').subscribe({
      next:(response) =>  {        
        if(response.codigo === 200) {
          this._listadoCandidatos = response.data
        }
      },
      error(err) {
          console.error({err});          
      },
    })
  }

  /**
   * 
   * @param content 
   * @param candidato 
   */
  openModal(content:TemplateRef<any>, candidato: CandidatoData) {

    this._candidato = candidato

    this.modal.open(content, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })

  }

  /**
   * 
   * @param event 
   */
  onClickEvent(event: boolean) {
    if(event) {
      this.modal.dismissAll()
    }
  }

  /**
   * 
   * @param event 
   */
  _onEventEmmiterReaload(event:boolean): void {

    if(event) {
      this.loadCandidatos()
    }

  }

}



