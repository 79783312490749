<div class="card" *ngIf="_formato && _itsReady">
    <div class="card-body" *ngFor="let control of _controls">
        <form [formGroup]="_formConsentimiento" class="needs-validation createfile-form" autocomplete="off" novalidate>
            <div class="row g-2 mt-4">
                <div class="row g-2 mt-4">
                    <ng-container *ngIf="control.type == 'frame'; else titleTemplate ">
                        <!-- TITULOS -->
                        <div class="bg-primary">
                           <h4 class="fs-4 text-white bold text-uppercase text-center">{{control.label}} </h4>     
                       </div>
       
                   </ng-container>

                    <ng-template #titleTemplate>

                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{control.label}} </h4>     
                        </div>

                        <div class="card">
                            <div class="card-body">
                                <div class="col-12">
                                    <div class="row g-2">
                                        <div *ngFor="let objects of control.Objects; let i = index" class="col-12">
                                         <div class="row g-2">
                                            <div class="col-12">
                                                <span class="fs-normal lh-sm"> {{objects.label}} </span>
                                            </div>
                                            <div class="col-12">
                                                <ng-container *ngIf="objects.name === '1_2_acepto_consentimiento_informado_aplicacion_sedacion' || objects.Objects ; else _inputTemplate ">
                                                  <div *ngIf="objects.name === '1_2_acepto_consentimiento_informado_aplicacion_sedacion' ">
                                                    <ng-select
                                                    [items]="_anestesiologos"
                                                    bindLabel="name"
                                                    bindValue="id_salesforce"
                                                    [clearable]="false"
                                                    [formControlName]="objects.name"> 
                                                    </ng-select>
                                                  </div>
                                                  <div *ngIf="objects.Objects">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let objs of objects.Objects">
                                                            <div *ngIf="objs.type === 'radio' ">
                                                                <div class="form-check form-check-right form-radio-primary">
                                                                    <input 
                                                                    class="form-check-input" 
                                                                    type="radio" 
                                                                    [name]="objects.name"         
                                                                    [value]="objs.name"                                        
                                                                    id="{{objs.name}}" 
                                                                    [formControlName]="objects.name">
                                                                    <label class="form-check-label" for="{{objs.name}}">
                                                                    {{objs.label}}
                                                                    </label>
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </ng-container>
                                                <ng-template #_inputTemplate>
                                                    
                                                    <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                                        <div class="">
                                                            <input
                                                            [type]="objects.type"
                                                            [name]="objects.name"
                                                            [required]="objects.required"
                                                            [formControlName]="objects.name"
                                                            [readonly]="objects.readonly"                                                       
                                                            class="form-control">                                                      
                                                        </div>
                                                    </div>
                                                    <div *ngIf="objects.type == 'date' ">
                                                        <div class="">
                                                            <input                                                 
                                                            class="form-control flatpickr-input" 
                                                                             
                                                            [id]="objects.name"     
                                                            [type]="objects.type"     
                                                            mwlFlatpickr 
                                                            [altInput]="true" 
                                                            [formControlName]="objects.name"
                                                            [value]="objects.value"
                                                            [convertModelValue]="true"                   
                                                            >                                                        
                                                        </div>
                                                    </div>
                                                    <div *ngIf="objects.type == 'checkbox' ">
                                                        <div class="form-check form-check-right mb-3">
                                                            <!--        (change)="_onCheckChange($event, objects.name)" -->
                                                            <input
                                                            class="form-check-input" 
                                                            [type]="objects.type" 
                                                            [name]="objects.name" 
                                                            [formControlName]="objects.name"
                                                            [required]="objects.required"
                                                           
                                                            [checked]="objects.value == 'false' ? false : objects.value "                                         
                                                            [id]="objects.name + i">
                                                            <!-- <label class="form-check-label" for="{{objects.name + i}}">{{objects.label}}</label> -->
                                                        </div>
                                                    </div>
                                                    <div *ngIf="objects.type == 'canvas' "> 
    
                                                        <div *ngIf="objects.name === 'firma_paciente_nombre_firma_consentimiento_informado_aplicacion_sedacion' ">
                                                            <ng-container *ngIf="_firmaPaciente != null; else _firmaTemplate">
                                                                <div class="card card-body">
                                                                    <div class="d-flex justify-content-center">
                                                                        <figure class="figure mb-0">
                                                                            <img [src]="_firmaPaciente[0].url" class="figure-img img-fluid" max  alt="" width="300">
                                                                        </figure> 
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>      
    
                                                        <div *ngIf="objects.name === 'firma_medico_nombre_firma_consentimiento_informado_aplicacion_sedacion' ">
                                                            <ng-container *ngIf="_firmaMedico != null ; else _firmaTemplate">
                                                                <div class="card card-body">
                                                                    <div class="d-flex justify-content-center">
                                                                        <figure class="figure mb-0">      
                                                                            <img [src]="_firmaMedico[0].url" class="figure-img img-fluid" max  alt="" width="300">                                                                 
                                                                         <!--    <div *ngIf="_firma">
                                                                                
                                                                            </div>   -->                                                                          
                                                                        </figure> 
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
    
                                                        <div *ngIf="objects.name === 'firma_testigo_1_nombre_firma_consentimiento_informado_aplicacion_sedacion' ">
                                                            <ng-container *ngIf="_firmaTestigo1 != null; else _firmaTemplate">
                                                                <div class="card card-body">
                                                                    <div class="d-flex justify-content-center">
                                                                        <figure class="figure mb-0">
                                                                            <img [src]="_firmaTestigo1[0].url" class="figure-img img-fluid" max  alt="" width="300">
                                                                        </figure> 
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
    
                                                        <div *ngIf="objects.name === 'firma_testigo_2_nombre_firma_consentimiento_informado_aplicacion_sedacion'">
                                                            <ng-container *ngIf="_firmaTestigo2!= null; else : _firmaTemplate">
                                                                <div class="card card-body">
                                                                    <div class="d-flex justify-content-center">
                                                                        <figure class="figure mb-0">
                                                                            <img [src]="_firmaTestigo2[0].url" class="figure-img img-fluid" max  alt="" width="300">
                                                                        </figure> 
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        
                                                        <ng-template #_firmaTemplate>
                                                            <app-firma-doctor (eventsignatureImg)="_onSaveSing($event,objects.name)"></app-firma-doctor>
                                                        </ng-template>
                                                    </div>
                                                </ng-template>                                        
                                            </div>
                                         </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>
</div>

