<div class="card" *ngIf="_formato">

    <div class="card card-body" *ngIf="_notasEvolucion && _notasEvolucion.length">
        <div class="col-12">
            <fieldset>
                <legend class="text-muted text-uppercase fs-16 text-center ">Notas de evolución </legend>
                <div class="row">
                    <div class="col-md-auto" *ngFor="let item of _notasEvolucion">
                        <div class="float-start">
                            <div class="d-grid gap-2" >                       
                                <button type="button" class="btn btn-light btn-label waves-effect waves-light rounded-pill" (click)="_set_log_notasEvolucion(item)">
                                    <i class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i> {{item.Cita_Id}}
                                </button>                                    
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <hr>
            <fieldset class="mt-4">
                <legend>
                    <h5 class="text-muted text-uppercase mb-3">Notas de evolución -  {{_cita_selected}} </h5>
                </legend>
                <div class="row">
                    <div class="col-md-4 col-xxl-2 col-xl-3">
                        <ngx-simplebar style="max-height: calc(50vh - 70px)">                                                    
                            <div class="d-flex align-items-center ">
                                <div class="flex-grow-1 ms-3 ">                     
                                    <div class="row gap-2 m-2">
                                        <div class="col-12" *ngFor="let item of _log_notas_evolucion">
                                            <div class="d-grid gap-2" >                       
                                                <button type="button" class="btn btn-light btn-label waves-effect waves-light rounded-pill" (click)="_setNotaEvolucion(item)">
                                                    <i class="ri-error-warning-line label-icon align-middle rounded-pill fs-16 me-2"></i> Nota # {{item.sesion}}
                                                </button>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-simplebar>
                    </div>
                    <div class="col-md-8 col-xxl-10 col-xl-9" *ngIf="_selected_nota_evolucion">
                        
                        <div class="card card-body">
                            <div class="row">                                                    
                                <div class="col-xxl-4 col-md-6">
                                    <label class="control-label">Fecha</label>
                                    <input class="form-control" [value]="_selected_nota_evolucion.fecha" readonly disabled>
                                </div>
                                <div class="col-xxl-6 col-md-12">
                                    <label class="control-label">subjetivo</label>
                                    <input class="form-control" [value]="_selected_nota_evolucion.subjetivo" readonly disabled>
                                </div>
                                <div class="col-xxl-6 col-md-6">
                                    <label class="control-label">objetivo</label>
                                    <input class="form-control" [value]="_selected_nota_evolucion.objetivo" readonly disabled>
                                </div>
                                <div class="col-xxl-6 col-md-6">
                                    <label class="control-label">analisis</label>
                                    <input class="form-control" [value]="_selected_nota_evolucion.analisis" readonly disabled>
                                </div>
                                <div class="col-xxl-6 col-md-6">
                                    <label class="control-label">plan</label>
                                    <input class="form-control" [value]="_selected_nota_evolucion.plan" readonly disabled>
                                </div>
                                <div class="col-xxl-6 col-md-6">
                                    <label class="control-label">Medico</label>
                                    <input class="form-control" [value]="_selected_nota_evolucion.nombre_medico " readonly disabled>
                                </div>
                            </div>                                              
                        </div>
                    </div>
                </div>
            <!--     <div class="d-flex mt-4" *ngIf="_im && _selected_nota_evolucion.imagenes.length">
                    <fieldset>
                        <div class="mt-4">
                            <app-gallery-image [base64]="false" 
                            [GalleryImage]="_selected_nota_evolucion.imagenes"
                            ></app-gallery-image>
                        </div>
                    </fieldset>
                </div>  -->                                   
                <hr>
                <div class="d-flex mt-4" *ngIf="_img_nota_evolucion&& _img_nota_evolucion.length">
                    <fieldset>
                        <legend class="text-muted fw-bold fs-14">Todos los archivos</legend>                                                
                        <div class="mt-4">
                            <app-gallery-image [base64]="false" [GalleryImage]="_img_nota_evolucion"></app-gallery-image>
                        </div>
                    </fieldset>
                </div>
            </fieldset>
        </div>      
    </div>
    <div class="card-body">
        

    <!--     <div class="row g-2 m-4" *ngFor="let item of _controls">
            <ng-container *ngIf="item.type == 'frame'; else otherTypes ">

                <div class="col-12">
                    <div class="bg-primary">
                        <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>
                    </div>
                </div>
                <form [formGroup]="formNotaEvolucion" class="needs-validation createfile-form" autocomplete="off"
                    novalidate>
                    <div class="row">
                        <div *ngFor="let obj of item.Objects"
                            [ngClass]="obj.type != 'textarea' ? 'col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2' :'col-12'  ">

                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input class="form-control flatpickr-input" [id]="obj.name" [type]="obj.type"
                                        [formControlName]="obj.name" [readonly]="obj.readonly">
                                    <label [for]="obj.name" class="form-label">{{obj.label}} </label>
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'time' ">
                                <div class="form-floating">
                                    <input type="time" [name]="obj.name" [formControlName]="obj.name"
                                        [readonly]="obj.readonly" class="form-control">
                                    <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                </div>

                            </div>

                            <div *ngIf="obj.type =='text' ">
                                <div class="form-floating">
                                    <input class="form-control" [type]="obj.type" [name]="obj.name"
                                        [formControlName]="obj.name" [readonly]="obj.readonly"
                                        [required]="obj.required">
                                    <label class="form-label" for="{{obj.name}}">{{obj.label}} </label>
                                </div>
                            </div>

                            <div *ngIf="obj.type =='textarea' ">
                                <div class="row">
                                    <div class="col-12">
                                        <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                        <textarea [name]="obj.name" class="form-control" [formControlName]="obj.name"
                                            [readonly]="obj.readonly" [rows]="5"></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </form>
            </ng-container>
            <ng-template #otherTypes>
                <div class="col-12">
                    <div class="bg-primary">
                        <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>
                    </div>
                </div>
                <ng-container *ngIf="_Firma && _Firma.length; loadedTemplate">
                    <div class="col-12 ">
                        <h4 class="fs-4 text-primary bold text-uppercase text-center">Firma digital </h4>
                    </div>
                    <fieldset class="mt-4">
                        <div class="card" *ngFor="let item of _Firma">
                            <div class="card-body">
                                <div class="d-flex justify-content-center">
                                    <figure class="figure mb-0">
                                        <img [src]="item.url" class="figure-img img-fluid" max alt="" width="300">
                                    </figure>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </ng-container>
                <ng-template #loadedTemplate>
                    <app-firma-doctor></app-firma-doctor>
                </ng-template>
                
            </ng-template>
        </div> -->
        <div class=" mt-4">
            <div class="card-body border border-1">
                <form [formGroup]="_formNotasEvolucion"  class="needs-validation createfile-form" autocomplete="off" novalidate>
                    <div class="row mt-2  g-md-4 g-1">
                        <div class="col-xl-4 col-md-6">
                            <div class="form-floating">
                                <input class="form-control flatpickr-input" type="date" formControlName="fecha" >
                                <label class="form-label">Fecha </label>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="mb-3">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="subjetivo">
                                    <label  class="form-label">Subjetivo</label>
                                </div>
                            </div>                                
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="mb-3">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="objetivo">
                                    <label  class="form-label">Objetivo</label>
                                </div>
                            </div>                                
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="mb-3">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="analisis">
                                    <label  class="form-label">Analisis</label>
                                </div>
                            </div>                                
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="mb-3">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="plan">
                                    <label  class="form-label">Plan</label>
                                </div>
                            </div>                                
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="mb-3">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="usuario">
                                    <label  class="form-label">Usuario</label>
                                </div>
                            </div>                                
                        </div>
                    </div>

                    <div class="mt-4">
                        <fieldset>  
                            <div class="card card-body">
                            <app-custom-upload-files 
                            [showUpload]="false" 
                            [_maxFiles]="10" 
                            [_showGallery]="true"
                            [_acceptedFileTypes]="['image/jpeg','image/png','image/jpg','application/pdf']"
                            [eventSubjectClerFiles]="eventSubjectClerFiles.asObservable()"
                            (_filesPondsEvent)="_onUploadFiles($event)">
                            </app-custom-upload-files>
                            </div>
                        </fieldset>    
                        <div class="mt-4" *ngIf="isDesktopDevice">
                            <app-ng-web-cam 
                            [showBtnSaveIt]="false"
                            (eventSaveImages)="_onSaveItImages($event)"
                            ></app-ng-web-cam>                                         
                        </div>
                    </div>

                    <div class="d-flex justify-content-center mt-2">
                        <button type="button" class="btn btn-success w-50 text-uppercase fw-bold " (click)="_onsave()">Guardar nota de Evolucion</button>
                     </div>
                </form>        
            </div>
        </div>
    </div>
</div>