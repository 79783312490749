import { AuthenticationService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(   
        private tokenService: TokenStorageService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {       
            // add authorization header with jwt token if available
            const currentUser =  this.tokenService.getUser() //this.authService.currentUserValue;
                        
            if (currentUser && this.tokenService.getToken() ) {
                request = request.clone({
                    setHeaders: {
                        'x-auth': ` ${this.tokenService.getToken()}`,
                    },
                });
            }
       // }
        return next.handle(request);
    }
}
