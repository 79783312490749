import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Lightbox } from 'ngx-lightbox';


// import Swiper core and required components
import SwiperCore , {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper';

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Thumbs,
  Controller
])



@Component({
  selector: 'app-gallery-image',
  templateUrl: './gallery-image.component.html',
  styleUrls: ['./gallery-image.component.scss']
})
export class GalleryImageComponent implements OnChanges, OnInit {

  @Input()
    GalleryImage: any[] = []
  @Input()
    GalleryBase64: any[] = []
  @Input()
    base64: boolean = false  
  @Input()
  _showDeleteBtn: boolean = false
  @Output()
    eventEmmitDelete: EventEmitter<any>  = new EventEmitter<any>();


  images: { src: string; thumb: string; caption: string }[] = [];
  Base64images: { src: string; thumb: string; caption: string }[] = [];

  _image: any[] = ['jpeg','png','jpg']

  constructor (
    private sanitizer: DomSanitizer,
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
  //  console.log({GalleryImage: this.GalleryImage});    

    if(this.GalleryImage && this.GalleryImage.length) {
      _.map(this.GalleryImage, (a, i) => { 
        this.images.push({
          src: a.url,
          caption: a.filename ? a.filename : a.url,
          thumb:  a.filename ? a.filename : a.url,
        })
      })
    }

    if(this.GalleryBase64 && this.GalleryBase64.length) {

      console.log({_GALLERY_BASE_64: this.GalleryBase64, LENGTH__: this.GalleryBase64.length});
      
  /*     _.map(this.GalleryBase64, (a, i) => {
        console.log({a});
        

        */

      console.log({Base64images: this.Base64images});  
    }
      
  }

  /**
   * 
   * @param item 
   */
  _onDelete(item: any) {
    this.eventEmmitDelete.emit(item)   
  }
  
  /**
   * 
   * @param index 
   */
  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.images, index, { });
  }

  /**
   * 
   * @param index 
   */
  openBase64(index: number) {
    this.lightbox.open(this.Base64images, index, { });
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  /**
   * 
   * @param filename 
   */
  isPDF(filename: string) {
    const _striped = filename.split('.');
    if(_striped[1] === 'pdf' ) {
      return true
    }   

    return false
  }

  openPDF(file: any) {
    console.log({file});

    setTimeout(() => {
      window.open(file.url, '_blank', 'width=1000, height=800');
    }, 100);
  }

}
