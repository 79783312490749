import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { dataInfo } from 'src/app/layouts/models/user-data';
import { FormatsService } from 'src/app/shared/services/formats.service';
import Swal from 'sweetalert2';
import { ProfileService } from '../../services/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';

import { LocalSessionService } from '../../services/local-session.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { CalendarService } from '../../calendar/services/calendar.service';
import * as moment from 'moment';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { DatePipe } from '@angular/common';
import { FilePondOptions } from 'filepond';
import { FilePondComponent } from 'ngx-filepond';

interface TabProfile {
  activeId: number
}

interface HorasBloqueos {
  inicio: string
  fin: string
}

interface BloqueoRecurrent {
  dia: string
  horas: HorasBloqueos[]
}

interface BloqueoEspecifico {
  fecha: string
  horas: HorasBloqueos[]
}

interface BloqueoHorarios {
  doctor: string
  bloqueo_horario: {
    bloqueo_recurrente?: BloqueoRecurrent[]
    bloqueo_especifico?: BloqueoEspecifico[]
  }
}

const _JSON_PROFILE = '_profile_session'

@Component({
  selector: 'app-index-profile',
  templateUrl: './index-profile.component.html',
  styleUrls: ['./index-profile.component.scss']
})

export class IndexProfileComponent implements OnInit, AfterContentChecked {

  @ViewChild("myPond") myPond: FilePondComponent;

  userData: dataInfo | null;

  _previewImage: any[] = []

  _image:any[] = []

  currentUser: any
  _firma: any
  _profile: any

  //_firma: any[] = []
  _Archivos: any[] = []
  _firmaLoaded: any = null
  _previewProfile: any
  _profileImage: any[] = []
  //_profile = 

  _Doctores: any[] = []
  _doctor: string

  _role: any

  _dateBloqueo: any

  _today = new Date()

  _showRangeDates!: boolean;
  _formRangeDate!: FormGroup
  _showform!:boolean;


  //#region FilePondUpload
  pondOptions: FilePondOptions = {
    allowMultiple: true,
    labelIdle: "Suelte aquí o seleccione el archivo a subir",
    acceptedFileTypes:['image/jpeg','image/png','image/jpg'],
    maxFiles:1,
    className:'dropzone_sec',
    chunkUploads:true
  };

  pondFiles: FilePondOptions["files"] = [];
  //#endregion

  

  _dias_ = ['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado']
  _bloqueo_recurrente: any[] = []
  _bloqueo_especifico: any[] = []

  _formBloqueo : FormGroup = this.formBuilder.group({})
  _formBloqueoDates: FormGroup = this.formBuilder.group({})


     //#region WEbCamera
     public showWebcam = false;
     public allowCameraSwitch = false;
     public multipleWebcamsAvailable = false;
     public deviceId: string;
   
     public videoOptions: MediaTrackConstraints = {
        width: {ideal: 1024},
        height: {ideal: 576},
      
     };
     public errors: WebcamInitError[] = [];
   
     // latest snapshot
     public webcamImage: WebcamImage | null
     _pictures: any[] = []
       
     // webcam snapshot trigger
     private trigger: Subject<void> = new Subject<void>();
     // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
     private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
     //#endregion

  _showCamera!: boolean;

  constructor(
    private credentials: CredentialsService,
    private formatService: FormatsService,
    private tokenService: TokenStorageService,
    private _profileService: ProfileService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private changeRef: ChangeDetectorRef,
    private localStorage: LocalSessionService,
    private formBuilder: FormBuilder,
    private calendar: CalendarService,
    private alert: AlertsService,
    private date: DatePipe
    ) { 
      flatpickr.localize(Spanish)
    }

  ngOnInit(): void {

    this.initData()   

    this.userData = this.credentials.credentials;      
    if(this.credentials.credentials?.archivos?.Perfil) {
      this._previewProfile = this.credentials.credentials.archivos.Perfil[0].url
    }
    this.loadImages()
    this.spinner.show()

    //this.initWebCam()

    
  }

  /**
   * 
   */
  private initData(): void {
    this.currentUser = this.tokenService.getUser()   
      
    if(this.currentUser) {
      this._role = this.currentUser.role

      if(this._role && !this._role?.Doctor) {
        this.loadDoctores()
    
      }else {
        const _dataUser = this.credentials._getdataUser()
        if(_dataUser) {            
          this._doctor = _dataUser.id_salesforce      
          this.loadBloqueos(_dataUser.id_salesforce )    
          
        }
      }
    }  

    this.initFormBloqueo()

  }


  /**
   * 
   */
  private loadBloqueos (_doctor: string) : void {
    this._bloqueo_recurrente = []
    this._bloqueo_especifico = []
    
    this.calendar._ConsultarBloqueoHorario(_doctor).then((resp: any) => {      
      if(resp.codigo === 200 ) {     
        const bloqueo_horario = resp.data.bloqueo_horario
        if(bloqueo_horario) {
          this._bloqueo_recurrente = bloqueo_horario.bloqueo_recurrente ? bloqueo_horario.bloqueo_recurrente : []
          this._bloqueo_especifico = bloqueo_horario.bloqueo_especifico ? bloqueo_horario.bloqueo_especifico : []

          this.initFormBloqueo() 
        }
       // this._bloqueo_recurrente = resp.data.bloqueo_horario.bloqueo_recurrente ?  
        //this._bloqueo_especifico = resp.data.bloqueo_horario.bloqueo_especifico ?
                
      }
    }).catch((error) => {
      console.error({error});      
    })
  }

  /**
   * 
   */
  private loadDoctores() {
    this.calendar._getDoctores('true').subscribe({
      next:(value) => {
        if(value.codigo === 200) {
          this._Doctores = value.data.info
        }
        
        
      },
    })
  }
  
  
  initWebCam(){
    WebcamUtil
    .getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        this._showCamera = true
        this.showWebcam = true
    }); 
  }


  _permissions (): void {
    navigator.mediaDevices.getUserMedia({
      video: {
        width: 400,
        height: 300,
        aspectRatio:1.333,
        frameRate: 30,
        facingMode:'user' 
      },
      audio: false
    })
  }

  ngAfterContentChecked(): void {
    this.changeRef.detectChanges()
  }

  get f () {
    return this._formBloqueo.controls
  }

  //#region Bloqueo Recurrente

  get bloqueos(): FormArray{ 
    return this._formBloqueo.get('bloqueos') as FormArray
  }

  get horas_Bloqueo(): FormArray {
    return this._formBloqueo.get('horas') as FormArray;
  }

  get _formRange() {
    return this._formRangeDate.controls
  }

  _bloqueos(): FormArray {
    return this._formBloqueo.get('bloqueos') as FormArray;
  }

  horas(empIndex: number): FormArray {
    return this._bloqueos()
      .at(empIndex)
      .get('horas') as FormArray;
  }

  //#endregion

  //#region  Bloqueo Especifico
  get bloqueoEsp(): FormArray {
    return this._formBloqueoDates.get('bloqueo_especifico') as FormArray
  }

  get horas_BloqueoEsp(): FormArray {
    return this._formBloqueoDates.get('horas') as FormArray;
  }

  _bloqueosEsp(): FormArray {
    return this._formBloqueoDates.get('bloqueo_especifico') as FormArray
  }

  horasEsp(empIndex: number): FormArray {
    return this._bloqueosEsp().at(empIndex).get('horas') as FormArray
  } 

  //#endregion

  private initFormBloqueo () {

    this._formBloqueo = this.formBuilder.group({
      bloqueos: this.formBuilder.array([])
    })

    this._formBloqueoDates = this.formBuilder.group({
      bloqueo_especifico: this.formBuilder.array([])
    })
    
    this._addControls()
  }

  private _addControls () {
    
    if(this._bloqueo_recurrente.length > 0 ) {
      this._addControlsBloqueoHorarios()    

    }else {
      _.map(this._dias_, (a) => {
        this.bloqueos.push(this.newControlBloqueo(a, []))
      })
    }

  }

  _addControlsBloqueoHorarios() {

    _.map(this._dias_, (a, i:number) => {
      let _find = _.find(this._bloqueo_recurrente, { dia: a  })   

      if(_find) {       
        this.bloqueos.push(this.newControlBloqueo(_find.dia,[]))        
        _.map(_find.horas, (c) => {
          let _controls = this.bloqueos.controls[i]  
          let _horas_bloqueo = _controls.get('horas') as FormArray          
          _horas_bloqueo.push(new FormGroup({
            inicio: new FormControl(c.inicio),
            fin: new FormControl(c.fin)
          })) 
        })

      }else {
        this.bloqueos.push(this.newControlBloqueo(a,[]))
      }        
    })  

    if(this._bloqueo_especifico.length > 0 ) {
      _.map(this._bloqueo_especifico, (a, i:number) => {
        this.bloqueoEsp.push(this.newControlBloqueosEspefico(a.fecha, []))

        _.map(a.horas,(b) => {
          let _controls = this.bloqueoEsp.controls[i]    
          let _horas_bloqueo = _controls.get('horas') as FormArray    
      
          _horas_bloqueo.push(new FormGroup({
            inicio: new FormControl(b.inicio),
            fin: new FormControl(b.fin)
          }))    
        })



        
      })
    }
  }
    
  /**
   * 
   * @param dia 
   * @returns 
   */
  public newControlBloqueo(dia: any, _horas:any[]): FormGroup {
    return this.formBuilder.group({
      dia,
      horas: new FormArray(_horas)
    })
  }

  /**
   * 
   * @param fecha 
   * @param _horas 
   */
  public newControlBloqueosEspefico(fecha: string, _horas:any[]) {
    return this.formBuilder.group({
      fecha,
      horas: new FormArray(_horas)
    })
  }

  /**
   * 
   */
  private loadImages () {
    this.formatService._getImageProfile(this.currentUser.username).subscribe({
      next:(resp) => {
        this.spinner.hide()

        if(resp.codigo == 200) {
         this._firmaLoaded = resp.data.formato.archivos.Firma;  
         /**
          * Imagen de Perfil
          */
         if(resp.data.formato.archivos.Perfil) {
          const _perfil = resp.data.formato.archivos.Perfil[0]
            
         this._profileService.setCurrentProfileImg(_perfil)

         }

        }      
        
      },error:(error) => {
        this.spinner.hide()
        console.error({_ERROR: error });        
      }
    })
  }

  /**
   * 
   * @param evet 
   */
  fileChange(event: any, type: string) {

    let message = [];
    let progressInfos = [];
    let selectedFiles = event.target.files

    this._previewImage = []

    this._image = selectedFiles;

    this._firma   = type == 'firma' ? true: null
    this._profile = type == 'profile' ? true: null
    

    if(selectedFiles && selectedFiles[0]) {
      const numberOfFiles = selectedFiles.length;

      for (let i = 0; i < numberOfFiles; i++) {
        const reader  = new FileReader();
        reader.readAsDataURL(selectedFiles[i]);
        reader.onload = async (event) => {
          
          if(type == 'firma') {
            this._previewImage.push({         
              base64: event.target?.result
            })
          }


          if(type == 'profile') {
            this._previewProfile = event.target?.result;
            this._profileImage = selectedFiles
            
            
            setTimeout(() => {
              this.UploadImageProfile()
            }, 300);
            
          }


        }        
      }
    }  
  }

  /**
   * guardamos
   */
  _onSaveIt() {
    this.spinner.show()
    //console.log({_image: this._image, CurrentUser: this.currentUser});
    this.formatService._uploadImage({ cita_id: '', paciente_id: '', files: this._image, user: this.currentUser.username, profile: this._profile, firma: this._firma }).then((resp) => {
        
      if(resp) {
        this.toastr.success('Se ha cargo la imagen, correctamente','Exito al cargar',{
          timeOut: 3200
        })
      }
      this.loadImages()
    }).catch((error) => {
      console.error({error});
      
    })     
  }

  /**
   * 
   */
  private UploadImageProfile() {
    this._onSaveIt()
  }

  /**
   * elimina la imagen
   * @param item 
   */
  _onDelete(item: any) {       

    Swal.fire({
      title:'Por favor confirme esta accion, antes de continuar',
      text:'¿Desea eliminar esta firma?',
      confirmButtonText:'Sí, borrar',
      showCancelButton:true,
      icon:'question',
      cancelButtonText:'No, Cancelar'
    }).then((resp) => {
      if(resp.value) {
        this.spinner.show()
        this.formatService._PostdeleteImage({user: this.currentUser.username, filename: item.filename, cita_id: null, paciente_id: null }).then((resp) => {
          this.spinner.hide()
          if(resp.codigo == 200) {

            this.toastr.success('Su firma, ha sido eliminada, puede cargar otra','Exito al borrar',{
              timeOut: 3200
            })
            this._previewImage = []
            this._firmaLoaded = null


          }else {
            this.toastr.warning('Error ocurrido al responder el borrado de la imagen','Error inesperado',{
              timeOut: 3200
            })
          }

        }).catch((error) => {
          this.spinner.hide()
          this.toastr.error('Ocurrio un error al tratar de eliminar la imagen','Error ocurrido',{
            timeOut: 3200
          })
        })
      }
    })
  }

  canLoadFirma(): boolean {

    if(this.credentials.credentials?.roles.Administrador || this.credentials.credentials?.roles.Doctor || this.credentials.credentials?.roles.Anestesiologo){
      return true
    }

    return false
  }

  /**
   * 
   * 
   */
  _getTabActiveId() {
    const _tab_id = this.localStorage.getJsonValues(_JSON_PROFILE) as TabProfile;
    if(_tab_id) {
      return _tab_id.activeId
    }
    return 1
  }

  /**
   * 
   */
  _setTabActiveId (tabId: number) {
    let _json_tab_profile: TabProfile = {
      activeId: tabId
    }
    this.localStorage.savedJsonValues(_JSON_PROFILE, _json_tab_profile)
  }

  /**
   * 
   * @param _index 
   */
  _addControl (_index: number) {
    let _controls = this.bloqueos.controls[_index]    
    let _horas_bloqueo = _controls.get('horas') as FormArray    

    _horas_bloqueo.push(new FormGroup({
      inicio: new FormControl(null),
      fin: new FormControl(null)
    }))     
  }

  /**
   * 
   * @param item 
   * @param _hora_control_index 
   * @param _bloqueo_index 
   */
  _removeControl (item: any, _hora_control_index: number, _bloqueo_index: number) {

    let _controls = this.bloqueos.controls[_bloqueo_index]
    let _hora_bloqueo = _controls.get('horas') as FormArray

    _hora_bloqueo.removeAt(_hora_control_index)
  }
  //#region ngxWebCam

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this._showCamera = !this._showCamera;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.showWebcam = !this.showWebcam;
   // this.pictureTaken.emit(webcamImage);
   this.webcamImage = webcamImage
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  //#endregion

  /**
   * 
   */
  _onSaveBloqueos(): void {

    let _bloqueoHorario: BloqueoHorarios;
    
    _bloqueoHorario = {
      doctor: this._doctor,
      bloqueo_horario:{
        bloqueo_recurrente: this._formBloqueo.value.bloqueos
      }
    }

    this.calendar._CrearBloqueoHorario(_bloqueoHorario).then((resp) => {
      console.log({resp});     
      this.toastr.success('Se ha capturado correctamente el bloqueo de horario','Exito al guardar',{
        timeOut: 3200
      })
    }).catch((error) => {
      console.error({error});      
    })    

  }


  /**
   * 
   */
  _onchage(event: any) {

    this._doctor = event

    const _controls  = this.bloqueos.controls

    while (_controls.length !== 0) {
      this.bloqueos.removeAt(0)
    }
    
    console.log({_COMTROLS__: this.bloqueos.controls});
    

/*     while(Object.keys(this.bloqueos.controls).length){
      const toRemove = Object.keys(this._formBloqueo.controls)[0];
      this._formBloqueo.removeControl(toRemove)
    }     */
    this.loadBloqueos(this._doctor)
    this._addControlsBloqueoHorarios()

  }

  /**
   * 
   */
  _addDates() : void {

    if(!(this._showRangeDates)) {
      
      const _fecha = moment(this._dateBloqueo).format('YYYY-MM-DD').toString()
      const _formValues = this._formBloqueoDates.value

      if(_formValues.bloqueo_especifico.length > 0) {    
        let _find = _.find(_formValues.bloqueo_especifico, { fecha: _fecha } )
        if(!_find) {
        return this.bloqueoEsp.push(this.newControlBloqueosEspefico(_fecha,[]))
        }      
      }else {
        this.bloqueoEsp.push(this.newControlBloqueosEspefico(_fecha,[]))
      }
    }else {            
      
      //console.log({_DATES__: this._dateBloqueo});  

      let _formDates_ = {
        startDate: this.date.transform(this._dateBloqueo.from,'yyyy-MM-dd') ,
        endDate: this.date.transform(this._dateBloqueo.to,'yyyy-MM-dd')
      }            

      this._formRangeDate = this.formBuilder.group({
        startDate:[this._dateBloqueo.from],
        endDate:[this._dateBloqueo.to],

        inicio:[null],
        fin:[null]
       /*  horas: new FormGroup({
          inicio: new FormControl(null),
          fin: new FormControl(null)
        }) */
      })      

      this._formRangeDate.patchValue(_formDates_)


      console.log({_formRangeDate: this._formRangeDate});
      this._showform = true
    }

  }

  /**
   * 
   * @param index 
   */
  _removeControlBloqueoEsp(item: any, _hora_control_index: number, _bloqueo_index: number): void {
    let _controls = this.bloqueoEsp.controls[_bloqueo_index]
    let _hora_bloqueo = _controls.get('horas') as FormArray

    _hora_bloqueo.removeAt(_hora_control_index)

  }

  /**
   * 
   * @param index 
   */
  _removeCoontrolHour (index: number) : void {
    this.bloqueoEsp.removeAt(index)

  }
  
  /**
   * 
   * @param index 
   */
  _addControlHours(_index: number) : void {
    let _controls = this.bloqueoEsp.controls[_index]    
    let _horas_bloqueo = _controls.get('horas') as FormArray    

    _horas_bloqueo.push(new FormGroup({
      inicio: new FormControl(null),
      fin: new FormControl(null)
    }))     

    



  }

  /**
   * 
   */
  _onSaveItBloqueos(): void {

    let _bloqueoHorario: BloqueoHorarios;

    _bloqueoHorario = {
       doctor: this._doctor,
       bloqueo_horario:{
        bloqueo_especifico: this._formBloqueoDates.value.bloqueo_especifico
       }
    }

    this.alert._showLoader('Guardando espere...')
    
    this.calendar._CrearBloqueoHorario(_bloqueoHorario).then((resp) => {      
      this.toastr.success('Se ha capturado correctamente el bloqueo de horario','Exito al guardar',{
        timeOut: 3200
      })
      this.alert._hideLoader()
    }).catch((error) => {
      this.alert._hideLoader()
      this.toastr.error('Ocurrio un error al guardar','Error',{
        timeOut: 3200
      })
      console.error({error});      
    })    
  }


  /**
   * 
   */
  _uploadCedula(): void {

  }

  /**
   * 
   */
  onChange() {
    console.log({_showRangeDates: this._showRangeDates});
    
  }

  /**
   * 
   */
  _onSaveItFormDateRange(): void {    
    const _val_ = this._formRangeDate.value
    console.log({_val_});   
  }

  //#region Pondfiles
  pondHandleInit() {
    console.log("FilePond has initialised", this.myPond);
  }

  /**
   * Agregamos los archivos
   * @param event 
   */
  pondHandleAddFile(event: any) {
    if(!event.error) {
      this.pondFiles?.push(event.file.file)
      console.log("A file was added", event);
      console.log({pondFiles: this.pondFiles});    
    }    
  }

  pondRemoveFile(event: any) {    
    if(this.pondFiles) {
      const _id = event.file.id;    
      _.remove(this.pondFiles, (a:any) => {return a.file.id  === _id })   
     // this.eventEmmit()
    }     
  }
  //#endregion

}
