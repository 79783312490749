import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';

import { ProfileRoutingModule } from './profile-routing.module';
import { IndexProfileComponent } from './index-profile/index-profile.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { NgxSpinnerModule } from 'ngx-spinner';

// FlatPicker
import { FlatpickrModule } from 'angularx-flatpickr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WebcamModule } from 'ngx-webcam';


//FIelpond


//simppleBar
import { SimplebarAngularModule } from 'simplebar-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilePondModule,registerPlugin } from 'ngx-filepond';
import * as filePondFileValidate from "filepond-plugin-file-validate-type";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: 'https://httpbin.org/post',
  maxFilesize: 1,
  acceptedFiles: 'image/*'
};

registerPlugin(filePondFileValidate );


@NgModule({
  declarations: [
    IndexProfileComponent
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    DropzoneModule,
    ProfileRoutingModule,
    NgxSpinnerModule,
    WebcamModule,
    NgSelectModule,
    SimplebarAngularModule,
    FlatpickrModule,
    FilePondModule
  ],
  providers:[],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProfileModule { }
