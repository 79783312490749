import { Component, Input, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { Doctor } from 'src/app/pages/models/doctores';




@Component({
  selector: 'app-details-doctor',
  templateUrl: './details-doctor.component.html',
  styleUrls: ['./details-doctor.component.scss']
})
export class DetailsDoctorComponent implements OnInit, OnDestroy {
  @ViewChild('DetallesDoctor') DetallesDoctor: TemplateRef<any>

  @Input() 
    events: Observable<string>;

  private eventsSubscription: Subscription;  
  canvasRef: NgbOffcanvasRef;

  _doctor: Doctor;

  constructor (
    private _offCanvasService: NgbOffcanvas,
    private calendarService: CalendarService
    ) {}

  ngOnInit(): void {

    this.eventsSubscription = this.events.subscribe((doctorId) => {

      if(doctorId) {
          this.LoadData(doctorId)
        
      /*  */

      }           
    })    
  }

  /**
   * 
   * @param doctoId 
   */
  private LoadData(doctoId: string) {
    this.calendarService._getDoctores(doctoId).subscribe({
      next:(response) => {
        if(response.codigo == 200) {
          let _response = response.data as any;
          this._doctor = _response.info.salesforce as Doctor;   
          
          this._openCanvas()
        }        
      }
    })    
  }

  private _openCanvas() {
    this.canvasRef = this._offCanvasService.open(this.DetallesDoctor, {
      animation:true,
      backdrop: true,
      position:'end',
      scroll: false
    })
  }

  /**
   * 
   */
  ngOnDestroy(): void {
    if(this.eventsSubscription) {
      this.Unsiscribe()
    }
    
  }

  /**
   * 
   */
  private Unsiscribe() {
    
    this.eventsSubscription.unsubscribe()
  }

  /**
   * 
   */
  _onClose() {
    this._offCanvasService.dismiss('Click View')
  //  this.Unsiscribe()
  }

  /**
   * 
   */
  _hideCanvas() {
    this.canvasRef.close()
  }




}
