export class CreateCandidate {
    Candidato_Id?: string
    generales: _generalesObj
    direccion: _direccionObj
    relaciones: _relacionObj
    informacion: _informacionObj
}

export interface _generalesObj  {
    correo: string
    telefono: string
    comentarios: string

    estatus:string
    cm_web?:string
    responsable_asistencia?:string
    responsable_call_center:string
    responsable_marketing:string
}

export interface _direccionObj {
    pais: string
    estado: string
    municipio: string
    codigo_postal: string
    calle: string
    colonia: string
    num_exterior: string
    num_interior: string
}

export interface _relacionObj {
    centro_medico: {
        Id: string
        Name: string
    },
    nombre: string
    apellidos: string
    ultima_actividad_reporte: string
}

export interface _informacionObj {
    origen_paciente: string
    especialidad: string
    metodo_contacto: string
}