import { Component, OnInit } from '@angular/core';
import { EventService } from '../../core/services/event.service';
import { NavigationService } from '../services/navigation.service';
import { MenuItem } from '../models/menu.model';
import { dataInfo } from '../models/user-data';
import * as _ from 'lodash';
import { SessionPageService } from 'src/app/pages/services/session-page.service';


@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalComponent implements OnInit {

  isCondensed = false;
  menuItems: MenuItem[] = [];
  _role: string;
  _userIno: dataInfo;


  constructor(
    private eventService: EventService, 
    private sessionPage: SessionPageService,
    private navigationService: NavigationService) {

    //this.navigationService._setUserDetails()

    this.navigationService.getNavigationItems().subscribe((response: any) => {

      if(response.length > 0 ) {
        this._role = response[0].label        
        //this.menuItems = response[0].menu       
        let menuItems = response[0].menu;
        _.map(menuItems, (a) => {
         // this.menuItems.push(a)

         /*  if(a.label == 'Pacientes Formatos' ) {
            this.sessionPage._setPacientesData(a.subItems)
          } */

            if(a.label != 'Pacientes Formatos') {

              //this.menuItems.push(a)
            }else { 
              this.sessionPage._setPacientesData(a.subItems)
            }
        })
         
      } 
      
     })
  }

  ngOnInit(): void {
    if (document.documentElement.getAttribute('data-layout') == 'semibox') {
      document.documentElement.setAttribute('data-layout', 'semibox');
    } else {
      document.documentElement.setAttribute('data-layout', 'vertical');
    }
    document.documentElement.setAttribute('data-topbar', 'light');
    document.documentElement.setAttribute('data-sidebar', 'light');
    document.documentElement.setAttribute('data-sidebar-size', 'sm');
    document.documentElement.setAttribute('data-layout-style', 'default');
    document.documentElement.setAttribute('data-bs-theme', 'light');
    document.documentElement.setAttribute('data-layout-width', 'fluid');
    document.documentElement.setAttribute('data-layout-position', 'fixed');
    document.documentElement.setAttribute('data-preloader', 'disable');
    
    /* window.addEventListener('resize', function () {
      if (document.documentElement.clientWidth <= 767) {
        document.documentElement.setAttribute('data-sidebar-size', '');
        document.querySelector('.hamburger-icon')?.classList.add('open')
      }
      else if (document.documentElement.clientWidth <= 1024) {
        document.documentElement.setAttribute('data-sidebar-size', 'sm');
        document.querySelector('.hamburger-icon')?.classList.add('open')
      }
      else if (document.documentElement.clientWidth >= 1024) {
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
        document.querySelector('.hamburger-icon')?.classList.remove('open')
      }
    }) */

  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    const currentSIdebarSize = document.documentElement.getAttribute("data-sidebar-size");
    if (document.documentElement.clientWidth >= 767) {
      if (currentSIdebarSize == null) {
        (document.documentElement.getAttribute('data-sidebar-size') == null || document.documentElement.getAttribute('data-sidebar-size') == "lg") ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg')
      } else if (currentSIdebarSize == "md") {
        (document.documentElement.getAttribute('data-sidebar-size') == "md") ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'md')
      } else {
        (document.documentElement.getAttribute('data-sidebar-size') == "sm") ? document.documentElement.setAttribute('data-sidebar-size', 'lg') : document.documentElement.setAttribute('data-sidebar-size', 'sm')
      }
    }
    if (document.documentElement.clientWidth <= 767) {
      document.body.classList.toggle('vertical-sidebar-enable');
    }
    this.isCondensed = !this.isCondensed;
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
    const rightBar = document.getElementById('theme-settings-offcanvas');
    if (rightBar != null) {
      rightBar.classList.toggle('show');
      rightBar.setAttribute('style', "visibility: visible;");
    }
  }

  onResize(event: any) {
    if (document.body.getAttribute('layout') == "twocolumn") {
      if (event.target.innerWidth <= 767) {
        this.eventService.broadcast('changeLayout', 'vertical');
      } else {
        this.eventService.broadcast('changeLayout', 'twocolumn');
        document.body.classList.remove('twocolumn-panel');
        document.body.classList.remove('vertical-sidebar-enable');
      }
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

}
