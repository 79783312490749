import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-patients',
  templateUrl: './layout-patients.component.html',
  styleUrls: ['./layout-patients.component.scss']
})
export class LayoutPatientsComponent {

}
