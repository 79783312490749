import { Injectable, OnDestroy } from '@angular/core';



import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { CredentialsService } from '../core/services/credentials.service';
import { EMPTY, map, share } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../core/services/token-storage.service';


interface WsMessage  {
  client: string
  pacient: string
  cita_id: string
  token: string
}

@Injectable({
  providedIn: 'root'
})
export class InitWebSocketService implements OnDestroy {

  private socket$: WebSocketSubject<any>;
  private socketClient$: WebSocketSubject<any>
  private socketAnestesiolgo$: WebSocketSubject<any>

  private _clientWs: string //= '0001'
  private _token : string //=

  private _clientDataUser: any

  private readonly _wsUrl = environment.webSocket
  private readonly _protocol = environment.wsProtocol

  constructor(
    private _credentials: CredentialsService,
    private token: TokenStorageService
  ) { 

    const _Credentials = this._credentials._getdataUser()
    if(_Credentials) {
      this._clientWs = _Credentials.Websockets?.paciente_espera ?  _Credentials.Websockets?.paciente_espera.cliente : null
      this._clientDataUser = _Credentials
    }   

    const _token = this.token.getToken()
    if(_token) {
      this._token = _token
    }
  }


  /**
   * 
   */
  connectWsAnestesiologo(): void {
    //onst _webSocket = this._clientDataUser.Websockets.anestesio_graph_1
    //console.log({_webSocket});
    if(this._clientDataUser.Websockets && this._clientDataUser.Websockets.anestesio_graph_1) {
      this._connectWsAnestesiologo( this._clientDataUser.Websockets.anestesio_graph_1.cliente)
    }
    
  }

  /**
   * TODOS los CLientes
   */
  connect(): void {
    if(this._clientWs) {
      this.socket$ = webSocket({
        url: `${this._wsUrl}/api/websockets/paciente_espera/${this._clientWs}`,
   
        closeObserver:{
          next:(resp) => {
            console.log({ MSG: 'Conexión WebSocket cerrada',  RESP_: resp});          
          },  
        },
        closingObserver: {
          next:(resp) => {
            console.log({__RESP: resp});          
          }
        },
        openObserver:{
          next:(response) => {
            console.log({MSG: 'Conexión WebSocket abierta', RESPONSE: response});          
          }
        },
        serializer: msg => msg,    
        deserializer: msg => msg
      })
    }

  }

  /**
   * Solo Recepcionista
   */
  connectClientWs(_clientWs: string): void {
  
      this.socketClient$ = webSocket({
        url: `${this._wsUrl}/api/websockets/paciente_espera/${_clientWs}`,
   
        closeObserver:{
          next:(resp) => {
            console.log({ MSG: 'Conexión WebSocket cerrada',  RESP_: resp});          
          },  
        },
        closingObserver: {
          next:(resp) => {
            console.log({__RESP: resp});          
          }
        },
        openObserver:{
          next:(response) => {
            console.log({MSG: 'Conexión WebSocket Cliente abierta', RESPONSE: response});          
          }
        },
        serializer: msg => msg,    
        deserializer: msg => msg
      })
      
      this.socketClient$.subscribe()

  }

  /**
   * Websocket Anestesiolgos
   */

  _connectWsAnestesiologo(_clienteWS: string): void {
    this.socketAnestesiolgo$ = webSocket({
      url: `${this._wsUrl}/api/websockets/anestesio_graph_1/${_clienteWS}`,
      closeObserver:{
        next:(resp) => {
          console.log({ MSG: 'Conexión WebSocket Anestesiologo cerrada',  RESP_: resp});          
        },  
      },
      closingObserver: {
        next:(resp) => {
          console.log({__RESP: resp});          
        }
      },
      openObserver:{
        next:(response) => {
          console.log({MSG: 'Conexión Anestesiolgo!! WebSocket Cliente abierta', RESPONSE: response});          
        }
      },
      serializer: msg => msg,    
      deserializer: msg => msg
    })

    this.socketAnestesiolgo$.subscribe();

  }

  /**
   * 
   */
  ngOnDestroy(): void {
    if(this.socket$) {
      this.socket$.unsubscribe()
    }
    if(this.socketClient$) {
      this.socketClient$.unsubscribe()
    }

    if(this.socketAnestesiolgo$) {
      this.socketAnestesiolgo$.unsubscribe()
    }
  }

  /**
   * 
   * @returns 
   */
  getMessages() {
    console.log('INICIANDO LECTURA DE MENSAJES!!!!');   
    if(this.socket$) {
       return this.socket$.asObservable()
     }
     return EMPTY     
   }
   
   
   /**
    * 
    * @param cita_id 
    * @param _clientWs 
    * @param pacient 
    */
   sendMessage(cita_id: string, _clientWs: string, pacient: string) {
    //connectClient(_clientWs)

    let message: WsMessage

    message = {
      cita_id,
      client:_clientWs,
      pacient,
      token: this._token
    }   

    if(this.socketClient$) {    
      this.socketClient$.next(JSON.stringify(message))          
    }else {
      console.log('NOOOOO');      
    }

   }
   
 
}
