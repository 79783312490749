<!-- <app-breadcrumbs title="Tabs" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs> -->

<ul ngbNav #Arrow="ngbNav" [activeId]="_getTabId()" class="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0">
    <li [ngbNavItem]="1" (click)="onChange($event)" >
        <a ngbNavLink routerLink="/"> <span class="d-sm-block">Calendario</span> </a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
        </ng-template>
    </li>
    <li [ngbNavItem]="2" (click)="onChange($event)">
        <a ngbNavLink routerLink="/tabla_citas"> <span class="d-sm-block">Tabla de Citas</span> </a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
        </ng-template>
    </li>
    <li [ngbNavItem]="3" (click)="onChange($event)">
        <a ngbNavLink routerLink="/plancha"> <span class="d-sm-block">Plancha</span> </a>
        <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
        </ng-template>
    </li>

</ul>
  <!-- Tab panes -->
  <div class="tab-content  text-muted">
    <div [ngbNavOutlet]="Arrow"></div>
  </div>

<ng-template #TabContent>    
    <router-outlet></router-outlet>
</ng-template>