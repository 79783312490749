
<div class="card">   
    <div class="accordion accordion-flush">   
      <fieldset class="m-4">
        <div class="row">
          <div class="col-xl-6">
            <div class="mt-3">
              <label class="form-label mb-0">Buscar por ango de fechas</label>          
              <input 
              class="form-control flatpickr-input" 
              type="text"
              placeholder="Seleccione un rango de fechas"
              [(ngModel)]="_dateRange"
              mwlFlatpickr 
              [altInput]="true"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="_onModelChange()"
              [convertModelValue]="true"               
              mode="range">
            </div>
          </div>
        </div>
      </fieldset>
      <!-- Doctores -->
      <div class="card-body" *ngIf="_doctorsFilter()">                
        <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
            <ngb-panel id="static-1" style="background-color: transparent;" >
              <ng-template ngbPanelTitle>
                <button class="border-0 bg-transparent" type="button" data-bs-toggle="collapse"
                  data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                  <span class="text-muted text-uppercase fs-13 fw-medium">doctores</span>                        
                </button>
              </ng-template>

              <ng-template ngbPanelContent>
                <div class="accordion-body text-body p-0">   
                    <div class="form-check form-switch form-switch-md" dir="ltr">
                        <input 
                        type="checkbox" 
                        class="form-check-input" 
                        id="all-doctors" 
                        [(ngModel)]="_all_doctors"
                        [checked]="_all_doctors"
                        (change)="changeEvent($event)">
                        <label class="form-check-label" for="all-doctors">Todos los doctores</label>
                    </div>
                    <div class="m-2">
                      <div class="d-flex justify-content-center">
                        <input 
                        type="text" 
                        class="form-control" 
                        [(ngModel)]="doctorModel"
                        placeholder="Buscar en los servicios">
                      </div>
                    </div>

                    <ngx-simplebar style="height: 50vh;"> 
                        <div class="d-flex flex-column gap-2 mt-3" *ngFor="let item of _selectedDoctors | filter: doctorModel">
                            <div class="form-check">
                              <input 
                              class="form-check-input"
                              name="check-{{item.Id}}" 
                              type="checkbox" 
                              [id]="item.Id"             
                              [checked]="item.checked"                         
                              (change)="changeDoctors(item)">
                              <label class="form-check-label" for="{{item.Id}}">{{item.Name}}</label>
                            </div>
                        </div>
                    </ngx-simplebar>
                </div>
              </ng-template>

            </ngb-panel>
        </ngb-accordion>
      </div>       
      
      <!-- Servicios -->
      <div class="card-body ">
        <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
            <ngb-panel id="static-1" style="background-color: transparent;" >
              <ng-template ngbPanelTitle>
                <button class="border-0 bg-transparent" type="button" data-bs-toggle="collapse"
                  data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                  <span class="text-muted text-uppercase fs-13 fw-medium">Servicios</span>
            
                </button>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="accordion-body text-body p-0">      
                    <div class="form-check form-switch form-switch-md" dir="ltr">
                        <input 
                        type="checkbox"
                        class="form-check-input" 
                        id="all-servicios" 
                        [(ngModel)]="_all_servicios"
                        [checked]="_all_servicios"
                        (change)="changeEventServicios($event)">
                        <label class="form-check-label" for="all-doctors">Todos los servicios</label>
                    </div>

                    <div class="m-2">
                      <div class="d-flex justify-content-center">
                        <input 
                        type="text" 
                        class="form-control" 
                        [(ngModel)]="servicioModel"
                        placeholder="Buscar en los servicios">
                      </div>
                    </div>

                    <ngx-simplebar style="height: 50vh;">
                        <div class="d-flex flex-column gap-2 mt-3" *ngFor="let item of _selectedServicios | filter:servicioModel ">
                            <div class="form-check">
                              <input 
                              class="form-check-input" 
                              name="check-{{item.Id}}" 
                              type="checkbox"                                       
                              [id]="item.Id"
                              [checked]="item.checked"
                              (change)="ChangeServicios(item)">
                              <label class="form-check-label" for="{{item.Id}}">{{item.Name}}</label>
                            </div>                                    
                            <div>
                            </div>
                          </div>
                    </ngx-simplebar>

                </div>
              </ng-template>
            </ngb-panel>
        </ngb-accordion>
      </div>  
      <!-- Recursos -->
      <div class="card-body" *ngIf="_doctorsFilter()">
        <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
            <ngb-panel id="static-1" style="background-color: transparent;" >
              <ng-template ngbPanelTitle>
                <button class="border-0 bg-transparent" type="button" data-bs-toggle="collapse"
                  data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                  <span class="text-muted text-uppercase fs-13 fw-medium">Recursos</span>
            
                </button>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="accordion-body text-body p-0">      
                    <div class="form-check form-switch form-switch-md" dir="ltr">
                        <input 
                        type="checkbox" 
                        [(ngModel)]="_all_recursos"
                        [checked]="_all_recursos"
                        class="form-check-input" 
                        id="all-recursos" 
                        (change)="changeAllRecrusos($event)">
                        <label 
                        class="form-check-label" for="all-doctors">Todos los recursos</label>
                    </div>

                    <div class="m-2">
                      <div class="d-flex justify-content-center">
                        <input 
                        type="text" 
                        class="form-control" 
                        [(ngModel)]="recursoModel"
                        placeholder="Buscar en los servicios">
                      </div>
                    </div>

                    <ngx-simplebar style="height: 50vh;">
                        <div class="d-flex flex-column gap-2 mt-3" *ngFor="let item of _selectedRecursos | filter: recursoModel">
                            <div class="form-check">
                              <input 
                              class="form-check-input" 
                              name="check-{{item.Id}}" 
                              type="checkbox"                                       
                              [id]="item.Id"                             
                              [checked]="item.checked"
                              (change)="changeEventRecruso(item)">
                              <label class="form-check-label" for="{{item.Id}}">{{item.Name}}</label>
                            </div>                                    
                            <div>
                            </div>
                          </div>
                    </ngx-simplebar>

                </div>
              </ng-template>
            </ngb-panel>
        </ngb-accordion>
      </div>  

    </div>
</div>
