import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage } from 'ngx-webcam';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormatsService } from 'src/app/shared/services/formats.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hoja-indicaciones',
  templateUrl: './hoja-indicaciones.component.html',
  styleUrls: ['./hoja-indicaciones.component.scss']
})
export class HojaIndicacionesComponent implements OnInit, OnDestroy {

  @Input()
  id: string;

  @Input()
  _formatoData: any

  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean

  record: any;
  _Cita: Cita;

  isDesktopDevice!: boolean
  _formato_ine_frente: any[] = []
  _formato_ine_frente_pdf: any[] = []

  _formato_ine_reverso: any[] = []
  _formato_ine_reverso_pdf: any[] = []

  constructor (
    private formatService: FormatsService,
    private date: DatePipe,
    private credentials: CredentialsService,
    private deviceService: DeviceDetectorService,
    private alert: AlertsService,
    private toastr: ToastrService
  ) {
    this.record = this.credentials.userInfoData.data.records[0]

    const cita = this.credentials.getCitaJson()
    if(cita) {
      this._Cita = cita
    }

    this.epicFunction();
  }

  ngOnInit(): void {
    if(this._formatoData) {
      this._formato = this._formatoData
      this._label = this._formato.label;
      this._controls = this._formato.form.controls;

      this._itsReady = true

      this.loadImages()
    }
    
  }

  private loadImages() {
    this.formatService._getListImages(this._Cita.Cita_Id,this.record.Name).subscribe({
      next:(response) => {
        if(response.codigo === 200) { 
          const data = response.data
          if(data.formato && data.formato.archivos) {

            const archivos = data.formato.archivos  

            const formato_ine_frente  = archivos.formato_ine_frente ? archivos.formato_ine_frente : []
            const formato_ine_reverso = archivos.formato_ine_reverso ? archivos.formato_ine_reverso : []

            this._formato_ine_frente = []
            this._formato_ine_reverso_pdf = []

            /**
             * 
             */
            _.map(formato_ine_frente, (a) => {
              const _filename = String(a.filename).split('.')
              if(_filename.length === 2 && _filename[1] != 'pdf') {
                this._formato_ine_frente.push(a)
              }else if (_filename.length === 2 && _filename[1] == 'pdf') {
                this._formato_ine_reverso_pdf.push(a)
              }     
            })

            /**
             * 
             */
            _.map(formato_ine_reverso, (a)  => {
              const _filename = String(a.filename).split('.')
              if(_filename.length === 2 && _filename[1] != 'pdf') {
                this._formato_ine_reverso.push(a)
              }else if (_filename.length === 2 && _filename[1] == 'pdf') {
                this._formato_ine_reverso_pdf.push(a)
              }     
            })


          }
        }
      },
    })

  }

  /**
   * 
   */
  private epicFunction() {
    this.isDesktopDevice = this.deviceService.isDesktop()
  }

  /**
   * 
   */
  ngOnDestroy(): void {
    
  }

  /**
   * 
   * @param name 
   * @returns 
   */
  _getValue(name: string) {

    switch (name) {
      case 'name':
        return this.record.Nombre_completo__c       
      case 'age':
        return this.record.P05_Edad__c;
      case 'sex':
        return this.record.P05_Sexo__c;
      case 'n_expediente':
        return this.record.Name;
      case 'birth_date':
        return this.record.P05_Fecha_de_Nacimiento__c
      case 'fecha':
        return this.record.CreatedDate //this.date.transform(, 'yyyy-MM-dd')
      case 'hora':           
        return this.date.transform(this.record.CreatedDate,'hh:mm a')
      
      default:
        return 'N/D'
        break;
    }
  }

  /**
   * 
   * @param files 
   * @param _control 
   */
  _onUploadFiles(_files: any[], _control: any): void {
    this.alert._showLoader('Subiendo, por favor espere')
    console.log({_files});
    this.formatService.UploadFiles(this._Cita.Cita_Id, this.record.Name,_control.name, _files).then((resp) => {

      if(resp) {
        console.log({resp});
      
        this.alert._hideLoader()
        this.toastr.success('Exito al subir los archivos','Exito!!',{
          timeOut: 3200
        })
        this.loadImages()
        return console.log('');        
      }

      this.alert._hideLoader()
      this.toastr.error('Algo salio mal, al tratar de subir los archivos','Error!!', {
        timeOut: 3200
      })

      
    
    }).catch((error) => {
      this.alert._hideLoader()
      this.toastr.error('Algo salio mal, al tratar de subir los archivos','Error!!', {
        timeOut: 3200
      })
    })
  }

  /**
   * 
   * @param webcamImage 
   * @param _control 
   */
  _onSaveItImages(webcamImage: WebcamImage[],  _control: any): void {

    if(webcamImage && webcamImage.length) {

      this.alert._showLoader('Guardando, espere...')         
      this.formatService.UploadFiles(this._Cita.Cita_Id,this.record.Name,_control.name,null, webcamImage).then((resp) => {
        this.alert._hideLoader()
        this.toastr.success('Exito al subir las imagenes','Exito!!',{
          timeOut: 3200
        })
        this.loadImages()
      }).catch((error) => {
        console.error({error});
        
        this.alert._hideLoader()
        this.toastr.error('Algo salio mal, al tratar de subir las imagenes','Error!!', {
          timeOut: 3200
        })
      })
      
  }
    

  }

  _openPDF(filePdf: any) {
    console.log({filePdf});

    setTimeout(() => {
      window.open(filePdf.url, '_blank', 'width=1000, height=800');
    }, 100);
  }


  _onDeleteImg(img: any, _control: any) {
    Swal.fire({
      title:'Por favor confirme esta accion',
      text:'¿Desea eliminar esta imagen '+ img.filename + ' ?',
      showCancelButton:true,
      icon:'question',
      confirmButtonText:'Sí, eliminar',
      cancelButtonText:'No, Cancelar'
    }).then((result) => {
      if(result.value) {
        this.alert._showLoader('Por favor espere...')

        this.formatService._DeleteImg(this._Cita.Cita_Id,this.record.Name,_control.name,img.filename).then((resp) => {          
          console.log({resp});
          this.alert._hideLoader()          
          this.toastr.success('Se ha eliminado correctamente, la imagen','Exito!!', {
            timeOut: 3200
          })
          this.loadImages()

        }).catch((error) => {
          console.error({error});
          this.alert._hideLoader()
          this.toastr.error('No se pudo eliminar, la imagen','Error!!', {
            timeOut: 3200
          })
        })

      }
    })
  }

}
