
<div *ngFor="let toast of toastService.toasts"> 

	<ngb-toast
    [autohide]="true" 
    (hidden)="show = false " 
    [autohide]="autohide"
    (mouseenter)="autohide = false"
	(mouseleave)="autohide = true"
    [delay]="15000" 
	(hidden)="toastService.remove(toast)"
    class="bg-container bg-success text-center text-white  top-0 end-0 p-2" style="z-index: 1200;"
			>
            <ng-template ngbToastHeader>
                <img src="assets/images/logo-sm.png" alt="" class="me-2" height="18">
                <span class="fw-semibold me-auto"> Clinica IML </span>
              <!--   <small>06 mins ago</small> -->
            </ng-template>
            <div class="d-flex justify-content-center"  (click)="_goToForm(toast)">
                <p> <b> {{toast.data.pacient}} <!-- El paciente <b>Daniel nava Vazquez --> </b> ha llegado a su cita </p>
            </div>  
			<ng-template >
               
            </ng-template>

			</ngb-toast>
</div>



<ng-template #tostrTemplate> 
    IML.
</ng-template>

    


