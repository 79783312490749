import { Component } from '@angular/core';

@Component({
  selector: 'app-pages404',
  templateUrl: './pages404.component.html',
  styleUrls: ['./pages404.component.scss']
})
export class Pages404Component {

    // set the current year
    year: number = new Date().getFullYear();

}
