<!-- <app-breadcrumbs ></app-breadcrumbs> -->

<ng-container *ngIf="!isMobile; else mobileTemplate">   

    <fieldset class="mt-4" *ngIf="_LaboratorioFiles && _LaboratorioFiles.length">
        <div class="card">
            <div class="card-body">
                <div class="row d-flex justify-content-between">
                    <div class="col-4" *ngFor="let file of _LaboratorioFiles">
                        <div class="d-flex justify-content-center">
                            <a href="javascript:void(0);" (click)="_openPDF(file)">
                                <i style="font-size: 40px;" class='bx bx-file-blank' > </i>                               
                            </a>                               
                        </div>                                           
                       <div class="mt-4">
                        <div class="d-flex justify-content-center">
                            <button class="btn btn-sm btn-danger" (click)="onDelete(file)"> <i class="bx bx-trash"></i> </button>
                        </div>                        
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <div class="card" *ngIf="_showUploadFiles">
        <div class="card-header">
            SUBIR ARCHIVOS
        </div>
        <div class="card-body">
            <file-pond
            #myPond
            [options]="pondOptions"
            [files]="pondFiles"
            (oninit)="pondHandleInit()"
            (onaddfile)="pondHandleAddFile($event)"
            (onremovefile)="pondRemoveFile($event)">
            </file-pond>
        </div>
        <div class="card-footer" *ngIf="pondFiles && pondFiles.length">
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-success btn-load" (click)="_onUploadFile()">
                    <span class="d-flex align-items-center">
                        <span *ngIf="_uploadFiles" class="spinner-grow flex-shrink-0" role="status">
                            <span  class="visually-hidden">Subiendo archivos...</span>
                        </span>
                        <span class="flex-grow-1 ms-2">
                           Subir archivos
                        </span>
                    </span>
                </button>
            </div>
        </div>
    </div>

    <!-- Align Self -->
    <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-4 align-self-start">
            <div class="row p-2">
                <div class="col-12">
                    <div class="col-md-auto">  
                        <div class="card border shadow-none card-body text-muted mb-0">
                            <div class="m-4 p-2">
                                <div class="form-group">                                  
                                    <input type="text" class="form-control" [(ngModel)]="_searchText" placeholder="Buscar paciente">
                                </div>                                
                            </div>
                            <ngx-simplebar style="height: 50vh;">
                                <div class="list-group p-2" *ngFor="let item of _jsonCalendar | filterPatients: _searchText ">
                                    <button 
                                    type="button" 
                                    (click)="_OnLoadPatient(item)"
                                    [ngClass]="{'active fw-bold': _paciente_id == item.N_Expediente }"
                                    class="list-group-item list-group-item-action" >                                    
                                    {{item.Paciente_Nombre_Completo}}
                                    </button>                           
                                </div>
                            </ngx-simplebar>
                        </div>
                    </div>
                </div>
            </div>           
        </div>
        <div class="col-xl-9 col-lg-9 col-md-8 ">
          
            <div class="card">
                <div class="card-body">  
                    <div class="row g-2 mt-4">
                        <div  [ngClass]="record && record.P00_Nombre__c ? 'col-lg-2': 'd-none' ">
                        
                            <div class="card">
                                <div class="card-body">
                                    <ngx-simplebar style="height: 50vh;">
                                        <div class="list-group p-2" *ngFor="let cita of _citasPaciente let i = index;">
                                            <button 
                                            type="button" 
                                            (click)="onLoadDetallesCita(cita)"
                                            [ngClass]="{'active fw-bold': _cita_id_ == cita.Name }"
                                            class="list-group-item list-group-item-action" >
                                                {{cita.Name}}
                                            </button>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="record && record.P00_Nombre__c ? 'col-lg-10': 'col-lg-12' ">
                         
                            <ng-template [ngTemplateOutlet]="userData"></ng-template>                               
                        </div>
                    </div>
                </div>
         
            </div>
        </div>   
    </div>  
    <!-- <ngx-spinner [name]="spinner1" type="ball-clip-rotate" [fullScreen]="false" size="medium"> </ngx-spinner> -->
</ng-container>


<!-- Mobile Template -->
<ng-template #mobileTemplate>

    <app-mobile-template-pharmacy  
    (eventEmmiterPaciente)="_onEventEmmiterPaciente($event)" 
    (eventEmmiterCita)="_onEventEmmiterCita($event)"
    [eventSubjectCanvas]="eventSubjectSpinner.asObservable()" 
    [_jsonCalendar]="_jsonCalendar"
    [_citasPaciente]="_citasPaciente"
    >
</app-mobile-template-pharmacy>   
     <fieldset class="mt-4" *ngIf="_LaboratorioFiles && _LaboratorioFiles.length">
        <div class="card">
            <div class="card-body">
                <div class="row d-flex justify-content-between">
                    <div class="col-4" *ngFor="let file of _LaboratorioFiles">
                        <div class="d-flex justify-content-center">
                            <a href="javascript:void(0);" (click)="_openPDF(file)">
                                <i style="font-size: 40px;" class='bx bx-file-blank' > </i>                               
                            </a>    
                           
                        </div>      
                                     
                       <div class="mt-4">
                        <div class="d-flex justify-content-center">
                            <button class="btn btn-sm btn-danger" (click)="onDelete(file)"> <i class="bx bx-trash"></i> </button>
                        </div>                        
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <div class="d-flex justify-content-center" *ngIf="record">
        <button (click)="_openModal()" class="btn btn-primary">Cargar archivos</button>
    </div>

    <div class="mt-4">
        <ng-template [ngTemplateOutlet]="userData"></ng-template>
    </div>
   
    
</ng-template>

<div class="row" *ngFor="let formato of _formatos">        
    <div class="col-12" *ngIf="formato.label == 'Historia_Clinica' ">
        <app-clinical-history [_formato]="formato" [_cita_id]="_cita_id_" [createInterval_]="false"></app-clinical-history>
    </div>
</div>

<!-- Card Datos Generales -->
<ng-template #userData>    
    <div class="card">

        <div class="row mt-4">
            <div class="col-12 ">
                <h4 class="fs-4 text-primary bold text-uppercase text-center">Datos Generales del paciente </h4>     
            </div>
        </div>
        <div class="card-body" *ngIf="record && record.P00_Nombre__c">            

            <fieldset class="mt-4">

                <div class="row g-2">    

                    <!-- Nombres -->
                    <div class="col-xxl-5 col-lg-4 col-md-4 col-sm-6">
                        <div class="form-floating">
                            <input 
                            class="form-control"
                            [value]="record.P00_Nombre__c"
                            readonly>
                            <label class="control-label">Nombre (s)</label>
                        </div>
                    </div>
    
                    <!-- Apellidos -->
                    <div class="col-xxl-5 col-lg-5 col-md-5 col-sm-6">
                        <div class="form-floating">
                            <input 
                            class="form-control"
                            [value]="record.P05_Apellidos__c"
                            readonly>
                            <label class="control-label">Apellido (s)</label>
                        </div>
                    </div>
    
                    <!-- Fecha de Nacimiento -->
                    <div class="col-xxl-2 col-lg-3 col-md-3 col-sm-4">
                        <div class="form-floating">
                            <input 
                            class="form-control"
                            [value]="record.P05_Fecha_de_Nacimiento__c | date: 'd-MM-Y' "
                            readonly>
                            <label class="control-label">Fecha de Nacimiento</label>
                        </div>
                    </div>  

                    <!-- Edad -->
                    <div class="col-xxl-2 col-lg-2 col-md-2 col-sm-4">
                        <div class="form-floating">
                            <input 
                            class="form-control"
                            [value]="record.P05_Edad__c"
                            readonly>
                            <label class="control-label">Edad</label>
                        </div>
                    </div>

                    <!-- Sexo -->
                    <div class="col-xxl-3 col-lg-4 col-md-4 col-sm-4">
                        <div class="form-floating">
                            <input 
                            class="form-control"
                            [value]="record.P05_Sexo__c"
                            readonly>
                            <label class="control-label">Sexo</label>
                        </div>
                    </div> 

                    <!-- Estado Civil -->
                    <div class="col-xxl-3 col-lg-4 col-md-4 col-sm-6">
                        <div class="form-floating">
                            <input 
                            class="form-control"
                            [value]="record.P05_Estado_civil__c"
                            readonly>
                            <label class="control-label">Estado civil</label>
                        </div>
                    </div>

                    <!-- Telefono -->
                    <div class="col-xxl-3 col-lg-4 col-md-4 col-sm-6">
                        <div class="form-floating">
                            <input 
                            class="form-control"
                            [value]="record.P05_Telefono__c"
                            readonly>
                            <label class="control-label">Teléfono</label>
                        </div>
                    </div>
                </div>
                
            </fieldset>

            <fieldset class="mt-4">
                <div class="row g-2">
                    <div class="col-12">
                        <div class="">
                            <label class="control-label">Comentario</label>
                            <textarea
                            class="form-control"
                            [value]="record.P05_Comentario__c"
                            [rows]="10"                            
                            readonly                
                            disabled            
                            ></textarea>
                        </div>
                    </div>
                </div>
            </fieldset>

         
        </div>
    </div>
</ng-template>

<ngx-spinner [name]="spinner1" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple" [fullScreen]="true">
    <p style="color: white" > Cargando... </p>
</ngx-spinner>

<ng-template #modalFiles let-modal>
    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title"> Cargar archivos</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
        (click)="closeEventModal()"></button>
    </div>

    <div class="modal-body p-4">
        <div class="card">
            <div class="card-header">
                SUBIR ARCHIVOS
            </div>
            <div class="card-body">
                <file-pond
                #myPond
                [options]="pondOptions"
                [files]="pondFiles"
                (oninit)="pondHandleInit()"
                (onaddfile)="pondHandleAddFile($event)"
                (onremovefile)="pondRemoveFile($event)">
                </file-pond>
            </div>
            <div class="card-footer" *ngIf="pondFiles && pondFiles.length">
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-success btn-load" (click)="_onUploadFile()">
                        <span class="d-flex align-items-center">
                            <span *ngIf="_uploadFiles" class="spinner-grow flex-shrink-0" role="status">
                                <span  class="visually-hidden">Subiendo archivos...</span>
                            </span>
                            <span class="flex-grow-1 ms-2">
                               Subir archivos
                            </span>
                        </span>
                    </button>
                </div>
            </div>
        </div>      
        

    <!--     <div class="container-fluid">
            <dropzone [dropzone]="singleConfig" class="dropzone_sec dropzone-container" message=""   #drpzone="ngxDropzone" >
                <div class="dz-message needsclick">
                    <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
                    <h3 class="dropzone-msg-title mt-4">Sube o selecciona el archivo.</h3>
                    <p class="dropzone-msg-desc">Por favor selecciona el archivo a subir</p>
                </div>                        
            </dropzone>
        </div> -->
    </div>

</ng-template>