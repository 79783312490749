import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { CredentialsService } from '../core/services/credentials.service';
import { TokenStorageService } from '../core/services/token-storage.service';


const routes = {
  default: () => ``,
  doctor :() => `/doctor`,
  plancha:() => `/calendario/plancha`, 
  calendario:() => `/calendario/calendario`,
  error:() => `/default`
}

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(
    private _router: Router, 
    private credentials: CredentialsService,
    private tokenService: TokenStorageService
    
    ) { }

  public to (url?: string, extras? :NavigationExtras  ){
    
    // Get the redirect URL. If no redirect has been set, use the default
    let redirect = url ?? routes.default();

    // Set our navigation extras object
    // that passes on our global query params and fragment
    let navigationExtras: NavigationExtras = extras || {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
    };

    this._router.navigate([redirect], navigationExtras);
    
    /* if(this.credentials.credentials?.roles.Enfermera_General || this.credentials.credentials?.roles.Enfermera_Quirurgica) {

      this._router.navigate([routes.plancha()])

    }else {
      if(this.credentials.credentials?.roles.Anestesiologo) {
                
        this._router.navigate([routes.plancha()], navigationExtras);
      }else {
        this._router.navigate([redirect], navigationExtras);
      }
      
    } */


      // Redirect the user
      
  }

  public _redirectToIndex() {

    const currentUser = this.tokenService.getUser()
    //console.log({_CURRENT_USER: currentUser});

    if(currentUser && (currentUser.role.Administrador || currentUser.role.Doctor )) {
      this._router.navigate([routes.default()])
    } else if (currentUser && (currentUser.role.Anestesiologo || currentUser.role.Enfermera_General || currentUser.role.Enfermera_Quirurgica)) {
      this._router.navigate([routes.plancha()])
    } else if (currentUser && (currentUser.role.Administracion || currentUser.role.Asistencia)) {
      this._router.navigate([routes.calendario()])
    }else {
      this._router.navigate([routes.calendario()])
    }
    

    //if()

    

  }

 /*  private _getRouteDefault(): string {
    if(this.credentials.credentials?.roles.Anestesiologo) {
      return routes.anestesiologo()
    }
    return routes.default()

  } */



  

}
