import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { Recurso } from 'src/app/pages/models/recursos';

@Component({
  selector: 'app-details-recurso',
  templateUrl: './details-recurso.component.html',
  styleUrls: ['./details-recurso.component.scss']
})
export class DetailsRecursoComponent implements OnInit, OnDestroy {
  
  @ViewChild('DetallesRecurso') DetallesRecurso: TemplateRef<any>

  @Input()
    events: Observable<string>;

  private eventsSubscription: Subscription;  
  canvasRecursoRef: NgbOffcanvasRef;

  _recurso: Recurso;

  constructor (
    private _offCanvasService: NgbOffcanvas,
    private calendarService: CalendarService
    ) {}

  ngOnInit(): void {

    this.eventsSubscription = this.events.subscribe((recursoId) => {
      
      if(recursoId) {
        this.LoadData(recursoId)
      }
      
    })

  }

  /**
   * 
   * @param recursoId 
   */
  private LoadData(recursoId: string) {

    this.calendarService._getRecursos(recursoId).subscribe({
      next: (response) => {
        if(response.codigo == 200) {          
          this._recurso = response.data[0];
          this.openCanvas()
        }
      }
    })

   /*   */
  }

  private openCanvas () {

    this.canvasRecursoRef = this._offCanvasService.open(this.DetallesRecurso, {
      animation:true,
      backdrop: true,
      position:'end',
      scroll: false
    })
  }

  ngOnDestroy(): void {
    if(this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  _hideCanvas() {
    //this._offCanvasService.dismiss()
    this.canvasRecursoRef.close()
  }

}
