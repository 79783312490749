<div class="card" *ngIf="_formato && _itsReady">
    <div class="card-body">
        <div class="row g-2 m-4" *ngFor="let item of _controls">

            <ng-container *ngIf="item.type == 'frame' " >
                <div class="col-12" >
                    <div class="bg-primary">
                        <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                    </div>
                </div>
                <div class="row" >              
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2" *ngFor="let obj of item.Objects" > 

                        <div *ngIf="obj.type == 'date' ">
                            <div class="form-floating">
                                <input                        
                                class="form-control flatpickr-input"                                             
                                [id]="obj.name"     
                                [type]="obj.type"     
                                readonly   
                                [readonly]="obj.readonly"
                                
                                              
                                >
                                <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                            </div>
                        </div>

                        <div *ngIf="obj.type == 'text' || obj.type == 'number' ">
                            <div class="form-floating">
                                <input 
                                class="form-control"
                                [type]="obj.type"
                                [name]="obj.name"
                                [readonly]="obj.readonly"
                                [value]="_getValue(obj.name)"
                                [required]="obj.required"
                                >
                                <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                            </div>                              
                        </div>

                        <div *ngIf="obj.type == 'time' ">
                            <input 
                            type="text"
                            [name]="obj.name"
                            [value]="_getValue(obj.name)"
                            class="form-control" 
                            >
                        </div>


                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="item.type == 'table' ">
                <hr>
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2" *ngFor="let obj of item.Objects">

                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input                        
                                    class="form-control flatpickr-input"                                             
                                    [id]="obj.name"     
                                    [type]="obj.type"     
                                    readonly   
                                    [readonly]="obj.readonly"
                                    
                                                  
                                    >
                                    <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'time' ">
                                <input 
                                type="text"
                                [name]="obj.name"
                                
                                class="form-control" 
                                >
                            </div>
    

                            <div *ngIf="obj.type == 'text' || obj.type == 'number' ">
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [readonly]="obj.readonly"
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>                              
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- INE -->
            <ng-container *ngIf="item.name === 'formato_ine_1' ">
                <div class="col-12">
                    <div class="card mt-4" *ngFor="let obj of item.Objects">
                        <div class="card-header">
                            <h4 class="card-title text-uppercase text-muted"> {{obj.label}}  </h4>
                        </div>
                        <div class="card-body">       

                            <div *ngIf="obj.name === 'formato_ine_frente' ">
                                <ng-container *ngIf="_formato_ine_frente && _formato_ine_frente.length || _formato_ine_frente_pdf && _formato_ine_frente_pdf.length; else uploadTemplate ">
                                    <div class="d-flex mt-4" *ngIf="_formato_ine_frente && _formato_ine_frente.length">
                                        <app-gallery-image [base64]="false" [GalleryImage]="_formato_ine_frente" [_showDeleteBtn]="true" (eventEmmitDelete)="_onDeleteImg($event, obj)"></app-gallery-image>
                                    </div>
                                    
                                    <div class="d-flex mt-4" *ngIf="_formato_ine_frente_pdf && _formato_ine_frente_pdf.length">
                                        <fieldset>
                                          <legend class="text-muted fs-12 fw-bold"> Archivos</legend>
                                          <div class="card card-body">
                                              <div class="row d-flex justify-content-between">
                                                  <div class="col-4" *ngFor="let file of _formato_ine_frente_pdf">
                                                      <div class="d-flex justify-content-center">
                                                          <a href="javascript:void(0);" (click)="_openPDF(file)">
                                                              <i style="font-size: 40px;" class='bx bx-file-blank' > </i>                               
                                                          </a>                               
                                                      </div> 
                                                  </div>
                                              </div>
                                          </div>  
                                        </fieldset>                                      
                                      </div>
                                </ng-container>   
                            </div>

                            <div *ngIf="obj.name === 'formato_ine_reverso' ">
                                <ng-container *ngIf="_formato_ine_reverso && _formato_ine_reverso.length || _formato_ine_reverso_pdf && _formato_ine_reverso_pdf.length; else uploadTemplate "> 
                                    <div class="d-flex mt-4" *ngIf="_formato_ine_reverso && _formato_ine_reverso.length">
                                        <app-gallery-image [base64]="false" [GalleryImage]="_formato_ine_reverso" (eventEmmitDelete)="_onDeleteImg($event, obj)" [_showDeleteBtn]="true" ></app-gallery-image>
                                    </div>
                                    
                                    <div class="d-flex mt-4" *ngIf="_formato_ine_reverso_pdf && _formato_ine_reverso_pdf.length">
                                        <fieldset>
                                          <legend class="text-muted fs-12 fw-bold"> Archivos</legend>
                                          <div class="card card-body">
                                              <div class="row d-flex justify-content-between">
                                                  <div class="col-4" *ngFor="let file of _formato_ine_reverso_pdf">
                                                      <div class="d-flex justify-content-center">
                                                          <a href="javascript:void(0);" (click)="_openPDF(file)">
                                                              <i style="font-size: 40px;" class='bx bx-file-blank' > </i>                               
                                                          </a>                               
                                                      </div> 
                                                  </div>
                                              </div>
                                          </div>  
                                        </fieldset>                                      
                                      </div>
    
    
                                </ng-container>
                            </div>

                            <ng-template #uploadTemplate>
                                <div class="mt-4">
                                    <fieldset>  
                                        <div class="card card-body">
                                        <app-custom-upload-files 
                                        [showUpload]="true" 
                                        [_maxFiles]="1" 
                                        [_allowMultiple]="false"
                                        [_showGallery]="true"
                                        [_acceptedFileTypes]="['image/jpeg','image/png','image/jpg','application/pdf']"
                                        (_filesPondsEvent)="_onUploadFiles($event,obj)">
                                        </app-custom-upload-files>
                                        </div>
                                    </fieldset>    
                                    <div class="mt-4" *ngIf="isDesktopDevice">
                                        <app-ng-web-cam 
                                        [allowOnlyOneImg]="true"
                                        (eventSaveImages)="_onSaveItImages($event, obj)"
                                        ></app-ng-web-cam>                                         
                                    </div>
                                </div>  
                            </ng-template>   
                                                                               
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>
