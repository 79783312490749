import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, delay, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormatsService } from 'src/app/shared/services/formats.service';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import * as moment from 'moment';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';

import { Anestesilogo } from 'src/app/pages/calendar/models/anestesiologo';

interface Firma {
  ruta_archivo_firma?:string
  url:string
  filename:string
}

@Component({
  selector: 'app-consentimiento-aplicacion-sedacion',
  templateUrl: './consentimiento-aplicacion-sedacion.component.html',
  styleUrls: ['./consentimiento-aplicacion-sedacion.component.scss']
})
export class ConsentimientoAplicacionSedacionComponent implements OnInit, OnDestroy {

  @Input()
  id: string

  @Input()
  _formatoData: any
  
  
  _controls: any[] = []
  _formato: any = {}
  record: any;

  _Cita: Cita;
  _label: string

  _itsReady: boolean

  _today = new Date()

  _formConsentimiento: FormGroup = this.formBuilder.group({})
  $interval!: Subscription;
  _interval: number = 0;

  _firmaPaciente!: any[] | null
  _firmaMedico!:  any[]  | null
  _firmaTestigo1!:any[]  | null
  _firmaTestigo2!: any[]  | null

  _anestesiologo: string = 'true'
  _anestesiologos: Anestesilogo[] = []
  _firma:Firma | null

  constructor (
    private formBuilder: FormBuilder,
    private formatService: FormatsService,
    private alert: AlertsService,
    private credentials: CredentialsService,
    private toastr: ToastrService,
    private calendar: CalendarService,
    
  ) {

    flatpickr.localize(Spanish)

    this.record = this.credentials.userInfoData.data.records[0]
    const cita = this.credentials.getCitaJson()
    const dataUser = this.credentials._getdataUser()
    
    if(dataUser) {
      console.log({dataUser});
 /*      if(cita && cita.Empleado_Id){
        this.calendar._getDoctores(cita.Empleado_Id).subscribe({
          next:(response) => {
            console.log({response});
            
          },
          error(err) {
            console.error({err});
            
          },
        })
      } */
      
        /**
         * // TODO
         * Esperar cambio en el servicio
         */
     // this.loadImagesByUser(dataUser)
    }

    if(cita) {
      this._Cita = cita
    }

    this.loadAnestesilogos()
    
    
  }

  ngOnInit(): void {

    if(this._formatoData) {
      
      this._formato = this._formatoData
      
      this._label = this._formato.label;
      this._controls = this._formato.form.controls;

      this._itsReady = true
      this.initForm();
      this.loadImages() 


    }
    

  /*   if(this.id) {
      this._itsReady = false;
      this.formatService._getFormat(this.id, this._Cita.Cita_Id).subscribe({
        next:(response) => {
          if(response.codigo == 200){
            this._formato = response.data.formato;

            if(this._formato.form){
              this._label = this._formato.label;
              this._controls = this._formato.form.controls;

              this._itsReady = true
             // this.initForm();
            }else {

            }
          }
        },error: (error) => {
          console.error({error});          
        }
      })
    }  */  
    
  }

  private loadAnestesilogos() {
    this.calendar._getAntestesilogo(this._anestesiologo).subscribe({
      next:(resp) => {
        if(resp.codigo === 200) {
          this._anestesiologos = resp.data.info
        }

      },error:(error) => {
        console.error({error});
        
      }
    })
  }

  private loadImages () {
    this.formatService._getListImages(this._Cita.Cita_Id, this.record.Name).subscribe({
      next:(response) => {
        if(response.codigo === 200) {
          const data = response.data

          if(data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos  
            console.log({archivos});

            this._firmaPaciente = archivos.firma_paciente_nombre_firma_consentimiento_informado_aplicacion_sedacion ? archivos.firma_paciente_nombre_firma_consentimiento_informado_aplicacion_sedacion : null   
            
            this._firmaMedico = archivos.firma_medico_nombre_firma_consentimiento_informado_aplicacion_sedacion ? archivos.firma_medico_nombre_firma_consentimiento_informado_aplicacion_sedacion : null

            this._firmaTestigo1 = archivos.firma_testigo_1_nombre_firma_consentimiento_informado_aplicacion_sedacion ? archivos.firma_testigo_1_nombre_firma_consentimiento_informado_aplicacion_sedacion : null

            this._firmaTestigo2 = archivos.firma_testigo_2_nombre_firma_consentimiento_informado_aplicacion_sedacion ? archivos.firma_testigo_2_nombre_firma_consentimiento_informado_aplicacion_sedacion : null

            console.log({_firmaMedico___: this._firmaMedico});
            

            
          }
        }
      }
    })
  }
  
  private loadImagesByUser (user: any) {
      this.formatService._getImageProfile(user.usuario).subscribe({
        next:(response) => {
          
          if(response.codigo === 200) {
            const archivos = response.data.formato.archivos

            if(archivos) {
              this._firma = archivos.Firma[0]
            }
          }          
        },error(err) {
          console.error({err});          
        },
      })
  }

  ngOnDestroy(): void {
    if(this.$interval) {
      this.$interval.unsubscribe()
    }
    this._savePrevDestroy()
  }


  private _savePrevDestroy(): void {
    let _formValues = this._formConsentimiento.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  private initForm () {

    _.map(this._controls, (a) => {
      _.map(a.Objects, (b) => {       

        let _value = b.value;

        if(b.name === 'fecha_consentimiento_informado_aplicacion_sedacion') {
          _value = this._today
        }
        
        if(b.name === 'cumplimiento_consentimiento_informado_aplicacion_sedacion') {        
         _value = this.record.P00_Nombre__c                   
        }
        if (b.name === 'detalle_cumplimiento_consentimiento_informado_aplicacion_sedacion') {
          _value = this.record.P05_Apellidos__c
        }
        
        if (b.name === '1_acepto_consentimiento_informado_aplicacion_sedacion' || b.name === 'completo_medico_nombre_firma_consentimiento_informado_aplicacion_sedacion' ) {
          _value= this._Cita.Doctor
        }

        if(b.name === 'hora_consentimiento_informado_aplicacion_sedacion' && _value === null ) {
          _value = moment().format('H:mm')    
        }

/*          {
         
        }else if (b.name === 'hora_consentimiento_informado_aplicacion_sedacion') {

          const date = 

          const  _value_ = moment(_value).format('H:mm')   

          console.log({_value_});
          
        } */
        
        if(b.name === '2_consentimiento_informado_aplicacion_sedacion' && _value === null ) {
          _value = 'si_acepto_2_consentimiento_informado_aplicacion_sedacion'
        }

        if(b.name === '3_consentimiento_informado_aplicacion_sedacion' && _value === null ) {
          _value = 'si_acepto_3_consentimiento_informado_aplicacion_sedacion'
        }

        if(b.name === '3_2_consentimiento_informado_aplicacion_sedacion' && _value === null ) {
          _value = 'si_acepto_3_2_consentimiento_informado_aplicacion_sedacion'
        }

        
        if(b.name === '4_consentimiento_informado_aplicacion_sedacion' && _value === null ) {
          _value = 'si_acepto_4_consentimiento_informado_aplicacion_sedacion'
        }

        
        if(b.name === '5_consentimiento_informado_aplicacion_sedacion' && _value === null ) {
          _value  = 'si_acepto_5_consentimiento_informado_aplicacion_sedacion'
        }

        if(b.name === 'completo_nombre_firma_consentimiento_informado_aplicacion_sedacion' ) {
          _value = this.record.Nombre_completo__c
        }

        if(b.name === '6_consentimiento_informado_aplicacion_sedacion' && _value === null ) {
          _value = 'si_acepto_6_consentimiento_informado_aplicacion_sedacion'
        }

        if(b.name === '7_consentimiento_informado_aplicacion_sedacion' && _value === null ) {
          _value = 'si_acepto_7_consentimiento_informado_aplicacion_sedacion'
        }

     /*  

      

      

 */


        _.map(b.Objects, (c) => {          
          this._formConsentimiento.addControl(c.name, this.formBuilder.control(c.value))          
        })
        
        this._formConsentimiento.addControl(b.name, this.formBuilder.control( _value ))
      })      
    })
    
    this.createInterval()

  }

  private createInterval () {
    const myObservable = interval(1000);
    this.$interval = myObservable.pipe(
      delay(500),

    ).subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();        
        this._newSaveFormat()            
      }           

      this._interval = integer            
    })
  }

  private _newSaveFormat() {
    
    let _formValues = this._formConsentimiento.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    console.log({_bodyForm});
    this._saveFormatValues(_bodyForm, true)
    

  }

  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  private _saveFormatValues (_jsonObject: any, createInterval: boolean = false): void  {
    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información, correctamente','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        this.$interval.unsubscribe()

        if(createInterval) {     
          this.createInterval()
        }

      })
    }).catch((error) => {
      console.error({error});
      
    })

  }


  /**
   * 
   * @param event 
   * @param main_label 
   */
  _onSaveSing(event: any, main_label: string) {  

    if(event) {
      this.alert._showLoader('Cargando, espere...')

      this.formatService.UploadFirmas(this._Cita.Cita_Id,this.record.Name, main_label, event).then((resp) => {  
        this.alert._hideLoader()
        this.loadImages()
        this.toastr.success('Firma, cargada exitosamente','Exito!!',{
          timeOut: 3200
        })
      }).catch((error) => {
        console.error({_ERROR_LOAD_FIRMA: error });
        
        this.alert._hideLoader()
        this.toastr.error('Error al cargar la firma','Error!!',{
          timeOut: 3200
        })
      })
    }
    
  }

}


