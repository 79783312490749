import { Component, OnInit } from '@angular/core';
import { SessionPageService } from '../../services/session-page.service';

@Component({
  selector: 'app-layout-dashboard',
  templateUrl: './layout-dashboard.component.html',
  styleUrls: ['./layout-dashboard.component.scss']
})
export class LayoutDashboardComponent { 

  constructor(
    private sessionPage: SessionPageService
  ) {}


  _getTabId() {
    return this.sessionPage._getTabId()
  }

  onChange(event: Event) {
    const _val = (event.target as HTMLInputElement).outerText; //.srcElement.offsetParent.offsetParent.dataset;
    let _tab_id: number = 1;

    switch (_val) {

      case 'Calendario':
        _tab_id = 1
        break;

      case 'Tabla de Citas':
        _tab_id  = 2;
        break;

      case 'Plancha' :
          _tab_id = 3
        break;
    
      default:
        _tab_id = 1;
        break;
    }

    this.sessionPage._setActiveTab(_tab_id);   
  }

}
