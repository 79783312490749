import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { VoiceRecognitionService } from '../../services/voice-recognition.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-text-area-voice-recorder',
  templateUrl: './text-area-voice-recorder.component.html',
  styleUrls: ['./text-area-voice-recorder.component.scss']
})
export class TextAreaVoiceRecorderComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
   name: string
  @Input()
   required: boolean
  @Input()
   control: FormControl
  @Input()
   readonly: boolean
  @Input()
   rows: number
  @Input()
  transcript: string
  
  _text_value: string 


  @Output()
  eventEmmiterValue: EventEmitter<string> = new EventEmitter<string>()

  itsRecording:boolean = false 
  subscription: Subscription | undefined;

  constructor (
    public voice: VoiceRecognitionService
  ) {
   
  }

  ngOnInit(): void {

    this.voice.init()

    this._text_value = this.transcript

//    console.log({transcript___: this.transcript});
    

    this.subscription = this.voice.transcript.subscribe((text) => {
      this.transcript = text;
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
   // console.log({___________________transcript__________________: this.transcript});
    
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.voice.stopListening(); // Clean up when component is destroyed
  }

  /**
   * 
   */
  startListening() {
    this.itsRecording = ! this.itsRecording
    this.voice.startListening();
  }

  /**
   * 
   */
  stopListening() {
    this.itsRecording = ! this.itsRecording
    this.voice.stopListening();
    const _value_ = `${this._text_value} ${this.transcript}`
    this._text_value = _value_
    this.eventEmmiterValue.emit(_value_)

  }

  _onModelChange() {
    //console.log({_evebt_______: this._text_value});    
  }






}
