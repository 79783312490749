
<div class="card" *ngIf="showUpload">
    <div class="card-header">
        SUBIR ARCHIVOS
    </div>
    <div class="card-body">
        <!-- app.component.html -->
        <file-pond
        #myPond
        [options]="pondOptions"
        [files]="pondFiles"
        (oninit)="pondHandleInit()"
        (onaddfile)="pondHandleAddFile($event)"
        (onaddfileprogress)="pondFileProgress($event, $event)"
        (onaddfilestart)="pondFileStart($event)"
        (onprocessfilestart)="pondProcessfilestart($event)"
        (onupdatefiles)="onupdatefiles($event)"
        (onactivatefile)="pondHandleActivateFile($event)"
        (onprocessfiles)="pondProcessfiles($event)"
        (onpreparefile)="onpreparefile($event)"
        (onprocessfileprogress)="onprocessfileprogress($event)"                
        >
        </file-pond>
    </div>
    <div class="card-footer">
        <div class="d-flex justify-content-center">
            <button (click)="_onUploadFile()" class="btn btn-primary">Subir archivos </button>
        </div>
    </div>
</div>