<div *ngIf="_formato &&  _itsReady">
    <form [formGroup]="_formAvisoPrivacidad">
        <div class="mt-2" *ngFor="let control of _formato.form.controls">
            <div *ngFor="let objects of control.Objects">
    
                <div *ngIf="objects.type === 'label'">
                    <div class="container-fluid">
                        <p class="text-wrap lead lh-base"> {{objects.value}} </p>
                    </div>
                </div>
    
                <div *ngIf="objects.type === 'text' ">
                    <div class="form-group">
                        <label class="control-label"> {{objects.label}} </label>
                        <input type="text" [formControlName]="objects.name" class="form-control">
                    </div>  
                </div>
                
                <div *ngIf="objects.type === 'canvas'">
                    <label class="control-label"> {{objects.label}} </label>
                    <ng-container *ngIf="firma_paciente_aviso_privacidad != null; else _firmaTemplate">
                        <div class="card card-body">
                            <div class="d-flex justify-content-center">
                                <figure class="figure mb-0">
                                    <img [src]="firma_paciente_aviso_privacidad" class="figure-img img-fluid" max  alt="" width="300">
                                </figure> 
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #_firmaTemplate>
                        <app-firma-doctor (eventsignatureImg)="_onSaveSing($event,objects.name)"></app-firma-doctor>
                    </ng-template>
                    <!-- <ng-template [ngTemplateOutlet]="_firmaTemplate" ></ng-template> -->
                </div>            
    
               
            </div>
        </div>
    </form>
</div>