<div class="card" *ngIf="_formato && _itsReady">
    <div class="card-body">        
        <form [formGroup]="formDescQuirurgica" class="needs-validation createfile-form" autocomplete="off" novalidate>
            <div class="row g-2 m-4" *ngFor="let item of _controls">

                <ng-container *ngIf="item.type == 'frame' ">
                        
                    <div class="col-12">
                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                        </div>
                    </div>

                    <div class="row" >              
                        <div class="mt-4 mr-2" *ngFor="let obj of item.Objects" [ngClass]="obj.type == 'textarea' || obj.type =='label' ? 'col-12' : 'col-sm-12 col-md-6 col-lg-6 col-xl-4' " > 

                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input                        
                                    class="form-control flatpickr-input"                                             
                                    [id]="obj.name"     
                                    [type]="obj.type"     
                                    [formControlName]="obj.name"                                    
                                    readonly   
                                    [readonly]="obj.readonly"
                                    >
                                    <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'text' || obj.type == 'number' ">
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [readonly]="obj.readonly"
                                    [formControlName]="obj.name"
                                    [value]="_getValue(obj.name)"
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>                              
                            </div>

                            <div *ngIf="obj.type == 'time' ">
                                <input 
                                type="text"
                                [name]="obj.name"
                                [formControlName]="obj.name"
                                [value]="_getValue(obj.name)"
                                class="form-control" 
                                >
                            </div>

                            <div *ngIf="obj.type == 'textarea' ">
                                <div class="card-body">
                                    <div class="row mt-3">
                                        <div class="col-xl-3">
                                            <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                        </div>
                                        <div class="col-xl-9 col-lg-12">                                   
                                            <textarea
                                            [name]="obj.name"
                                            class="form-control"
                                            [formControlName]="obj.name"
                                            [readonly]="obj.readonly"
                                            [rows]="5"></textarea>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
                <!-- 
                    //TODO

                    Corregir Canvas Firma

                 -->

            <!--    <ng-container *ngIf="item.type  == 'label' ">
                    <div class="row g-2 m-4">
                        <div class="mt-4 mr-2" *ngFor="let obj of item.Objects" [ngClass]="obj.type == 'textarea' || obj.type =='label' ? 'col-12' : 'col-sm-12 col-md-6 col-lg-6 col-xl-6' ">
                            <fieldset>
                                <legend class="fs-14 text-muted"> {{obj.label}}</legend>
                                <div class="card-body">
                                    <app-canvas-firma-doctor></app-canvas-firma-doctor>
                                </div>
                            </fieldset>                        

                        </div>
                    </div>                
                </ng-container> -->
            </div>
        </form>
    </div>
</div>
