<div class="card" *ngIf="_formato && _itsReady">
    <div class="card-body" *ngFor="let control of _controls">
        <form [formGroup]="_formControl" class="needs-validation createfile-form" autocomplete="off" novalidate>
            <div class="row g-2 mt-4">
                <div class="row g-2 mt-4">
                    <ng-container *ngIf="control.type == 'frame'; else titleTemplate ">
                        <!-- TITULOS -->
                        <div class="bg-primary">
                           <h4 class="fs-4 text-white bold text-uppercase text-center">{{control.label}} </h4>     
                       </div>
       
                   </ng-container>

                    <ng-template #titleTemplate>
                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{control.label}} </h4>
                        </div>

                        <div class="card">
                            <div class="card-body">
                                <div class="col-12">
                                    <div class="row g-2">
                                        <div *ngFor="let objects of control.Objects; let i = index" class="col-12">
                                         <div class="row g-2">
                                            <div class="col-12">
                                                <span class="fs-normal lh-sm"> {{objects.label}} </span>
                                            </div>
                                            <div class="col-12">

                                                <div *ngIf="objects.type == 'text' || objects.type == 'time' || objects.type == 'number' ">
                                                    <div class="">
                                                        <input
                                                        [type]="objects.type"
                                                        [name]="objects.name"
                                                        [formControlName]="objects.name"
                                                        [required]="objects.required"
                                                        [readonly]="objects.readonly"                                                       
                                                        class="form-control">                                                      
                                                    </div>
                                                </div>
                                                <div *ngIf="objects.type == 'date' ">
                                                    <div class="">
                                                        <input                                                 
                                                        class="form-control flatpickr-input" 
                                                        [formControlName]="objects.name"                                                                         
                                                        [id]="objects.name"     
                                                        [type]="objects.type"     
                                                        mwlFlatpickr 
                                                        [altInput]="true" 
                                                        [value]="objects.value"
                                                        [convertModelValue]="true"                   
                                                        >                                                        
                                                    </div>
                                                </div>
                                                <div *ngIf="objects.type == 'checkbox' ">
                                                    <div class="form-check form-check-right mb-3">
                                                        <!--        (change)="_onCheckChange($event, objects.name)" -->
                                                        <input
                                                        class="form-check-input" 
                                                        [type]="objects.type" 
                                                        [name]="objects.name" 
                                                        [required]="objects.required"
                                                        [formControlName]="objects.name"
                                                        [checked]="objects.value == 'false' ? false : objects.value "                                         
                                                        [id]="objects.name + i">
                                                        <!-- <label class="form-check-label" for="{{objects.name + i}}">{{objects.label}}</label> -->
                                                    </div>
                                                </div>
                                                
                                                <ng-container *ngIf="objects && objects.Objects">
                                                    <div class="row mt-3 gp-2">
                                                        <div  *ngFor="let objs of objects.Objects" 
                                                        [ngClass]="objs.type == 'checkbox' || objs.type == 'radio' ? 'col-4' : 'col-12' "  >

                                                            <div *ngIf="objs.type == 'text' || objs.type == 'time' || objs.type == 'number' ">

                                                                <div class="row mt-3 g-2">
                                                                    <div class="col-12">
                                                                        <span class="fs-normal lh-sm"> {{objs.label}} </span>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="">
                                                                            <input
                                                                            [type]="objs.type"
                                                                            [name]="objs.name"
                                                                            [required]="objs.required"
                                                                            [readonly]="objs.readonly"                                                       [formControlName]="objs.name"
                                                                            class="form-control">
                                                                          <!--   <label class="form-label bold text-uppercase"> {{objs.label}} </label> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="objs.type == 'checkbox' ">
                                                                <div class="form-check  mb-3">
                                                                    <input
                                                                    class="form-check-input" 
                                                                    type="checkbox"
                                                                    [name]="objs.name"
                                                                    [required]="objs.required"
                                                                    [formControlName]="objs.name"
                                                                    [readonly]="objs.readonly">
                                                                    <label class="form-check-label" for="{{objs.name}}">{{objs.label}} </label>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="objs.type == 'radio' ">
                                                                <div class="form-check form-check-right form-radio-primary mb-3">
                                                                    <input 
                                                                    class="form-check-input" 
                                                                    type="radio" 
                                                                    [name]="objects.name"         
                                                                    [value]="objs.name"                                        
                                                                    id="{{objs.name}}" 
                                                                    [formControlName]="objects.name"
                                                                    >
                                                                    <label class="form-check-label" for="{{objs.name}}">
                                                                    {{objs.label}}
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="objs.Objects" class="mt-4">
                                                                <span class="fs-normal lh-sm"> {{objs.label}} </span>
                                                                <div class="row mt-3 gp-2">
                                                                    <div *ngFor="let _objs of objs.Objects" [ngClass]="_objs.type == 'checkbox' || _objs.type == 'radio' ? 'col-4' : 'col-12' " >
                                                                        <div *ngIf="_objs.type == 'radio' ">
                                                                            <div class="form-check form-check-right form-radio-primary mb-3">
                                                                                <input 
                                                                                class="form-check-input" 
                                                                                type="radio" 
                                                                                [name]="objs.name"         
                                                                                [value]="_objs.name"                                        
                                                                                id="{{_objs.name}}" 
                                                                                [formControlName]="objs.name"
                                                                                >
                                                                                <label class="form-check-label" for="{{_objs.name}}">
                                                                                {{_objs.label}}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <div *ngIf="objects.type === 'canvas' ">
                                                    
                                                    <div *ngIf="objects.name === 'firma_paciente_nombre_firma_consentimiento_informado_procedimiento_diagnostico' ">
                                                        <ng-container *ngIf="_firma_paciente_nombre_firma_consentimiento_informado_procedimiento_diagnostico; else _firmaTemplate">
                                                            <div class="card card-body">
                                                                <div class="d-flex justify-content-center">
                                                                    <figure class="figure mb-0">
                                                                        <img [src]="_firma_paciente_nombre_firma_consentimiento_informado_procedimiento_diagnostico.url" class="figure-img img-fluid" max  alt="" width="300">
                                                                    </figure> 
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div *ngIf="objects.name === 'firma_medico_nombre_firma_consentimiento_informado_procedimiento_diagnostico' ">
                                                        <ng-container *ngIf="_firma_medico_nombre_firma_consentimiento_informado_procedimiento_diagnostico; else _firmaTemplate">
                                                            <div class="card card-body">
                                                                <div class="d-flex justify-content-center">
                                                                    <figure class="figure mb-0">    
                                                                       <!--  <div *ngIf="_firma"> -->
                                                                            <img [src]="_firma_medico_nombre_firma_consentimiento_informado_procedimiento_diagnostico.url" class="figure-img img-fluid" max  alt="" width="300">
                                                                    <!--     </div>     -->         
                                                                    </figure> 
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div *ngIf="objects.name === 'firma_testigo_1_nombre_firma_consentimiento_informado_procedimiento_diagnostico' ">
                                                        <ng-container *ngIf="_firma_testigo_1_nombre_firma_consentimiento_informado_procedimiento_diagnostico; else _firmaTemplate">
                                                            <div class="card card-body">
                                                                <div class="d-flex justify-content-center">
                                                                    <figure class="figure mb-0">
                                                                        <img [src]="_firma_testigo_1_nombre_firma_consentimiento_informado_procedimiento_diagnostico.url" class="figure-img img-fluid" max  alt="" width="300">
                                                                    </figure> 
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div *ngIf="objects.name === 'firma_testigo_2_nombre_firma_consentimiento_informado_procedimiento_diagnostico' ">
                                                        <ng-container *ngIf="_firma_testigo_2_nombre_firma_consentimiento_informado_procedimiento_diagnostico; else _firmaTemplate">
                                                            <div class="card card-body">
                                                                <div class="d-flex justify-content-center">
                                                                    <figure class="figure mb-0">
                                                                        <img [src]="_firma_testigo_2_nombre_firma_consentimiento_informado_procedimiento_diagnostico.url" class="figure-img img-fluid" max  alt="" width="300">
                                                                    </figure> 
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <ng-template #_firmaTemplate>
                                                        <app-firma-doctor (eventsignatureImg)="_onSaveSing($event,objects.name)"></app-firma-doctor>
                                                    </ng-template>
                                                </div>
                                            </div>
                                         </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>

                    </ng-template>
                </div>
            </div>
        </form>
    </div>
</div>

