import {  Component,  ViewChild, forwardRef } from '@angular/core';

import { NgSignaturePadOptions} from '@almothafar/angular-signature-pad'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-canvas-firma-doctor',
  templateUrl: './canvas-firma-doctor.component.html',
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CanvasFirmaDoctorComponent),
      multi: true
    }
  ],
  styleUrls: ['./canvas-firma-doctor.component.scss']
})
export class CanvasFirmaDoctorComponent implements ControlValueAccessor {

 // @ViewChild('signature') signature: ElementRef;
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;

  public options: Object = {};

  public _signature: any = null;

  signatureNeeded: boolean = true

  public propagateChange: Function //= null;

  signatureImg!: string;

//  public signaturePad: SignaturePadComponent;

  signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300
  };

  constructor () {}
  registerOnTouched(fn: any): void {
    throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
/* 
  ngOnInit(): void {
    
  } */

  ngAfterViewInit(): void {
    this._init()
    //this.initSigned()
   //
    
  }

  private _init(): void {
    setTimeout(() => {
      const canvas = document.querySelector('canvas')
      if(canvas) {
        this.signaturePad = new SignaturePad(canvas)
      }
      this.signaturePad.clear()
      
    }, 300);
  }

  get signature(): any {
    return this._signature;
  }

/*   set signature(value: any) {
    this._signature = value;
    console.log('set signature to ' + this._signature);
    console.log('signature data :');
    console.log(this.signaturePad.toData());
    this.propagateChange(this.signature);
  } */

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this._signature = value;
    this.signaturePad.fromDataURL(this.signature);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  private initSigned() {
  /*   setTimeout(() => {
      this.signaturePad = new SignaturePadComponent(this.signature.nativeElement)
      this.signaturePad.set('minWidth', 5);
      this.signaturePad.clear(); 
    }, 300); */
  }

  public drawBegin(): void {
    console.log('Begin Drawing');
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
   // console.log('Completed drawing', event);
    this.signatureImg = this.signaturePad.toDataURL('image/jpeg', 0.5);
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Start drawing', event);
  }

  public clearPad(): void {
    this.signatureNeeded = true
    this.signaturePad.clear();
    this.signatureImg = ''
    //this.signature = 
    this._init()
  }

  savePad () {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (!this.signatureNeeded) {
      this.signatureNeeded = false;
    }
  }

}
