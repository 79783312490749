import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EliminarReceta, NuevoProducto, RecetaMedicamento } from '../models/medicamentos';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecetasService {

  private readonly apiURL: string = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * 
   * @returns 
   */
  getMedicamentos() {
    return this.http.get(`${this.apiURL}receta/medicamentos_materiales`)
  }

  /**
   * 
   * @param _receta 
   * @returns 
   */
  async CrearRecetaMedicamento(_receta: RecetaMedicamento) {
    return await firstValueFrom(  this.http.post(`${this.apiURL}receta/agregar_receta`,_receta))
  }

  async _GuardarMedicamentonuevo(_producto:NuevoProducto) {
    return firstValueFrom(
      this.http.post(`${this.apiURL}receta/agregar_productos_receta`,_producto)
    )
  }

  /**
   * 
   * @param _receta 
   */
  async EditarReceta(_receta: RecetaMedicamento) {
    return await firstValueFrom(  this.http.post(`${this.apiURL}receta/editar_receta`,_receta))
  }

  /**
   * Eliminarreceta
   * @param receta 
   * @returns 
   */
  async _eliminarReceta(receta: EliminarReceta) {
    return await firstValueFrom(this.http.post(`${this.apiURL}receta/eliminar_receta`,receta))
  }

  /**
   * E-172403
   * @param _citaID 
   * @returns 
   */
  GetRecetaByCita(_citaID: string) {
    return this.http.get(`${this.apiURL}receta/consultar_receta?cita_id=${_citaID}`)
  }

  /**
   * 
   * @param cita_id 
   * @param paciente_id 
   * @param id_receta 
   * @returns 
   */
  ImprimirRecceta(cita_id: string, paciente_id : string,  id_receta : string  ) {
    return this.http.post(`${this.apiURL}receta/generar_pdf_receta?cita_id=${cita_id}&paciente_id=${paciente_id}&id_receta=${id_receta}`,{})
  }

 
  /**
   * 
   * @returns 
   */
  GetTipoMedicamento() {
    return firstValueFrom(this.http.get(`${this.apiURL}receta/tipo`))
  }
}
