import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-full-calendar',
  templateUrl: './loader-full-calendar.component.html',
  styleUrls: ['./loader-full-calendar.component.scss']
})
export class LoaderFullCalendarComponent {

}
