import { Component } from '@angular/core';

@Component({
  selector: 'app-history-logs',
  templateUrl: './history-logs.component.html',
  styleUrls: ['./history-logs.component.scss']
})
export class HistoryLogsComponent {

}
