import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

interface SelectedFIlters   {
  Id: string
  Name: string
  checked: boolean;
}

@Component({
  selector: 'app-top-bar-formatos',
  templateUrl: './top-bar-formatos.component.html',
  styleUrls: ['./top-bar-formatos.component.scss']
})
export class TopBarFormatosComponent {

  @Input()
    _selectedDoctors:   SelectedFIlters[] = []
  @Input()
    _selectedServicios: SelectedFIlters[] = []
  @Input()
    _selectedRecursos:  SelectedFIlters[] = []


  @Output()
    _eventEmmiterFilter: EventEmitter<any> = new EventEmitter()


  constructor(private offcanvasService: NgbOffcanvas) {}


  openEnd(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  /**
   * 
   * @param event 
   */
  changeEvent(event: any) {

  }

  changeBrand(item: any) {
    
  }

  /**
   * 
   * @param filter 
   */
  _OnEventEmmitFilters(filter: any) {

    this._eventEmmiterFilter.next(filter)

  }

}
