import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import { TablaDeCitasComponent } from 'src/app/shared/components/tabla-de-citas/tabla-de-citas.component';
import { CalendarioPlanchaComponent } from 'src/app/shared/components/calendario-plancha/calendario-plancha.component';

const routes: Routes = [
  {
    path: '',
    component: CalendarComponent
  },
  {
    path:'tabla_citas',
    component: TablaDeCitasComponent
  },{
    path: 'plancha',
    component: CalendarioPlanchaComponent
  }
/*   {
    path: 'index-anestesiologo',
    component: IndexAntesiologoComponent,
  },{
    path: 'pharmacy',
    component:PharmacyComponent
  },{
    path:'old-clinical-history',
    component:OldHistoryClinicalComponent
  } */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DashboardsRoutingModule { }
