import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { MenuItem } from '../models/menu.model';
import { HttpClient } from '@angular/common/http';


import * as _ from 'lodash';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

import { environment } from 'src/environments/environment';


interface _exportMenu  {
  label: string,
  tipo: string,
  menu: MenuItem[]
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private readonly apiUrl: string =  environment.apiUrl //environment.apiUrl;

  constructor(
    private http:HttpClient, 
    private tokenService: TokenStorageService) { }

  /**
   * 
   * @returns 
   */
   getNavigationItems(): Observable<_exportMenu[]> { 
    
   return this.http.get<_exportMenu[]>(`${this.apiUrl}users/validate_role`).pipe(map((response: any) => {   

      let _menu : _exportMenu[] = []

      if(response.codigo == 200 ) {

        this.tokenService.saveRole(response.data.permisons.label)

        let _menu_: MenuItem[] = []

        _.map(response.data, (a: any)=> {           

          if(a.Scheema) {        

            let _keys = Object.keys(a.Scheema)            
            const _schemma = a.Scheema;            
            let _id = 0

            for (let index = 0; index < _keys.length; index++) {   
              const element = _keys[index];
              const _items = _schemma[element];      
               _id +=1  
              let _parent_link = element
              _parent_link = _parent_link.toLocaleLowerCase()
              let _parent_id = index + 1;
              let _subitemKeys = Object.keys(_items);                
              let subItems: any[] = []             

              let _id_ = 0
              for (let indexJ = 0; indexJ < _subitemKeys.length; indexJ++) {
                _id_ += 1
                const _sub_element = _subitemKeys[indexJ];             
                const _subItems = _items[_sub_element]    

                let _link_label = _sub_element
                
                _link_label = _link_label.toLocaleLowerCase()

                let _sub_id = 0;
                let _nested_items: any[]  = [] 

                _.map(_subItems, (c, i) => {
                  let _type_of = typeof(c)
                  _sub_id += 1

                  if(_type_of === 'object') {     

                    let _sub_link_label = i                    
                    _nested_items.push({
                      id: _sub_id,
                      label: c.label,
                      _formato_id: i,
                      _sub_link: _sub_link_label,
                      link: `${_link_label}/${_sub_link_label}`,
                      parentId: _id_
                    })                  
                  }

                })                    
                //console.log({__sub_element___: _sub_element });
                
                subItems.push({
                  id: _id_,
                  _formato_id: _sub_element,
                  label: _subItems.label,
                  _sub_link: _link_label,
                  link: `${_parent_link}/${_link_label}`,
                  parentId: _parent_id,
                  subItems: _nested_items
                })

              } 

              const _label_ = element.replace('_', ' ')

              _menu_.push({
                id: _id,
                icon: 'chevron-right',
                label: _label_,
                subItems,                
              })              
            }


            

            _menu.push({
              label: a.label,
              tipo: a.tipo,
              menu: _menu_
            })  
          }  

        })
       
        return _menu
      }      

      return []    
    }))  
  } 

  getMenuItems(response: any) {
    let _menu : _exportMenu[] = []

    let _menu_: MenuItem[] = []

    _.map(response.data, (a: any)=> {           

      if(a.Scheema) {     
        
        //console.log({___A___: a});
        

        let _keys = Object.keys(a.Scheema)            
        const _schemma = a.Scheema;            
        let _id = 0        
        
        for (let index = 0; index < _keys.length; index++) {   
          const element = _keys[index];
          const _items = _schemma[element];
          //console.log({_items: _items.});
          
          _id +=1  

          let _parent_link = element
              _parent_link = _parent_link.toLocaleLowerCase()

          let _parent_id = index + 1;
          let _subitemKeys = Object.keys(_items);                
          let subItems: any[] = []             

          let _id_ = 0
          for (let indexJ = 0; indexJ < _subitemKeys.length; indexJ++) {
            _id_ += 1
            const _sub_element = _subitemKeys[indexJ];             
            const _subItems = _items[_sub_element]   
            
          //  console.log({_subItems});
            

            let _link_label = _sub_element            
            _link_label = _link_label.toLocaleLowerCase()
            let _sub_id = 0;
            let _nested_items: any[]  = [] 
            _.map(_subItems, (c, i) => {
              let _type_of = typeof(c)
              _sub_id += 1

              if(_type_of === 'object') {     

                let _sub_link_label = i                    
                _nested_items.push({
                  id: _sub_id,
                  label: c.label,
                  _formato_id: i,
                  _sub_link: _sub_link_label,
                  link: `${_link_label}/${_sub_link_label}`,
                  parentId: _id_
                })                  
              }

            }) 
            
            subItems.push({
              id: _id_,
              _formato_id: _sub_element,
              label: _subItems.label,
              permision:{
                edite: _subItems.edite,
                new: _subItems.new,
                vista: _subItems.vista
              },
              _sub_link: _link_label,
              link: `${_parent_link}/${_link_label}`,
              parentId: _parent_id,
              subItems: _nested_items
            })

          } 

          const _label_ = element.replace('_', ' ')

          _menu_.push({
            id: _id,
            icon: 'chevron-right',
            label: _label_,
            subItems,                
          })              
        }  

        _menu.push({
          label: a.label,
          tipo: a.tipo,
          menu: _menu_
        })         

      }  
    })
   
    return _menu

  }

  /**
   * 
   * @returns 
   */
 /*  getNavigationItems(): Observable<_exportMenu[]> {     
    return this.http.get<_exportMenu[]>(`${this.apiUrl}users/validate_role`).pipe(map((response: any) => {   
 
       let _menu : _exportMenu[] = []
 
       if(response.codigo == 200 ) {
 
         this.tokenService.saveRole(response.data.permisons.label)
         let _menu_: MenuItem[] = []
         _.map(response.data, (a: any)=> {           
 
           if(a.Scheema) {        
 
             let _keys = Object.keys(a.Scheema)
 
             if(typeof(a.Scheema) === "object" ) {
 
               _.map(_keys, (k,i) => {    
                 let _id_ = i + 1
                 let keyIndex = k.replace('_' , ' ');              
                 let subItems: any[] = []
                 let _subitem_ = k.toLocaleLowerCase()    
                 
                 _.map(a.Scheema[k], (c, j: number) => {      
                   //console.log({_ITEMS_: c});
                   
                   let _sub_link = c.label.replace(/\s/g, '_'); 
 
                   subItems.push({
                     id: j,                
                     label: c.label, 
                     link: `/${_subitem_}/${_sub_link.toLocaleLowerCase()}`,
                     parentId: _id_
                   })
 
                 })   
                 
                   _menu_.push({
                     id: _id_,                   
                     icon: 'chevron-right',
                     subItems,             
                     label: keyIndex
                   })      
                   
                 })             
               
             }else{
               console.log('Algo no salio Bien');             
               
             }
 
             _menu.push({
               label: a.label,
               tipo: a.tipo,
               menu: _menu_
             })  
 
           }
           
         })
         return _menu
       }      
       return []    
    }))  
  } */
}
