import { NgModule } from '@angular/core';
import { PreloadAllModules,  RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layouts/layout.component';

// Auth
import { AuthGuard } from './core/guards/auth.guard';
import { Pages404Component } from './errorPages/pages404/pages404.component';

const routes: Routes = [  
  {
    path:'',
    component: LayoutComponent,
    loadChildren:() => import ('./pages/pages.module').then(m => m.PagesModule),
    canActivate:[AuthGuard]
  },
  { 
    path: 'auth', 
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule) 
  },
  {
    path:'**',
    component: Pages404Component,
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',  
  }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
