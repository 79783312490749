<div class="card" *ngIf="formato">
    <div class="card-body">       
  
        <div class="row g-2 m-4" *ngFor="let item of controls"> 

            <form [formGroup]="formNotaIngreso" class="needs-validation createfile-form" autocomplete="off" novalidate>

                <div *ngIf="item.type == 'frame' && item.name != 'nota_ingreso_frame_1' ">                
                    <div class="col-12">
                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                        </div>
                    </div>
                    
                    <div class="row" >              
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2" *ngFor="let obj of item.Objects" > 

                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input                        
                                    class="form-control flatpickr-input"                                             
                                    [id]="obj.name"     
                                    [type]="obj.type"     
                                    readonly   
                                    [readonly]="obj.readonly"
                                    [formControlName]="obj.name"
                                    [value]="_getValue(obj.name) | date: 'yyyy-MM-dd' "
                                                
                                    >
                                    <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'text' || obj.type == 'number' ">
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [readonly]="obj.readonly"
                                    [formControlName]="obj.name"
                                    [value]="_getValue(obj.name)"
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>                              
                            </div>

                            <div *ngIf="obj.type == 'time' ">
                                <input 
                                type="text"
                                [name]="obj.name"
                                [formControlName]="obj.name"
                                [value]="_getValue(obj.name)"
                                class="form-control" 
                                >
                            </div>


                        </div>
                    </div>
                </div>

                <div *ngIf="item.type == 'subtitle'">
                    <div class="col-12">
                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}}</h4>     
                        </div>
                    </div>
                    <div *ngIf="item.name == 'resultado_lab_gab' ">
                        
                        <fieldset class="mt-4" *ngIf="_LaboratorioFiles && _LaboratorioFiles.length">
                            <legend class="text-muted fs-12 fw-bold"> Archivos cargados</legend>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-4" *ngFor="let file of _LaboratorioFiles">
                                            <div class="d-flex justify-content-center">
                                                <a href="javascript:void(0);" (click)="_openPDF(file)">
                                                    <i style="font-size: 40px;" class='bx bx-file-blank' > </i>                               
                                                </a>                               
                                            </div>                                           
                                       <!--     <div class="mt-4">
                                            <div class="d-flex justify-content-center">
                                                <button class="btn btn-sm btn-danger" (click)="onDelete(file)"> <i class="bx bx-trash"></i> </button>
                                            </div>                        
                                           </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        
                    </div>
                    <div class="row g-2"  >
                        <div  *ngFor="let obj of item.Objects" class="col-sm-12 col-md-12 col-lg-6 col-xl-4 " 
                            [ngClass]="item.name == 'pronostico' ? 'border border-3 m-2': '' ">  

                            

                            <div *ngIf="obj.type =='text' || obj.type == 'number' ">
                                <label class="form-label"> {{obj.label}} </label>
                                <input
                                class="form-control"
                                [type]="obj.type"
                                [name]="obj.name"
                                [formControlName]="obj.name">
                            </div>

                            <div *ngIf="obj.type == 'label' && obj.name != 'tratamiento' " >

                                <label class="form-label"> {{obj.label}} </label>
                                
                                <div class="row g-2 "  >
                                    <div class="col-6" *ngFor="let _obj of obj.Objects; let i = index">
                                        <div *ngIf="_obj.type == 'radio' ">
                                            <!-- Custom Radio Color -->
                                            <div class="form-check form-check-right form-radio-primary mb-3">
                                                <input 
                                                class="form-check-input" 
                                                type="radio" 
                                                [name]="obj.name"         
                                                [value]="_obj.name"                                        
                                                id="{{_obj.name}}" 
                                                [formControlName]="obj.name"
                                                >
                                                <label class="form-check-label" for="{{_obj.name}}">
                                                {{_obj.label}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="obj.type == 'textarea' && item.name != 'impresion_diagnostica' ">
                                <label class="form-label"> {{obj.label}} </label>
                                <textarea
                                [name]="obj.name"
                                class="form-control"
                                [formControlName]="obj.name"
                                [rows]="4">
                                
                                </textarea>
                            </div>   

                      

                        </div>
                    </div>

                    <div *ngIf="item.name == 'impresion_diagnostica' ">
                        <div class="row g-2">
                            <div class="col-12" *ngFor="let obj of item.Objects" >
                                <div *ngIf="obj.type == 'textarea' ">
                                    <label class="form-label"> {{obj.label}} </label>
                                    <textarea
                                    [name]="obj.name"
                                    class="form-control"
                                    [formControlName]="obj.name"
                                    [rows]="4"
                                    >
        
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="item.name == 'tratamiento' " >
                        <div class="row g-2">
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12" *ngFor="let _obj of item.Objects">
                                <div *ngIf="_obj.label">
                                    <label class="form-label"> {{_obj.label}} </label>
                                    <div class="row g-2">
                                        <div class="col-4" *ngFor="let _objs of _obj.Objects; let _i = index">
                                            <div *ngIf="_objs.type == 'radio' ">
                                                <!-- Custom Radio Color -->
                                                <div class="form-check form-check-right form-radio-primary mb-3">
                                                    <input 
                                                    class="form-check-input" 
                                                    type="radio" 
                                                    [name]="_obj.name" 
                                                    id="{{_objs.name}}" 
                                                    [value]="_objs.name"
                                                    [formControlName]="_obj.name"
                                                    >
                                                    <label class="form-check-label" for="{{_objs.name}}" >
                                                    {{_objs.label}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="item.type == 'title' ">
                    <div class="col-12">
                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                        </div>
                    </div>
                    <div class="row g-2">
                        <div *ngFor="let obj of item.Objects" [ngClass]="obj.type =='textarea' ? 'col-12': 'col-sm-12 col-md-12 col-lg-6 col-xl-4' " >
                            <label class="form-label"> {{obj.label}} </label>
                            <textarea
                            [name]="obj.name"
                            class="form-control"
                            [formControlName]="obj.name"
                            [rows]="4">
                            
                            </textarea>
                        </div>
                    </div>
                </div>

                

            </form>
        </div>  

        <div class="row g-2 m-4">      
            <ng-container *ngIf="_Firma && _Firma.length; loadedTemplate">
                <div class="col-12 ">
                    <h4 class="fs-4 text-primary bold text-uppercase text-center">Firma digital </h4>     
                </div>
                <fieldset class="mt-4" >
                    <div class="card" *ngFor="let item of _Firma" >
                     <div class="card-body">
                         <div class="d-flex justify-content-center"  >
                             <figure class="figure mb-0">
                                 <img [src]="item.url" class="figure-img img-fluid" max  alt="" width="300">
                             </figure>                                        
                         </div>                                    
                     </div>
                   
                    </div>
                 </fieldset>
            </ng-container>
            <ng-template #loadedTemplate>
                <app-firma-doctor></app-firma-doctor>
            </ng-template>
        </div>
    </div>
</div>
