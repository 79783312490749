import { ChangeDetectionStrategy, Component, Input, OnChanges,  SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';


import { JsonFormControls } from 'src/app/shared/models/json-form-controls';
import { JsonFormData } from 'src/app/shared/models/json-form-data';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { ToastService } from 'src/app/shared/services/toast.service';



@Component({
  selector: 'app-data-patients',
  templateUrl: './data-patients.component.html',
  styleUrls: ['./data-patients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataPatientsComponent implements OnChanges {

  @Input() jsonFormData:JsonFormData
  @Input() title: string;

  submit!: boolean;

  _its_ready: boolean;

  public myForm: FormGroup =this.fb.group({});

  constructor (private fb: FormBuilder, private _toastr: ToastService) {
    flatpickr.localize(Spanish)
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['jsonFormData'].firstChange) {      
      this.createForm(this.jsonFormData.controls)      
    }    
  }

    /**
   * Returns form
   */
    get form() {
      return this.myForm.controls;
    }

  /**
   * 
   * @param controls 
   */
  private createForm(controls: JsonFormControls[]) {
      for(const control of controls) {

        const validatorsToAdd: any[] = [];

        for (const [key, value] of Object.entries(control.validators)) {
          switch (key) {
            case 'min':
              validatorsToAdd.push(Validators.min(value));
              break;
            case 'max':
              validatorsToAdd.push(Validators.max(value));
              break;
            case 'required':
              if (value) {
                validatorsToAdd.push(Validators.required);
              }
              break;
            case 'requiredTrue':
              if (value) {
                validatorsToAdd.push(Validators.requiredTrue);
              }
              break;
            case 'email':
              if (value) {
                validatorsToAdd.push(Validators.email);
              }
              break;
            case 'minLength':
              validatorsToAdd.push(Validators.minLength(value));
              break;
            case 'maxLength':
              validatorsToAdd.push(Validators.maxLength(value));
              break;
            case 'pattern':
              validatorsToAdd.push(Validators.pattern(value));
              break;
            case 'nullValidator':
              if (value) {
                validatorsToAdd.push(Validators.nullValidator);
              }
              break;
            default:
              break;
          }

        }

        this.myForm.addControl(
          control.name,
          this.fb.control(control.value, validatorsToAdd)
        )
      }
      this._its_ready = true;
  }

  onSubmit() {

    if(this.myForm.valid) {
      const _values = this.myForm.value

      
      

    }else {
      this._toastr._error('Por favor revise los campos en Rojo','Los campos en rojo son obligatorios')

      this.myForm.markAllAsTouched()
      this.myForm.markAsDirty()
      this.submit = true
    }

    
    console.log('Form values: ', this.myForm.controls);

  }

}
