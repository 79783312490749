import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';


// Range Slider
import { Options } from '@angular-slider/ngx-slider';

import * as _ from 'lodash';
import { CalificacionCandidato } from 'src/app/pages/calendar/models/candidatos';
import {  Subscription } from 'rxjs';
import { CandidatosService } from 'src/app/pages/calendar/services/candidatos.service';
import { AlertsService } from '../../services/alerts.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wizard-calificacion',
  templateUrl: './wizard-calificacion.component.html',
  styleUrls: ['./wizard-calificacion.component.scss']
})
export class WizardCalificacionComponent implements OnChanges {

  @Input()
  _candidato_id!: string |  null

  @Input()
  _calificacionCandidato: CalificacionCandidato = new CalificacionCandidato();


  options: Options = {
    floor: 0,
    ceil: 4,
    showTicks: true
  };

  _CalificacionPaciente = [
    { 
      title:'Comezón',
      name:'comezon',
      option:[{
        label:'Sí',
        value:'si'
      },{
        label: 'No',
        value:'no'
      }],     
      puntuacion:1,
      opt: '',
      calification: 0
    },
    { 
      title:'Sensación de bolita',
      option:[{
        label:'Sí',
        value:'si'
      },{
        label: 'No',
        value:'no'
      }],
      name: 'sensacion_bolita',
      puntuacion:1,
      opt:null,
      calification: 0
    },
    { 
      title:'Dolor',
      option:[{
        label: 'Durante el día',
        value:'durante_el_dia'
      },{
        label:'Al evacuar',
        value:'al_evacuar'
      }],
      name:'dolor',
      puntuacion:[0,1,2,3,4],
      opt:null ,
      calification: 0
    },
    { 
      title:'Sangrado',
      option:[{
        label:'Sí',
        value:'si'
      },{
        label: 'No',
        value:'no'
      }],
      name:'sangrado',
      puntuacion:2,
      opt:null,
      calification: 0
    }
  ]

  formCalificacion: FormGroup = this.formBuilder.group({})
  _total:number = 0

  _nivelDesc: string
  _calificacion: CalificacionCandidato = new CalificacionCandidato()
  $_subscription: Subscription;

  constructor (
    private formBuilder: FormBuilder,
    private candidato: CandidatosService,
    private alert: AlertsService,
    private toastr: ToastrService
  ) {
    this.initForm()
  }

  get form () {
    return this.formCalificacion.controls
  }

  get calificacion(): FormArray {
    return this.formCalificacion.get('calificacion') as FormArray
  }

  /**
   * 
   */
  private initForm () {

    this.formCalificacion = this.formBuilder.group({
      calificacion: this.formBuilder.array([])
    })

    this.addControl() 
  }

  /**
   * 
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(this._calificacionCandidato && this._calificacionCandidato.candidato_id) { 

      const _val = this._calificacionCandidato      

      let _values = [
        { 
          name:'comezon',  
          opt: _val.comezon ? 'si' : 'no' ,
          calification: _val.comezon ? 1 : 0
        },
        {          
          name: 'sensacion_bolita',       
          opt: _val.sensacion_bolita ? 'si' : 'no' ,
          calification: _val.sensacion_bolita ? 1 : 0
        },
        {   
          name:'dolor',          
          opt: _val.dolor.durante_el_dia ? 'durante_el_dia' : _val.dolor.al_evacuar ? 'al_evacuaral_evacuar' : 'no' ,
          calification: _val.dolor.nivel_dolor
        },
        { 
          name:'sangrado',          
          opt: _val.sangrado ? 'si' : 'no' ,
          calification: _val.sangrado ? 1 : 0
        }
      ]            

      this.calificacion.patchValue(_values)
      this._getTotal()      
    }
  }

  /**
   * 
   */
  private addControl () {
    _.map(this._CalificacionPaciente, (a) => {
      this.calificacion.push(this.AddnewControl(a))
    })   
    this._getTotal()    
  }

  /**
   * 
   * @param a 
   * @returns 
   */
  private AddnewControl (a: any): FormGroup {
    return this.formBuilder.group({
      name: a.name,
      title:a.title,
      opt:[null],
      option:[a.option],
      puntuacion:[a.puntuacion],
      calification:[0]
    })
  }

  /**
   * 
   * @param event 
   */
  _onModelChange(event: string, control: any) {
    if(event === 'no'){
      control.value.calification = 0
      this._getTotal()
      return
    }

    if(typeof(control.value.puntuacion) === 'number') {
      control.value.calification = control.value.puntuacion
      this._getTotal()
    }     
  }

  /**
   * 
   * @param event 
   * @param control 
   */
  _onSliderChange(event: number, control: any){
    console.log({SLIDER___: event, ____CONTROL____: control});
    this._getTotal()    
  }

  /**
   * 
   */
  _onSendCalification () {
    const values = this.formCalificacion.value.calificacion
    console.log({__VALUE_____: values});

    const _comezon = _.find(values, { name: 'comezon' })
    const _sensacion_bolita = _.find(values, { name: 'sensacion_bolita'})
    const _dolor = _.find(values, { name: 'dolor'})
    const _sangrado = _.find(values, { name: 'sangrado'})

    if(this._candidato_id) {

      this.alert._showLoader('Enviando calificacion, espere...')

      this._calificacion = {
        candidato_id: this._candidato_id,
        sensacion_bolita: _sensacion_bolita.opt === 'si' ? true :  false,
        comezon:  _comezon.opt === 'si' ? true :  false,
        dolor:{
          al_evacuar: _dolor.opt === 'al_evacuar' ? true : false,
          durante_el_dia: _dolor.opt === 'durante_el_dia' ? true: false,
          nivel_dolor: _dolor.calification
        },
        sangrado: _sangrado.opt === 'si' ? true : false,
        prioridad: this._total
      }

      console.log({_calificacion___: this._calificacion});
      
      this.candidato._guardarCalifPaciente(this._calificacion).subscribe({
        next:(value)  =>{
          this.alert._hideLoader()

          this.toastr.success('Exito al enviar la calificacion del paciente','Exito!!',{
            timeOut: 3200
          })
          
        },
        error:(err)  => {
          this.alert._hideLoader()
          console.error({err});
          
        },
      })
      


    }




    //console.log({_comezon});
    
    


}
  
  /**
   * 
   */
  private _getTotal() {
    const _values = this.formCalificacion.value.calificacion
    this._total = _.sumBy(_values, (a: any) => { return a.calification } )
  }
  
  /**
   * 
   * @param total 
   * @returns 
   */
  getbgBadge(total: number): String {
    
    if(total >= 0 && total <= 1) {
      return 'bg-danger'
    }/* else if (total >=5 && total <= 9) {
      return 'bg-brown'
    } */else if (total=== 2 ) {
      return 'bg-warning'
    }else if (total === 3 ){
      return 'bg-yellow'
    }else if (total >=4 && total <= 10 ) {
      return 'bg-success'
    }

    return 'bg-light'
  }
  
  /**
   * 
   * @param total 
   * @returns 
   */
  getLevel (total: number): number {

    if(total >= 0 && total <= 1) {
      return 5
    }else if (total=== 2 ) {
      return 3
    }else if (total === 3 ){
      return 2
    }else if (total >=4 && total <= 10 ) {
      return 1
    }

    return 0
  }

}
