import { data } from './../../../../../assets/data';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { Doctor } from 'src/app/pages/models/doctores';
import { Medicamento, NuevoMedicamento, NuevoProducto,  ProductosRecetados, RecetaMedicamento, Recetas } from 'src/app/pages/models/medicamentos';
import { RecetasService } from 'src/app/pages/services/recetas.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-receta-medicamento',
  templateUrl: './receta-medicamento.component.html',
  styleUrls: ['./receta-medicamento.component.scss']
})
export class RecetaMedicamentoComponent implements OnInit {  

  @Input()
  _closeModal: boolean = false
  @Input()
  _loadRecetas: boolean = false


  @Input()
  _receta: Recetas

  @Output()
  eventCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Output()
  eventLoadMedicamentos:  EventEmitter<boolean> = new EventEmitter<boolean>();

  _medicamentos: Medicamento[] = []
  _medicamento: Medicamento[] =[]

  _formMedicamentos: FormGroup
  _rowsMedicamentos: any[] = []

  _selectTipo: any[] = []

  _Cita: Cita 
  _Paciente: any;
  //_recetas:Recetas[] = []
  _citaID: string

  _doctor: Doctor

  constructor (
    private formBuilder: FormBuilder,
    private receta: RecetasService,
    private credentials: CredentialsService,
    private alert: AlertsService,
    private toastr: ToastrService,
    private calendar: CalendarService
  ) {
    this._Paciente = this.credentials.userInfoData.data?.records[0]

    const _cita = this.credentials.getCitaJson() //this._session._get

    if(_cita) {
      this._Cita = _cita

      this._citaID = this._Cita.Cita_Id
    }

    this._loadMedicamentos()
    this._getTipoMedicamento()
    this._initformMedicamentos()  
  }

  ngOnInit(): void {


  }


  get medicamentos() {
    return this._formMedicamentos.get('medicamentos') as FormArray
  }

  /**
   * 
   * @param medicamento 
   * @returns 
   */
  private _addControlMedicamento(medicamento: Medicamento): FormGroup {
    console.log({medicamento});
    
    return this.formBuilder.group({
      nombre_producto:[medicamento.nombre_producto],      
      tipo:[medicamento.tipo],
      fecha:[medicamento.fecha ? medicamento.fecha : moment().format('YYYY-MM-DD') ],

      nombre_paciente:[this._Paciente.Nombre_completo__c],     
      descripcion_producto:[medicamento.descripcion],    
      descripcion_aplicacion:[medicamento.descripcion_aplicacion ? medicamento.descripcion_aplicacion : null ],
      readOnly: medicamento.readonly
    })
  }

  /**
   * 
   */
  private _getTipoMedicamento () {
    this.receta.GetTipoMedicamento().then((resp: any) => {
      
      if(resp.codigo === 200 ){
        
        this._selectTipo = resp.data
      }

    }).catch((error) => {
      console.error({error});
      
    })
  }

  /**
   * 
   */
  private _initformMedicamentos() {
    this._formMedicamentos = this.formBuilder.group({
      medicamentos: this.formBuilder.array([])
    })
  }

  /**
   * 
   */
  _addMedicamentos() {

    _.map(this._medicamento, (a) => {
      let _find = this._findMedicamento(a)
      if(!_find) {
        this.medicamentos.push(this._addControlMedicamento(a))
        
      }      
    })
  }

  /**
   * 
   * @param a 
   * @returns 
   */
  _findMedicamento(a: any): boolean {
    let _medicamentos = this._formMedicamentos.value.medicamentos
    let _find = _.find(_medicamentos, {nombre_producto: a.nombre_producto  })
    return _find
  }

  /**
   * 
   */
  private _loadMedicamentos () {
    this.receta.getMedicamentos().subscribe({
      next:(response: any) =>{
        
        if(response.codigo === 200) {
          console.log({response});

          _.map(response.data, (a) => {
            a.readonly = true
          })

          this._medicamentos = response.data        
          
          if(this._receta) {      
            _.map(this._receta.productos_recetados, (a) => {         
              let _find = _.find(this._medicamentos, {nombre_producto: a.nombre_producto  })
              let readonly: boolean = true
              if(!_find) {
                readonly = false
              }        
              
              this._medicamento.push({
                nombre_producto: a.nombre_producto,
                tipo: a.tipo,
                descripcion: a.descripcion_producto,
                descripcion_aplicacion: a.descripcion_aplicacion,
                fecha: this._receta.fecha,
                readonly: readonly
              }) 
            })
            
            this._addMedicamentos()   
          }
        }
      },
      error(err) {
        console.error({err});        
      },
    })
  }

  /**
   * 
   */
  async _onSaveMedicamentos() {

    const _medicamentos = this._formMedicamentos.value.medicamentos;
    console.log({_medicamentos});

    let _nuevoMedicamento: NuevoMedicamento[] = []
    
    this.alert._showLoader('Guardando, por favor espere...')

    let _recetas: Recetas [] = []

    let _productoRecetas: ProductosRecetados[] = []   

    let fecha!: string
    let nombre_paciente!: string

    _.map(_medicamentos, (a, i:number) => {

      if(i === 0 ) {
        fecha = a.fecha,
        nombre_paciente = a.nombre_paciente
      }

      _productoRecetas.push({
        descripcion_aplicacion: a.descripcion_aplicacion,
        descripcion_producto: a.descripcion_producto,
        nombre_producto: a.nombre_producto,
        tipo: a.tipo
      })


      if(!a.readOnly) {
        _nuevoMedicamento.push({
          tipo: a.tipo,
          descripcion_producto: a.descripcion_producto,
          nombre_producto: a.nombre_producto
        })
      }

    })

    _recetas.push({
      fecha, 
      nombre_paciente, 
      productos_recetados:_productoRecetas
    })

    let _recetaMedicamento : RecetaMedicamento = new RecetaMedicamento() 
    let _NuevoProducto: NuevoProducto = new NuevoProducto(); 

    //return console.log({_recetaMedicamento, _NuevoProducto: _NuevoProducto});
    
    /**
     * EDICION
     */
    if(this._receta && this._receta.id_receta) {   
      
      _recetaMedicamento = {
        cita_id: this._citaID,
        id_receta: this._receta.id_receta,
        productos_recetados: _productoRecetas
      }   

     
      this.receta.EditarReceta(_recetaMedicamento).then((resp) => {
        console.log({resp});      

        this.okSuccess()
      }).catch(error => {
        console.error({error});
        this.alert._hideLoader()
        this.toastr.error('Ha ocurrido un error al tratar de procesar la actualización de la receta.','Error',{
          timeOut:3200
        })      
      })
      
      return console.log('edited');
      
    }

    //CREAR NUEVA RECETA
    _recetaMedicamento = {
      cita_id: this._citaID,
      recetas: _recetas
    }   
    
    //#region Guardar nuevo medicamento/material
    if(_nuevoMedicamento && _nuevoMedicamento.length) {
      
      _NuevoProducto = {
        productos: _nuevoMedicamento
      }  


      await this.receta._GuardarMedicamentonuevo(_NuevoProducto).then((resp) => {
        console.log({resp});
        
      }).catch((error) => {
        console.error({error});
        
      })
    }

    //#endregion
    
    this.receta.CrearRecetaMedicamento(_recetaMedicamento).then((resp) => {
      console.log({resp});      

      this.okSuccess()

      
    }).catch((error) => {
      console.error({error});
      this.alert._hideLoader()
      this.toastr.error('Ha ocurrido un error al tratar de procesar el guardado','Error',{
        timeOut:3200
      })      
    }) 

  //  console.log({_medicamentos, _receta });    
  }

  /**
   * 
   */
  private removeAllControls() {
    _.map(this._formMedicamentos.value.medicamentos, (a, i: number) => {
      this.onDeleteMedicamento(i)    
    })
  }

  /**
   * 
   */
  private okSuccess() {
   
    this.alert._hideLoader()
    this.toastr.success('La receta se ha guardado con exito','Exito al guardar',{
      timeOut: 3200
    })

    if(this._loadRecetas) {
      this.eventLoadMedicamentos.next(true)

    }

    if(this._closeModal) {
      this.eventCloseModal.next(true)
    }

   
    this.calendar._setCurrentLoadReceta(true)
    this.removeAllControls()
  }

  

  /**
   * 
   * @param index 
   */
  onDeleteMedicamento(index: number) {
    let control_ = this.medicamentos.controls[index];

    if (control_) {
      this.medicamentos.removeAt(index);
    }
  }

  _AddedMedicamento() {
    this.medicamentos.push(this._addControlMedicamento({
      nombre_producto:'',
      descripcion:'',
      tipo:'',
      readonly: false
    }))
  }

}
