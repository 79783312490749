<!-- 

</signature-pad> -->

<div class="d-flex justify-content-center" >
    <div class="card">
        <div class="card-body" *ngIf="signatureNeeded">
            <signature-pad 
            #signature 
            [options]="signaturePadOptions" (onBeginEvent)="drawBegin()" (onEndEvent)="drawComplete()"
            >
            </signature-pad>

           <!--  <canvas #canvas [options]="signaturePadOptions" (onBeginEvent)="drawBegin()" (onEndEvent)="drawComplete()"           height="200"></canvas>     -->
        </div>
        <div class="card-body" *ngIf="!signatureNeeded" >
            <img src="{{ signatureImg }}" alt="" srcset="" />
        </div>
    </div>
</div>


<div class=" d-flex justify-content-center p-4">
    <button type="button" class="btn btn-sm btn-warning m-2" (click)="clearPad()" >Limpiar</button> 
    <button type="button" class="btn btn-sm btn-success m-2" (click)="savePad()" >Cofnirmar</button> 
    <button type="button" class="btn btn-sm btn-primary m-2">Cargar Firma</button>
</div>