<div class="d-flex justify-content-center" >
    <div class="card">
        <div class="card-body" *ngIf="signatureNeeded">
            <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="300"            height="200"></canvas>    
        </div>
        <div class="card-body" *ngIf="!signatureNeeded" >
            <img src="{{ signatureImg }}" alt="" srcset="" />
        </div>
    </div>
</div>
