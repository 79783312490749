import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// Range Slider
import { Options } from '@angular-slider/ngx-slider';

import * as _ from 'lodash';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CalificacionVarices } from 'src/app/pages/calendar/models/candidatos';
import { AlertsService } from '../../services/alerts.service';
import { CandidatosService } from 'src/app/pages/calendar/services/candidatos.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-calificacion-varices',
  templateUrl: './calificacion-varices.component.html',
  styleUrls: ['./calificacion-varices.component.scss']
})
export class CalificacionVaricesComponent implements OnChanges {

  @Input()
  _candidato_id: string | null

  @Input()
  _calificacion: CalificacionVarices = new CalificacionVarices()

  options: Options = {
    floor: 0,
    ceil: 4,
    showTicks: true
  };

  _CalificacionPaciente = [
    {
      title:'Comezón',
      name:'comezon',
      option:[{
        label:'Sí',
        value:'si'
      },{
        label: 'No',
        value:'no'
      }],     
      puntuacion:1      
    },{
      title:'Dolor',
      option:[{
        label:'Sí',
        value:'si'
      },{
        label: 'No',
        value:'no'
      }],
      name:'dolor',
      puntuacion:[0,1,2,3,4]
    },{
      title:'Ardor',
      name:'ardor',
      option:[{
        label:'Sí',
        value:'si'
      },{
        label: 'No',
        value:'no'
      }],     
      puntuacion:1  
    },{
      title:'Sensación  de Pesadez',
      name:'sensacion_pesadez',
      option:[{
        label:'Sí',
        value:'si'
      },{
        label: 'No',
        value:'no'
      }],     
      puntuacion:1  
    },{
      title:'Piernas Cansadas',
      name:'piernas_cansadas',
      option:[{
        label:'Sí',
        value:'si'
      },{
        label: 'No',
        value:'no'
      }],     
      puntuacion:1  
    }
    
  ]

  formCalificacion: FormGroup = this.formBuilder.group({})
  _total:number = 0

  _nivelDesc: string
  _calificacionVarices: CalificacionVarices = new CalificacionVarices()


  constructor (
    private formBuilder: FormBuilder,
    private alert: AlertsService,
    private candidato: CandidatosService,
    private toastr: ToastrService
  ) {
    this.initForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this._calificacion && this._calificacion.candidato_id) {
      const val = this._calificacion     

      let _values = [
        {
          name:'comezon',
          opt: val.comezon ? 'si': 'no',
          calification: val.comezon ? 1 : 0
        },
        {
          name: 'dolor',
          opt: val.dolor.dolor ? 'si' : 'no',
          calification: val.dolor.nivel_dolor
        },
        {
          name:'ardor',
          opt: val.ardor ? 'si' : 'no',
          calification: val.ardor ? 1 : 0
        },
        {
          name:'sensacion_pesadez',
          opt: val.sensacion_pesadez ? 'si' : 'no' ,
          calification: val.sensacion_pesadez ? 1 : 0
        },
        {
          name:'piernas_cansadas',
          opt: val.piernas_cansadas ? 'si' : 'no',
          calification: val.piernas_cansadas  ? 1 : 0
        },
      ]
      
      this.calificacion.patchValue(_values)
      this._getTotal()
    }
  }

  
  get form () {
    return this.formCalificacion.controls
  }

  get calificacion(): FormArray {
    return this.formCalificacion.get('calificacion') as FormArray
  }

  /**
   * 
   */
  private initForm () {
    this.formCalificacion = this.formBuilder.group({
      calificacion: this.formBuilder.array([])
    })

    this.addControl()
  }

  /**
   * 
   */
  private addControl () {
    _.map(this._CalificacionPaciente, (a) => {
      this.calificacion.push(this.AddnewControl(a))
    })
    this._getTotal()    
  }

  /**
   * 
   * @param event 
   * @param control 
   */
  _onSliderChange(event: number, control: any){
    console.log({SLIDER___: event, ____CONTROL____: control});
    this._getTotal()    
  }
  
  /**
   * 
   */
  _onSendCalification () {
    const values = this.formCalificacion.value.calificacion

    const comezon= _.find(values, {name: 'comezon'} )
    const dolor = _.find(values, {name: 'dolor'} )
    const ardor = _.find(values, {name: 'ardor'} )
    const sensacion_pesadez = _.find(values, {name: 'sensacion_pesadez'} )
    const piernas_cansadas = _.find(values, {name: 'piernas_cansadas'} )
    
    const prioridad = this._total

    if(this._candidato_id) {
      this.alert._showLoader('Enviando calificacion, espere...')

      this._calificacionVarices = {
        candidato_id: this._candidato_id,
        comezon: comezon.opt === 'si' ? true : false,
        dolor:{
          dolor: dolor.opt === 'si'? true : false,
          nivel_dolor: dolor.calification
        },
        ardor: ardor.opt === 'si' ? true : false,
        sensacion_pesadez: sensacion_pesadez.opt === 'si' ? true : false,
        piernas_cansadas: piernas_cansadas.opt === 'si' ? true : false,
        prioridad
      }

      this.candidato._guardarCalificacionVarices(this._calificacionVarices).subscribe({
        next:(response) => {
          this.alert._hideLoader()
          this.toastr.success('Exito al enviar la calificacion','Exito!!',{
            timeOut: 3200
          })
        },
        error:(err) => {
          this.alert._hideLoader()
          console.error({err});          
        },
      })   
    }
  }

  /**
   * 
   * @param event 
   * @param control 
   * @returns 
   */
  _onModelChange(event: string, control: any) {
    if(event === 'no'){
      control.value.calification = 0
      this._getTotal()
      return
    }

    if(typeof(control.value.puntuacion) === 'number') {
      control.value.calification = control.value.puntuacion
      this._getTotal()
    }     
  }

  /**
   * 
   * @param a 
   * @returns 
   */
  private AddnewControl (a: any): FormGroup {
    return this.formBuilder.group({
      name: a.name,
      title:a.title,
      opt:[null],
      option:[a.option],
      puntuacion:[a.puntuacion],
      calification:[0]
    })
  }

  /**
   * 
   */
  private _getTotal() {
    const _values = this.formCalificacion.value.calificacion    
    this._total = _.sumBy(_values, (a: any) => { return a.calification } )
  }

  /**
   * 
   * @param total 
   * @returns 
   */
  getbgBadge(total: number): String {
    
    if(total >= 0 && total <= 1) {
      return 'bg-danger'
    }else if (total=== 2 ) {
      return 'bg-warning'
    }else if (total === 3 ){
      return 'bg-yellow'
    }else if (total >=4 ) {
      return 'bg-success'
    }

    return 'bg-light'
  }


  /**
   * 
   * @param total 
   * @returns 
   */
  getLevel (total: number): number {

    if(total >= 0 && total <= 1) {
      return 5
    }else if (total=== 2 ) {
      return 3
    }else if (total === 3 ){
      return 2
    }else if (total >=4  ) {
      return 1
    }

    return 0
  }


}
