

export class RecetaMedicamento {
    cita_id: string
    id_receta?: number
    recetas?: Recetas[]

    productos_recetados?: ProductosRecetados[]
}

export class NuevoProducto {
    productos: NuevoMedicamento[]
}

export class NuevoMedicamento {
    nombre_producto: string 
    descripcion_producto: string 
    tipo: string 

}

export class EliminarReceta {
    cita_id: string //"": "string",
    id_receta: number //"": 0
}

export interface Recetas {
    fecha:string 
    nombre_paciente?: string 
    productos_recetados: ProductosRecetados[]
    id_receta?: number
    archivos?: RecetaObjFiles

}

export interface ProductosRecetados {
    nombre_producto:string
    descripcion_producto:string
    tipo:string
    descripcion_aplicacion:string 
}

export interface Medicamento {
    nombre_producto: string 
    descripcion: string 
    tipo: string 

    descripcion_aplicacion?: string
    fecha?: string

    readonly?: boolean
    
}

export interface RecetaObjFiles {
    filename: string
    id_receta: number
    ruta_archivo_receta: string
    url: string
}

