import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-anestesia',
  templateUrl: './anestesia.component.html',
  styleUrls: ['./anestesia.component.scss']
})
export class AnestesiaComponent implements OnInit, OnDestroy {

  @Input()
  id: string

  @Input()
  _formatoData: any
  
  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean

  $interval!: Subscription;
  public formAnestesia: FormGroup = this.formBuilder.group({})

  _Cita: Cita;

  _BODYFORM: any;

  constructor (
    private formatService: FormatsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private credentials: CredentialsService
    ) {
      const cita = this.credentials.getCitaJson()

      if(cita) {
        this._Cita = cita
      }
    }

  ngOnInit(): void {

    if(this._formatoData) {
      this._formato = this._formatoData
      this._controls = this._formato.form.controls;
      //console.log({_controls: this._controls});   
      this.initForm();
    }

/*     if(this.id) {

      this.formatService._getFormat(this.id, this._Cita.Cita_Id).subscribe({
        next:(response) => {

          if(response.codigo == 200) {
            this._formato = response.data.formato;
            if(this._formato.form) {
              this._controls = this._formato.form.controls;
              console.log({_controls: this._controls});   
              this.initForm();
            }
          }
          

        },
        error:(error) => {

        }
      })

    } */
  }

  ngOnDestroy(): void {
    if(this.$interval) {
      this.$interval.unsubscribe()
    }
    this._savePrevDestroy()
  }

  get f() {
    return this.formAnestesia.controls;
  }

  
  private _savePrevDestroy(): void {
    let _formValues = this.formAnestesia.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  private initForm() {
    
    _.map(this._controls, (a) => {

      if(a.type === 'subtitle' ) {
       // console.log({_SUBTITLE: a});

       this.formAnestesia.addControl(
        a.name, this.formBuilder.control(a.value)
      )
        
      }

      _.map(a.Objects, (b) => {          

        if(b.type == 'title') {
          _.map(b.Objects, (c) => {
            if(c.type == 'label') {
              _.map(c.Objects, (d) => {
                if(d.type == 'label') {
                  _.map(d.Objects, (e) => {
                    this.formAnestesia.addControl(
                      e.name, this.formBuilder.control(e.value)
                    )
                  })

                }else {
                  this.formAnestesia.addControl(
                    d.name, this.formBuilder.control(d.value)
                  )
                }    
              })             
              
            }else {
              console.log({NOTLABEL: c});
              
            }
          })
        }else {
          _.map(b.Objects, (c) => {
              this.formAnestesia.addControl(
                c.name, this.formBuilder.control(c.value)
              )
          })        

        }

        this.formAnestesia.addControl(
          b.name, this.formBuilder.control(b.value)
        )

      })
      
    })

    console.log({formAnestesia: this.formAnestesia});
    

    this.createInterval()    

  }

  /**
   * 
   */
  private createInterval () {
    const myObservable= interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();      
        console.log('GO SAVE IT!!!!');        
        //this.show = !this.show;       
       // this._onSaveIt()     
       this._newSaveFormat()
       
      }           
     // this._interval = integer
      
    })
  }

  /**
   * 
   */
  _newSaveFormat(): void {

    let _formValues = this.formAnestesia.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

  //  this._formatoValues = _bodyForm   
    this._saveFormatValues(_bodyForm, true)
  }

  _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {

    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        if(createInterval) {
                    
          this.createInterval()
        }
      })
    }).catch((error) => {
      console.error({error});
      
    })


  }

  private _onSaveIt () {

    let _formato = this._formato;

    _.map(_formato.form.controls, (a) => {

      _.map(a.Objects, (b) => {
        if(b.type == 'title') {
          _.map(b.Objects, (c) => {
            if(c.type == 'label') {
              _.map(c.Objects, (d) => {
                if(d.type == 'label') {
                  _.map(d.Objects, (e) => {
                    e.value = this.f[e.name].value
                  })
                }else {
                  d.value = this.f[d.name].value
                }
              })
            }
          })
        }
      })
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      form: _formato.form
    }
    this._BODYFORM = _bodyForm

    this.formatService._postFormato(_bodyForm).then((resp) => {
      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      })
      this.$interval.unsubscribe()
       setTimeout(() => {
          //this.createInterval()     
        }, 5000);

    }).catch((error) => {
      console.error({error});
      
    })

  }

  /**
   * 
   */
  _onCheckChange(event: any, name: string ) {
    let val = event.target.checked
    this.formAnestesia.controls[name].patchValue(val)  
  }

}
