
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PatientsService } from '../../calendar/services/patients.service';
import { map } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { RedirectService } from 'src/app/services/redirect.service';
import { Cita } from '../../calendar/models/calendar';
import * as _ from 'lodash';
import { DetallesCita } from '../../calendar/models/detalles-cita';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { CalendarService } from '../../calendar/services/calendar.service';
import { InitWebSocketService } from 'src/app/services/init-web-socket.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormatsService } from 'src/app/shared/services/formats.service';
import { LocalSessionService } from '../../services/local-session.service';

@Component({
  selector: 'app-search-result-table',
  templateUrl: './search-result-table.component.html',
  styleUrls: ['./search-result-table.component.scss']
})
export class SearchResultTableComponent implements OnInit {

  @ViewChild('canvasDetail') canvasDetail: TemplateRef<any>;

  @Input()
    content: any;
  @Input()
    results: any[] = []
  @Input()
    custom: boolean;


  _columns: any[] = []

  _detallesCita: DetallesCita;
  
  _canvasRef_ : NgbOffcanvasRef;

  _hideColumns = ['Paciente_Id', 'Recurso_Id', 'Servicio_Id', 'Empleado_Id', 'Id_Paciente']

  _disabledBtn:boolean = false

  _spinner = 'spinner'

  constructor (
    private patients: PatientsService,
    private credentialService: CredentialsService,
    private redirect: RedirectService,
    private offCanvasService: NgbOffcanvas,
    private calendar: CalendarService,
    private wbSocket: InitWebSocketService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private foramto: FormatsService,
    private localService: LocalSessionService
    
    ) {}

  ngOnInit(): void {
   // console.log({results: this.results});
    _.map(this.results, (a, i) => {
      if(i === 0) {
       let _Keys = Object.keys(a);
       _.map(_Keys, (a) => {
        if(!this._hideColumns.includes(a)) {
          this._columns.push(a)
        }
       })
      }
    })    
  }

  /**
   * 
   * @param pacienteId 
   */
  _gotoForm(_cita: Cita) {       
    this.patients._getPatientInfo(_cita.Paciente_Id).pipe(map((response) => {     
      this.credentialService.saveUserinfo(response);
    })).subscribe({
      next:() => {
        this.redirect.to('/pacientes_formatos')
      },error:(error) => {
        console.error({error});        
      }
    })
  }

  /**
   * 
   * @param item 
   */
  openCanvas(item: any) {

    this._detallesCita = new DetallesCita()

    this._detallesCita = {
      cita: item,
      //servicio
      doctor:{
        Name: item.Doctor,
        Id: item.Empleado_Id
      },
      servicio:{
        Name: item.Servicio,
        Id: item.Servicio_Id
      }

    }

   // console.log({item});

    
    this._canvasRef_ = this.offCanvasService.open(this.canvasDetail, {
      position:'end',
      backdrop: true,
      scroll: false,
      
    })
    
  }

  /**
   * 
   * @param item 
   */
  sendWebsocket(item:any)  {
   // 
    //
   // console.log({item});
  // this.wbSocket.connect()
  //  this.wbSocket.connectClient(item.Cita_Id,'0001',item.Paciente_Nombre_Completo)

    this._disabledBtn = true
    
   // this.wbSocket.sendMessage(item.Cita_Id,'0001',item.Paciente_Nombre_Completo)

    this.calendar._getDoctorByid(item.Empleado_Id).subscribe({
      next:(response) => { 

        if(response.codigo == 200) {
          const _webSocket = response.data.info?.Websockets
          if(_webSocket) {
            //console.log({_webSocket});
            this.wbSocket.connectClientWs(_webSocket.paciente_espera.cliente)    
            this.wbSocket.sendMessage(item.Cita_Id,_webSocket.paciente_espera.cliente,item.Paciente_Nombre_Completo)
            this.toastr.success('Notificacion enviada con exito','Exito al enviar',{
              timeOut: 3200
            })
          }else { 
            console.log('NOT FOUND WS');
            this.toastr.warning('No existe una forma de notificar al doctor','Error en notififacion',{
              timeOut: 3200
            })
            
          }
        }

       // console.log({response});
       this._disabledBtn = false
        
      },
      error:(error) => {
        this._disabledBtn = false
        console.error({error});        
      }
    }) 

    
  }

  /**
   * 
   * @param item 
   */
    _onEventClick(item: any, _col: string) {
      console.log({_ITEM___: item, _col});

      this.spinner.show(this._spinner)

      if(_col === 'Name_Paciente') {
        this.calendar._GetCitasPaciente(item.Id_Paciente).then((resp) => {
          this.spinner.hide(this._spinner)
          this.PacienteData(item.Id_Paciente)
        })

      }else if (_col === 'Name' ) {

        

        this.calendar._getDetallesCita(item.Name).subscribe({
          next:(response: any) => {
            if(response.codigo == 200) {
              this.spinner.hide(this._spinner)

              const _data = response.data
              const _dataCita = _data.Datos_Cita

              if(_data && _data.length) {
                const _resp = _data[0]
                console.log({response});
                
                this.toastr.warning(_resp.details,_resp.error, {
                  timeOut: 3200
                })
                return
              }

              console.log({_data,   _dataCita});
              
              
              this._getFormatosByCita(_data, _dataCita)
              

              this.toastr.success('Exito!!!','Cita Encontrada!!!',{
                timeOut: 3200
              })
             // this.spinner.hide(this._spinner)
        
          }              
          },
          error:(err) => {
            this.spinner.hide(this._spinner)
            console.error({err});              
          },
        })
      }


   /*    let _paciente_id = item.Paciente_Id ? item.Paciente_Id : item.Id_Paciente

      this.patients._getPatientInfo(_paciente_id).pipe(map((response) => {     
        this.credentialService.saveUserinfo(response);
      })).subscribe({
        next:() => {
          this.redirect.to('/pacientes_formatos')
        },error:(error) => {
          console.error({error});        
        }
      }) */
      
    }

    private _getFormatosByCita(_data: any, _dataCita: any) {
      this.foramto._getForamtoByCita(_data.Cita_Id).then((resp) => {
        this.spinner.hide()   
        if(resp){
          this.localService.savedJsonValues('_json_formatos_cita',resp)
         // this._getPatientsData(_cita)    

         let _cita: Cita;
         _cita = {
          Cita_Id: _data.Cita_Id,
          Servicio: '',
          Recurso: _dataCita.detalle_cita.recurso,
          Fecha_Inicio: _dataCita.detalle_cita.fecha_inicio,
          Fecha_Final: _dataCita.detalle_cita.fecha_final,
          Paciente_Id: _dataCita.Salesforce_Id,
          Recurso_Id: _dataCita.detalle_cita.recurso,
          Servicio_Id: _dataCita.detalle_cita.servicio,
          Tipo_Cita: _data.Tipo_Evento,
          Procedimiento: '',
          Estatus: _data.Estatus,
          Empleado_Id: _data.Empleado_Id,
          N_Expediente: 'aaaa', //item.Name,
          Paciente_Nombre_Completo: '',
          Candidato_Cirugia: ''

         }
         this.credentialService.saveCitaJson(_cita)
         this.redirect.to('/pacientes_formatos')
          
        }
      }).catch((error) => {
        this.spinner.hide()
        console.error({error});     
  
      })
    }


    private PacienteData (_idPaciente: string) {
      this.patients._getPatientInfo(_idPaciente).pipe(map((resp) => {
          this.credentialService.saveUserinfo(resp);
          return resp
        })).subscribe({
          next:(resp) =>  {
              this.redirect.to('/pacientes')
          },
          error(err) {
            console.error({err});
            
          },
        }) 

    }

    _onEventEmmiter(event: string) {
      if(event === 'redirect') {
        this._canvasRef_.close()
        this.redirect.to('/pacientes_formatos')
      }
    }

}
