import { Pipe, PipeTransform } from '@angular/core';
import { Cita } from '../calendar/models/calendar';

@Pipe({
  name: 'filterPatients'
})
export class FilterPatientsPipe implements PipeTransform {

  transform(Collection:Cita[], searchText: string)  {
    if (typeof searchText === typeof undefined) return Collection;

    return Collection.filter((item) => 
    item.Paciente_Nombre_Completo.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()) ||
    item.N_Expediente.toLocaleUpperCase().includes(searchText.toLocaleUpperCase())
    )
  }

}
