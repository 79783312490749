import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layout-formatos',
  templateUrl: './layout-formatos.component.html',
  styleUrls: ['./layout-formatos.component.scss']
})
export class LayoutFormatosComponent {

  @Input() Id: string;

}
