import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CandidatosService } from '../../calendar/services/candidatos.service';
import { CandidatoData } from '../../calendar/models/candidatos';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-candidatos',
  templateUrl: './candidatos.component.html',
  styleUrls: ['./candidatos.component.scss']
})
export class CandidatosComponent implements OnInit, OnDestroy {

  @ViewChild('modalCandidato') modalCandidato!: TemplateRef <any>;

  _listadoCandidatos: CandidatoData[]

  _candidato: CandidatoData;
  _ngbModalRef: NgbModalRef

  $_subscriber: Subscription;

  
  _spinner = '_spinner_'

  constructor (
    private candiatos: CandidatosService,
    private modal: NgbModal,
    private spinner: NgxSpinnerService
  ) {

    this.loadCandidatos()
  }

  ngOnInit(): void {
    this. $_subscriber = this.candiatos.CurrentLoadCandidatos.subscribe({
      next:(value) => {
        if(value) {
          this.loadCandidatos()
        }
      },
      error(err) {
        console.error({err});
        
      },
    })
  }

  ngOnDestroy(): void {
    if(this.$_subscriber) {
      this.$_subscriber.unsubscribe()
    }
  }


  /**
   * 
   */
  private loadCandidatos(): void {
    this.spinner.show(this._spinner)
    this.candiatos.ObtenerListadoCanidatos('true').subscribe({
      next:(response) =>  {      
        this.spinner.hide(this._spinner)  
        if(response.codigo === 200) {
          this._listadoCandidatos = response.data
        }
      },
      error:(err) =>{
        this.spinner.hide(this._spinner)  
          console.error({err});          
      },
    })
  }

  /**
   * 
   * @param content 
   * @param candidato 
   */
  openModal(content:TemplateRef<any>, candidato: CandidatoData) {

    this._candidato = candidato

    this.modal.open(content, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })

  }

  /**
   * 
   * @param event 
   */
  onClickEvent(event: boolean) {
    console.log({event});
    
    if(event) {
      this.modal.dismissAll()
    }
  }

  /**
   * 
   * @param event 
   */
  _onEventEmmiterReaload(event:boolean): void {

    if(event) {
      this.modal.dismissAll()
      this.loadCandidatos()
    }

  }


  /**
   * 
   * @param item 
   */
  _onEdit(candidato: CandidatoData) {

  //  console.log({item});
    this._candidato = candidato  

    this._ngbModalRef = this.modal.open(this.modalCandidato, {
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })  

  }

  /**
   * 
   * @param event 
   */
  _onEventCloseModal(event: boolean) {

    if(event) {
      this._ngbModalRef.close()
      this.loadCandidatos()
    }

  }

}
