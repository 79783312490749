<app-breadcrumbs title="Resultados de busqueda" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header border-0">
                <form [formGroup]="_formSearch" (ngSubmit)="_onSerach()" novalidate>
                    <div class="row justify-content-center mb-4">
                        <div class="col-lg-6">
                            <div class="row g-2">
                                <div class="col">
                                    <div class="position-relative mb-3">
                                        <input 
                                        type="text" 
                                        class="form-control form-control-lg bg-light border-light"
                                        formControlName="searchText"
                                        placeholder="Search here.." 
                                        >                               
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button type="submit" class="btn btn-primary btn-lg waves-effect waves-light"><i
                                            class="mdi mdi-magnify me-1"></i> Buscar </button>
                                </div>
                            </div>
                        </div><!--end col-->
                        <div class="col-lg-12">
                            <h5 class="fs-16 fw-semibold text-center mb-0">Showing results for "<span
                                    class="text-primary fw-medium fst-italic">{{_textSearch}} </span> "</h5>
                        </div>
                    </div><!--end row-->
                </form>
                
            </div>

            <ng-container *ngIf="_results && _results.length;">
                <app-search-result-table [results]="_results" [custom]="false" ></app-search-result-table>

            </ng-container>

            <ng-container *ngIf="_resulSerach && _resulSerach.length" >
                <hr>                
                <div class="container-fluid mt-4">
                    <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-pills nav-justified card-footer-tabs fs-17" role="tablist">
                        <li *ngFor="let item of _resulSerach; let i = index"  [ngbNavItem]="item" class="nav-item" [ngbNavItem]="i + 1 ">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab" >
                              {{item.label}}
                            </a>
                            <ng-template  ngbNavContent>
                                <div class="pb-3">           
                                    <app-search-result-table [results]="item.data" [custom]="true"> </app-search-result-table>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content text-muted">
                        <div [ngbNavOutlet]="customNav"></div>
                    </div><!--end tab-content-->
                </div><!--end card-body-->

            </ng-container>

          
      

        </div>
    </div>
</div>