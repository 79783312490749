import { DatePipe } from '@angular/common';
import { Component, ElementRef, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbOffcanvasRef, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita, CalendarObjects, CitasObjects } from 'src/app/pages/calendar/models/calendar';
import { DetallesCita } from 'src/app/pages/calendar/models/detalles-cita';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { Filters } from 'src/app/pages/models/filters';
import { RedirectService } from 'src/app/services/redirect.service';

@Component({
  selector: 'app-calandario-citas-por-dia',
  templateUrl: './calandario-citas-por-dia.component.html',
  styleUrls: ['./calandario-citas-por-dia.component.scss']
})
export class CalandarioCitasPorDiaComponent {

  @ViewChild('viewContent') viewContent: TemplateRef<any>;

  @ViewChild('offcanvasRight') offcanvasRight: ElementRef | undefined;
  @ViewChild('modalShow') modalShow!: TemplateRef<any>;  

  _citas: Cita[] = []
  _itsToday: boolean;

  responseData: CalendarObjects;

  _citas_today: Cita[] = []
  _citas_tomorrow: Cita[] = [] 

  private readonly _today = new Date();  
  private readonly _tomorrow = new Date(moment().add('days',1).toISOString());

  today_    : string | null
  tomorrow_ : string | null


  _citaSelected: Cita;
  _doctor: string = ''
  _servicio: string;

  _detallesCita: DetallesCita;

  _itsReady: boolean;
  _loaderError:boolean = false

  //eventSubject: Subject<_canvasDetails> = new Subject<_canvasDetails>();
  //eventCitaSubject: Subject<Cita> = new Subject<Cita>();

  _canvasRef_ : NgbOffcanvasRef;

  _filters: Filters = new Filters()
  _isAdmin: boolean

  constructor(
    private modalService: NgbModal,
    private date: DatePipe,
    private offcanvasService: NgbOffcanvas,
    private credentialService: CredentialsService,
    private Calendar: CalendarService,
    private toastr:ToastrService,
    private redirect: RedirectService
  ) {      
    this.today_ = this.date.transform(this._today,'yyyy-MM-dd')   
    this.tomorrow_ = this.date.transform(this._tomorrow, 'yyyy-MM-dd')
  } 

  ngOnInit(): void {   
     
    if(this.credentialService.credentials?.roles.Doctor) {      
      this._doctor = this.credentialService.credentials?.id_salesforce ? this.credentialService.credentials.id_salesforce : '' ;
    }else if (this.credentialService.credentials?.roles.Administrador) {
      this._isAdmin = true
    }

    //if(this._)
    
    this._filters.doctor = this._doctor
    this._filters.inicio = '' //this._startDate //this.date.transform(_startOfMonth,'yyyy-MM-dd')
    this._filters.final  = '' //this._endData //this.date.transform(_today_,'yyyy-MM-dd')
    
    this._loadCitas()
  }

  /**
   * 
   */
  private _loadCitas() {

    this._itsReady = false;
    this.Calendar._getCalendarReport(this._filters).subscribe({
      
      next: (response: any) =>  {
        if(response.codigo == 200) {           
         this.responseData = response.data // as CalendarObjects;     
         this._itsReady = true;      
         this._onSetData()   
        }
      },
      error:(err: any) =>  {
        console.error({err});
        this._loaderError = true;
        this._itsReady = true
        this.toastr.error('Ocurrio un error al obtener los datos del calendario','Error' ,{
          timeOut: 3200
        })
      },
    })

  }

  private _onSetData() {
    if(this.responseData) {
    //  let _citasObjects = this.responseData.Citas;

 /*      if(_citasObjects.length) {
        this._itsToday = false;
        this._citas =[...this.responseData.Citas]
      }else { */

        this._itsToday = true;

        let _citasObjects = this.responseData.Citas  as CitasObjects;     

        if(_citasObjects.Today && _citasObjects.Today.length) {
          this._citas_today = [..._citasObjects.Today];
        }

        if(_citasObjects.Tomorrow && _citasObjects.Tomorrow.length) {
          this._citas_tomorrow = [..._citasObjects.Tomorrow];
        }

     // }
    } 
  }

  /**
   * Abrimos el Canvas
   * @param _id 
   * @param content 
   */
  onClick(_id: number, content: TemplateRef<any>){    
  this._canvasRef_ =  this.offcanvasService.open(content,{
      position:'end',
      scroll: false
    })
    
  }

  /**
   * Detecteamoslos cambios
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {

 
  }

  /**
   * 
   */
  hideCanvas() {
    
    this.offcanvasService.dismiss('cross click')
  }

  /**
   * 
   */
  hideModal () {
    this.modalService.dismissAll()    
  }

  /**
   * 
   * @param itemId 
   * @returns 
   */
  getDoctor(itemId: string): string {
    let _find = _.find(this.responseData.Doctores, {Id: itemId }  )      

    if(_find) {
      return _find.Name
    }

    return 'N/D'
  }

  /**
   * 
   * @param itemId 
   * @returns 
   */
  getServicio(itemId: string): string{

    let _find = _.find(this.responseData.Servicios, { Id: itemId })

    if(_find) {
      return _find.Name
    }
    return 'N/D'
    
  }

  /**
   * 
   * @param cita 
   */
  _onEventEmmitCita(cita: Cita) {
    
    let _doctor   = _.find(this.responseData.Doctores, {Id: cita.Empleado_Id }  )
    let _servicio = _.find(this.responseData.Servicios, { Id: cita.Servicio_Id })

    this._citaSelected = cita;

    this._detallesCita = {
      cita,
      doctor: _doctor,
      servicio: _servicio
    }

    let _v = this.offcanvasService.activeInstance;
    
   this._canvasRef_ = this.offcanvasService.open(this.viewContent,{
      position:'end',
      scroll: false
    })

    
  }
  
  /**
   * 
   * @param event 
   */
  onEventCanvasEmmitter(event: string) {
    //this.offcanvasService.dismiss();
    this._canvasRef_.close()

    if(event == 'redirect') {
      this.redirect.to('/pacientes_formatos')
 /*      this.redirect.to('/doctor',{ queryParams:{
        tabId: 'Historia_Clinica'
      }}) */
    }
    
  }

  _getCardClass(status: string){   
    switch (status) {
      case 'Programada':
          return 'card-primary'
      case 'Exitosa':
          return 'card-info'
      case 'Confirmada':
          return 'card-success';
      case 'Cancelada':
          return 'card-danger'    
      default:
        return 'card-warning'        
    }
  }

  

}
