<!-- <button (click)="topFunction()" class="btn btn-danger btn-icon" id="back-to-top">
    <i class="ri-arrow-up-line"></i>
</button> -->

<!--preloader-->
<div id="preloader">
    <div id="status">
        <div class="spinner-border text-primary avatar-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

<div class="customizer-setting">
    <div class="btn-primary rounded-pill shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
        data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas"
        (click)="openEnd(filtetcontent)">
        <i class='mdi mdi-menu  fs-22'></i>
    </div>
</div>

<!-- Theme Settings -->
<ng-template #filtetcontent let-offcanvas>
    <div class="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
        <h5 class="m-0 me-2 text-white">Opciones</h5>
        <button type="button" class="btn-close btn-close-white ms-auto " data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body p-0">     
        
        <div class="p-4">
            <h6 class="mb-0 fw-semibold text-uppercase">Listado de pacientes</h6>                
            <ngx-simplebar style="height: 50vh;">
                <div class="list-group p-2" *ngFor="let item of _clientes_espera let i = index;">
                    <button 
                    type="button"     
                    (click)="_onSetPatient(item)"                   
                    class="list-group-item list-group-item-action" >                                    
                    {{item.data.pacient}}
                    </button>                           
                    </div>
            </ngx-simplebar>
        </div>

        <div class="p-2">
            <h6 class="mb-0 fw-semibold text-uppercase">Formatos de pacientes</h6>                
                <!-- end preloader-menu -->
            <div class="m-4">
                <li *ngFor="let formato of Formatos">
                            
                    <a data-bs-toggle="collapse" href="javascript:void(0);" role="button" aria-expanded="true" aria-controls="collapseExample" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed">
                           <!--  <i class="ri-folder-2-line align-bottom me-2"></i>  -->
                        <span class="fs-5">{{formato.label}} </span>
                    </a>

                    <div class="collapse show" id="collapseExample" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <ul class="sub-menu list-unstyled">
                            <li *ngFor="let _formato of formato.subItems">
                                <a href="javascript:void(0);" (click)="_onSetIdFormato(_formato)" class="mt-4 fw-bold fs-5" 
                                    [ngClass]="_formato.subItems && _formato.subItems.length ? 'text-muted  ' : ''" >{{_formato.label}}</a>                                      
                                <ul *ngIf="_formato.subItems && _formato.subItems.length" class="sub-menu list-unstyled">
                                    <li *ngFor="let subitem of _formato.subItems">
                                            <a href="javascript:void(0);" (click)="_onSetIdFormato(subitem)" class="mt-4 fs-5"  >{{subitem.label}} </a>  
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </li>
            </div>
        </div>

        <div class="p-2" *ngIf="_CitasSubsecuentes && _CitasSubsecuentes.length">
            <h6 class="mb-0 fw-semibold text-uppercase">Formatos de pacientes</h6>                
            
        </div>

    </div>
</ng-template>