<ng-container >
    <div class="card card-height-50 bg-primary-subtle">
        <div class="card-body">   
            <div class="float-end" *ngIf="_citaSelected">
                <!-- <p>{{ secondsLeft }} seconds remaining</p> -->
                <!-- <span class="fs-2 fw-bold"> {{ secondsLeft | date:'mm:ss' }} </span> -->
                <app-count-down [citaSelected]="_citaSelected"></app-count-down>
            </div>
            <div class="">
                <!-- <img src="{{data.icon}}" alt="" class="avatar-sm"> -->
                <i class="" class="avatar-sm"></i>
                <i class="ri-asterisk display-4 text-primary"></i>
            </div>
    
            <div *ngIf="_citaSelected">
                <a href="javascript:void(0);">
                    <h6 class="fs-15 fw-semibold">{{_citaSelected.Fecha_Inicio | date:'dd-MM-YYYY H:mm' }}
                        <span class="text-muted fs-13"> {{_citaSelected.Estatus}}  </span>
                    </h6>
                    
                </a>
                <p class="mb-0">
                    <i class="ri-user-6-fill align-bottom"></i>
                    <span class="text-muted fs-13"> {{_citaSelected.Paciente_Nombre_Completo}}  </span>
                </p>
                <p class="text-muted mb-0">
                    <i class="ri-building-line align-bottom"></i> {{_citaSelected.Procedimiento}} 
                    <span class="ms-2"><i class=" ri-heart-2-line align-bottom"></i>  {{_citaSelected.Tipo_Cita}} </span>
                    
                </p>
            </div>
           
    
        </div>
    </div>
    </ng-container>