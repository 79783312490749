import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { delay, interval, Subscription } from 'rxjs';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { FormatsService } from '../../services/formats.service';
import { AlertsService } from '../../services/alerts.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.scss']
})
export class AvisoPrivacidadComponent implements OnInit {

  @Input()
  _formatoData: any

  @Input()
  record: any

  @Input()
  _cita: Cita



  _controls : any[] = []
  _formato  : any = {}

  _label: string

  _itsReady: boolean
  _formAvisoPrivacidad: FormGroup;

  $interval!: Subscription;
  _createInterval = true
  
  _interval: number = 0;

  firma_paciente_aviso_privacidad: string | null

  constructor(
    private formBuilder: FormBuilder,
    private formatService: FormatsService,
    private alert: AlertsService,
    private toastr: ToastrService
  ) {} 

  ngOnInit(): void {
    if(this.$interval) {
      this.$interval.unsubscribe()
      console.log('ON DESTROY!!!! INIT');      
    }   

    if(this._formatoData) {
      this._formato = this._formatoData
      this._label = this._formato.label;
      this._controls = this._formato.form.controls;     

      this.initForm()
      this.loadImages() 
    }
  }

  private initForm() {

    this._formAvisoPrivacidad = this.formBuilder.group({
      nombre_paciente_aviso_privacidad: [this._cita.Paciente_Nombre_Completo],      
    })

    this._itsReady = true

    if(this._createInterval) {
      this.createInterval()
    }
  }

  private createInterval() {
    const myObservable = interval(1000);
    this.$interval = myObservable.pipe(
      delay(500),

    ).subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();        
        this._newSaveFormat()            
      }           

      this._interval = integer            
    })
  }
  
  /**
   * 
   */
  _newSaveFormat(): void {

    let _formValues = this._formAvisoPrivacidad.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    //  this._formatoValues = _bodyForm   
    this._saveFormatValues(_bodyForm, true)
  }

  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  private _saveFormatValues (_jsonObject: any, createInterval: boolean = false): void  {
    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {

        if(createInterval) {     
          this.createInterval()
        }
      })
    }).catch((error) => {
      console.error({error});
      
    })

  }

  /**
   * 
   */
  private loadImages () {
    this.formatService._getListImages(this._cita.Cita_Id, this.record.Name).subscribe({
      next:(response) => {
        if(response.codigo === 200) {
          const data = response.data

          if(data.formato && data.formato.archivos) {
            const archivos = data.formato.archivos  

            this.firma_paciente_aviso_privacidad = archivos.firma_paciente_aviso_privacidad && archivos.firma_paciente_aviso_privacidad.length ? archivos.firma_paciente_aviso_privacidad[0].url : null








            console.log({archivos, firma_paciente_aviso_privacidad_____: this.firma_paciente_aviso_privacidad});
            
          }
        }
      }
    })
  }

  /**
   * 
   * @param event 
   * @param main_label 
   */
  _onSaveSing(event: any, main_label: string) {

    if(event) {
      this.alert._showLoader('Guardando por favor espere...')   
      this.formatService.UploadFirmas(this._cita.Cita_Id,this.record.Name, main_label, event).then((resp) => {  
        
        console.log({resp});
        
        this.alert._hideLoader()
        this.loadImages()
        this.toastr.success('Firma, cargada exitosamente','Exito!!',{
          timeOut: 3200
        })
      }).catch((error) => {
        console.error({_ERROR_LOAD_FIRMA: error });
        
        this.alert._hideLoader()
        this.toastr.error('Error al cargar la firma','Error!!',{
          timeOut: 3200
        })
      })

    }

  }
}
