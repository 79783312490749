
<div class="d-flex justify-content-center mt-4">
    <div class="">
        <button type="button" class="btn btn-primary text-uppercase" (click)="_openModalSvg()"> <i class="mdi mdi-plus-box-multiple align-middle me-1 "></i> Agregar exploración física</button>
    </div>
</div>

<div class="d-flex mt-4" *ngIf="_svgEditors && _svgEditors.length">

    <div class="card card-body">
        
        <div class="row gp-2 ">
            <div class="col-lg-6 col-xxl-4" *ngFor="let item of _svgEditors"> 
                <app-svg-editor
                [_svgItem]="item"
                [_path]="item.path"
                (eventEmmiterSaveImg)="_onEvenEmmiter($event)"
                (eventEmmiterImage)="_onEventEmmiterImg($event)"
                (evnetEmmiterDeleteSVG)="_onEventEmmiteDelte($event)"
                ></app-svg-editor>
            </div>
        </div>   
    </div>
</div>
<ng-template #modalSvg let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="text-muted text-uppercase">selecciona la exploracion para agregar </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <ngx-simplebar style="max-height: calc(70vh - 70px)">
                <div class="row m-2">
                    <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let item of _assetsSvg; let i = index">
                        <div class="card">
                            <div class="card-body">
                                <img src="{{item}}" class="img-fluid">                              
                            </div>
                            <div class="card-footer">
                                <button class="btn w-100 btn-primary text-uppercase" (click)="_onSetsvg(item, i)">seleccionar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-simplebar>           
        </div>
    </div>
</ng-template>
