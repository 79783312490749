<div class="card" *ngIf="_formato">
    <div class="card-body">            
        <form [formGroup]="formAnestesia"class="needs-validation createfile-form" autocomplete="off" novalidate >
            <div class="row g-2 m-4" *ngFor="let item of _controls">

                <ng-container *ngIf="item.type=='frame' || item.type == 'title' ; else otherTypes" >
                    
                    <ng-container *ngIf="item.type=='frame'; else titleTemplate">
                        <div class="col-12" >
                            <div class="bg-primary">
                                <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                            </div>
                        </div>

                        <div class="row g-2 m-1">
                            <div class="col-md-12 col-lg-6 col-xl-4" *ngFor="let obj of item.Objects">
                                <div class="bg-success">
                                    <h4 class="fs-4 text-white bold text-uppercase text-center">{{obj.label}} </h4>     
                                </div>
                                <div *ngIf="obj.type == 'text'" >
                                    <div class="form-floating">
                                        <input 
                                        class="form-control"
                                        [type]="obj.type"
                                        [name]="obj.name"
                                        [readonly]="obj.readonly"           
                                        [formControlName]="obj.name"                     
                                        [required]="obj.required"
                                        >
                                        <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-template #titleTemplate >
                        <div class="col-12 ">
                            <div class="bg-primary">
                                <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} --- {{item.name}} </h4>  
                            </div>

                            <ng-container *ngIf="item.name === 'listado_personal'; else _antoherTemplates ">

                                <div class="row g-2 m-1">
                                    <div class="col-12" *ngFor="let obj of item.Objects">
                                        <div class="bg-dark">
                                            <h4 class="fs-16 text-white bold text-uppercase text-center">{{obj.label}} </h4>     
                                        </div>
                                        <div *ngIf="obj.type == 'label' ">
        
                                            <div class="row g-2 m-1">
                                                <div class="col-12" *ngFor="let _objl of obj.Objects" >
                                                 
                                                    <div *ngIf="_objl.type == 'text' ">
                                                        <div class="form-floating">
                                                            <input 
                                                            class="form-control"
                                                            [type]="_objl.type"
                                                            [name]="_objl.name"
                                                            [readonly]="obj.readonly"           
                                                            [formControlName]="_objl.name"                     
                                                            [required]="obj.required"
                                                            >
                                                            <label class="form-label" for="{{_objl.name}}" >{{obj.label}} </label>
                                                        </div>
                                                    </div>
    
                                                    <div *ngIf="_objl.type == 'textarea' ">
                                                        <fieldset>
                                                            <legend class="fs-14 text-muted">{{_objl.label}} </legend>
                                                            <textarea
                                                            [name]="_objl.name"
                                                            [rows]="4"
                                                            class="form-control"
                                                            [readonly]="_objl.readonly"
                                                            ></textarea>
                                                        </fieldset>
                                                    </div>
    
                                                    <div *ngIf="_objl.type == 'canvas' ">
                                                        <fieldset>
                                                            <legend class="fs-14 text-muted">{{_objl.label}} </legend>
                                                            <div class="card" style="border: none;">
                                                                <div class="card-body">
                                                                    <app-canvas-firma-doctor></app-canvas-firma-doctor>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
    
                                                </div>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                            
                            </ng-container>

                            <ng-template #_antoherTemplates>
                                <div class="row g-2 m-1">
                                    <div class="col-12" *ngFor="let obj of item.Objects">
    
                                        <div class="bg-dark">
                                            <h4 class="fs-16 text-white bold text-uppercase text-center">{{obj.label}} </h4>     
                                        </div>
    
                                        <div class="row g-2 m-1" *ngIf="obj.type == 'title' ">
                                            <div class="col-md-6 col-lg-6 col-xl-6" *ngFor="let _obj of obj.Objects">
                                                <fieldset class="mt-4">
                                                    <legend class="fs-14 text-muted"> {{_obj.label}} </legend>                                                
                                                    <div class="row">
                                                        <div class="col-12" *ngFor="let _objItems of _obj.Objects">
                                                            <div *ngIf="_objItems.type =='checkbox' " [ngClass]="" >
                                                                <div class="form-check  mb-3">
                                                                    <input
                                                                    class="form-check-input" 
                                                                    type="checkbox"
                                                                    [name]="_objItems.name"
                                                                    [formControlName]="_objItems.name"
                                                                    [required]="_objItems.required"
                                                                    [checked]="_objItems.value == 'false' ? false : obj.value "
                                                                    (change)="_onCheckChange($event, _objItems.name)"
                                                                    [readonly]="_objItems.readonly">
                                                                    <label class="form-check-label" for="{{_objItems.name}}">{{_objItems.label}} </label>
                                                                </div>
                                                            </div>
    
                                                            <div *ngIf="_objItems.type == 'textarea' ">
                                                                <textarea
                                                                class="form-control"
                                                                [name]="_objItems.name"
                                                                [formControlName]="_objItems.name"
                                                                rows="2"
                                                                ></textarea>
                                                            </div>
    
                                                            <div *ngIf="_objItems.type == 'text' ">
                                                                <div class="form-floating">
                                                                    <input 
                                                                    class="form-control"
                                                                    [type]="obj.type"
                                                                    [name]="obj.name"
                                                                    [readonly]="obj.readonly"           
                                                                    [formControlName]="_objItems.name"                     
                                                                    [required]="obj.required"
                                                                    >
                                                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                                                </div>
                                                            </div>
    
    
                                                            <div *ngIf="_objItems.type == 'label' ">
                                                                <legend class="fs-14 text-muted"> {{_objItems.label}} </legend>
                                                                <div class="row">
                                                                    <div class="col-12 d-inline-block" *ngFor="let _objLabel of _objItems.Objects">
                                                                        
                                                                        <div *ngIf="_objLabel.type =='checkbox' ">
                                                                            <div class="form-check  mb-3">
                                                                                <input
                                                                                class="form-check-input" 
                                                                                type="checkbox"
                                                                                [name]="_objLabel.name"
                                                                                [required]="_objLabel.required"
                                                                                [formControlName]="_objLabel.name"
                                                                                [checked]="_objLabel.value == 'false' ? false : obj.value "
                                                                                (change)="_onCheckChange($event, _objLabel.name)"
                                                                                [readonly]="_objLabel.readonly">
                                                                                <label class="form-check-label" for="{{_objLabel.name}}">{{_objLabel.label}} </label>
                                                                            </div>
                                                                        </div>
    
                                                                        <div *ngIf="_objLabel.type == 'textarea' ">
                                                                            <textarea
                                                                            class="form-control"
                                                                            [name]="_objLabel.name"
                                                                            [formControlName]="_objItems.name"   
                                                                            rows="2"
                                                                            ></textarea>
                                                                        </div>
    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div> 
                                                </fieldset>
                                            </div>
                                        </div>
                                        
                                        <div *ngIf="obj.type == 'label' ">
        
                                            <div class="row g-2 m-1">
                                                <div class="col-12" *ngFor="let _objl of obj.Objects" >
                                                 
                                                    <div *ngIf="_objl.type == 'text' ">
                                                        <div class="form-floating">
                                                            <input 
                                                            class="form-control"
                                                            [type]="_objl.type"
                                                            [name]="_objl.name"
                                                            [readonly]="obj.readonly"           
                                                            [formControlName]="_objl.name"                     
                                                            [required]="obj.required"
                                                            >
                                                            <label class="form-label" for="{{_objl.name}}" >{{obj.label}} </label>
                                                        </div>
                                                    </div>
    
                                                    <div *ngIf="_objl.type == 'textarea' ">
                                                        <fieldset>
                                                            <legend class="fs-14 text-muted">{{_objl.label}} </legend>
                                                            <textarea
                                                            [name]="_objl.name"
                                                            [rows]="4"
                                                            class="form-control"
                                                            [readonly]="_objl.readonly"
                                                            ></textarea>
                                                        </fieldset>
                                                    </div>
    
                                                    <div *ngIf="_objl.type == 'canvas' ">
                                                        <fieldset>
                                                            <legend class="fs-14 text-muted">{{_objl.label}} </legend>
                                                            <div class="card" style="border: none;">
                                                                <div class="card-body">
                                                                    <app-canvas-firma-doctor></app-canvas-firma-doctor>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
    
                                                </div>
                                            </div>                                    
                                        </div>
    
                                        <div *ngIf="obj.type == 'text'" >
                                            <div class="form-floating">
                                                <input 
                                                class="form-control"
                                                [type]="obj.type"
                                                [name]="obj.name"
                                                [readonly]="obj.readonly"           
                                                [formControlName]="obj.name"                     
                                                [required]="obj.required"
                                                >
                                                <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>  
                            </ng-template>

                                 

                        </div>
                    </ng-template>
                </ng-container>

                <ng-template #otherTypes>
                    <div class="bg-light">
                        <h4 class="fs-4 text-black fw-bold text-uppercase text-center">{{item.label}} </h4>     
                    </div>
                    <div class="row g-2 m-1">
                        <!-- class=""  -->
                        <div *ngFor="let obj of item.Objects" [ngClass]="obj.Objects && obj.Objects.length ? 'col-xxl-4 col-lg-6 col-md-12': 'col-md-12 col-lg-6 col-xl-4' ">
                            <div class="bg-success">
                                <h4 class="fs-4 text-white bold text-uppercase text-center">{{obj.label}} </h4>     
                            </div>

                            <div *ngIf="obj.Objects && obj.Objects.length">
                                <div class="col-12" *ngFor="let _obj of obj.Objects">
                                    <div class="row g-2 m-1">
                                        <div class="col-12">
                                            <div *ngIf="_obj.type == 'text'" >
                                                <div class="form-floating">
                                                    <input 
                                                    class="form-control"
                                                    [type]="_obj.type"
                                                    [name]="_obj.name"
                                                    [readonly]="_obj.readonly"           
                                                    [formControlName]="_obj.name"                     
                                                    [required]="_obj.required"
                                                    >
                                                    <label class="form-label" for="{{_obj.name}}" >{{_obj.label}} </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'text' || obj.type == 'time' " >
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [readonly]="obj.readonly"           
                                    [formControlName]="obj.name"                     
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'radio'">          
                                                               
                                <div class="form-check form-check-right form-radio-primary mb-3">
                                    <input 
                                    class="form-check-input" 
                                    type="radio" 
                                    [name]="item.name"         
                                    [value]="obj.name"                                        
                                    id="{{obj.name}}" 
                                    [formControlName]="item.name"
                                    >
                                    <label class="form-check-label" for="{{obj.name}}">
                                    {{obj.label}}
                                    </label>
                                </div>  
                            </div>
                        </div>
                    </div>
                </ng-template>

            </div>
        </form>
    </div>
</div>
