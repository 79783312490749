import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { InitWebSocketService } from 'src/app/services/init-web-socket.service';
import { RedirectService } from 'src/app/services/redirect.service';
import { ToastService } from './toast.service';
import * as _ from 'lodash';
import { SessionPageService } from 'src/app/pages/services/session-page.service';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { PatientsService } from 'src/app/pages/calendar/services/patients.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  @ViewChild('tostrTemplate') tostrTemplate: TemplateRef<any>;
 
  toastService = inject(ToastService);

  $interval!: Subscription;
  show = true;
	autohide = true;

  private _redirectTo: string | undefined;

  pacient:string;

  _data_response: any[] = []
  _jsonCitas: any[] = []

  constructor (
    private activeRoute: ActivatedRoute, 
    private redirect: RedirectService,   
    private _webSocket: InitWebSocketService,
    private sessionPage: SessionPageService,
    private credentials: CredentialsService,
    private patientService: PatientsService
    ) {
      this._webSocket.connect()
      const _jsonCitas = this.sessionPage._returnJSONSession()
      if(_jsonCitas) {
        this._jsonCitas = _jsonCitas
      }
    }

  ngOnInit(): void {
   // this.createInterval()
    this.activeRoute.data.subscribe((resp: any) => {
      this._redirectTo = resp.origin;      
    })

    this._webSocket.getMessages().subscribe({
      next:(resp: any) => {
        console.log({resp});
        
        const _data = JSON.parse(resp.data)
        this.pacient = _data ? _data.pacient: 'n-a'

        this._data_response.push(_data)
        
        this.toastService.show({template: this.tostrTemplate, data: _data  })
      
        
      
       // this.show = true //!this.show;  
      },
      complete() {
        console.log('COMPLETE');
        
      },
      error:(error) => {
        console.error({OCURRED: error});
        
      }
    })

  }

  
  private createInterval(){        
    
    const myObservable= interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if(integer === 20 ) { 
        this.show = !this.show;       
        this.$interval.unsubscribe();                 
        setTimeout(() => {
        //  this.createInterval()
        }, 5000);
      }           
      
    })
  }

  ngOnDestroy(): void {
    this.$interval?.unsubscribe()
    console.log('INTERVALO DESTRUIDO');
    
  }

  onClick() {
    console.log('AAAAAAAAAA');
    this.show =! this.show
    this._RedirectTo()
  }

  _goToForm(toastItem: any) {
    

    const _cita_id = toastItem.data.cita_id;
    
    
    
    //console.log('BBBBBBBBBB');
    let _find = _.find(this._jsonCitas, {Cita_Id: _cita_id })

    if(_find) {
      this.credentials.saveCitaJson(_find)
      this._getPatientDetalle(_find.Paciente_Id)
    }else {
      console.log('Not Foun!!');
      
    }

  }

  private _RedirectTo(){
    this.redirect.to('/doctor')
  }

  /**
   * 
   * @param paciente_id 
   */
  _getPatientDetalle(paciente_id: string) {
   // this.spinner.show(this._spinner)
    this.patientService._getPatientInfo(paciente_id).subscribe({
      next:(response: any) => {
       // 
      // this.spinner.hide(this._spinner)
        this.credentials.saveUserinfo(response);
       // this.router.
      // window.location.reload()
      this.show =! this.show    
      this._RedirectTo()
      },
      error: (error: any) => {
       // this.spinner.hide(this._spinner)
        console.error({error});        
      }
    })
    

  }


  /**
   * 
   * @param template 
   */
  _onClick(template: TemplateRef<any>) {  
    this.toastService.show({template})
  }

  showStandard(template: TemplateRef<any>) {
		this.toastService.show({ template });
	}

}
