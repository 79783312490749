import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { EliminarReceta, Recetas } from 'src/app/pages/models/medicamentos';
import { AlertsService } from '../../services/alerts.service';
import { RecetasService } from 'src/app/pages/services/recetas.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recetas-view',
  templateUrl: './recetas-view.component.html',
  styleUrls: ['./recetas-view.component.scss']
})
export class RecetasViewComponent {

  @ViewChild('modalReceta') modalReceta: TemplateRef<any>;

  @Input()
  _recetas: Recetas[] = []
  @Input()
  _citaID: string
  @Input()
  _Paciente: any
  @Input()
  id_receta: string

  @Output()
  eventLoadRecetas: EventEmitter<boolean> = new EventEmitter<boolean>()


  _receta_: Recetas;
  _ngModal: NgbModalRef;
  

  constructor (
    private alert: AlertsService,
    private receta: RecetasService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  /**
   * 
   * @param receta 
   */
  _generatePDF(receta: any) {
    console.log({receta});       
    
    this.alert._showLoader('Espere...')
    this.receta.ImprimirRecceta(this._citaID, this._Paciente.Name, receta.id_receta).subscribe({
      next:(value: any) =>  {        
        this.alert._hideLoader()
        if(value && value.codigo === 200) {
          setTimeout(() => {
            window.open(value.data.url, '_blank', 'width=1000, height=800');
          }, 100);                
        }       
      },
      error:(err) => {
        console.error({err});
        this.alert._hideLoader()
      },
    })        
  }

  /**
   * 
   * @param receta 
   */
  _editarReceta(receta: Recetas) {    
    this._receta_ = receta
    this._ngModal  = this.modalService.open(this.modalReceta,{
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })       
  }


  /**
   * 
   * @param event 
   */
  _onEventCloseModal2(event: boolean) {
    if(event) {
      this._ngModal.close()
    }
  }

  /**
   * 
   * @param receta 
   */
  _onDelete(receta: Recetas) { 
    Swal.fire({
      title:'Por favor confirme esta accion',
      text:'¿Desea eliminar esta receta ?',
      icon:'question',
      showCancelButton: true,
      cancelButtonText:'No, cancelar',
      confirmButtonText:'Sí, borrar'
    }).then((result) => {
      if(result.value) {
        let _receta: EliminarReceta  = new EliminarReceta()

        if(receta && receta.id_receta) {
          _receta = {
            cita_id: this._citaID,
            id_receta: receta.id_receta
  
          }
          this.receta._eliminarReceta(_receta).then((resp) => {
            this.toastr.info('Se ha eliminado la receta, exitosamente','Exito' ,{
              timeOut: 3200
            })
            this.eventLoadRecetas.next(true)
            //this._loadRecetas(this._citaID)
          })
        }

        
      }
    })
  }

  _onEventLoadMedicamentos(event: boolean) {
    console.log({__onEventLoadMedicamentos___: event});
    
    this.eventLoadRecetas.next(event)
  }


}
