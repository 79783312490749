<div class="mt-4">
    <app-upload-files [showUpload]="_showUploadfiles" [isMobile]="isMobile" (eventEmmitFilesAdded)="onEventAddedFiles($event)"></app-upload-files>
</div>

<fieldset class="mt-4">
    <form [formGroup]="_formOldClinicalHistory" class="needs-validation createfile-form" autocomplete="off" novalidate> 
        <div class="row g-2 m-4">
            <div class="col-12 ">
                <h4 class="fs-12 text-primary bold text-uppercase text-center"> Registre los datos, si cuenta con ellos </h4>     
            </div>
            <div class="col-12">
                <div class="row g-2">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" >           
                            <div class="form-group">
                                <label class="form-label">Nombre completo del paciente</label>
                                <input
                                type="text"
                                name="nombre_paciente"
                                formControlName="nombre_paciente"
                                class="form-control"
                                >
                            </div>                    
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="form-group">
                            <label class="form-label">Numero de cita</label>
                            <input
                            type="text"
                            name="cita"
                            formControlName="cita"
                            class="form-control"
                            >
                        </div> 
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="form-group">
                            <label class="form-label">id_Cita</label>
                            <input
                            type="text"
                            name="id_Cita"
                            formControlName="id_Cita"
                            class="form-control"
                            >
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-end"> 
                    <button class="btn btn-primary">Guardar</button>
                </div>
            </div>
        </div>
    </form>
</fieldset>