<ng-container *ngIf="!isMobile; else mobileTemplate" >
  <div class="card card-body">
    <div class="row">
      <ngx-simplebar style="max-height: calc(40vh - 70px)">  
        <div class="row m-2">      
            <div class="col-xxl-2 col-md-4" *ngFor="let item of _groupedCitas" >                    
              <app-stat 
              [title]="recursoName(item.recursoId) === 'N/D' ? item.Name : recursoName(item.recursoId) " 
              [value]="item.value" 
              [desciption]="'Ver citas en calendario'" 
              [recursoId]="item.recursoId"
              [DataCitas]="_citas"
              (eventEmmitCitas)="OnEmmitCitas($event)"
              [icon]="'bx-health'"></app-stat>      
            </div>                 
        </div>
      </ngx-simplebar>
    </div>
  </div>
  
  <hr>

  <div class="mt-4" *ngIf="calendarVisible"> 

    <div class="row mt-4">
      <div class="col ">
        <div class="float-end">
          <button class="btn bt-icon text-primary" (click)="_openModal()"> <i class="mdi mdi-filter align-middle me-1 fw-bold fs-2"></i>  </button>  
        </div>        
      </div>
      <div class="col-12">
        <ng-template [ngTemplateOutlet]="fullCalendar"></ng-template>
      </div>
    </div>
  </div>
</ng-container>

  <ng-template #mobileTemplate>
    <app-top-bar-formatos 
    [_selectedDoctors]="_selectedDoctors" 
    [_selectedRecursos]="_selectedRecursos" 
    [_selectedServicios]="_selectedServicios"
    (_eventEmmiterFilter)="OnEventFilter($event)"
    ></app-top-bar-formatos>
    <ng-template *ngIf="calendarVisible" [ngTemplateOutlet]="fullCalendar"></ng-template>
  </ng-template>
  
  <!-- FulllCalendar -->
  <ng-template #fullCalendar>
    <app-loader-full-calendar *ngIf="_showLoader" id="_show_spinner" ></app-loader-full-calendar>
    <full-calendar  [ngClass]="_showLoader ? 'inative-calendar' : '' " #fullCalendar [options]="calendarOptions" [deepChangeDetection]="true"  contentHeight="auto">
    </full-calendar>          
  </ng-template>
  
  <!-- Canvas -->
  <ng-template #canvasDetail let-offcanvas>
    <app-canvas-detalles-citas [_detallesCita]="_detallesCita" (eventCanvasEmiiter)="onEventCanvasEmmitter($event)"></app-canvas-detalles-citas>
  </ng-template>

  <ng-template #modalFilters let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Filtros de búsqueda para calendario de citas</h4>
      <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="m-2">
        <ngx-simplebar style="max-height: calc(70vh - 70px)">
          <app-form-filter-calendar 
          [dateRange]="_dateRange"
          [_selectedDoctors]="_selectedDoctors" 
          [_selectedRecursos]="_selectedRecursos" 
          [_selectedServicios]="_selectedServicios" 
          (onEventEmmitFilters)="SetEventEmmitFilters($event)"
          ></app-form-filter-calendar>
        </ngx-simplebar>
      </div>    
    </div>
  </ng-template>