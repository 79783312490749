export class NotaDeEvolucion {
    Cita_Id: string //"": "string",
    fecha: string //"": "2024-06-09",
    subjetivo: string //"": "string",
    objetivo: string //"": "string",
    analisis: string //"": "string",
    plan: string //"": "string",
    usuario: string //"": "string"
    imagenes?: imagenesNotaEvolucion[]
}

export interface imagenesNotaEvolucion {
    ruta: string
    ruta_archivo: string
    filename: string
}

export interface ResponseUploadImages {
    codigo: number
    mensaje: string
    data: imagenesNotaEvolucion

}