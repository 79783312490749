<ng-template #DetallesRecurso let-offcanvas>
    <div class="d-flex align-items-center bg-dark bg-gradient p-3 offcanvas-header">
        <h5 class="m-0 me-2 text-white text-uppercase">{{_recurso.Nombre_del_Recurso}} </h5>
        <button type="button" class="btn-close btn-close-white ms-auto " data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="_hideCanvas()"></button>
    </div>
    <div class="offcanvas-body profile-offcanvas p-0">

        <ngx-simplebar style="height: 90vh;">
            <div class="p-4">
                <h5 class="fs-15 mb-4">Detalles</h5>

                <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">ID Recurso</h6>
                        <p class="mb-0">{{_recurso.Name}} </p>
                    </div>                    
                </div>

                <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Nombre del recurso</h6>
                        <p class="mb-0">{{_recurso.Nombre_del_Recurso}} </p>
                    </div>                    
                </div>
                

                <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">URL Dirección</h6>
                        <p class="mb-0"> <a href="{{_recurso.Url_Direction}}" target="_blank" class="link-secondary text-decoration-underline  link-underline-opacity-100-hover" > {{_recurso.Url_Direction}} </a> </p>
                    </div>                    
                </div>

                <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Tipo de Recurso</h6>
                        <p class="mb-0">{{_recurso.Tipo_de_Recurso}} </p>
                    </div>    

                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Teléfono</h6>
                        <p class="mb-0">{{_recurso.Telphone}} </p>
                    </div>                    
                </div>

                <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1">Dirección</h6>
                    <p class="mb-0">{{_recurso.Direction}} </p>
                </div>    

                
                
               <!--  <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Color de Calendario</h6>
                        <p class="mb-0">{{_recurso.Color_de_Calendario}} </p>
                    </div>                    
                </div>
 -->
            </div>
        </ngx-simplebar>

    </div>
</ng-template>