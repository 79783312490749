import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { Images } from 'src/app/shared/models/list-images';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-nota-evolucion',
  templateUrl: './nota-evolucion.component.html',
  styleUrls: ['./nota-evolucion.component.scss']
})
export class NotaEvolucionComponent implements OnInit, OnDestroy {

  @Input()
  id: string

  @Input()
  _formatoData: any

  _Cita: Cita

  $interval!: Subscription;

  _BODYFORM: any;

  _Firma: Images[] = []

  currentUser: any

  public formNotaEvolucion : FormGroup = this.formBuilder.group({})

  constructor(
    private formatService: FormatsService,
    private credentials: CredentialsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private tokenService: TokenStorageService
    ) {

      const _cita = this.credentials.getCitaJson()
      if(_cita) {
        this._Cita = _cita
      }
      this.currentUser = this.tokenService.getUser()
    }


  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean

  

  ngOnInit(): void {

    if(this._formatoData) {
      this._formato = this._formatoData

      if(this._formato.form) {
          this._label = this._formato.label;
          this._controls = this._formato.form.controls;                       
          this._itsReady = true;
          this.initForm()
          this._loadImage()
    }

/*     if(this.id) {
      this._itsReady = false;
      this.formatService._getFormat(this.id,this._Cita.Cita_Id).subscribe({
        next:(response) => {
          if(response.codigo == 200) {
            this._formato = response.data.formato;

            if(this._formato.form) {
                this._label = this._formato.label;
                this._controls = this._formato.form.controls;                       
                this._itsReady = true;
                this.initForm()
                this._loadImage()
            }else{
              console.log({_formato: this._formato});              
            }
          }          
        },error:(error) => {

        }
      })
    }
    console.log({Id: this.id});  */   
  }
}

  ngOnDestroy(): void {
    if(this.$interval) {
      this.$interval.unsubscribe()
    }
  }

  get f() {
    return this.formNotaEvolucion.controls
  }

  private _loadImage() {
      /**
     * Cargamos la firma del doctor
     */
      this.formatService._getImageProfile(this.currentUser.username).subscribe({
        next:(response) => {
          if(response.codigo == 200) {
            this._Firma = response.data.formato.archivos.Firma;
          }
          
        }
      })
  }


  private initForm() {
    _.map(this._controls, (a) => {
      _.map(a.Objects, (b) => {        
        this.formNotaEvolucion.addControl(
          b.name, this.formBuilder.control(b.value)
        )
      })
    })

   // this.createInterval()


  }

  private createInterval(){        
    
    const myObservable= interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();      
        console.log('GO SAVE IT!!!!');        
        //this.show = !this.show;       
        this._onSaveIt()     
       
      }           
      //this._interval = integer
      
    })
  }

  _onSaveIt() {
    
    //console.log({_FORMVALUES: _formValues });
    console.log({_FOMATO: this._formato });

    let _formato = this._formato

    _.map(_formato.form.controls, (a) => {

      if(a.type == 'frame') {
        _.map(a.Objects, (b) => {
          b.value = this.f[b.name].value
        })
      }
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      form: _formato.form
    }

    this.formatService._postFormato(_bodyForm).then((resp) => {
      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      })

      this.$interval.unsubscribe()

       setTimeout(() => {
          this.createInterval()     
        }, 5000);

    }).catch((error) => {
      console.error({error});
      
    })

    this._BODYFORM = _bodyForm


    console.log({_BODYFORM: _bodyForm });


  }

}
