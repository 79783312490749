<div class="card">
    <div class="card-header">
        <h4 class="card-title mb-0 text-primary">CLASIFICACIÓN PACIENTE</h4>
    </div>
    <div class="card-body">
        <form [formGroup]="formCalificacion" (ngSubmit)="_onSendCalification()">
            <ng-container formArrayName="calificacion">
                <div class="row row-cols-xxl-3 row-cols-lg-2 row-cols-md-2 row-cols-1">
                    <div class="col" *ngFor="let control of calificacion.controls; index as i" [formGroupName]="i"> 
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title text-center text-primary"> {{control.value.title}}  </div>
                            </div>
                            <div class="card-body">       
                                <div class="form-check form-check-inline" *ngFor="let _opt of control.value.option; let j = index">
                                    
                                    <div class="form-check form-check-right">                                
                                        <input 
                                        class="form-check-input" 
                                        formControlName="opt" 
                                        [name]="_opt.name"
                                        type="radio" 
                                        [value]="_opt.value" 
                                        (ngModelChange)="_onModelChange($event,control)"
                                        [id]="'radio-'+control.value.name + j"  >
                                        <label class="form-check-label fw-bold text-primary fs-16" [for]="'radio-'+control.value.name + j">
                                            {{_opt.label}}
                                        </label>
                                    </div>
                                </div>                                
                                    <div class="mt-4 custom-slider" *ngIf="control.value.puntuacion && control.value.puntuacion.length" >
                                        <ngx-slider 
                                        formControlName="calification" 
                                        (ngModelChange)="_onSliderChange($event,control)"
                                        [options]="options">
                                        </ngx-slider>
                                    </div>
                            </div>
                        </div>                     
                    </div>
                </div>
                <div class="row row-cols-xxl-2 row-cols-lg-2 row-cols-md-2 row-cols-1">
                    <div class="col">
                        <div class=" float-end">
                            <p class="lead"> Puntos <span class="fw-bold">{{_total}} </span> </p>
                        </div>                        
                    </div>
                    <div class="col float-end">                         
                        <div class="d-flex flex-wrap gap-2">
                            <span class="badge fs-4 fw-bold" [ngClass]="getbgBadge(_total)">{{getLevel(_total)}} </span>                                
                        </div> 
                    </div>
                </div>                
            </ng-container>
            <div class="float-end">
                <button type="submit" class="btn btn-primary">Enviar  calificacion </button>
            </div>
        </form>   
    </div>
</div>