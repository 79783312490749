<div id="layout-wrapper" (window:resize)="onResize($event)">
    <!-- Top Bar -->
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()" ></app-topbar>
    <!-- Side Bar -->
    <app-sidebar [menuItems]="menuItems"></app-sidebar>
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <!-- Main Content -->
                <router-outlet></router-outlet>
            </div>
        </div>   
<!--         <app-footer></app-footer> -->
                    
    </div>
  </div>
  <!-- Right Side Bar -->
<button (click)="topFunction()" class="btn btn-danger btn-icon" id="back-to-top">
    <i class="ri-arrow-up-line"></i>
</button>