import { Component, Input, OnInit } from '@angular/core';
import { CredentialsService } from 'src/app/core/services/credentials.service';


import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent {

  @Input() 
  _basic_info: any[] = []

  record: any

  constructor(
    private credentials: CredentialsService
    ) {
    this.record = this.credentials.userInfoData.data.records[0]
    flatpickr.localize(Spanish)
  }

  /* ngOnInit(): void {
    console.log({_basic_info: this._basic_info});
    
    console.log({recor: this.record});   

  } */

  /**
   * 
   * @param name 
   * @returns 
   */
  _getValue(name: string) {
    switch (name) {
      case 'name':
        return this.record.Nombre_completo__c
      case 'fecha':
        return this.record.CreatedDate //this.date.transform(, 'yyyy-MM-dd')
      case  'birth_date':
        return this.record.Fecha_nacimiento_calendario__c  //;    
      case 'age':
        return this.record.P05_Edad__c;
      case 'sex':
        return this.record.P05_Sexo__c;
      case 'address':
        return this.record.DireccionCompleta__c;
      case 'telphone':
        return this.record.P05_Telefono__c;
      case 'celphone':
        return this.record.P05_Celular__c;
      case 'email':
        return this.record.P05_Correo__c;
      case 'seguro_medico':
      return this.record.Compa_a_de_SGMM__c;
      case 'firma':
        return 'N/A'
      default:
        return 'ESPERAA'
    }
  }

  /**
   * 
   * @param name 
   */
  _getClass(name: string) {

    switch (name) {
      case 'fecha':
          return 'col-sm-12 col-md-4 col-lg-3 col-xl-2';
      case 'name':;
          return 'col-sm-12 col-md-8 col-lg-9 col-xl-6'    
      case 'age':
          return 'col-sm-12 col-md-3 col-lg-4 col-xl-2';
      case 'sex' :
          return 'col-sm-12 col-md-4 col-lg-4 col-xl-2'
      case 'birth_date':
          return 'col-sm-12 col-md-5 col-lg-4 col-xl-2'
      case 'address':
          return 'col-sm-12 col-md-12 col-lg-12 col-xl-6'
      case 'telphone':
          return 'col-sm-12 col-md-6 col-lg-6 col-xl-2'
      case 'celphone':
          return 'col-sm-12 col-md-6 col-lg-6 col-xl-2'
      case 'email': 
          return 'col-sm-12 col-md-6 col-lg-6 col-xl-3'
      case 'seguro_medico':
          return 'col-sm-12 col-md-6 col-lg-6 col-xl-3'
      case 'firma' :
          return 'col-sm-12 col-md-12 col-lg-12 col-xl-6'
      default:
        return 'col-12'
        break;
    }

    
    //[class]="item.name == 'name' ? 'col-sm-12 col-md-12 col-lg-12 col-xl-6' : '' "

  }


}
