import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private timeOut: number = 3200;

  constructor(private toastrS: ToastrService) { }
  
  /**
   * Muestra un Success Toastr
   * @param title 
   * @param msg 
   * @param timeout 
   * @param onActionHide 
   */
  public _success(title: string, msg: string, timeout?: number,  onActionHide?: boolean ) {

    if(onActionHide) {
      this.toastrS.success(`${title}`, `${msg}`, {
        timeOut: timeout ? timeout: this.timeOut
      }).onHidden.subscribe((resp) => {

      })

    }else {
      this.toastrS.success(`${title}`, `${msg}`, {
        timeOut: timeout ? timeout: this.timeOut
      })
    }    
  }

  /**
   * Muestra un mensaje Toastr Error
   * @param title 
   * @param msg 
   * @param timeout 
   * @param onActionHide 
   */
  public _error(title: string, msg: string, timeout?: number,  onActionHide?: boolean) {

    if(onActionHide) {
      this.toastrS.error(`${title}`, `${msg}`, {
        timeOut: timeout ? timeout: this.timeOut
      }).onHidden.subscribe((resp) => {

      })

    }else {
      this.toastrS.error(`${title}`, `${msg}`, {
        timeOut: timeout ? timeout: this.timeOut
      })
    }    

  }

  /**
   * Muestra un Mensaje Toastr Info
   * @param title 
   * @param msg 
   * @param timeout 
   * @param onActionHide 
   */
  public _info(title: string, msg: string, timeout?: number,  onActionHide?: boolean) {
    if(onActionHide) {
      this.toastrS.info(`${title}`, `${msg}`, {
        timeOut: timeout ? timeout: this.timeOut
      }).onHidden.subscribe((resp) => {

      })

    }else {
      this.toastrS.info(`${title}`, `${msg}`, {
        timeOut: timeout ? timeout: this.timeOut
      })
    }    
  }

  /**
   * Muestra un mesaje Warning Toastr
   * @param title 
   * @param msg 
   * @param timeout 
   * @param onActionHide 
   */
  public _warning(title: string, msg: string, timeout?: number,  onActionHide?: boolean) {
    if(onActionHide) {
      this.toastrS.warning(`${title}`, `${msg}`, {
        timeOut: timeout ? timeout: this.timeOut
      }).onHidden.subscribe((resp) => {

      })

    }else {
      this.toastrS.warning(`${title}`, `${msg}`, {
        timeOut: timeout ? timeout: this.timeOut
      })
    }    
  }

}
