import { Injectable } from '@angular/core';

import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor() { }

  /**
   * 
   * @param text 
   */
  _showLoader(text: string) {

    Swal.fire({
      text: text, 
      allowEnterKey: false,
      allowEscapeKey: false,
       allowOutsideClick: false
    })

    Swal.showLoading()

    
  }

  /**
   * 
   */
  _hideLoader() {
    Swal.close()
  }

  /**
   * 
   * @param title 
   * @param text 
   */
  _showSuccess(title: string, text: string) {

  return Swal.fire({
      title: title,
      text: text,
      icon:'success',
      confirmButtonText:'Cerrar',
      allowOutsideClick:false
    })

  }

}
