<div id="preloader">
    <div id="status">
        <div class="spinner-border text-primary avatar-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

<div class="customizer-setting">
    <div class="btn-primary rounded-pill shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
        data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas"
        (click)="openCanvas(filtetcontent)">
        <i class='mdi mdi-menu  fs-22'></i>
    </div>
</div>

<ng-template #filtetcontent let-offcanvas>
    <div class="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
        <h5 class="m-0 me-2 text-white">Pacientes</h5>
        <button type="button" class="btn-close btn-close-white ms-auto " data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"></button>
    </div>

    <div class="offcanvas-body p-2">
        <div class="card border shadow-none card-body text-muted mb-0">
        
            <div class="p-2">
                <div class="form-group">                                  
                    <input type="text" class="form-control" placeholder="Buscar paciente" [(ngModel)]="_searchText" >
                </div>                                
            </div>

            <ngx-simplebar style="height: 50vh;">
                <div class="list-group p-2" *ngFor="let item of _jsonCalendar | filterPatients: _searchText ">
                    <button 
                    type="button" 
                    (click)="_OnLoadPatient(item)"
                    class="list-group-item list-group-item-action" >                                    
                        {{item.Paciente_Nombre_Completo}}
                    </button>                           
                </div>
            </ngx-simplebar>
        </div>

        <div class="card border shadow-none card-body text-muted mb-0 mt-4" *ngIf="_showLogs">
            <div class="card-header">
                <span class="text-muted">Logs de pacientes </span>
            </div>
            <ngx-simplebar style="height: 50vh;">
                <div class="card">
                    <div class="card-body">
                        <ngx-simplebar style="height: 50vh;">
                            <div class="list-group p-2" *ngFor="let cita of _citasPaciente; let i = index;">
                                <button 
                                type="button" 
                                (click)="onLoadDetallesCita(cita)"
                                class="list-group-item list-group-item-action" >
                                    {{cita.Name}}
                                </button>
                            </div>
                        </ngx-simplebar>
                    </div>
                </div>
            </ngx-simplebar>
        </div>
    </div>
</ng-template>



