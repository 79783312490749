<!-- <app-notification></app-notification>  -->
<app-breadcrumbs [title]="_nombre_completo" [breadcrumbItems]="breadCrumbItems" (eventClickEmitter)="_onEventEmmiterClick($event)" ></app-breadcrumbs>

<ng-container *ngIf="!isMobile; else mobileTemplate" >
    <div class="">
        <div class="card p-2">        
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6 col-xl-3 d-none d-xl-block">
                        <div class="row p-2">
                            <div class="col-12">
                                <fieldset>
                                    <div class="row d-flex justify-content-between ">
                                        <div class="col-9">
                                            <legend class="text-muted text-center fs-20 ">Listado de pacientes</legend>
                                        </div>
                                        <div class="col-md-auto">
                                        <a 
                                        class="btn btn-sm btn-primary" 
                                        data-bs-toggle="collapse" 
                                        aria-expanded="false"
                                        aria-controls="collapseExample" 
                                        (click)="collapse.toggle()"
                                        [attr.aria-expanded]="!Collapsed">
                                            <i [class]="!Collapsed ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up' "></i>
                                        </a>
                                        </div>
                                    </div>       
                                </fieldset>
    
                                <div class="collapse show" id="collapseExample" #collapse="ngbCollapse" [(ngbCollapse)]="Collapsed">
                                    <div class="card border shadow-none card-body text-muted mb-0">
                                        <ngx-simplebar style="height: 50vh;">
                                            <div class="list-group p-2" *ngFor="let item of _clientes_espera let i = index;">
                                                <button 
                                                type="button" 
                                                (click)="_onloadHistory(item)"
                                                class="list-group-item list-group-item-action" >                                    
                                                    {{item.data.pacient}}
                                                </button>                           
                                                </div>
                                        </ngx-simplebar>
                                    </div>
                                </div>
                            </div>  
                        </div>      
                        <div class="row p-2">
                            <div class="col-12">      
                                <fieldset>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-9 d-flex justify-content-start">
                                            <legend class="text-muted text-center fs-20 ">Formatos de pacientes</legend>
                                        </div>
                                        <div class="col-md-auto">
                                            <a 
                                            class="btn btn-sm btn-primary" 
                                            data-bs-toggle="collapse_" 
                                            aria-expanded="false"
                                            aria-controls="collapseExample" 
                                            (click)="collapse_.toggle()"
                                            [attr.aria-expanded]="!secondColleaps">
                                                <i [class]="!secondColleaps ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up' "></i>
                                            </a>
                                            </div>
                                    </div>                                
                                </fieldset>
    
                                <div class="collapse show" id="collapseExample" #collapse_="ngbCollapse" [(ngbCollapse)]="secondColleaps">
                                    <ngx-simplebar style="height: 50vh;">
                                        <div class="list-group list-group-fill-success">
                                            <a href="javascript:void(0);" *ngFor="let item of _pacientesFormatos"
                                            class="list-group-item list-group-item-action "  (click)="_onSetView(item)" >                       
                                            <span class="d-sm-block">{{item.label}}</span>
                                            </a>
                                        </div>
                                    </ngx-simplebar>  
                                </div>
                            
                            </div>
                        </div>  

                        <div class="row p-2">
                            <div class="col-12">
                                <fieldset>
                                    <div class="row d-flex justify-content-between mt-4 "> 
                                        <button class="btn btn-primary mt-4" (click)="_onSetView('Datos_Paciente_Recomendaciones')">Recomendaciones paciente </button>
                                        <button class="btn btn-primary mt-4" (click)="_onSetView('Consentimiento_Informado_Aplicacion_Sedacion')">Consentimiento informado aplicacion sedacion </button>
                                        <button class="btn btn-primary mt-4" (click)="_onSetView('Consentimiento_Informado_Procedimiento_Diagnostico')">Consentimiento informado procedimiento diagnostico </button>
                                        <button class="btn btn-primary mt-4" (click)="_onSetView('Historia_Clinica_Varices')">Historia clinica varices</button>


                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-9">
                        <app-right-sidebar 
                        class="d-none d-md-block d-xl-none"
                        (settingsButtonClicked)="onSettingsButtonClicked()" 
                        [Formatos]="_pacientesFormatos" 
                        (SetIdFormato)="onSetIdFormato($event)"
                        ></app-right-sidebar>                        
                        <div class="card" style="border: none;">
                            <div class="card-body">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-4">
                                            <!-- Buttons with Label -->
                                                <button type="button" class="btn btn-dark btn-sm btn-label waves-effect waves-light" (click)="onBack()">
                                                    <i class=" ri-arrow-left-s-line  label-icon "></i> Volver atras </button> 
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="p-2">
                                                <button type="button" class="btn btn-soft-primary waves-effect w-lg">
                                                    <span class="text-uppercase bold"> Nuevo HC </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="p-2">
                                                <button type="button" class="btn btn-soft-primary waves-effect w-lg" >
                                                    <i class="ri-pencil-line label-icon align-middle fs-16 me-2"></i> <span class="text-uppercase bold"> receta </span> </button>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </fieldset>
                            </div>

                            <ng-template [ngTemplateOutlet]="userData"></ng-template>

                            <div class="card-body border-top">             
                                <ng-template [ngTemplateOutlet]="layoutTemplates"></ng-template>                            
                            </div>
                            <div class="card-footer hstack gap-2 p-3 d-none">
                                <button class="btn btn-success btn-sm w-100"><i class="ri-phone-line align-bottom me-1"></i> Call</button>
                                <button class="btn btn-primary btn-sm w-100"><i class="ri-question-answer-line align-bottom me-1"></i>
                                  Message</button>
                            </div>
                        </div> 
                    </div>
                </div>    

                <div class="row">
                    <div class="col-"></div>
                </div>
                
            </div>
        </div>
    </div>

</ng-container>

<ng-template #mobileTemplate>

    <app-right-sidebar 
    (settingsButtonClicked)="onSettingsButtonClicked()" 
    [Formatos]="_pacientesFormatos" 
    (SetIdFormato)="onSetIdFormato($event)"
    ></app-right-sidebar>
    <fieldset>
        <div class="row">
            <div class="col-6">
                <div class="p-2">
                    <button type="button" class="btn btn-soft-primary waves-effect w-lg">
                        <span class="text-uppercase bold"> Nuevo HC </span>
                    </button>
                </div>
            </div>
            <div class="col-6">
                <div class="p-2">
                    <button type="button" class="btn btn-soft-primary waves-effect w-lg">
                        <i class="ri-pencil-line label-icon align-middle fs-16 me-2"></i> <span class="text-uppercase bold"> receta </span> </button>
                </div>
            </div>
        </div>

        <ng-template [ngTemplateOutlet]="userData"></ng-template>
        

    </fieldset>
    <app-layout-formatos [Id]="_viewId"></app-layout-formatos>
</ng-template>

<ng-template #layoutTemplates>
    <app-layout-formatos [Id]="_viewId"></app-layout-formatos>
</ng-template>

<ng-template #userData>
    <div class="card" *ngIf="record &&  _cita" >
        <div class="card-body">
            <div class="row g-2 m-4">
                <h4 class="fs-14 text-primary bold text-uppercase text-center">Datos Generales  </h4>    

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="form-floating">
                        <input 
                        class="form-control"
                        [value]="record.P00_Nombre__c"
                        readonly                        
                        >
                        <label class="control-label">Nombre (s)</label>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="form-floating">
                        <input 
                        class="form-control"
                        [value]="record.P05_Apellidos__c"
                        readonly                        
                        >
                        <label class="control-label">Apellido (s)</label>
                    </div>
                </div>

                <div class="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-floating">
                        <input 
                        class="form-control"
                        [value]="record.P05_Edad__c"
                        readonly                        
                        >
                        <label class="control-label">Edad</label>
                    </div>
                </div>

                <div class="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-floating">
                        <input 
                        class="form-control"
                        [value]="record.P05_Sexo__c"
                        readonly                        
                        >
                        <label class="control-label">Sexo</label>
                    </div>
                </div>

                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-floating">
                        <input 
                        class="form-control"
                        [value]="record.P05_Fecha_de_Nacimiento__c | date: 'd-MM-Y' "
                        readonly                        
                        >
                        <label class="control-label">Fecha de Nacimiento</label>
                    </div>
                </div> 

            </div>
            <div class="row g-2 m-4">
                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-floating">
                        <input 
                        class="form-control"
                        [value]="_cita.Fecha_Inicio | date: 'd-MM-Y H:m a' "
                        readonly                        
                        >
                        <label class="control-label">Fecha inicio</label>
                    </div>
                </div> 

                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-floating">
                        <input 
                        class="form-control"
                        [value]="_cita.Fecha_Final | date: 'd-MM-Y H:m a' "
                        readonly                        
                        >
                        <label class="control-label">Fecha final</label>
                    </div>
                </div> 

                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-floating">
                        <input 
                        class="form-control"
                        [value]="_cita.Tipo_Evento "
                        readonly                        
                        >
                        <label class="control-label">Evento</label>
                    </div>
                </div> 

                



                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-6  col-md-6">
                    <div class="form-floating">
                        <input
                        class="form-control"
                        [value]="record.Nombre_del_Recurso__c"
                        readonly
                        >
                        <label class="control-label">Nombre del recurso</label>
                    </div>
                </div>
                <div class="col-xxl-2 col-xxl-2 col-xl-4 col-lg-6 col-md-6">
                    
                    <div class="form-floating">
                        <input
                        class="form-control"
                        [value]="_cita.Servicio"
                        readonly
                        >
                        <label class="control-label">Servicio</label>
                    </div>
                </div>

                <div class="col-12">
                    <fieldset>
                        <legend class="text-muted fs-14">Comentarios</legend>
                        <textarea
                        class="form-control"
                        [value]="record.P05_Comentario__c"
                        readonly
                        [rows]="10"                    
                       >
   
                       </textarea>
                    </fieldset>
                    
                  
                        
                    
                 
                </div>

            </div>
        </div>
    </div>
</ng-template>

<!-- Canvas -->
<ng-template #canvasDetail let-offcanvas>
    <app-canvas-detalles-citas [_detallesCita]="_detallesCita" (eventCanvasEmiiter)="onEventCanvasEmmitter($event)"></app-canvas-detalles-citas>
</ng-template>

<ngx-spinner [name]="_spinner" [fullScreen]="true" type="ball-scale-multiple" ></ngx-spinner>





