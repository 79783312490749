import { ResponseUploadImages } from './../models/nota-evolucion';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';


import { FormatoResponse } from '../models/formato-response';
import { ListImages } from '../models/list-images';
import { WebcamImage } from 'ngx-webcam';
import { NotaDeEvolucion, imagenesNotaEvolucion } from '../models/nota-evolucion';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  //private readonly apiUrl: string = environment.production ? environment.apiUrl : env.environment.apiUrl
  private readonly apiUrl: string = environment.apiUrl;    

  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  /**
   * 
   * @param formato 
   */
  _getFormat(formato: string, Cita_Id?: string, ): Observable<FormatoResponse> {
    return this.http.get<FormatoResponse>(`${this.apiUrl}formatos/?formato=${formato}&Cita_Id=${Cita_Id}`)
  }

  /**
   * 
   * @param formato 
   * @param Cita_Id 
   * @returns 
   */
  async _getFormatoAsync(formato: string, Cita_Id?: string) {
    return await firstValueFrom(this.http.get<FormatoResponse>(`${this.apiUrl}formatos/?formato=${formato}&Cita_Id=${Cita_Id}`))
  }

  /**
   * 
   * @param Cita_Id 
   * @returns 
   */
  _getForamtoByCita(Cita_Id: string) {
    return firstValueFrom(
        this.http.get<FormatoResponse>(`${this.apiUrl}formatos/?formato=true&Cita_Id=${Cita_Id}`)
    )
  }

  /**
   * 
   * @param _body 
   */
  async _postFormato(_body:any) {
    return await firstValueFrom(this.http.post(`${this.apiUrl}formatos/guardar_formato`,_body)).then((response: any) => {
      return response
    }).catch(error => {
      console.error({error});      
    })
  }

  /**
   * 
   * @param cita_id 
   * @param iamge 
   */
  public async _uploadImage({ cita_id, paciente_id, files, user, profile, firma, main_label, label, laboratorio }: { cita_id: string; paciente_id: string; files: any[] | undefined; user?: any; profile?: any; firma?: any; main_label?:string, label?: string, laboratorio?: boolean | null }  ) {

      let total = 0;
      if(files){
        for (let index = 0; index < files.length; index++) {
          const element = files[index];      
          //const _file = element
          console.log({element});
          

          const formData: FormData = new FormData();
          
          /**
           * 
           */
          if(paciente_id != '') {
            formData.append('paciente_id',`${paciente_id}`);
          }

          /**
           * 
           */
          if(cita_id != ''){
            formData.append('cita_id', `${cita_id}`);
          }
          
          /**
           * 
           */
          if(user != null) {
            formData.append('user',`${user}`)
          }

          /**
           * 
           */
          if(profile != null) {
            formData.append('profile',`${profile}`)
          }

          /**
           * 
           */
          if(firma != null) {
            formData.append('firma',`${firma}`)
          }

          /**
           * 
           */
          if(main_label != null ){
            formData.append('main_label',`${main_label}`)
          }

          /**
           * 
           */
          if(label != null ){
            formData.append('label',`${label}`)
          }

          if(laboratorio != null) {
            formData.append('laboratorio',`${laboratorio}`)
          }

          
          
          formData.append('file', element);        

          let headers = new HttpHeaders();
          headers.append('Content-Type', 'multipart/form-data');
          headers.append('Accept', 'application/json');

          await  firstValueFrom (this.http.post(`${this.apiUrl}formatos/guardar_imagen`,formData)).then(resp => {
            total++;
          })
      
        }

        if(total == files.length) {
          return true
        }else {
          return false
        }    
      }
      return false
  }

  /**
   * 
   * @param dataURI 
   * @returns 
   */
  private b64toArrayBuffer(dataURI: any) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return ia;
  }
  
  /**
   * 
   * @param dataURI 
   * @param mimetype 
   * @returns 
   */
  private b64toBlob(dataURI: any, mimetype: any) {
    return new Blob([this.b64toArrayBuffer(dataURI)], {
      type: mimetype
    });
  }

  //public async UploadSignatureFromFormat()

  /**
   * 
   * @param cita_id 
   * @param paciente_id 
   * @param picture 
   */
  public async UploadFromCamera(cita_id: string, paciente_id: string, picture: WebcamImage, label: string, main_label: string) {

    try {
 
      const formData: FormData = new FormData();
      
      const _blob = this.dataURItoBlob(picture.imageAsDataUrl) //this.b64toBlob(picture.imageAsDataUrl, picture.imageAsBase64.);

      formData.append('file',_blob)
      formData.append('cita_id',`${cita_id}`)
      formData.append('paciente_id',`${paciente_id}`)

     /*  formData.append('label',`${label}`)
      formData.append('main_label',`${main_label}`) */

      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');

      await firstValueFrom (this.http.post(`${this.apiUrl}formatos/guardar_imagen`,formData))

      return true


    }catch(e) {
      console.error({e});
      return false
      
    }

  }

  /**
   * 
   * @param cita_id 
   * @param paciente_id 
   * @param main_label 
   * @param label 
   * @param files 
   * @param pictures 
   * @returns 
   */
  public async UploadFiles(cita_id: string, paciente_id: string, main_label: string,  files?:any[] | null, pictures?:any[] | null , _svgFiles?: any[] ) {
    let _total = 0
    
    /**
     * 
     */
    if (files && files.length) {
      for (let index = 0; index < files.length; index++) {
        const element = files[index]//.file

        console.log({element});

        //return console.log('aaaaaaaaaaaaaaaaaaaaa');
        
        
        
        //Archivo
        const file = element.pondFile.file
        const formData: FormData = new FormData();

        formData.append('cita_id', `${cita_id}`);
        formData.append('paciente_id',`${paciente_id}`);
        formData.append('main_label',`${main_label}`)
        
        if(element.comentario) {
          formData.append('comentario',`${element.comentario}`)
        }
    

        formData.append('file', file);

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        
        await firstValueFrom(
          this.http.post(`${this.apiUrl}formatos/guardar_imagen`,formData)
        ).then((resp) => {
          console.log({_resp: resp});          
          _total ++
        }).catch((error) => {
          console.error({_ERRORR__: error});          
        })       
      }
      
      if(_total == files.length) {
        return true
      }else {
        return false
      }          
    }
    
    /**
     * 
     */
    if(pictures && pictures.length) {
      for (let index = 0; index < pictures.length; index++) {
        const element = pictures[index];
        const _blob = this.dataURItoBlob(element.file.imageAsDataUrl)         

        const formData: FormData = new FormData();

        formData.append('cita_id', `${cita_id}`);
        formData.append('paciente_id',`${paciente_id}`);
        formData.append('main_label',`${main_label}`)     

        if(element.comentario) {
          formData.append('comentario',`${element.comentario}`)
        }

        formData.append('file',_blob)

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
  
        await firstValueFrom(
          this.http.post(`${this.apiUrl}formatos/guardar_imagen`,formData)
        ).then((resp) => {
          console.log({_resp: resp});
          
          _total ++
        }).catch((error) => {
          console.error({_ERRORR__: error});          
        })  
           
      }

      if(_total == pictures.length) {
        return true
      }else {
        return false
      } 

    }

    /**
     * 
     */
    if(_svgFiles && _svgFiles.length) {
      for (let index = 0; index < _svgFiles.length; index++) {
        const element = _svgFiles[index];       
        
        const formData: FormData = new FormData();
        formData.append('cita_id', `${cita_id}`);
        formData.append('paciente_id',`${paciente_id}`);
        formData.append('main_label',`${main_label}`)

        if(element.comment) {
          formData.append('comentario', `${element.comment}`)
        }

        formData.append('file', element._imgSVG)

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        await firstValueFrom(
          this.http.post(`${this.apiUrl}formatos/guardar_imagen`,formData)
        ).then(async (resp) => {
          console.log({_resp: resp});  
          _total ++
        }).catch((error) => {
          console.error({_ERRORR__: error});
        })  
   
      }
      
      if(_total === _svgFiles.length) {
        return true
      }else {
        return false
      }
    }    

    return false
  }

  /**
   * 
   * @param cita_id 
   * @param paciente_id 
   * @param main_label 
   * @param filename 
   * @returns 
   */
  async _DeleteImg(cita_id: string, paciente_id: string, main_label: string, filename: string, ) {

    let _bodyDeleteImg = {
      cita_id,
      paciente_id,
      main_label,
      filename     
    }    
    
    return await firstValueFrom(
      this.http.post(`${this.apiUrl}formatos/eliminar_imagen`, _bodyDeleteImg)
    )
  }

 /**
  * 
  * @param _nota_evolucion 
  * @param _svgFiles 
  */
  async AgregarNotaEvolucion(_nota_evolucion: NotaDeEvolucion, _svgFiles: any[] | null, paciente_id: string, main_label: string ) {

    if(_svgFiles && _svgFiles.length) {
      let _urlImages: imagenesNotaEvolucion [] = []
      let _total: number = 0
     
      /**
       * Subimos las imagenes
       */

      for (let index = 0; index < _svgFiles.length; index++) {
        const file = _svgFiles[index];

        const formData: FormData = new FormData();
        formData.append('cita_id', `${_nota_evolucion.Cita_Id}`);
        formData.append('paciente_id',`${paciente_id}`);
        formData.append('main_label',`${main_label}`)

        
        if(file.comment) {
          formData.append('comentario', `${file.comment}`)
        }

        formData.append('file', file._imgSVG)

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        await firstValueFrom(
          this.http.post<ResponseUploadImages>(`${this.apiUrl}formatos/guardar_imagen`,formData)
        ).then(async (resp: ResponseUploadImages) => { 
                
          _urlImages.push(resp.data)            

        }).catch((error) => {
          console.error({_ERRORR__: error});
        })   

        _total ++ 
      }

      if(_total === _svgFiles.length) {
        _nota_evolucion.imagenes = _urlImages
      }  
    }

    return firstValueFrom(
      this.http.post(`${this.apiUrl}formatos/agregar_nota_evolucion_varices`,_nota_evolucion)
    )
  }

  /**
   * 
   * @param _citaId 
   * @returns 
   */
  GetNotasEvolucion(paciente_id: string) {
    return this.http.get(`${this.apiUrl}formatos/listar_nota_evolucion_varices_por_paciente?paciente=${paciente_id}`)
   // return this.http.get(`${this.apiUrl}formatos/listar_nota_evolucion_varices?cita_id=${_citaId}`)
  }

  /**
   * 
   * @param dataURI 
   * @returns 
   */
  private dataURItoBlob(dataURI: any) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

  /**
   * 
   * @param cita_id 
   * @returns 
   */
  _getListImages(cita_id: string, paciente_id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}formatos/lista_imagenes?cita_id=${cita_id}&paciente_id=${paciente_id}`)
  }

  /**
   * 
   */
  _getImageProfile(user: string): Observable<ListImages> {
    return this.http.get<ListImages>(`${this.apiUrl}formatos/lista_imagenes?user=${user}`)
  }


  /**
   * Delete Imagen
   */
  async _PostdeleteImage({ user, cita_id, paciente_id, filename, main_label, label, laboratorio   }: { user?: string | null; cita_id?: string | null; paciente_id?: string | null ; filename: string; main_label?: string | null; label?: string | null, laboratorio?: string | null }) {
    return await firstValueFrom(
        this.http.post
        (`${this.apiUrl}formatos/eliminar_imagen?user=${user}&cita_id=${cita_id}&paciente_id=${paciente_id}&filename=${filename}&main_label=${main_label}&label=${label}&laboratorio=${laboratorio}`,{}))
        .then((response: any) => {
      return response
    })
  }

  /**
   * 
   * @param cita_id 
   * @param paciente_id 
   * @param main_label 
   * @param _file 
   */
  async UploadFirmas(cita_id: string, paciente_id: string, main_label: string, _file: any) {

    console.log({cita_id, paciente_id,  main_label});

    const _blob = this.dataURItoBlob(_file)         

    const formData: FormData = new FormData();

    formData.append('cita_id', `${cita_id}`);
    formData.append('paciente_id',`${paciente_id}`);
    formData.append('main_label',`${main_label}`)     

    formData.append('file',_blob)

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    await firstValueFrom(
      this.http.post(`${this.apiUrl}formatos/guardar_imagen`,formData)
    )
    

  }

}
