import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EventApi, EventClickArg } from '@fullcalendar/core';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

import { ToastrService } from 'ngx-toastr';
import { Anestesilogo } from 'src/app/pages/calendar/models/anestesiologo';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { Doctor } from 'src/app/pages/models/doctores';
import { Recurso } from 'src/app/pages/models/recursos';
import { Servicio } from 'src/app/pages/models/servicios';
import { CreateCita } from 'src/app/shared/models/create-cita';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PatientsService } from 'src/app/pages/calendar/services/patients.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { empleadoResponsable } from 'src/app/pages/models/lista-empleados-responsable';
import * as moment from 'moment';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { DataEmpresaOdoo } from 'src/app/pages/calendar/models/empresa-odoo';
import { timeout } from 'rxjs';
import { DetallesCita } from 'src/app/pages/calendar/models/detalles-cita';



@Component({
  selector: 'app-create-citas',
  templateUrl: './create-citas.component.html',
  styleUrls: ['./create-citas.component.scss']
})
export class CreateCitasComponent implements OnInit {  

  @Input()
  _dataCita: any;

  @Input()
  editable: boolean = false

  @Input()
  _cita_id: string | null

  @Input()
  _detalles_cita: DetallesCita | null

  @Input()
  cita_sub_secuente_id : string;

  @Output()
    eventClickCancel: EventEmitter<boolean> = new EventEmitter();

  submitted = false;
  newEventDate: any;

  @ViewChild('editmodalShow') editmodalShow!: TemplateRef<any>;
  @ViewChild('modalShow') modalShow !: TemplateRef<any>;

  editEvent: any;

  _showCalendar:boolean = false;

  _formCita: FormGroup;
  _CitasCalednario: CreateCita = new CreateCita();

  currentEvents: EventApi[] = [];
  
  _listaDoctores: Doctor[] = []
  _Doctores:  Doctor[]    = []

  _selectedDoctor: Doctor;
  _Servicios: Servicio[]  = []
  _Recursos:  Recurso[]   = []
  _Anestesitas: Anestesilogo[] = []

  _Pacientes: any[] = []

  _loader: boolean;

  _estatus: any[] = []
  _motivo_cancelacion:any[] = []
  _tipo_evento: any[] = []
  _pixel_gooogle: any[] = []
  _camapañas: any[] = []

  _responsable: empleadoResponsable[] = []

  _loaderDoctor: boolean
  _loaderPaciente: boolean
  _loadingtipoCita: boolean

  _modalCitas: NgbModalRef

  _calendarCitas: any[] = []
  
  _horasCitas: any[] = []
  _disabledHorasCitas: boolean;

  _horasCitasFianl: any[] = []
  _disabledHorasCitasFinal: boolean

  _servicioSelected: any;  
  _role: string;
  _credentials: any
  _empresasOdoo: DataEmpresaOdoo[]
  _cita_edited: any;
  _spinner_cita = 'cita_create'

  _tipo_procedimiento: any[] = []

  _selectedDate: any
  _horaIndex: number
  _fechaIndex: number

  _fechaSelected: string
  _horaSelected: string

  _response: any
  _cita_salesforce_Id: string

  constructor (
    private fb: FormBuilder,
    private calendarService: CalendarService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private patients: PatientsService,
    private spinner: NgxSpinnerService,
    private tokenService: TokenStorageService,
    private credentials: CredentialsService
  ) {
    flatpickr.localize(Spanish)
  }

  ngOnInit(): void {

    const _dataUser = this.credentials._getdataUser()
    if(_dataUser) {
      this._credentials = _dataUser
    }

    this._role = this.tokenService.getRole()

    this._loaderDoctor = true
    this._loaderPaciente = true

    this._disabledHorasCitas = true
    this._disabledHorasCitasFinal = true
    this.getDoctores();

    this.initForm()
  }

  /**
   * 
   */
  initForm() {
    this._formCita = this.fb.group({

      fecha_inicio:[null,Validators.required],
      hora_inicio:[null],
      fecha_final:[null,Validators.required],
      hora_final:[null],
      estatus:[null,],
      motivo_cancelacion:[null,],
      tipo_evento:[null,Validators.required],
      pixel_gooogle:[null],
      pixel_facebook:[null],
      id_campaña:[null],
      enviar_recordatorio:[null],
      enviar_encuesta:[null],
      fecha_envio_encuesta:[null],
      comentarios:[null],
      doctor:[null, Validators.required],
      paciente:[null, Validators.required],
      servicio:[null, Validators.required],
      recurso:[null, Validators.required],
      anestesista:[null],
      ayudante:[null],

      recordatorio:[null],
      responsable:[null]
    })

    this.patchFormValues()
  }


  /**
   * 
   */
  private patchFormValues () {

    if(this.editable && this._cita_id != null ) {
      
      this._showCalendar = true 
      this.spinner.show(this._spinner_cita)

      this.calendarService._getDetallesCita(this._cita_id).subscribe({
        next:(response: any) => {
          this.spinner.hide(this._spinner_cita)        
          
          if(response.codigo == 200 ) {

            this._cita_salesforce_Id = response.data.Salesforce_Id

            const _data = response.data.Datos_Cita
            this._getDatesByDoctor(_data.detalle_cita.doctor)  

            this._response = _data
            this._cita_edited = response.data;
            let _jsoData: any = {}     
            _jsoData = {
              ..._data.atencion_paciente,
              ..._data.detalle_cita,
              ..._data.generales
            }    

            this._formCita.patchValue(_jsoData)
            this._fechaSelected = moment(_data.generales.fecha_inicio).format('YYYY-MM-DD')
            this._horaSelected  = moment(_data.generales.fecha_inicio).format('HH:mm')

            this._formCita.patchValue({
              fecha_final: moment(_data.generales.fecha_final).format('YYYY-MM-DD'),
              fecha_inicio: this._fechaSelected,
              hora_inicio: this._horaSelected,
              hora_final: moment(_data.generales.fecha_final).format('HH:mm')
            })
       

            this._onChangePatient()
            this._onLoadTipoProcedimiento(_data.generales.tipo_evento)

       
            this._formCita.patchValue({
              servicio: _data.detalle_cita.servicio
            })


            

          }            
        },
        error:(err) => {
          console.error({Error_____________________: err});
          this.spinner.hide(this._spinner_cita)
          this.toastr.warning(`No se pudo obtener los detalles de la cita ${this._cita_id}, por favor vuelva a intentarlo`,'Error en obtener cita',{
            timeOut: 3200
          })
          
        },
      })

    }else if(this._role == 'Doctor') {
      this._formCita.patchValue({
        doctor: this._credentials.id_salesforce
      })

      this._getDatesByDoctor(this._credentials.id_salesforce)

    }else if (this._dataCita) {

      console.log({_dataCITA___: this._dataCita});     
      let _jsoData: any = {}

      this._onLoadTipoProcedimiento(this._dataCita.generales.tipo_evento)

      _jsoData = {
        ...this._dataCita.atencion_paciente,
        ...this._dataCita.detalle_cita,
        ...this._dataCita.generales
      }      

      this._formCita.patchValue(_jsoData)  
      this._getDatesByDoctor(this._dataCita.detalle_cita.doctor)
      
      
    }

  }

  get form () {
    return this._formCita.controls
  }

  private getDoctores() {
      /**
     * Doctores
     */
      this.calendarService._getDoctores('true').subscribe({
        next:(response) => {
          if(response.codigo == 200) {

            this._loaderDoctor = false
            this._listaDoctores = response.data.info;  

            const _doctores = response.data.info;
            this._Doctores = _doctores;

            if(this._role == 'Doctor') {              
              let _findDoc = _.find(_doctores,{ id_salesforce : this._credentials.id_salesforce  })
              if(_findDoc) {
                this._listaDoctores = [_findDoc] 
              }             
              
            }else {
              this._listaDoctores = _doctores
            }
            
            this.LoadCatalogs()     
          }
        },
        error:(error) => {
          console.error({error});
          this._loaderDoctor = false
          this.toastr.error('Hubo un error al listar los doctores','Lista doctores Error', {
            timeOut: 3200
          })
  
        }
      })
  }

  /**
   * Catalogos de alta Cita
   */
  private LoadCatalogs() {

    /**
     * Obtenemos los Pacientes
     */

    if(!this.editable) {
      this.patients._getPatientInfo('true').pipe(timeout(60000)).subscribe({
        next:(response: any) => {
          if(response.codigo == 200) {  
            this._Pacientes = response.data.records;
            this._loaderPaciente = false
          // console.log({paitents: response});
          }  
        },  
        error:(error) => {
          console.error({error});        
        }
      })
    }else {
      if(this._detalles_cita?.cita) {
        this.patients._getPatientInfo(this._detalles_cita?.cita.Paciente_Id).subscribe({
          next:(value: any) => {
            console.log({_value__: value});
            
            this._Pacientes = value.data.records
            this._loaderPaciente = false                                  
          },
        })
      }
 
    }

      

    /**
     * Servicios
     */
    this.calendarService._getServicio('true').subscribe({
      next:(response) => {
        if(response.codigo == 200 ){
          this._Servicios = response.data    
        }        
      },
      error: (error) => {
        console.error({error});
        
        this.toastr.error('Hubo un error al listar los Servicios','Lista Servicios Error', {
          timeOut: 3200
        })
      }
    })

    /**
     * Recursos
     */
    this.calendarService._getRecursos('true').subscribe({
      next:(response) => {

        if(response.codigo == 200) {
          this._Recursos = response.data;
        }

      },
      error: (error) => {
        console.error({error});
        
        this.toastr.error('Hubo un error al listar los Recursos ','Lista recursos Error', {
          timeOut: 3200
        })
      }
    })

    /**
     * Anestesiologos
     */
    this.calendarService._getAntestesilogo('true').subscribe({
      next:(response) => {
        if(response.codigo == 200) {
          this._Anestesitas = response.data.info;
        }
      },
      error:(error) => {
        console.error({error});        
        this.toastr.error('Hubo un error al listar los Anestesiologs','Lista Anestesiologos Error', {
          timeOut: 3200
        })
      }
    })


    
    /**
     * Get motivos de cancelacion
     */
    this.calendarService._getMotivoCancelacion().subscribe({
      next:(response: any) => {               
        if(response.codigo == 200) {
          this._motivo_cancelacion = response.data[0].motivo_cancelacion
        }
      }
    })

    /**
     * GET Estatus
     */
    this.calendarService._getCitaEstatus().subscribe({
      next:(response: any) => {               
        if(response.codigo == 200) {  
          this._estatus = response.data[0].estatus;
        }
      },
      error:(error) => {
        console.error({error});        
        this.toastr.error('Hubo un error al listar los Citas','Lista Anestesiologos Error', {
          timeOut: 3200
        })
      }
    })

    /**
     * Lista empleados Responsables
     */
    this.patients._getListaEmpleadosResponsable().subscribe({
      next:(response)=> {
        if(response.codigo == 200) {
          this._responsable = response.data;
        }        
      }
    })

    /**
     * 
     */

    this.calendarService._getOdooEmpresa().subscribe({
      next:(response) => {
        if(response.codigo == 200 ) {
          this._empresasOdoo = response.data
        }
      },
      error(err) {
        console.error({err});        
      }
    })

  }

  get f() {
    return this._formCita.controls;
  }

  openModal(event?: any) {
    this.submitted = false;
    this.newEventDate = event;   
    
    this._modalCitas =  this.modalService.open(this.modalShow, { centered: true, animation:true, size:'lg' });
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.editEvent = clickInfo.event;

   /*  this.formEditData = this.formBuilder.group({
      editTitle: clickInfo.event.title,
      editCategory: clickInfo.event.classNames[0],
      editlocation: clickInfo.event.extendedProps['location'],
      editDescription: clickInfo.event.extendedProps['description'],
      editDate: clickInfo.event.start,
      editStart: clickInfo.event.start,
      editEnd: clickInfo.event.end
    }); */

    this.modalService.open(this.editmodalShow, { centered: true });
  }

  /**
   * 
   * @param events 
   */
  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  /**
   * 
   */
  closeEventModal() {   
    this._modalCitas.close()
    //this.modalService.dismissAll();
  }

  /**
   * 
   * @param event 
   */
  _onCahnge(event: Doctor) {
    this._selectedDoctor = event;
  
    this.spinner.show()

    this._showCalendar = false;
    
    this._getDatesByDoctor(event.id_salesforce)
    
    
  }


  /**
   * 
   * @param id_salesforce 
   */
  private _getDatesByDoctor(id_salesforce: string) {
    this.calendarService._getCitasDoctor(id_salesforce).subscribe({
      next:(response: any) => {
        if(response.codigo == 200) {    
          this._showCalendar = true
          this.spinner.hide()
          this._calendarCitas = response.data;               
  

        }        
      },
      error: (error) => {
        this.spinner.hide()
        console.error({error});
        
      }
    })  
  }



  /**
   * 
   * @param event 
   */
/*   onCahngeDate(event:any) {
    
    this._formCita.patchValue({
      fecha_final: event.fecha
    })

    this._horasCitas = event.horas;
    this._disabledHorasCitas = false
    
    
    this.resetValues()

  } */

  /**
   * 
   */
  private resetValues() {
    this._formCita.patchValue({
      hora_inicio: null,
      hora_final: null
    })
  }



  /**
   * 
   * @param event 
   */
  _onChageHour (event: any) {    
    
 //   let _hora_cita = event 
   // let _hora_servicio = this._servicioSelected.Tiempo_del_Evento // moment().format('YYYY-MM-DD') +' '+ this._servicioSelected.Tiempo_del_Evento
  //  let _time_ = moment(_hora_servicio, 'HH:mm').format('HH:mm')


    //Formatos de horas del Array
  //  let _inicio = moment(_hora_cita, 'HH:mm').format('HH:mm');      

    //Sumamos las horas/minutos del array al tiempo del servicio seleccionado
   // let _sum_horas = moment(_inicio,'HH:mm').add(_time_,'minutes')
   // let _final = _sum_horas.format('HH:mm')

  //  let _index_ = this._horasCitas.indexOf(_final)  

/*     if(_index_ > 0 ) {
      let _val_   = this._horasCitas[_index_]          

      this._formCita.patchValue({
        hora_final: _val_,
        fecha_final: this.form['fecha_inicio'].value.fecha
      })

    } */
    /* else {
      this.toastr.warning('No hay un horario valido, en las fechas establecidas, por favor seleccione otra fech y horario','Error en la fecha de la cita', {
        timeOut: 3200
      })
      this._formCita.patchValue({
        fecha_final: null,
        hora_final: null
      })
    }   */ 
  }
  

  /**
   * 
   * @param event 
   */
  onChangeDateFinal(event: any) {
    this._horasCitasFianl = event.items;
    this._disabledHorasCitasFinal = false
  }

  
  

  onSubmit() {
    
    if(this._formCita.valid) {
      this.spinner.show(this._spinner_cita)

      let _values = this._formCita.value;

      this._CitasCalednario.atencion_paciente = {
        recordatorio: _values.recordatorio,
        responsable: _values.responsable,
      }

      if(this.cita_sub_secuente_id) {

        this._CitasCalednario.generales = {
          fecha_inicio: `${_values.fecha_inicio} ${_values.hora_inicio}`,
          fecha_final: `${_values.fecha_final} ${_values.hora_final}`,
          estatus: _values.estatus,
          motivo_cancelacion: _values.motivo_cancelacion,
          pixel_gooogle: _values.pixel_gooogle,
          pixel_facebook: _values.pixel_facebook,
          comentarios: _values.comentarios,
          id_campaña: _values.id_campaña,
          enviar_recordatorio: _values.enviar_recordatorio != "" ? _values.enviar_recordatorio : null  ,
          enviar_encuesta: _values.enviar_encuesta != "" ? _values.enviar_encuesta: null,
          fecha_envio_encuesta: _values.fecha_envio_encuesta != null ? moment(_values.fecha_envio_encuesta).format('YYYY-MM-DD') : null
        }

        this._CitasCalednario.detalle_cita = {
          paciente: _values.paciente,         
          anestesista: _values.anestesista,
          ayudante: _values.ayudante
        }

        this._CitasCalednario.cita_sub_secuente = false
        this._CitasCalednario.cita_sub_secuente = false

      }else{ 
        this._CitasCalednario.generales = {
          fecha_inicio: `${_values.fecha_inicio} ${_values.hora_inicio}`,
          fecha_final: `${_values.fecha_final} ${_values.hora_final}`,
          tipo_evento: _values.tipo_evento,
          comentarios: _values.comentarios,
          //enviar_encuesta: _values.enviar_encuesta,
         // enviar_recordatorio: _values.enviar_recordatorio,
          estatus: _values.estatus,
         // fecha_envio_encuesta: _values.fecha_envio_encuesta != null ? moment(_values.fecha_envio_encuesta).format('YYYY-MM-DD') : null,
         // id_campaña: _values.id_campaña,
         // motivo_cancelacion: _values.motivo_cancelacion,
        //  pixel_gooogle: _values.pixel_gooogle,
        //  pixel_facebook: _values.pixel_facebook
        }
  
        this._CitasCalednario.detalle_cita = {
          paciente: _values.paciente,
          doctor: _values.doctor,
          recurso: _values.recurso,
          servicio: _values.servicio,
          anestesista: _values.anestesista,
          ayudante: _values.ayudante,
        }
  
 
      }            

      if(!this.editable) {     
        
        this.spinner.show(this._spinner_cita)

        this.calendarService._CrearCitaCalendario(this._CitasCalednario).subscribe({
          next:(response: any) => {
            if(response.codigo == 200 ) {                   
              let _CitaId = response.data.Cita_Id              
              
              this.toastr.success(`El registro de la cita, se ha relizado correctamente. <br> <b>Cita:  ${_CitaId} </b>`,'Exito al crear',{
               // tapToDismiss: false,
                enableHtml:true,
                progressBar:true,
                timeOut: 20000            
              }).onHidden.subscribe(() => {
                this.onCancel()
              })
            }

            this.calendarService._setCurrentLoader({loadCitas: true})
            this.spinner.hide(this._spinner_cita)   
            this.EmmitClose()

          },
          error:(error) => {
            this.spinner.hide(this._spinner_cita)   
            this.toastr.error('Ocurrio un error al tratar de crear, la cita','Error ocurrido', {
              timeOut: 3200
            })
            console.error({error});
            
          }
        })

      }else if(this.editable) {

        this.spinner.show(this._spinner_cita)
        
        delete this._CitasCalednario.detalle_cita.paciente;      
        this._CitasCalednario.Salesforce_Id = this._cita_salesforce_Id
        
        this.calendarService._UpdateCitaCalendario(this._CitasCalednario).subscribe({
          next:(response: any) => {

            if(response.codigo === 200) {
              this.toastr.success(`La actualizacion de la cita, se ha relizado correctamente.`,'Exito!!',{
                // tapToDismiss: false,
                 enableHtml:true,
                 progressBar:true,
                 timeOut: 20000            
               }).onHidden.subscribe(() => {
                 this.onCancel()
               })
            }

            this.calendarService._setCurrentLoader({loadCitas: true})
            this.spinner.hide(this._spinner_cita)   
            this.EmmitClose()

         
          },
          error:(error) => {
            this.spinner.hide(this._spinner_cita)   
            
            this.toastr.error('Ocurrio un error al tratar de actualizar, la cita','Error ocurrido', {
              timeOut: 3200
            })
            console.error({error});
          },
        })
      } 

    }else {
      let _errors = this._formCita.controls    
      let totalErrors = 0;

      let _msg = `Por favor revise los campos en Rojo <br> <ul> `

      Object.keys(_errors).forEach(key => {
        let _controls = this._formCita.get(key)?.errors
        if( _controls && _controls != null) {
          const controlErrors : ValidationErrors = _controls;
          totalErrors ++;
          Object.keys(controlErrors).forEach(keyError => {         
            _msg += `<li> ${key} - ${keyError} </li>`  
           });
          }
        })     
        _msg+= `</ul>`;

        this.toastr.error(`${_msg}`,'Campos faltantes',{
          timeOut: 15000,
          enableHtml: true,
          positionClass:'toast-bottom-center'
        })
      this._formCita.markAllAsTouched()
      this._formCita.markAsDirty()
    }

  }

  /**
   * 
   */
  onCancel() {
    this.EmmitClose()
  }

  /**
   * 
   */
  private EmmitClose() {
    this.eventClickCancel.emit(true)
  }


  /**
   * 
   * @param event 
   */
  _onChangeServicio(event: any) {       

    this._servicioSelected = event   

    this.spinner.show()

    let _hora_servicio = this._servicioSelected.Tiempo_del_Evento // moment().format('YYYY-MM-DD') +' '+ this._servicioSelected.Tiempo_del_Evento
    let _time_ = moment(_hora_servicio, 'HH:mm').format('HH:mm')

    let _new_cita_horas: any[] = []
    _.map(this._calendarCitas, (a: any, j: number) => {     

      let _dates_new: any[] = [] // = []

      if(j === 0) {

        _.map(a.horas, (b:any, i : number) => {

          //Formatos de horas del Array
          let _inicio = moment(b, 'HH:mm').format('HH:mm');      

          //Sumamos las horas/minutos del array al tiempo del servicio seleccionado
          let _sum_horas = moment(_inicio,'HH:mm').add(_time_,'minutes')
          let _final = _sum_horas.format('HH:mm')



          let _prev_final: any = null
          let _prev_incio: any = null



          let _index_ = a.horas.indexOf(_final)
          let _val_ = a.horas[_index_]

          if(_index_ > 0) {


      
            if(i > 0 ) {              
              _prev_final = a.horas[_index_ - 1]
              _prev_incio =   moment(a.horas[i - 1], 'HH:mm').format('HH:mm');  

              if(_prev_final > _inicio ){
                console.log('AAAAA');
                
              }else{
                console.log('BBBBB');
                
              }

            }


            _dates_new.push({
              _i_: i,
              b,
              _horario:{
                _inicio,
                _final,
              },          
              prev:{
                _prev_incio,
                _prev_final
         
              },
              _items_: {
                _index_,
                _val_,
                _VAL_: moment(_val_,'HH:mm').subtract(_time_,'minutes').format('HH:mm')
              },
            })
        }

/* 
          if(i === 0) {
            


          }else {
            
            _prev_final = a.horas[_index_ - 1]
            if(_prev_final < _val_ ) {
              console.log({SI___: _val_, _final, _inicio, _index_, i });
              
            }else {
              console.warn({NO___: _val_, _final, _inicio, _index_, i });
            }
            //let _time_prev = moment(_prev_final,'HH:mm').format('HH:mm')


            //if(_ > )


          } */

/* 
          if(_val_ == _inicio) {
            
            
          }else {
            console.warn({NO______:  _val_, _final, _inicio, _index_, i });
            
          } */


         // console.log({horas_original: b, new_horas: _format_horas});


    /*       
          _dates_new.push({           
            _I: i,
            _index_,
            inicio: _inicio,
            final: _final,
            _val_index: _val_   ,
            prev:{
              _prev_final
            } */
           
         // })
          

        //  let _find = a.horas.includes(_format_horas);

        //  if(_find) {
      /*      let _index_ = a.horas.indexOf(_format_horas)

            if(b == a.horas[_index_] ) {
              console.log({_format_horas, _find, _B: b, _index_, _ID_: i, item: a.horas[_index_]   });
            }else {

            } */

        
            
  /*         }else {
            console.warn({'NOT_FOUND': _find});
            
          } */


        //   console.log({_format_horas});
          


         /*    _dates_new.push({
              hora: _horas,
              _format_horas,
              disabled: true
            }) */
         // }

        })

        let _horas_filter: any[] = []


        _new_cita_horas.push({
          fecha: a.fecha,
          horas: _dates_new,
          _horas_filter
        })
    }

    })

    console.log({FECHAS_: _new_cita_horas});
    
    
    this.spinner.hide()

   // this.resetValues()

  }

  /**
   * 
   */
  _endDateChange(event: any) {
    this._horasCitasFianl = event.horas;

  }

  onCahngeDate2(event: any) {
    this._horasCitas = event.horas;
  }

  /**
   * 
   * @param event 
   */
  _onLoadTipoProcedimiento(event: any) {
    this._tipo_procedimiento = []
    this.f['servicio'].patchValue(null)
    if(event) {
      this.calendarService._ConsultarTipoProcedimiento(event, this.f['doctor'].value).subscribe({
        next:(value: any) => {

          if(value.codigo == 200) {
            this._tipo_procedimiento = value.data

            if(this.editable && this._cita_id != null) {

              let _find_servicio = _.find(this._Servicios, { Id: this.form['servicio'].value }) 

              if(_find_servicio) {
                this._servicioSelected = _find_servicio
              }   
            }
          }            
        },
        error(err) {
          console.error({_ERROR_:err});          
        },
      })
    }
  }

  /**
   * 
   * @param fecha 
   * @param hora 
   */
  _onsetHour(fecha: string, hora: string, horaIndex: number, fechaIndex : number) {

    this._fechaSelected = fecha
    this._formCita.patchValue({
      fecha_final: fecha,
      fecha_inicio: fecha,
      hora_inicio: hora,
      hora_final: null
    })


    this._horaSelected = hora    
    this._horaIndex = horaIndex
    this._fechaIndex = fechaIndex
    
  }

  _onChangePatient() {
    const paciente_id = this.editable && this._cita_id != null ? 'true'  : this.form['paciente'].value    
     /**
     * Get Tipo de Evento
     */
        this._loadingtipoCita = true
        this.calendarService._getTipoEvento(paciente_id).subscribe({
          next: (response: any) => {    
            if(response.codigo == 200) {              
              this._tipo_evento= response.data[0].tipo_evento;
              this._loadingtipoCita = false              
            }    
          },
          error:(error) => {
            console.error({error});    
            this._loadingtipoCita = false    
            this.toastr.error('Hubo un error al listar los tipos de evento','Lista Anestesiologos Error', {
              timeOut: 3200
            })
          }
        })
  }

}
