
<!-- <div class="container-fluid">
    <div class="mt-2">

        <form class="">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input type="text" class="form-control">
                        <label class="form-label">Buscar</label>
                    </div>  
                </div>
            </div>
        </form>

   
    </div>

</div> -->
<div class="offcanvas-body p-0">
    <div class="p-2 mt-4">       
        
            <form [formGroup]="_formSearch" (ngSubmit)="_onSerach()">
                <div class="hstack gap-3"> 
                    <input 
                    class="form-control me-auto"     
                    formControlName="searchText"       
                    type="text" 
                    (keyup)="_onEventSearch()"
                    placeholder="Ingrese un texto para buscar">
                    <button type="submit" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle p-3">
                        <i class="ri-search-line  fs-22"></i>
                    </button>
                </div>     
            </form>    
       
    </div>
    <div class="p-2" *ngIf="_searchResult && _searchResult.length">
        <h6 class="mb-0 fw-semibold text-uppercase d-flex justify-content-center">Resultados de busqueda</h6>       
        <ngx-simplebar style="height: 20vh;">    
            <div class="notification-list">
                <a *ngFor="let item of _searchResult"   (click)="_onClickSearch(item)"  href="javascript:void(0);" class="dropdown-item notify-item py-2"> 
    
                    <div class="flex-grow-1">
                        <h6 class="m-0">{{item.Paciente_Nombre_Completo}} </h6>
                        <span class="fs-11 mb-0 text-muted">Cita {{item.Doctor}} </span>
                        <br>
                        <span class="fs-11 mb-0 text-muted">No. Expediente {{item.N_Expediente}} </span>
                    </div>
                </a>
            </div>  
        </ngx-simplebar>
         
    </div>
</div>

<!-- <div class="container-fluid">
    <div class="mt-4">
        <form [formGroup]="_formSearch" (ngSubmit)="_onSerach()">
            <div class="hstack gap-3"> 
                <input 
                class="form-control me-auto"     
                formControlName="searchText"       
                type="text" 
                (keyup)="_onEventSearch()"
                placeholder="Ingrese un texto para buscar">
                <button type="submit" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle p-3">
                    <i class="ri-search-line  fs-22"></i>
                </button>
            </div>     
        </form>    
    </div>
    <div>
        <li>AAA</li>
    </div>
</div> -->




<!-- <div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasExample"
aria-labelledby="offcanvasExampleLabel">
<div class="offcanvas-body">
    <button type="button" class="btn-close text-reset float-end" data-bs-dismiss="offcanvas"
        aria-label="Close"></button>
    <div class="d-flex flex-column h-100 justify-content-center align-items-center">
        <div class="search-voice">
            <i class="ri-mic-fill align-middle"></i>
            <span class="voice-wave"></span>
            <span class="voice-wave"></span>
            <span class="voice-wave"></span>
        </div>
        <h4>Talk to me, what can I do for you?</h4>
    </div>
</div>
</div> -->