<!-- <div [ngSwitch]="Id"> -->
    <app-clinical-history *ngIf="Id == 'Historia_Clinica'" [id]="Id" ></app-clinical-history>   
    <app-nota-ingreso *ngIf=" Id == 'Nota_Ingreso'" [id]="Id" ></app-nota-ingreso>   
    <app-nota-evolucion *ngIf="Id=='Nota_Evolucion'" [id]="Id"></app-nota-evolucion>
    <app-nota-preoperatoria *ngIf="Id == 'Nota_Preoperatoria'" [id]="Id"></app-nota-preoperatoria>
    <!-- Multiple!! -->
    <app-anestesia *ngIf="Id == 'Anestesia'" [id]="Id"></app-anestesia>
    <!-- Multiple!! -->
    <app-enfermeria-endoscopia *ngIf="Id == 'Enfermeria_Endoscopia' " [id]="'Enfermeria_Endoscopia'"  ></app-enfermeria-endoscopia>

    <app-nota-egreso *ngIf=" Id == 'Nota_Egreso' " [id]="'Nota_Egreso'" ></app-nota-egreso>
    
    <app-hoja-indicaciones *ngIf="Id=='Hoja_Indicaciones'" [id]="'Hoja_Indicaciones_Medicas'"></app-hoja-indicaciones>
    <app-hoja-post-operatoria *ngIf="Id =='Hoja_Post_Operatoria'" [id]="'Hoja_Post_Operatoria'"></app-hoja-post-operatoria>
    <app-descipcion-quirurgica *ngIf="Id=='Descripcion_Quirurgica'" [id]="'Descripcion_Quirurgica'" ></app-descipcion-quirurgica>

    <app-enfermeria-operatorios  *ngIf="Id=='Enfermeria_Operatorios'" [id]="'registro_enfermeria_preTrans_postOperatorio'">
    </app-enfermeria-operatorios>
    

    <app-recomendaciones-paciente *ngIf="Id=='Datos_Paciente_Recomendaciones'" [id]="'Datos_Paciente_Recomendaciones'"  ></app-recomendaciones-paciente>
    <app-consentimiento-aplicacion-sedacion *ngIf="Id=='Consentimiento_Informado_Aplicacion_Sedacion'"  [id]="'Consentimiento_Informado_Aplicacion_Sedacion'" ></app-consentimiento-aplicacion-sedacion>
    <app-consentimiento-procedimiento-diagnostico *ngIf="Id=='Consentimiento_Informado_Procedimiento_Diagnostico'" [id]="'Consentimiento_Informado_Procedimiento_Diagnostico'"></app-consentimiento-procedimiento-diagnostico>
    <app-historia-clinica-varices *ngIf="Id =='Historia_Clinica_Varices'" [id]="'Historia_Clinica_Varices'"></app-historia-clinica-varices>

    <app-doctor-ayudante *ngIf="Id == 'Doctor_Ayudante'"></app-doctor-ayudante>

<!-- </div> -->

<!-- "" -->