import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Feather Icon
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { CountToModule } from 'angular-count-to';

import { 
  NgbDropdownModule, 
  NgbNavModule,
  NgbTypeaheadModule, 
  NgbPaginationModule, 
  NgbToastModule,   
  NgbCollapseModule,  
  NgbAccordionModule,
  NgbAlertModule  } from '@ng-bootstrap/ng-bootstrap';

  import { NgxMasonryModule } from 'ngx-masonry';

import { SimplebarAngularModule } from 'simplebar-angular';
// Apex Chart Package
import { NgApexchartsModule } from 'ng-apexcharts';
// Swiper Slider
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
// Flat Picker
import { FlatpickrModule } from 'angularx-flatpickr';



//Module
import { DashboardsRoutingModule } from "./dashboards-routing.module";
import { SharedModule } from '../../shared/shared.module';
import { ToastsContainer } from './dashboard/toasts-container.component';
import { NurseDashboardComponent } from './nurse-dashboard/nurse-dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [
    ToastsContainer,
    NurseDashboardComponent,
    CalendarComponent
  ],
  imports: [
    CommonModule,
    FeatherModule.pick(allIcons),
    CountToModule,
    NgbToastModule,
    NgbAlertModule,    
    NgbDropdownModule,
    NgbNavModule,
    SimplebarAngularModule,
    NgApexchartsModule,
    NgbAccordionModule,
    NgxUsefulSwiperModule,    
    NgbCollapseModule,
    FlatpickrModule.forRoot(),
    DashboardsRoutingModule,
    NgxMasonryModule,
    SharedModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    NgxDatatableModule
  ],
})
export class DashboardsModule { }
