<div class="card" *ngIf="_its_ready">
    <div class="card-header">
        <span class="title">{{title}}</span>
    </div>
    <div class="card-body">
        <div class="live-preview">       
            <form [formGroup]="myForm" class=""  (submit)="onSubmit()">            
                <div class="row mt-4 align-items-center g-3">                    
                    <div class="col-xxl-4 col-lg-6 col-md-6" *ngFor="let control of jsonFormData?.controls">

                        <!-- Input Text -->
                        <div *ngIf="control.type == 'text' " >
                            <div class="form-floating">                                                          
                                <input 
                                [placeholder]="'Por favor ingrese '+ control.label "
                                class="form-control" 
                                [formControlName]="control.name"                            
                                [id]="control.name"     
                                [value]="control.value"
                                [type]="control.type"                                        
                                [ngClass]="{'is-invalid': submit && form[control.name].errors }">
                                                                
                                <label [for]="control.name" class="form-label">{{control.label}} </label> 
                                <div class="invalid-feedback" *ngIf="form[control.name].errors?.['required']">
                                    Este campo es requerido                                    
                                </div>
                                <div class="invalid-feedback" *ngIf="form[control.name].errors?.['minlength']" >
                                    El valor minimo  permitido para este campo es es de 
                                    {{form[control.name].errors?.['minlength']['requiredLength']}} 
                                </div>

                            </div>
                        </div>

                        <!-- Date -->
                        <div *ngIf="control.type == 'date' ">
                            <div class="form-floating">
                                <input 
                                [placeholder]="'Por favor ingrese '+ control.label "
                                class="form-control flatpickr-input" 
                                [formControlName]="control.name"                            
                                [id]="control.name"     
                                [type]="control.type"     
                                mwlFlatpickr 
                                [altInput]="true" 
                                [value]="control.value"
                                [convertModelValue]="true"                   
                                >
                                <label [for]="control.name" class="form-label" >{{control.label}} </label> 
                            </div>
                        </div>

                        <!-- Text Area -->
                        <div *ngIf="control.type == 'textarea' ">
                            <div class="form-floating">                                
                                <textarea
                                class="form-control"
                                [id]="control.name"
                                rows="4"
                                [value]="control.value"
                                [formControlName]="control.name"
                                [name]="control.name">

                                </textarea>
                                <label [htmlFor]="control.name" class="form-label" > {{control.label}} </label>
                            </div>
                        </div>

                        <!-- CheckBox -->
                        <div *ngIf="control.type=='checkbox' ">
                            <div class="form-check mb-2">
                                <input class="form-check-input" 
                                type="checkbox" 
                                [formControlName]="control.name"
                                [value]="control.value"
                                [id]="control.name">
                                <label class="form-check-label" [for]="control.name">
                                    {{control.label}}
                                </label>
                            </div>                            
                        </div>

                        <div *ngIf="control.type=='toggle'">
                            <div class="form-check form-switch form-switch-lg" dir="ltr">
                                <input 
                                type="checkbox" 
                                role="switch"
                                class="form-control form-check-input" 
                                [id]="control.name" 
                                [checked]="control.value"
                                [formControlName]="control.name" >
                                <label class="form-check-label" [for]="control.name">{{control.label}} </label>
                            </div>
                        </div>


                    </div>
                </div><!--end row-->

                <div class="d-flex justify-content-end ">
                    <button class="btn btn-success w-lg"> Guardar </button>
                </div>
            </form>  
          <!--    -->
        </div>
    </div>
</div>

