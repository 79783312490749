import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-mobile-template-pharmacy',
  templateUrl: './mobile-template-pharmacy.component.html',
  styleUrls: ['./mobile-template-pharmacy.component.scss']
})
export class MobileTemplatePharmacyComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  _jsonCalendar: any[] = []

  @Input()
  _citasPaciente: any[] = []

  @Input()
    eventSubjectCanvas: Observable<boolean>
  
  @Output()
    eventEmmiterPaciente: EventEmitter<string> = new EventEmitter<string>();

  @Output()
    eventEmmiterCita: EventEmitter<any> = new EventEmitter<any>()
  
  _showLogs: boolean;

  $subscriber: Subscription;

  _canvasRef : NgbOffcanvasRef;

  _searchText: string

  constructor (
    private offcanvasService: NgbOffcanvas   
  ) {}
  
  ngOnInit(): void {
    this._showLogs = false;

    this.$subscriber = this.eventSubjectCanvas.subscribe({
      next:(resp) => {

        console.log({resp});

        this._hideCanvas()
        

      },  
      error:(error) => {
        console.error({error});
        
      }
    })
      
  }
  ngOnChanges(changes: SimpleChanges): void {

    if(this._citasPaciente && this._citasPaciente.length) {

    }

  }

  ngOnDestroy(): void {
    if(this.$subscriber) {
      this.$subscriber.unsubscribe()
    }
  }

  /**
   * 
   */
  private _hideCanvas() {
    
    this._canvasRef.close()
    
    if(this.$subscriber) {
      this.$subscriber.unsubscribe()
    }

  }

  /**
   * 
   */
  openCanvas(content: TemplateRef<any>) {
    this._canvasRef = this.offcanvasService.open(content, { position: 'end' });
  }

  /**
   * 
   * @param id 
   */
  _OnLoadPatient(paciente: any) {     
    this._showLogs = true
    this._canvasRef.close()
    this.eventEmmiterPaciente.emit(paciente)    
  }

  /**
   * 
   * @param cita 
   */
  onLoadDetallesCita(cita: any) {
    console.log({'aaaaaaaaa': cita});
    this._canvasRef.close()
    this.eventEmmiterCita.emit(cita)
    
  }
}
