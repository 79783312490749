
<div class="card mt-3 mb-2" style="border: none;">
  
  <div class="card-header bg-light">
    <div class="row mt-4">
      
      <div class="col-md-12 col-xxl-3">
        <div class="mb-3">
          <label class="control-label">Por tipos de Cita </label>
          <ng-select
          [items]="_tipoCitaGrouped"
          bindLabel="name"
          [(ngModel)]="_TipoCitaCollect"
          (ngModelChange)="_onChangeEventTipoCita($event)"
          [closeOnSelect]="false"
          [multiple]="true">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                <p class="fw-medium mb-0">
                  <i class="ri-asterisk text-primary fs-14 align-middle me-2"></i> {{item.name}}
                </p>
                <div>
                    <span class="text-muted pe-5">{{item.total}} Citas </span>
                    
                </div>
              </div>              
            </ng-template>
          </ng-select>
        </div>  
      </div>

      <div class="col-md-12 col-xxl-3">        
        <div class="mb-3">
          <label class="control-label">Citas por servicios </label>
          <ng-select
          [items]="_serviciosGrouped"
          bindLabel="name"
          [(ngModel)]="_ServiciosCollect"
          (ngModelChange)="_onChangeEventServicios($event)"
          [closeOnSelect]="false"
          [clearable]="false"
          [multiple]="true">

          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
              <p class="fw-medium mb-0">
                <i class="ri-asterisk text-primary fs-14 align-middle me-2"></i> {{item.name}}
              </p>
              <div>
                  <span class="text-muted pe-5">{{item.total}} Citas </span>
                  
              </div>
            </div>              
          </ng-template>

          </ng-select>
        </div>        
      </div>

      <div class="col-md-12 col-xxl-3">        
        <div class="mb-3">
          <label class="control-label">Citas por Doctores </label>
          <ng-select
          [items]="_doctoresGrouped"
          bindLabel="name"
          [(ngModel)]="_DoctoresCollect"
          (ngModelChange)="_onChangeEvent($event)"
          [closeOnSelect]="false"          
          [clearable]="false"
          [multiple]="true">

          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
              <p class="fw-medium mb-0">
                <i class="ri-asterisk text-primary fs-14 align-middle me-2"></i> {{item.name}}
              </p>
              <div>
                  <span class="text-muted pe-5">{{item.total}} Citas </span>
                  
              </div>
            </div>              
          </ng-template>

          </ng-select>
        </div>        
      </div>

      <div class="col-md-12 col-xxl-3">        
        <div class="mb-3">
          <label class="control-label">Citas por Recursos </label>
          <ng-select
          [items]="_recursosGrouped"
          bindLabel="name"
          [(ngModel)]="_RecursosCollect"
          (ngModelChange)="_onChangeEventRecursos($event)"
          [closeOnSelect]="false"
          [multiple]="true">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                <p class="fw-medium mb-0">
                  <i class="ri-asterisk text-primary fs-14 align-middle me-2"></i> {{item.name}}
                </p>
                <div>
                    <span class="text-muted pe-5">{{item.total}} Citas </span>
                    
                </div>
              </div>              
            </ng-template>
          </ng-select>
        </div>        
      </div>
     
    </div>
  </div>

  <div class="card-body">    
    <div class="row d-flex mt-2 mb-4" *ngIf="_responseData && _responseData.length;"> 
      <div class="row g-2 m-2">
          <div class="col-6">
            <div class="float-start">
              <blockquote class="blockquote custom-blockquote blockquote-outline blockquote-secondary rounded mb-0">
                <dt class="text-body bold mb-2">Total de registros {{_responseData.length}}  </dt>   
                <footer class="blockquote-footer mt-0">Máximo de registros a mostrarse <cite title="Source Title">900</cite></footer>
              </blockquote>
            </div>
          </div>
      </div>

<!--       <div class="clearfix">
        <div class="float-start" *ngIf="_filtersItems && _filtersItems.length ">          
          <button  type="button" class="btn btn-primary" (click)="_showAll()" >Mostrar Todo</button>
        </div>
        <div class="float-end">
          <div >
            <a href="javascript:void(0);" (click)="_openFilters(modalFilters)" > 
              <i-feather name="filter" class="fill-primary"></i-feather> 
            </a>        
          </div>
        </div>
      </div>  -->

      <div class="table-responsive mt-4 mb-2">          
          <table class="table table-sm table-hover align-middle mb-0">          
            <thead class="table-light">
              <tr>              
                <th scope="col">Fecha de inicio </th>
                <th scope="col">Paciente </th>
                <th scope="col">Nombre</th>
                <th scope="col">Doctor</th>
                <th scope="col">Tipo de Evento</th>
                <th scope="col">Servicio o Procedimiento: Nombre de Servicios o Procedimiento</th>
                <th scope="col">Candidato a sirugia?</th>
                <th scope="col">Estatus</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of _responseData | paginate: {  itemsPerPage: 15, currentPage: p } ">     
                <td> {{item.Fecha_Inicio | date: 'dd/MM/yyyy'  }} </td>     
                <td><!--  {{}} -->
                  <a href="javascript:void(0);" class="link-secondary  text-decoration-underline  link-underline-opacity-100-hover" href="javascript:void(0);" (click)="_gotoForm(item)"> {{item.N_Expediente}} </a>
                </td>
                <td> {{item.Paciente_Nombre_Completo}} </td>   
                <td> {{_getDoctorName(item.Empleado_Id)}} </td>
                <td> {{item.Tipo_Cita}} </td>
                <td> {{_getServicio(item.Servicio_Id) }} </td>
                <td> {{item.Candidato_Cirugia}} </td>
                <td> {{item.Estatus}} </td>
              </tr>
            </tbody>      
          </table>        
          <pagination-controls (pageChange)="p = $event" nextLabel="Siguiente" previousLabel="Anterior" class="my-pagination" ></pagination-controls>       
      </div>   
    </div>   
  </div>

  <ngx-spinner [name]="_spinner_table"  [fullScreen]="true" type="ball-scale-multiple" >  </ngx-spinner>   
</div>

  <ng-template #modalFilters role="" let-modal>
      
    <div class="modal-content  zoomIn">
        <div class="modal-header p-3 bg-primary-subtle ">
            <h5 class="modal-title" id="modal-title">Filtros de busqueda </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
                (click)="hideModal()"></button>
        </div>
    </div>
  
    <div class="modal-body p-4">
      <form [formGroup]="_formFilter">
        <div class="row d-flex justify-content-between ">

          <div class="col-12">
            <div class="mt-3">
              <label class="form-label mb-0">Rango de fechas</label>          
              <input 
              class="form-control flatpickr-input" 
              type="text"
              placeholder="Seleccione un rango de fechas"
              formControlName="_dateRange"
              mwlFlatpickr 
              [altInput]="true"
              [convertModelValue]="true"               
              mode="range">
            </div>
          </div>

          <div class="col-12">
            <div class="mt-3">
              <label class="form-label">Buscar por doctores</label>
              <ng-select
              [items]="_doctores"
              bindValue="id_salesforce"
              bindLabel="name"
              formControlName="doctor"
              [multiple]="true"
              [closeOnSelect]="false"
              ></ng-select>
            </div>
          </div>

          <div class="col-12">
            <div class="mt-3">
              <label class="form-label">Buscar por servicios</label>
              <ng-select
              [items]="_servicios"
              bindValue="Id"
              bindLabel="Name"
              formControlName="servicios"
              [multiple]="true"
              [closeOnSelect]="false"
              ></ng-select>
            </div>
          </div>
          
        </div>
    
      </form>

    </div>

    <div class="modal-footer p-1">
      <div class="text-center">
        <button type="button" class="btn btn-primary" (click)="_onSearch()" >Buscar</button>
      </div>
      
    </div>
  </ng-template>
  