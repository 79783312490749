<div class="card" *ngIf="_formato">
    <div class="card-body">

        <div class="row g-2 m-4" *ngFor="let item of _controls">

            <ng-container *ngIf="item.type == 'frame'; else otherTypes ">
                
                <div class="col-12">
                    <div class="bg-primary">
                        <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                    </div>
                </div>
                <form [formGroup]="formNotaEvolucion"class="needs-validation createfile-form" autocomplete="off" novalidate >
                <div class="row">                    
                        <!-- time text -->
                        <div  *ngFor="let obj of item.Objects" [ngClass]="obj.type != 'textarea' ? 'col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2' :'col-12'  "   >
                                                    
                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input                        
                                    class="form-control flatpickr-input"                                             
                                    [id]="obj.name"     
                                    [type]="obj.type"                     
                                    [formControlName]="obj.name"                   
                                    [readonly]="obj.readonly" >
                                    <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                </div>
                            </div>
                            
                            <div *ngIf="obj.type == 'time' ">
                                <div class="form-floating">
                                    <input 
                                    type="time"
                                    [name]="obj.name"
                                    [formControlName]="obj.name"      
                                    [readonly]="obj.readonly"
                                    class="form-control">
                                    <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                </div>
                        
                            </div>

                            <div *ngIf="obj.type =='text' ">
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [formControlName]="obj.name"      
                                    [readonly]="obj.readonly"                                
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>
                            </div>   
                            
                            <div *ngIf="obj.type =='textarea' ">
                                <div class="row">
                                    <div class="col-12">                                        
                                        <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                        <textarea
                                        [name]="obj.name"
                                        class="form-control"
                                        [formControlName]="obj.name"
                                        [readonly]="obj.readonly"
                                        [rows]="5"
                                        ></textarea>
                                            
                                        
                                    
                                    </div>
                                </div>
                            </div>
                            
                        </div>
             
                </div>
                </form>

            </ng-container>

            <ng-template #otherTypes>
                <div class="col-12">
                    <div class="bg-primary">
                        <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                    </div>
                </div>
                <ng-container *ngIf="_Firma && _Firma.length; loadedTemplate">
                    <div class="col-12 ">
                        <h4 class="fs-4 text-primary bold text-uppercase text-center">Firma digital </h4>     
                    </div>
                    <fieldset class="mt-4" >
                        <div class="card" *ngFor="let item of _Firma" >
                         <div class="card-body">
                             <div class="d-flex justify-content-center"  >
                                 <figure class="figure mb-0">
                                     <img [src]="item.url" class="figure-img img-fluid" max  alt="" width="300">
                                 </figure>                                        
                             </div>                                    
                         </div>
                       
                        </div>
                     </fieldset>
                </ng-container>
                <ng-template #loadedTemplate>
                    <app-firma-doctor></app-firma-doctor>
                </ng-template>
                <!-- <app-firma-doctor></app-firma-doctor> -->
            </ng-template>

        </div>
    </div>
</div>
