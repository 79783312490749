import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-firma-doctor',
  templateUrl: './firma-doctor.component.html',
  styleUrls: ['./firma-doctor.component.scss']
})
export class FirmaDoctorComponent {

  @ViewChild('canvas') canvasEl!: ElementRef;

  @Output()
  eventsignatureImg: EventEmitter<any> = new EventEmitter<any>();

  signatureNeeded: boolean = true
  signaturePad!: SignaturePad;
  
  signatureImg!: string | null;


  ngAfterViewInit(): void {
    this.initSigned()
  }

  private initSigned() {
    setTimeout(() => {      
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    }, 300);
    
  }


  clearPad() {
    this.signaturePad.clear();
    this.signatureNeeded = true
    this.signatureImg = null
    this.eventsignatureImg.emit(this.signatureImg)
    this.initSigned()
    
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    console.log({signatureImg: this.signatureImg});

    this.eventsignatureImg.emit(this.signatureImg)
    
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (!this.signatureNeeded) {
      this.signatureNeeded = false;
    }
  }

  
  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }



}
