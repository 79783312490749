import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-old-history-clinical',
  templateUrl: './old-history-clinical.component.html',
  styleUrls: ['./old-history-clinical.component.scss']
})
export class OldHistoryClinicalComponent implements OnInit {


  public innerWidth: number;
  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView()
  }

  _showUploadfiles: boolean = true

  _formOldClinicalHistory: FormGroup;

  constructor (
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.checkView()
    this.initform()
  }

  get isMobile(): boolean {
    return this.innerWidth <= 1024;
  }
  private checkView(): void {    
    this.innerWidth = window.innerWidth;
  }

  /**
   * 
   */
  private initform() {
    this._formOldClinicalHistory = this.fb.group({
      nombre_paciente:[null],
      cita: [null],
      id_Cita:[null]

    })
  }


  /**
   * 
   * @param $event 
   */
  onEventAddedFiles(event: any) {

    console.log({_EVENT: event});
    

  }

}
