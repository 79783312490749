import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-enfermeria-endoscopia',
  templateUrl: './enfermeria-endoscopia.component.html',
  styleUrls: ['./enfermeria-endoscopia.component.scss']
})
export class EnfermeriaEndoscopiaComponent implements OnInit, OnDestroy {

  @Input()
  id: string;

  @Input()
  _formatoData: any


  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean

  record: any;

  
  $interval!: Subscription;
  public formEnfermeria: FormGroup = this.formBuilder.group({})

  _Cita: Cita;
  _BODYFORM: any;

  _formatoValues: any

  constructor(
    private formatService: FormatsService,
    private date: DatePipe,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private credentials: CredentialsService
    ) {
    this.record = this.credentials.userInfoData.data.records[0]

    const cita = this.credentials.getCitaJson()
    if(cita) {
      this._Cita = cita
    }

  }
  

  ngOnInit(): void {

    if(this._formatoData) {
      this._formato = this._formatoData
      
      this._label = this._formato.label;
      this._controls = this._formato.form.controls;

      this._itsReady = true
      this.initForm();
    }
    
  }

  get f() {
    return this.formEnfermeria.controls;
  }

  ngOnDestroy(): void {
    if(this.$interval) {
      this.$interval.unsubscribe()
    }

    this._savePrevDestroy()

  }

  private _savePrevDestroy(): void {
    let _formValues = this.formEnfermeria.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }
  
  /**
   * 
   */
  private initForm () {

    _.map(this._controls, (a) => {
      if(a.type != 'frame') {
        _.map(a.Objects, (b) => {
          if(b.Objects){
          _.map(b.Objects, (c) => {   
            if(c.Objects) {
              _.map(c.Objects, (d) => {     
                if(d.type == 'label') {   
                  _.map(d.Objects, (e) => {
                    this.formEnfermeria.addControl(
                      e.name, this.formBuilder.control(e.value)
                    )
                  })
                }else {
                  this.formEnfermeria.addControl(
                    d.name, this.formBuilder.control(d.value)
                  )                  
                }                
              })
            }else {
              this.formEnfermeria.addControl(
                c.name, this.formBuilder.control(c.value)  
              )              
            }
          })
        }else {
          this.formEnfermeria.addControl(
            b.name, this.formBuilder.control(b.value)
          )          
        }
        })
      } 
    })

    console.log({formEnfermeria: this.formEnfermeria.controls});   
    this.createInterval()

  }

  private createInterval () {
    const myObservable= interval(1000);
    this.$interval = myObservable.subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();      
        //console.log('GO SAVE IT!!!!');        
        //this.show = !this.show;       
        //this._onSaveIt()     
        this._newSaveFormat()
       
      }           
      //this._interval = integer
      
    })
  }

  private _newSaveFormat () {
    let _formValues = this.formEnfermeria.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
      _body_.push({
        name: i,
        value: a
      })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }

    //this._formatoValues = _bodyForm
    this._saveFormatValues(_bodyForm, true)
  }


  /**
   * 
   * @param _jsonObject 
   * @param createInterval 
   */
  _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {

    this.formatService._postFormato(_jsonObject).then((resp) => {

      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      }).onHidden.subscribe(() => {
        if(createInterval) {
                    
          this.createInterval()
        }
      })
    }).catch((error) => {
      console.error({error});
      
    })


  }


  /**
   * 
   */
  private _onSaveIt () {

    let _formato = this._formato;

    _.map(_formato.form.controls,(a) => {
      if(a.type != 'frame') {
        _.map(a.Objects, (b) => {
          if(b.Objects){
          _.map(b.Objects, (c) => {   
            if(c.Objects) {
              _.map(c.Objects, (d) => {     
                if(d.type == 'label') {   
                  _.map(d.Objects, (e) => {
                    e.value = this.f[e.name].value
                   /*  this.formEnfermeria.addControl(
                      e.name, this.formBuilder.control(e.value)
                    ) */
                  })
                }else {
                  d.value = this.f[d.name].value                                  
                }                
              })
            }else {
              c.value = this.f[c.name].value                        
            }
          })
        }else {
          b.value = this.f[b.name].value
             
        }
        })
      } 
    })

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      form: _formato.form
    }
    console.log({_BODYFORM: _bodyForm });

    this._BODYFORM = _bodyForm

    this.formatService._postFormato(_bodyForm).then((resp) => {
      this.toastr.info('Se ha guardado la información','Guardada', {
        timeOut: 3200
      })
      this.$interval.unsubscribe()
       setTimeout(() => {
        //  this.createInterval()     
        }, 5000);

    }).catch((error) => {
      console.error({error});      
    })
    
  }

  _onCheckChange(event:any, name: string) {
    let val = event.target.checked
    this.formEnfermeria.controls[name].patchValue(val)   
  }


  _getValue(name: string) {

    switch (name) {
      case 'name':
        return this.record.Nombre_completo__c       
      case 'age':
        return this.record.P05_Edad__c;
      case 'sex':
        return this.record.P05_Sexo__c;
      case 'n_expediente':
        return this.record.Name;
      case 'birth_date':
        return this.record.P05_Fecha_de_Nacimiento__c
      case 'fecha':
        return this.record.CreatedDate //this.date.transform(, 'yyyy-MM-dd')
      case 'hora':           
        return this.date.transform(this.record.CreatedDate,'hh:mm a')
      
      default:
        return 'N/D'
        break;
    }
  }


}
