import { Pipe, PipeTransform } from '@angular/core';
import { SelectedFIlters } from '../models/selected-filters';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(Collection:SelectedFIlters[], searchText: string) {
    if (typeof searchText === typeof undefined) return Collection;

    return Collection.filter((item) => item.Name.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()) )

    
  }

}
