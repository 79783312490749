<div class="card" *ngIf="_formato && _itsReady">
    <div class="card-body">
        <!-- <code>{{_BODYFORM | json }}</code> -->
        <form [formGroup]="formPostOperatoria" class="needs-validation createfile-form" autocomplete="off" novalidate>
            <div class="row g-2 m-4" *ngFor="let item of _controls">

                <ng-container *ngIf="item.type == 'frame'">
                    <div class="col-12" >
                        <div class="bg-primary">
                            <h4 class="fs-4 text-white bold text-uppercase text-center">{{item.label}} </h4>     
                        </div>
                    </div>
                    <div class="row" >              
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4 mr-2" *ngFor="let obj of item.Objects" > 

                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input                        
                                    class="form-control flatpickr-input"                                             
                                    [id]="obj.name"     
                                    [type]="obj.type"     
                                    readonly   
                                    [readonly]="obj.readonly"
                                    >
                                    <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'text' || obj.type == 'number' ">
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [readonly]="obj.readonly"
                                    [value]="_getValue(obj.name)"
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>                              
                            </div>

                            <div *ngIf="obj.type == 'time' ">
                                <input 
                                type="text"
                                [name]="obj.name"
                                [value]="_getValue(obj.name)"
                                class="form-control" 
                                >
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="item.type == 'title' ">
                    <hr>
                    <div class="row">
                        <div class="mr-2 mt-4 " *ngFor="let obj of item.Objects" [ngClass]="obj.type == 'textarea' || obj.type == 'label'  ? 'col-12' : 'col-md-6 col-lg-6 col-xl-4 ' " >

                            <div *ngIf="obj.type == 'date' ">
                                <div class="form-floating">
                                    <input                        
                                    class="form-control flatpickr-input"                                             
                                    [id]="obj.name"     
                                    [type]="obj.type"     
                                    [formControlName]="obj.name"
                                    [readonly]="obj.readonly"  
                                    >
                                    <label [for]="obj.name" class="form-label" >{{obj.label}} </label> 
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'text' || obj.type == 'number' ">
                                <div class="form-floating">
                                    <input 
                                    class="form-control"
                                    [type]="obj.type"
                                    [name]="obj.name"
                                    [readonly]="obj.readonly"
                                    [formControlName]="obj.name"
                                    [required]="obj.required"
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>                              
                            </div>

                            <div *ngIf="obj.type == 'time' ">
                                <div class="form-floating">
                                    <input 
                                    type="time"
                                    [name]="obj.name"       
                                    [formControlName]="obj.name"                     
                                    class="form-control" 
                                    >
                                    <label class="form-label" for="{{obj.name}}" >{{obj.label}} </label>
                                </div>
                        
                            </div>

                            <div *ngIf="obj.type =='textarea' ">
                                
                                <div class="card-body">
                                    <div class="row mt-3">
                                        <div class="col-xl-3">
                                            <label for="{{obj.name}}" class="form-label">{{obj.label}}</label>
                                        </div>
                                        <div class="col-xl-9 col-lg-12">                                   
                                            <textarea
                                            [name]="obj.name"
                                            class="form-control"
                                            [formControlName]="obj.name"
                                            [readonly]="obj.readonly"
                                            [rows]="5"></textarea>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="obj.type == 'label' ">
                                <div class="row">
                                    <div class="mr-2 mt-4" *ngFor="let _obj of obj.Objects" [ngClass]="_obj.type == 'textarea' ? 'col-12' : 'col-md-6 col-lg-6 col-xl-4' " >

                                        <div *ngIf="_obj.type == 'text' || _obj.type == 'number' ">
                                            <div class="form-floating">
                                                <input 
                                                class="form-control"
                                                [type]="_obj.type"
                                                [name]="_obj.name"
                                                [formControlName]="_obj.name"
                                                [readonly]="_obj.readonly"
                                                [required]="_obj.required"
                                                >
                                                <label class="form-label" for="{{_obj.name}}" >{{_obj.label}} </label>
                                            </div>                              
                                        </div>

                                        <div *ngIf="_obj.type == 'checkbox' ">
                                            <div class="form-check  mb-3">
                                                <input
                                                class="form-check-input" 
                                                type="checkbox"
                                                [name]="_obj.name"
                                                [required]="_obj.required"
                                                [formControlName]="_obj.name"
                                                [checked]="_obj.value == 'false' ? false : _obj.value "
                                                (change)="_onCheckChange($event, _obj.name)"
                                                [readonly]="_obj.readonly">
                                                <label class="form-check-label" for="{{_obj.name}}">{{_obj.label}} </label>
                                            </div>
                                        </div>

                                        <div *ngIf="_obj.type == 'radio' ">
                                            <!-- Switches Color -->
                                        <!--     <div class="form-check form-switch form-switch-md" dir="ltr">
                                                <input 
                                                type="checkbox"
                                                [id]="_obj.name"
                                                [name]="_obj.name"        
                                                [readonly]="_obj.readonly"
                                                [required]="_obj.required"                                    
                                                class="form-check-input"
                                            >
                                                <label class="form-check-label" for="customSwitchsizemd"> {{_obj.label}} </label>
                                            </div> -->

                                            <!-- Custom Radio Color -->
                                           
                                         <div class="form-check form-check-right form-radio-primary mb-3">
                                                <input 
                                                class="form-check-input" 
                                                type="radio" 
                                                [name]="obj.name"         
                                                [value]="_obj.name"                                        
                                                id="{{_obj.name}}" 
                                                [formControlName]="obj.name"
                                                >
                                                <label class="form-check-label" for="{{_obj.name}}">
                                                {{_obj.label}}
                                                </label>
                                            </div>

                                        </div>


                                        <div *ngIf="_obj.type =='textarea' ">
                                            <div class="card-body">
                                                <div class="row mt-3">
                                                    <div class="col-xl-3">
                                                        <label for="{{_obj.name}}" class="form-label">{{_obj.label}}</label>
                                                    </div>
                                                    <div class="col-xl-9 col-lg-12">                                   
                                                        <textarea
                                                        [name]="_obj.name"
                                                        class="form-control"
                                                        [formControlName]="_obj.name"
                                                        [readonly]="_obj.readonly"
                                                        [rows]="5"></textarea>                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    
                    
                        <!-- <div class="row" *ngFor="let obj of item.Objects">
                            <div class="col-6">
                                <p> {{obj.label}} </p>
                            </div>
                        </div> -->
                
                </ng-container>
            </div>
        </form>
    </div>
</div>
