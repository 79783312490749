import { B } from "@fullcalendar/core/internal-common"
import { CreateCandidate } from "src/app/shared/models/create-candidate"

export class CalificacionCandidato {
    candidato_id: string | null
    comezon:boolean 
    sensacion_bolita:boolean
    dolor: {
        durante_el_dia:boolean
        al_evacuar:boolean
        nivel_dolor: number
    }
    sangrado: boolean 
    prioridad: number

}

export class CalificacionVarices {
    candidato_id: string
    comezon: boolean
    dolor: {
        dolor: boolean | null
        nivel_dolor: number
    }
    ardor: boolean
    sensacion_pesadez: boolean
    piernas_cansadas: boolean
    prioridad: number
}

export interface CandidatosResponse {
    codigo:number
    mensaje: string
    data: CandidatoData[]
}

export interface CandidatoData {
    Candidato_Id: string
    Datos_Candidato: CreateCandidate
    Name:string
    Paciente_Id:string
    Recurso:string
    Salesforce_Id:string
}


export interface CandidatoEstatus {
    codigo:number
    mensaje: string
    data: any[]
}