import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FilePondOptions } from 'filepond';
import { FilePondComponent } from 'ngx-filepond';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent {

  @ViewChild('modalFiles') modalFiles !: TemplateRef<any>
  @ViewChild("myPond") myPond: FilePondComponent;

  @Input()
  showUpload: boolean = false;

  @Input()
  isMobile: boolean = true

  @Output()
  eventEmmitFilesAdded : EventEmitter<any[]> = new EventEmitter<any[]>()


    
  pondOptions: FilePondOptions = {
    allowMultiple: true,
    labelIdle: "Suelte aquí o seleccione el archivo a subir",
    acceptedFileTypes:['application/pdf'],
    maxFiles:1,
    className:'dropzone_sec',
    allowDrop: !this.isMobile,
    chunkUploads:true
  };


  pondFiles: FilePondOptions["files"] = [];
  

  constructor (
    private toastr: ToastrService
  ) {}


  //#region Eventos filePond

  
  pondHandleInit() {
    console.log("FilePond has initialised", this.myPond);
  }

  pondHandleAddFile(event: any) {

    this.pondFiles?.push(event.file.file)

    console.log("A file was added", event);
    console.log({pondFiles: this.pondFiles});

    
    
  }

  pondHandleActivateFile(event: any) {
    console.log("A file was activated", event);
    console.log({pondHandleActivateFile: event });
    
  }

  pondFileProgress(event: any, item: any) {
    console.log('PROGRESSSS');
    console.log({pondFileProgress: event});    
    
  }

  pondFileStart(event: any) {
    console.log({pondFileStart: event});    
  }

  pondProcessfilestart(event: any) {
    console.log({pondProcessfilestart: event});    
  }

  pondAddfileprogress(event: any) {
    console.log({pondAddfileprogress: event});    
  }

  /**
   * 
   * @param event 
   */
  pondProcessfiles(event: any) {
    console.log({pondProcessfiles: event});    
    console.log('AAAAAAAAAAAAAAAAVVVVVVVVVVVVVVVVV');
    
  }

  onpreparefile(event: any) {
    console.log({onpreparefile: event});    
    console.log('BBBBBBBBBBBBBBBBBBBBBBBBCCCCC');
    
  }

  /**
   * 
   * @param event 
   */
  onupdatefiles(event: any) {
    console.log({onupdatefiles: event });    
    console.log('CCCCCCCCCCCCCCCCCCCCCCCCDDDD');
    
  }

  /**
   * 
   * @param event 
   */
  onprocessfileprogress (event: any) {  
    console.log({onprocessfileprogress: event});
    console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDAAAAAAA');   

  }

  //#endregion


  _onUploadFile () {

    if(this.pondFiles && this.pondFiles.length > 0) {
      this.eventEmmitFilesAdded.next(this.pondFiles)
    }else{
      this.toastr.info('Por favor seleccione un archivo PDF a cargar','No hay archivo seleccionado',{
        timeOut: 3200
      })
    }
    


  }
}
