import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage } from 'ngx-webcam';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-form-notas-evolucion-varices',
  templateUrl: './form-notas-evolucion-varices.component.html',
  styleUrls: ['./form-notas-evolucion-varices.component.scss']
})
export class FormNotasEvolucionVaricesComponent{

  @Input()
  control: any;

  @Input()
  isDesktopDevice: boolean

  @Input()
  _cita: Cita

  @Input()
  record: any

  @Output()
  _svgFilesEventEmmiter: EventEmitter<any[]> = new EventEmitter<any[]>()

  @ViewChild('modalSvg') modalSvg: TemplateRef<any>;

  _assetsSvg = ['assets/images/VaricesIMG/Varices1B.svg','assets/images/VaricesIMG/Varices2B.svg', 'assets/images/VaricesIMG/Varices3B.svg','assets/images/VaricesIMG/Varices4B.svg']

  _svgEditors: any[] = []
  _svgfilesPng: any[] = []

  _ngbModalRef: NgbModalRef;


  constructor (
    private ngModal: NgbModal,
    private toastr: ToastrService,
    private alert: AlertsService,
    private formatService: FormatsService,
    
  ) {}

  _onEvenEmmiter(event: boolean) { }

  /**
   * 
   * @param event 
   */
  _onEventEmmiterImg(event: any) {
    this._svgfilesPng.push(event)   

    this._svgFilesEventEmmiter.next(this._svgfilesPng)

  }


  _openModalSvg(): void {
    
    this._ngbModalRef = this.ngModal.open(this.modalSvg, {    
      size:'xl',
      animation:true,
      backdrop:'static',
      fullscreen:'lg'
    })
  }

  /**
   * 
   * @param _assets 
   * @param _index 
   * @returns 
   */
  _onSetsvg(_assets: string, _index: number) : void {

    const _find = _.find(this._svgEditors, { id: _index })

    if(_find) {
      this.toastr.info('Ya existe, este formato','',{
        timeOut: 3200
      })
      return
    }

    this._svgEditors.push({
      id: _index,
      path: _assets,
      _showComments:false,
      comentario: null
    })
    
    this._ngbModalRef.close()

  }

  /**
   * 
   * @param _files 
   */
  _onUploadFiles(_files: any[]): void {

  }

  /**
   * 
   * @param webcamImage 
   */
  _onSaveItImages(webcamImage: WebcamImage []): void {
    
  }

  /**
   * 
   */
  _onEventEmmiteDelte(_path: string) {
    
    let _find = _.find(this._svgEditors, { path: _path })

    console.log({_find});
    
  }

  /**
   * 
   */
  _saveSvgToPng() {
/*     this.alert._showLoader('Guardando por favor espere...')   

    this.formatService.UploadFiles(this._cita.Cita_Id,this.record.Name,this.control.name,null, null, this._svgfilesPng).then((resp) => {
      console.log({resp});      
      this.alert._hideLoader()
    }).catch((error) => {
      console.error({error});
      this.alert._hideLoader()   
    }) */

  }


}
