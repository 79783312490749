import { Component, Input } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Cita } from 'src/app/pages/calendar/models/calendar';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss']
})
export class CountDownComponent {

  @Input()
  citaSelected: Cita;
  

  private subscription: Subscription;

  public dateNow = new Date();
  public dDay = new Date('2024-03-13T12:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;
  
  

  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  public hoursToDday: any;
  public daysToDday: any;

  ngOnInit() {
    if(this.citaSelected) {
      this.dDay = new Date(this.citaSelected.Fecha_Inicio)

      this.subscription = interval(1000)
      .subscribe(x => { 
        this.getTimeDifference(); 
      });

    }
 
 }
 
 ngOnDestroy() {
  this.subscription.unsubscribe();
}

  private getTimeDifference () {
    this.timeDifference = this.dDay.getTime() - new  Date().getTime();
    if(this.timeDifference > 0 ) {
      this.allocateTimeUnits(this.timeDifference);
    }else {
      console.log('aaa');

      this.subscription.unsubscribe()
      
    }
    
  }

  private allocateTimeUnits (timeDifference: any) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
}

}
