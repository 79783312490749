export class CreateCita {
    Salesforce_Id?: string
    generales: CitaGenerales
    detalle_cita: CitaDetalles
    atencion_paciente: CitaAtencionPaciente
    cita_sub_secuente?: boolean
    cita_sub_secuente_id?: string
}


export interface CitaGenerales {
    fecha_inicio?: string
    fecha_final?: string
    estatus?: string
    motivo_cancelacion?: string
    tipo_evento?: string
    comentarios?: string
    pixel_gooogle?: string
    pixel_facebook?: string
    id_campaña?: string
    enviar_recordatorio?: string
    enviar_encuesta?: boolean
    fecha_envio_encuesta?: any
}

export interface CitaDetalles {
    doctor?: string
    paciente?: string
    servicio?: string
    recurso?: string

    anestesista?: string
    ayudante?: string
}

export interface CitaAtencionPaciente {
    recordatorio?: Date
    responsable?: string
}