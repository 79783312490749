import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {

  @Input()
  Formatos: any[] = []

  @Input()
  _CitasSubsecuentes: any[] = []

  layout: string | undefined;
  mode: string | undefined;
  width: string | undefined;
  position: string | undefined;
  topbar: string | undefined;
  size: string | undefined;
  sidebarView: string | undefined;
  sidebar: string | undefined;
  attribute: any;
  sidebarImage: any;
  sidebarVisibility: any;
  preLoader: any;
  grd: any;

  _clientes_espera: any[] = []

  isCollapsed: boolean = true
  isCollapsedPacientes: boolean =  true
  isCollapsedOthers: boolean = true

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() SetIdFormato = new EventEmitter()

  constructor(
    private eventService: EventService, 
    private offcanvasService: NgbOffcanvas,
    private credentials: CredentialsService
    ) {}

  ngOnInit(): void {

    const _dataUser = this.credentials._getdataUser();

    if(_dataUser) {
      const _Websockets = _dataUser.Websockets;

      if(_Websockets && _Websockets.paciente_espera){
        this._clientes_espera = _Websockets.paciente_espera.Logs;
      }      
    }

    
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  openEnd(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  /**
   * 
   * @param item 
   */
  _onSetIdFormato(item: any) {
    this.offcanvasService.dismiss()
    this.SetIdFormato.emit(item)
  }
  
  /**
   * 
   * @param patient 
   */
  _onSetPatient(patient: any) {

  }

}
