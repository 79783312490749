import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

declare var webkitSpeechRecognition: any;
declare let window: any


const SpeechRecognition =
window.SpeechRecognition || window.webkitSpeechRecognition;
const SpeechGrammarList =
window.SpeechGrammarList || window.webkitSpeechGrammarList;
const SpeechRecognitionEvent =
window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionService {

  recognition: typeof SpeechRecognition | undefined;
  transcript: Subject<string> = new Subject<string>();
  
  //recognition =  new window.SpeechRecognition
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords: any;

  constructor(
    private toastr: ToastrService
  ) { }

  init() {
   // console.log('aaaa');
    

    //console.log({SpeechRecognition, SpeechGrammarList,  SpeechRecognitionEvent});
    
    
/*     this.recognition.interimResults = true;
    this.recognition.lang = 'es-MX';
    try {
      console.log('aaaaa');
      
      this.recognition.addEventListener('result', (e: { results: Iterable<unknown> | ArrayLike<unknown>; }) => {
        console.log({e________________________:e});        
        const transcript = Array.from(e.results)
        
          .map((result: any) => result[0])
          .map((result) => result.transcript)
          .join('');
          this.tempWords = transcript;
          console.log(transcript);
      })
    }catch (error) {
      console.error({error_____: error});
      
    }
     */
  }

  /**
   * 
   */
  startListening() {
    if (!this.recognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true; // Allow continuous recognition
      this.recognition.interimResults = true; // Get interim results

      // Handle recognition events
      this.recognition.onresult = (event: any) => {
        const transcript = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join('');
        this.transcript.next(transcript);
      };

      this.recognition.onerror = (event: any) => {
        console.error(event.error);
        this.toastr.error(event.error,'Error ocurrido',{timeOut: 3200})
      };
    }

    this.recognition.start();
  }

  stopListening() {
    if (this.recognition) {
      this.recognition.stop();
    }
  }

}