import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import {  CalendarObjects,  Cita,  Doctores, Servicios } from 'src/app/pages/calendar/models/calendar';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';


import { Filters } from 'src/app/pages/models/filters';




import { LocalSessionService } from 'src/app/pages/services/local-session.service';

import { RedirectService } from 'src/app/services/redirect.service';

import { Doctor } from 'src/app/pages/models/doctores';
import { Servicio } from 'src/app/pages/models/servicios';
import { FilterCalendar } from 'src/app/pages/models/filter-calendar';
import { Recurso } from 'src/app/pages/models/recursos';
import { PatientsService } from 'src/app/pages/calendar/services/patients.service';


const _DATE_TABLE = '_dates_table_calendar'

//#region Catalogos
const DOCTORES_  = '_cat_doctores'
const RECURSOS_  = '_cat_reursos'
const SERVICIOS_ = '_cat_servicios'
//#endregion

@Component({
  selector: 'app-tabla-de-citas',
  templateUrl: './tabla-de-citas.component.html',
  styleUrls: ['./tabla-de-citas.component.scss']
})
export class TablaDeCitasComponent {

  @ViewChild('modalFilters') modalFilters : TemplateRef<any>;

  _Doctores : Doctores[]  = []
  _Servicios: Servicios[] = []
  _responseData: Cita[] = []

  _doctores: Doctor[]
  _servicios: Servicio[]  = []
  _recursos: Recurso[] = []

  P05_Fecha__c: any[] = []

  _keyIndex: any[] = []

  _startDate:string = ''
  _endData:string   = ''
  _docto: string    = '' // a0E5e0000006nL8EAI

  _filters: Filters = new Filters()

  _loaderError: boolean = false;

  p:number = 1;

  _formFilter: FormGroup;

  _spinner_table = 'spinner_table_doc'

  _serviciosGrouped: any[] = []
  _collapsedServicios = true
  
  _doctoresGrouped: any[] = []
  _collapsedDoctor = true

  _recursosGrouped: any[] = []
  _collapsedRecursos = true

  _tipoCitaGrouped: any[] = []


  _groupedCitas_: any[] = []

  _all_items: any[] = []
  _allData: any // For Filter

  _filtersItems: any[] = []


  _calendarFilters: FilterCalendar = new FilterCalendar() 
  _isAdmin: undefined | boolean = false
  
  _ServiciosCollect: any[] = []
  _serviciosFilter: any[] = []

  _DoctoresCollect: any[] = []
  _doctoresFilter: any[] = []

  _RecursosCollect: any[] = []
  _recursosFilter: any[] = []

  _TipoCitaCollect: any[] = []
  _tipoCitaFilter: any[] = []

  constructor(
    private calendar: CalendarService,
    private fb: FormBuilder,
    private date: DatePipe,
    private modalService: NgbModal,
    private patients: PatientsService,
    private redirect: RedirectService,
    private toastr: ToastrService,
    private credentialService: CredentialsService,
    private spinner: NgxSpinnerService,
    private changeRef: ChangeDetectorRef,
    private local: LocalSessionService) {
      flatpickr.localize(Spanish)
    }

  ngOnInit(): void {

 //   this._isAdmin = this.credentialService.credentials?.roles.Administrador

    this.loadCatalogs()

    let _startOfMonth = moment().startOf('month').toISOString()
    let _today_ = moment().toISOString()

    if(this.credentialService.credentials?.roles.Doctor) {      
      this._docto = this.credentialService.credentials.id_salesforce;      
    }

    if(this._isAdmin) {

     // this._calendarFilters.doctor = ''
      this._calendarFilters.inicio = this.date.transform(_startOfMonth,'yyyy-MM-dd')
      this._calendarFilters.final  = this.date.transform(_today_,'yyyy-MM-dd')

    }else {
      this._filters.doctor = this._docto
      this._filters.inicio = this.date.transform(_startOfMonth,'yyyy-MM-dd') //this._startDate
      this._filters.final  = this.date.transform(_today_,'yyyy-MM-dd') // this._endData
    }


    

/*     */       
    this.initform()
  }

  ngAfterContentChecked(): void {
    this.changeRef.detectChanges()
  }

  /**
   * 
   */
  private initform() {
    this._formFilter = this.fb.group({
      _dateRange: [new Date, [Validators.required]],
      doctor:[''],

      servicios:[''],
      doctores:[''],
      recursos:[''],
    })

    this.patchValues()
   
  }

  get f () {
     return this._formFilter.controls
  }

  private loadCatalogs () {

    const _cat_DOCTORES_ = this.local.getJsonValues(DOCTORES_)
    const _cat_RECURSOS_ = this.local.getJsonValues(RECURSOS_)
    const _cat_SERVICIOS_ = this.local.getJsonValues(SERVICIOS_)
    
    if(_cat_DOCTORES_) {

      this._doctores = _cat_DOCTORES_

    }else {
      this.calendar._getDoctores('true').subscribe({
        next:(response) => {
          if(response.codigo == 200) {
            this._doctores = response.data.info

            this.local.savedJsonValues(DOCTORES_, this._doctores)
          }
        },
        error(err) {
          console.error({ERROR: err});
          
        },
      })
    }

    if(_cat_SERVICIOS_) {
      this._servicios = _cat_SERVICIOS_
    }else {
      this.calendar._getServicio('true').subscribe({
        next:(response) => {
          if(response.codigo == 200) {
            this._servicios = response.data
            this.local.savedJsonValues(SERVICIOS_, this._servicios)
          }
        },error(err) {
          console.error({ERROR: err});
        },
      })
    }

    
    if(_cat_RECURSOS_) {
      this._recursos = _cat_RECURSOS_
    }else {
      this.calendar._getRecursos('true').subscribe({
        next:(response) => {
          if(response.codigo == 200 ) {
            this._recursos = response.data
            this.local.savedJsonValues(_cat_RECURSOS_, this._recursos)
          }
        }
      })
    }

  

  }

  /**
   * 
   */
  private patchValues() {

    const _dates_ = this.local.getJsonValues(_DATE_TABLE)

    if(_dates_) {
      this._formFilter.patchValue({
        _dateRange: _dates_
      })
      //console.log({DATES__: _dates_});
      this._parseDatesFilter()      
    }

    if(this._isAdmin) {
      this.loadByFilters()
    }else {
      this.LoadData() 
    }

    

  }

  /**
   * 
   */
  private LoadData() {

    this._loaderError = false;
    this.spinner.show(this._spinner_table)
    this.calendar._getCalendarReport(this._filters).subscribe({
      next:(response: any) =>  {
        if(response.codigo == 200) {

          const resp = response.data as CalendarObjects;

          this._allData = resp

          this._Doctores = resp.Doctores;
          this._Servicios = resp.Servicios
          this._responseData = resp.Citas;       
              
          this._all_items = resp.Citas;
          
          this._getFilters(resp.Citas)
          this.spinner.hide(this._spinner_table)
        }
      },
      error:(err) =>  {
        console.error({err});
        this.spinner.hide(this._spinner_table)
        this._loaderError = true;
        this.toastr.error('Ocurrio un error al obtener los datos del calendario','Error' ,{
          timeOut: 3200
        })
      },
    }) 

  }

  /**
   * Filters Combo
   * @param Citas 
   */
  private _getFilters(Citas: any[]): void {
    console.log({_CITAS_______: Citas});
    

    const resp = this._allData;

    
    this._serviciosGrouped = _(Citas)
    .groupBy('Servicio_Id')
    .map((items, servicio_id) => ({
      servicio_id,
      name: _.find(resp.Servicios, {Id: servicio_id })?.Name,
      total: items.length,
      data: items
    })).value()


    this._doctoresGrouped = _(Citas)
    .groupBy('Empleado_Id')
    .map((items,empleado_id) =>({
      empleado_id,
      name: _.find(resp.Doctores, {Id: empleado_id})?.Name,
      total: items.length,
      data: items
    })).value()
   
    this._recursosGrouped = _(Citas)
    .groupBy('Recurso_Id')
    .map((items, recurso_id) => ({
      recurso_id,
      name: items[0].Recurso,
      total: items.length,
      data: items
    })).value()   


    this._tipoCitaGrouped = _(Citas)
    .groupBy('Tipo_Cita')
    .map((items,tipo_cita) => ({
      tipo_cita,
      name: items[0].Tipo_Cita,
      total: items.length,
      data: items
    })).value();


  }

  /**
   * 
   */
  private loadByFilters () {
    console.log({_FILTERS: this._calendarFilters});
    this.SetCalendarFilterAdmin()
    console.log('LOAD BY FILTER!!!');

    this.spinner.show(this._spinner_table)
    this.calendar._getFilterCalendar(this._calendarFilters).subscribe({
      next:(response: any) => {

        if(response.codigo == 200) {
          const resp = response.data as CalendarObjects;

          this._Doctores = resp.Doctores;
          this._Servicios = resp.Servicios
          this._responseData = resp.Citas;   
          
          this._all_items = resp.Citas;

          this._getFilters(resp.Citas)
          this.spinner.hide(this._spinner_table)
          
          }

      },
      error:(err) => {
        console.error({err});
        this.spinner.hide(this._spinner_table)
        this._loaderError = true;
        this.toastr.error('Ocurrio un error al obtener los datos del calendario','Error' ,{
          timeOut: 3200
        })
      },
    })

    //this._parseDatesFilter()
    //this.SetCalendarFilterAdmin()

   // console.log({_FILTERS: this._calendarFilters});
    
  }

  /**
   * 
   */
  private SetCalendarFilterAdmin () {
    this._calendarFilters.doctores = this.f['doctores'].value
    this._calendarFilters.recursos = this.f['recursos'].value
    this._calendarFilters.servicios = this.f['servicios'].value

  }//
  
  /**
   * Devuelve el Nombre del Doctor
   * @param idDoctor 
   */
  _getDoctorName(idDoctor:  string) {   
    const doctorName = _.find(this._Doctores,{ Id:idDoctor})
    if(doctorName){
      return doctorName.Name
    }
    return 'N/A'     
  }

  /**
   * Devuelve el Nombre del Servicio
   * @param idServicio 
   */
  _getServicio(idServicio: string) {

    const _servicio = _.find(this._Servicios, {Id: idServicio})
    if(_servicio) {
      return _servicio.Name
    }

    return 'N/A'

  }

  /**
   * 
   * @param event 
   */
  _onChange(event: any) {

       
    
  }

  /**
   * 
   */
  _onSearch() {
    const _dateRange = this._formFilter.value._dateRange;

    this._parseDatesFilter()

    this.local.savedJsonValues(_DATE_TABLE, _dateRange)

    //this._keyIndex = []
    this.modalService.dismissAll()
    this.LoadData()   
  }
  
  /**
   * 
   * @param content 
   */
  _openFilters(content: TemplateRef<any>) {
    this.modalService.open(this.modalFilters,{centered: true, size:'md', windowClass: 'modal-holder modal-dialog-right' })

  /*   this.offcanvasService.open(content,{
      position:'end',
     
    }) */

  }

  /**
   * 
   */
  private _parseDatesFilter(): void {
    const _dateRange = this._formFilter.value._dateRange;  
    console.log({_dateRange});   

   

    if(_dateRange.from) {
      this._filters.inicio = this.date.transform(_dateRange.from,'yyyy-MM-dd') // _b//new Date().toLocaleDateString() //

      if(this._isAdmin) {
        this._calendarFilters.inicio = this.date.transform(_dateRange.from,'yyyy-MM-dd')
      }
     
    }

    if(_dateRange.to) {
      this._filters.final = this.date.transform(_dateRange.to,'yyyy-MM-dd') //new Date(_dateRange.to).toLocaleDateString()

      if(this._isAdmin) {
        this._calendarFilters.final = this.date.transform(_dateRange.to,'yyyy-MM-dd')
      }
      
    }else {
      this._filters.final = this.date.transform(_dateRange.from,'yyyy-MM-dd')

      if(this._isAdmin) {
        this._calendarFilters.final = this.date.transform(_dateRange.from,'yyyy-MM-dd')
      }
    }

  }

  /**
   * 
   * @param _cita 
   */
  _gotoForm(_cita: Cita){
    
    this.patients._getPatientInfo(_cita.Paciente_Id).pipe(map((response) => {     
      this.credentialService.saveUserinfo(response);
    })).subscribe({
      next:(response) => {
        this.credentialService.saveCitaJson(_cita)
        this.redirect.to('/pacientes_formatos')
      },
      error:(err) => {

        console.error({err});
        
      }
    })

    //this.redirect.to('')
  }

  /**
   * 
   */
  hideModal(){
    this.modalService.dismissAll()
  }

  /**
   * 
   * @param items 
   */
  _onSetFilterItems(items: any) {

    console.log({ITEMS: items});

    this._filtersItems = []

    this._filtersItems.push(items)
    
    this._responseData = items.data;

    this.toastr.info(`Se Muestran los resultados, para ${items.name}`,`Total de registros ${items.data.length}`,{
      timeOut: 2000
    })
  }


  /**
   * 
   */
  _showAll() {
    this._filtersItems = []
    this._responseData = this._all_items
  }


  /**
   * 
   * @param event 
   */
  _onChangeEvent(event: any) {

    const resp = this._allData;
    let _data_: any[] = []   

    if(event && event.length > 0 ) {
      _.map(event, (a) => {  
        _.map(a.data, (b) => {
         _data_.push(b)
        })          
       })       

       
       this._serviciosGrouped = _(_data_)
       .groupBy('Servicio_Id')
       .map((items, servicio_id) => ({
         servicio_id,
         name: _.find(resp.Servicios, {Id: servicio_id })?.Name,
         total: items.length,
         data: items
       })).value()
  
  
      this._recursosGrouped = _(_data_)
      .groupBy('Recurso_Id')
      .map((items, recurso_id) => ({
        recurso_id,
        name: items[0].Recurso,
        total: items.length,
        data: items
      })).value()   

      this._tipoCitaGrouped = _(_data_)
      .groupBy('Tipo_Cita')
      .map((items,tipo_cita) => ({
        tipo_cita,
        name: items[0].Tipo_Cita,
        total: items.length,
        data: items
      })).value();

       this._responseData = _data_
       

    }else if (this._ServiciosCollect.length > 0 ) {

      this._onChangeEventServicios(this._ServiciosCollect)

    }else if(this._RecursosCollect.length > 0 ) {

    }else if (this._TipoCitaCollect.length > 0 ) {

    } else {
      this._responseData = this._all_items
      this._getFilters(resp.Citas)
    }        
  }

  /**
   * 
   * @param event 
   */
  _onChangeEventRecursos(event: any ){

    const resp = this._allData;
    let _data_: any[] = []   

    if(event && event.length > 0 ) {
      _.map(event, (a) => {  
        _.map(a.data, (b) => {
         _data_.push(b)
        })          
       })       

       this._doctoresGrouped = _(_data_)
       .groupBy('Empleado_Id')
       .map((items,empleado_id) =>({
         empleado_id,
         name: _.find(resp.Doctores, {Id: empleado_id})?.Name,
         total: items.length,
         data: items
       })).value()

       this._serviciosGrouped = _(_data_)
       .groupBy('Servicio_Id')
       .map((items, servicio_id) => ({
         servicio_id,
         name: _.find(resp.Servicios, {Id: servicio_id })?.Name,
         total: items.length,
         data: items
       })).value()

       this._tipoCitaGrouped = _(_data_)
       .groupBy('Tipo_Cita')
       .map((items,tipo_cita) => ({
         tipo_cita,
         name: items[0].Tipo_Cita,
         total: items.length,
         data: items
       })).value();

       this._responseData = _data_
    }else if (this._ServiciosCollect.length > 0 ) {

      this._onChangeEventServicios(this._ServiciosCollect)

    }else if (this._DoctoresCollect.length > 0 ) {

      this._onChangeEvent(this._DoctoresCollect)


    }else if (this._TipoCitaCollect.length > 0){

    } else {
      this._responseData = this._all_items
      this._getFilters(resp.Citas)
    }
  }

  /**
   * 
   * @param event 
   */
  _onChangeEventServicios(event: any) {

    const resp = this._allData;
    let _data_: any[] = []     
     
    if(event.length > 0 ) {      

     _.map(event, (a) => {  
       _.map(a.data, (b) => {
        _data_.push(b)
       })          
      })

      this._doctoresGrouped = _(_data_)
      .groupBy('Empleado_Id')
      .map((items,empleado_id) =>({
        empleado_id,
        name: _.find(resp.Doctores, {Id: empleado_id})?.Name,
        total: items.length,
        data: items
      })).value()
  
  
      this._recursosGrouped = _(_data_)
      .groupBy('Recurso_Id')
      .map((items, recurso_id) => ({
        recurso_id,
        name: items[0].Recurso,
        total: items.length,
        data: items
      })).value()   


      this._tipoCitaGrouped = _(_data_)
      .groupBy('Tipo_Cita')
      .map((items,tipo_cita) => ({
        tipo_cita,
        name: items[0].Tipo_Cita,
        total: items.length,
        data: items
      })).value();



      this._responseData = _data_;

    }else if (this._DoctoresCollect.length > 0 ) {

      this._onChangeEvent(this._DoctoresCollect)


    }else if (this._RecursosCollect.length > 0 ) {


    }else if (this._TipoCitaCollect.length > 0) {

    }else {
      this._responseData = this._all_items
      this._getFilters(resp.Citas)
    }
  }

  /**
   * 
   * @param event 
   */
  _onChangeEventTipoCita(event: any) {

    const resp = this._allData;
    let _data_: any[] = []   

    if(event.length > 0 ) {

      _.map(event, (a) => {  
        _.map(a.data, (b) => {
         _data_.push(b)
        })          
       })

       this._doctoresGrouped = _(_data_)
       .groupBy('Empleado_Id')
       .map((items,empleado_id) =>({
         empleado_id,
         name: _.find(resp.Doctores, {Id: empleado_id})?.Name,
         total: items.length,
         data: items
       })).value()
   
   
       this._recursosGrouped = _(_data_)
       .groupBy('Recurso_Id')
       .map((items, recurso_id) => ({
         recurso_id,
         name: items[0].Recurso,
         total: items.length,
         data: items
       })).value()

       this._serviciosGrouped = _(_data_)
       .groupBy('Servicio_Id')
       .map((items, servicio_id) => ({
         servicio_id,
         name: _.find(resp.Servicios, {Id: servicio_id })?.Name,
         total: items.length,
         data: items
       })).value()

       this._responseData = _data_;


    }else if (this._DoctoresCollect.length > 0 ) {

    }else if (this._RecursosCollect.length > 0) {

    }else if (this._ServiciosCollect.length > 0 ) {

    }

  }

  /**
   * 
   * @param event 
   */
  _onClear(event: any) {

    const resp = this._allData;

    this._doctoresGrouped = _(resp.Citas)
    .groupBy('Empleado_Id')
    .map((items,empleado_id) =>({
      empleado_id,
      name: _.find(resp.Doctores, {Id: empleado_id})?.Name,
      total: items.length,
      data: items
    })).value()

  }

  /**
   * 
   */
  setFilterData(): void {   
    const _ServiciosCollect = this._ServiciosCollect;
    const _DoctoresCollect = this._DoctoresCollect;
    const _RecursosCollect = this._RecursosCollect;
    const _citas = this._allData.Citas;
  }


  /**
   * 
   */
  _onFilter(): void {
    
  }

}
