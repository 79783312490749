import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalSessionService {

  constructor() { }

  /**
   * 
   * @param key 
   * @param value 
   */
  savedJsonValues(key: string, value: any) {
    if(value) {
      localStorage.setItem(key,JSON.stringify(value))
    }
  }

  /**
   * 
   * @param key 
   */
  getJsonValues(key: string) {

    const _jsonVal = localStorage.getItem(key)

    if(_jsonVal) {
      return JSON.parse(_jsonVal)
    }

    return null
  }

  /**
   * 
   * @param key 
   * @param _val 
   */
  setBooleanValue(key: string, _val: boolean) {
    localStorage.setItem(key,`${_val}`)
  }

 /**
  * 
  * @param key 
  * @returns 
  */
  getBooleanValue(key: string) {

    const _get_boolean = localStorage.getItem(key)

    if(_get_boolean && _get_boolean == 'true') {
      return true
    }else if (_get_boolean && _get_boolean == 'false') {
      return false
    }

    return null

  }

}
