import { Component } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { LocalSessionService } from 'src/app/pages/services/local-session.service';
import { SessionPageService } from 'src/app/pages/services/session-page.service';

@Component({
  selector: 'app-cita-pendiente',
  templateUrl: './cita-pendiente.component.html',
  styleUrls: ['./cita-pendiente.component.scss']
})
export class CitaPendienteComponent {

  _dateMoment = moment()
  //public dDay = new Date('2024-03-13T12:00:00');

  _jsonCitas: any[] = [] 
  _citaSelected: Cita

  constructor(
    private sessionPage: SessionPageService,
    private _local: LocalSessionService
    ) {
    this.loadPendienteCita()    
  }

  private loadPendienteCita(): void {

    const _jsonCitas = this.sessionPage._returnJSONSession()

    if(_jsonCitas) {
      this._jsonCitas = _jsonCitas 

      let _today   :any[]  = []
      let _tomorrow:any[]  = []
      let _others  :any[]  = []          

      _.map(this._jsonCitas, (a) => {       

        let _dateInicio = moment(a.Fecha_Inicio)    
        let _sameDay = _dateInicio.isSame(this._dateMoment,'day')
       // let _diff_days = _dateInicio.diff(this._dateMoment, 'days')        

        if(!this._citaSelected) {
          if(_sameDay) {
            //_today.push(a)
            let _diff_hrs = _dateInicio.diff(this._dateMoment,'hours')
            if(_diff_hrs >= 0) {
              let _diff_minutes = _dateInicio.diff(this._dateMoment,'minutes')         
              if(_diff_minutes > 0 && a.Estatus != 'Cancelada') {
                this._citaSelected = a
              }              
            }  
          }else if (!_sameDay) {            
            let _diffDay = _dateInicio.diff(this._dateMoment,'days')
            if(_diffDay === 0 && a.Tipo_Cita != 'Bloqueo' ) {             
              this._citaSelected = a              
            }else if (_diffDay >= 1 && a.Tipo_Cita != 'Bloqueo') {
              this._citaSelected = a  
            }  
          }             
        }           
      })
    }
  }

}
