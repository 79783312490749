<app-breadcrumbs title="Listado de pacientes"></app-breadcrumbs>

<div class="mt-4 d-none d-lg-block">
    <div class="card card-body">
        <div class="table-responsive">
            <table class="table table-hover table-sm table-nowrap">
                <thead class="text-center bg-light text-uppercase" >
                    <th scope="col">#</th>
                    <th scope="col">Paciente</th>
                    <th scope="col">Nombre completo</th>    
                    <th scope="col">Teléfono</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Direccion</th>
                    <th scope="col"></th>
                </thead>
                <tbody >
                    <tr *ngFor="let _paciente of _listadoPacientes; let i = index;">
                        <th scope="row"> {{i + 1}} </th>
                        <td> {{_paciente.Name}} </td>
                        <td> {{_paciente.Nombre_completo__c}} </td>
                
                        <td> {{_paciente.P05_Telefono__c | mask:'(000) 000 0000' }} </td>
                        <td> {{_paciente.P05_Correo__c}} </td>  
                        <td> <span class="text-wrap"> {{_paciente.DireccionCompleta__c}}  </span>  </td>       
                        <td> 
                            <div class="hstack gap-3">                                
                                <button class="btn btn-secondary btn-sm" (click)="_onEdit(_paciente)">Editar paciente</button>
                            </div>
                        </td>                                         
                    </tr>    
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="mt-4 d-block d-lg-none">
    <div class="row row-cols-xxl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
        <div class="col" *ngFor="let _paciente of _listadoPacientes">
            <div class="card mt-2 shadow-lg p-3 rounded">
                <div class="card-body">
                    <div class="d-md-flex align-items-center">   
                        <div class="ms-lg-3 my-3 my-lg-0">
                            <h5 class="fs-16 mb-2"> {{_paciente.Name}}</h5>
                            <p class="fw-bold text-primarymb-0">{{_paciente.Nombre_completo__c}} </p>
                                               
                        </div>   
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap fw-bold">Teléfono</p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span>{{_paciente.P05_Telefono__c | mask:'(000) 000 0000' }}</span>                            
                        </div>                      
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap  fw-bold"> Correo </p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{_paciente.P05_Correo__c}} </span>                            
                        </div>                      
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap  fw-bold"> Direccion </p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{_paciente.DireccionCompleta__c}}</span>                            
                        </div>                      
                    </div>                 
                    <div class="d-flex mt-4 justify-content-center ">
                        <div class="hstack gap-3">
                            <button class="btn btn-primary btn-sm text-uppercase" (click)="_onEdit(_paciente)">Editar paciente</button>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</div>

<ng-template #modalPatient let-modal>
    <div class="modal-header p-3 bg-primary-subtle">
        <h5 class="modal-title" id="modal-title">Editar datos de paciente </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
            (click)="closeEventModal()">
        </button>
    </div> 

    <div class="modal-body p-4">
        <app-create-patient 
        (eventClickCancel)="onClickEvent($event)" 
        (eventReloadCandidates)="_onEventReload($event)"
        [_paciente_id]="_paciente_id" 
        [editable]="true">
    </app-create-patient>
    </div>
</ng-template>

<ngx-spinner [name]="_spinner" type="ball-scale-multiple" [fullScreen]="false"  ></ngx-spinner>

