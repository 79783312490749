import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Auth Services
import { TokenStorageService } from '../services/token-storage.service';
import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private readonly _now = new Date()

    constructor(
        private router: Router,        
        private authService: AuthenticationService,
        private tokenService: TokenStorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.tokenService.getUser();
        
        if(currentUser && currentUser.token) {

            const _exp = new Date(currentUser.expiration)

            const  diff = (_exp.getTime() - this._now.getTime() ) / 3600000;          

            if( (_exp > this._now) &&( diff > 0) ) {                
                return true                
            }
            this.authService.logout()
             // not logged in so redirect to login page with the return url
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;     
        }             
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return false; 
    }
}
