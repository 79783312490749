<div class="row">
    <div class="col-lg-12">
        <div class="col--12">
            <div class="search-box">
                <input 
                type="text" 
                class="form-control search bg-light border-light"
                id="searchJob"
                autocomplete="off" 
                [(ngModel)]="doctorModel"
                placeholder="Buscar doctor por nombre/"
                   >
                <i class="ri-search-line search-icon"></i>
            </div>
        </div>
    </div>
    </div>

<div class="row mt-4">
    <div *ngFor="let item of _doctores | filterDoctorAyudante:doctorModel " class="col-lg-4 col-md-6">
        <div class="card">
            <div class="card-body">              
                <div class="avatar-sm mb-4">
                    <div class="avatar-title bg-light rounded"> <img src="/assets/images/IML2.png" alt="" class="avatar-xxs">
                    </div>
                </div> 
                <a href="javascript:void(0);">
                    <h5>{{item.name}}</h5>
                </a>
                <p class="text-muted">{{item.especialidad}}</p>
                <div class="d-flex gap-4 mb-3">
                    <div><i class="ri-phone-line text-primary me-1 align-bottom"></i> <small>{{item.celular}}</small> </div>
                    <div><i class="ri-mail-line text-primary me-1 align-bottom"></i>  <small>{{item.correo}}</small> </div>
                </div>
                <div class="m-4 p-2 hstack gap-2 text-center">            
                    <button type="button" class="btn btn-soft-success w-100" (click)="_onSetDoctorAyudante(item)" >Seleccionar</button> </div>
            </div>
        </div>
    </div>
</div>