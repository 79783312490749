import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutPatientsFormatComponent } from './components/layout-patients-format/layout-patients-format.component';
import { LayoutDashboardComponent } from './components/layout-dashboard/layout-dashboard.component';
import { IndexAntesiologoComponent } from './anestesiologo/index-antesiologo/index-antesiologo.component';
import { OldHistoryClinicalComponent } from './old-history-clinical/old-history-clinical.component';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { CandidatosComponent } from './components/candidatos/candidatos.component';
import { ListadoPacientesComponent } from './components/listado-pacientes/listado-pacientes.component';

const routes: Routes = [ 
  {
    path: '',
    component: LayoutDashboardComponent,
    loadChildren:() => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
  },{
    path:'calendario',
    loadChildren:() => import ('./calendar/calendar.module').then(m => m.CalendarModule)
  },{
    path: 'pacientes',
    component: LayoutPatientsFormatComponent,
    loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule),    
  },{
    path: 'pacientes_formatos',
    component:LayoutPatientsFormatComponent,
    loadChildren:() => import('./patients-formats/patients-formats.module').then(m => m.PatientsFormatsModule)
  },{
    path: 'profile',
    loadChildren:() => import ('./profile/profile.module').then(m => m.ProfileModule)
  },{
    path: 'index-anestesiologo',
    component: IndexAntesiologoComponent
  },{
    path: 'old-clinical-history',
    component: OldHistoryClinicalComponent
  },{
    path:'pharmacy',
    component: PharmacyComponent
  },{
    path:'search-results',
    component: SearchResultsComponent
  },{
    path:'candidatos',
    component: CandidatosComponent
  },{
    path:'listado-pacientes',
    component: ListadoPacientesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
