import { Component, OnInit } from '@angular/core';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { PostCalendarData } from 'src/app/pages/calendar/models/post-calendar-data';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { Doctor } from 'src/app/pages/models/doctores';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-doctor-ayudante',
  templateUrl: './doctor-ayudante.component.html',
  styleUrls: ['./doctor-ayudante.component.scss']
})
export class DoctorAyudanteComponent implements OnInit{

  breadCrumbItems!: Array<{}>;

  _doctores: any[] =[]

  doctorModel: string

  record: any;

  _cita: Cita;

  postCalendar: PostCalendarData = new PostCalendarData();

  constructor (private calendar: CalendarService,
    private alert: AlertsService,
    private credentials: CredentialsService) {
      this.record = this.credentials.userInfoData.data.records[0]

      const _b = this.credentials.getCitaJson();

      if(_b) {
        this._cita = _b
      }

      console.log({record: this.record});
      
    }

  ngOnInit(): void {

    this.breadCrumbItems = [
      { label: 'Jobs' },
      { label: 'Job Grid Lists', active: true }
    ];

    this.calendar._getDoctores('true').subscribe({
      next:(response: any) => {
        
        if(response.codigo == 200) {
          this._doctores = response.data.info;
        }

        
        
      },
      error:(error) => {

      }
    })

  }


  /**
   * 
   * @param doctor 
   */
  _onSetDoctorAyudante(doctor:Doctor) {

    Swal.fire({
      title: 'Por favor confirme esta accion.',
      text:'¿Desea asignar este doctor ayudante ?',
      icon: 'question',
      confirmButtonText:'Sí, continuar',
      cancelButtonText:'No, Cerrar'
    }).then((result) => {
      if(result.value) {

        this.postCalendar.cita = this._cita.Cita_Id;
        this.postCalendar.ayudante = doctor.id_salesforce;

        this.alert._showLoader('Asiganando espere...')

        this.calendar._setValuesCalendar(this.postCalendar).subscribe({
          next:(resp) => {

            this.alert._showSuccess('Se ha asigando correctamente','El doctor ayudante fue asignado correctamente')

          },error:(err) => {

          }
        })

      }
    })

  }

}
