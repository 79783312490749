<div class="card mt-4" style="border: none;" >  

    <div class="card-body p-4">
         <!-- -->
        <div class="row ">
            <div class="col-12 ">
                <h4 class="fs-4 text-primary bold text-uppercase text-center">Datos Generales  </h4>     
            </div>

            <div *ngFor="let item of _basic_info" 
            class="mt-2"
            [ngClass]="_getClass(item.name)"
             >
             
                <div *ngIf="item.type=='text' ">
                    <div class="form-floating"   >
                        <input
                        class="form-control"
                        [type]="item.type"
                        readonly
                        [value]="_getValue(item.name)"
                        [name]="item.name"    
                        >
                        <label [for]="item.name" class="form-label">{{item.label}} </label>
                    </div>
                </div>        

                <div *ngIf="item.type == 'date' ">
                    <ng-container *ngIf="item.name == 'birth_date'; else dateTemplate ">
                        <div class="form-floating" >
                            <input                        
                            class="form-control"                                             
                            [id]="item.name"     
                            type="text"     
                            readonly   
                            [readonly]="item.readonly"
                            [value]="_getValue(item.name)"
                                          
                            >
                            <label [for]="item.name" class="form-label" >{{item.label}} </label> 
                        </div>
    
                    </ng-container>               
                    <ng-template #dateTemplate>
                        <div class="form-floating">
                            <input                        
                            class="form-control flatpickr-input"                                             
                            [id]="item.name"     
                            [type]="item.type"     
                            readonly   
                            [readonly]="item.readonly"
                            [value]="_getValue(item.name) | date: 'yyyy-MM-dd' "
                                          
                            >
                            <label [for]="item.name" class="form-label" >{{item.label}} </label> 
                        </div>
                     
                    </ng-template>
                </div>     

                <div *ngIf="item.type == 'textarea' ">
                    <div class="form-floating">                                
                        <textarea
                        class="form-control"
                        [id]="item.name"
                        rows="4"
                        readonly
                        [value]="_getValue(item.name)"
                        [name]="item.name">

                        </textarea>
                        <label [htmlFor]="item.name" class="form-label" > {{item.label}} </label>
                    </div>
                </div>

                <div *ngIf="item.type == 'number' ">
                    <div class="form-floating"   >
                        <input
                        class="form-control"
                        type="text"
                        readonly
                        [value]="_getValue(item.name)"
                        [name]="item.name"    
                        >
                        <label [for]="item.name" class="form-label">{{item.label}} </label>
                    </div>
                </div>

            </div>    
        </div>
             
    </div>

</div>