import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Cita } from 'src/app/pages/calendar/models/calendar';
import { Images } from 'src/app/shared/models/list-images';
import { FormatsService } from 'src/app/shared/services/formats.service';

@Component({
  selector: 'app-nota-egreso',
  templateUrl: './nota-egreso.component.html',
  styleUrls: ['./nota-egreso.component.scss']
})
export class NotaEgresoComponent implements OnInit, OnDestroy {
  @Input()
  id: string;

  @Input()
  _formatoData: any

    
  _controls: any[] = []
  _formato: any = {}

  _label: string
  _itsReady: boolean

  record: any;

  $interval!: Subscription;
  public formNotaEgreso: FormGroup = this.formBuilder.group({})

  _interval: number = 0;

  _Cita: Cita;
  _BODYFORM: any;

  _Firma: Images[] = []

  currentUser: any

  constructor(
    private formatService: FormatsService,
    private date: DatePipe,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private credentials: CredentialsService,
    private tokenService: TokenStorageService
  ) {
    this.record = this.credentials.userInfoData.data.records[0]
    const cita = this.credentials.getCitaJson()
    if(cita) {
      this._Cita = cita
    }

    this.currentUser = this.tokenService.getUser()
  }

  ngOnInit(): void {

    if(this._formatoData) {
      this._formato = this._formatoData

      this._label = this._formato.label;
      this._controls = this._formato.form.controls;

      this._itsReady = true
      this.initForm();
      this._loadImages()

    }

/*     if(this.id) {
      this.formatService._getFormat(this.id, this._Cita.Cita_Id).subscribe({
        next:(response) => {

          if(response.codigo == 200) {
            this._formato = response.data.formato;

            if(this._formato.form){
              this._label = this._formato.label;
              this._controls = this._formato.form.controls;

              this._itsReady = true
              this.initForm();
              this._loadImages()
            }
          }

        },error:(error) => {
          console.error({error});
          
        }
      })
    } */
    
  }
  
  
  
  get f() {
    return this.formNotaEgreso.controls;
  }

  /***
  * Cargamos las imagenes
  */
  private _loadImages () {      
      /**
       * Cargamos la firma del doctor
       */
      this.formatService._getImageProfile(this.currentUser.username).subscribe({
        next:(response) => {
          if(response.codigo == 200) {
            this._Firma = response.data.formato.archivos.Firma;
          }
          
        }
      })
      
    }

  ngOnDestroy(): void {
    if(this.$interval) {
      console.log('ON DESTROYY');
      
      this.$interval.unsubscribe()
    }
    
    this._savePrevDestroy()
  }

  private _savePrevDestroy(): void {
    let _formValues = this.formNotaEgreso.value
    let _body_: any[] = []

    _.map(_formValues, (a, i) => {
        _body_.push({
          name: i,
          value: a
        })      
    })    

    let _bodyForm = {
      Cita_Id: this._Cita.Cita_Id,
      label: this._formato.label,
      new_data: _body_
    }
    this._saveFormatValues(_bodyForm, false)
  }

  private initForm() {

    _.map(this._controls, (a) => {      
      if(a.type != 'frame') {
        _.map(a.Objects, (b) => {
          if(b.Objects) {
            _.map(b.Objects, (c) => {    

              console.log({_C_DENTRO: c});
              
              if(c.name == 'pronostico_vida' || c.name == 'pronostico_funcion' ) {
                this.formNotaEgreso.addControl(
                  c.name, this.formBuilder.control(c.value)
                )
              }

              if(c.Objects) {
                _.map(c.Objects, (d) => {
                  this.formNotaEgreso.addControl(
                    d.name, this.formBuilder.control(d.value)
                  )
                })
              }else{
               // console.log({_C: c});
                
                this.formNotaEgreso.addControl(
                  c.name, this.formBuilder.control(c.value)
                )
              }             
            })
          }else {
            this.formNotaEgreso.addControl(
              b.name, this.formBuilder.control(b.value)
            )
          }
        })
      }      
    })
    console.log({formNotaEgreso: this.formNotaEgreso});
    this.createInterval()

  }

  
  /**
   * 
   */
  private createInterval(){        
    
    const myObservable = interval(1000);

    this.$interval = myObservable.subscribe((integer) => {
      if(integer === 20 ) { 
        this.$interval.unsubscribe();        
        this._newSaveFormat()            
      }           
      this._interval = integer            
    })
  }

    /**
   * 
   */
    _newSaveFormat(): void {

      let _formValues = this.formNotaEgreso.value
      let _body_: any[] = []
  
      _.map(_formValues, (a, i) => {
          _body_.push({
            name: i,
            value: a
          })      
      })    
  
      let _bodyForm = {
        Cita_Id: this._Cita.Cita_Id,
        label: this._formato.label,
        new_data: _body_
      }
  
    //  this._formatoValues = _bodyForm   
      this._saveFormatValues(_bodyForm, true)
    }
  
    /**
     * 
     * @param _jsonObject 
     * @param createInterval 
     */
    _saveFormatValues(_jsonObject: any, createInterval: boolean = false): void {
  
      this.formatService._postFormato(_jsonObject).then((resp) => {
  
        this.toastr.info('Se ha guardado la información','Guardada', {
          timeOut: 3200
        }).onHidden.subscribe(() => {
          if(createInterval) {
                      
            this.createInterval()
          }
        })
      }).catch((error) => {
        console.error({error});
        
      })
  
  
    }
  
  _getValue(name: string) {

    switch (name) {
      case 'name':
        return this.record.Nombre_completo__c       
      case 'age':
        return this.record.P05_Edad__c;
      case 'sex':
        return this.record.P05_Sexo__c;
      case 'n_expediente':
        return this.record.Name;
      case 'birth_date':
        return this.record.P05_Fecha_de_Nacimiento__c
      case 'fecha':
        return this.record.CreatedDate //this.date.transform(, 'yyyy-MM-dd')
      case 'hora':           
        return this.date.transform(this.record.CreatedDate,'hh:mm a')
      
      default:
        return 'N/D'
        break;
    }
  }
}
