import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { CredentialsService } from 'src/app/core/services/credentials.service';
import { CalendarObjects } from 'src/app/pages/calendar/models/calendar';
import { Filters } from 'src/app/pages/models/filters';
import { SessionPageService } from 'src/app/pages/services/session-page.service';

@Component({
  selector: 'app-calendario-index-citas',
  templateUrl: './calendario-index-citas.component.html',
  styleUrls: ['./calendario-index-citas.component.scss']
})
export class CalendarioIndexCitasComponent implements OnInit {

  _showdays: boolean = true
  _form_!: FormGroup; 


  responseData: CalendarObjects;

  _keyIndex: any[] = []

  _today_ = moment()

  _startDate:string = ''
  _endData:string   = ''
  _doctor: string   = ''

  _loaderError: boolean = false;
  _itsReady: boolean;

  _filters: Filters = new Filters()

  _dataUser: any
  _showCitasDia:boolean

  _showCoutnDown:boolean

  constructor (
    private fb: FormBuilder, 
    private credentialService: CredentialsService,
    private sessionPage: SessionPageService
    ) {
 /*  */
    }

  ngOnInit(): void {    

    this._showdays = this.sessionPage._getShowDaysCalendar()

    this._form_ = this.fb.group({
      showDays:[this._showdays]
    })

    if(this.credentialService.credentials?.roles.Doctor) {      
      this._doctor = this.credentialService.credentials.id_salesforce;      
    }

    this._filters.doctor = this._doctor
    this._filters.inicio = this._startDate 
    this._filters.final  = this._endData 
    this._loaderError = false;
    this._itsReady = false;

    
  } 

  Showday (event:any) {        
    let val = event.target.checked
    this._showdays = val          
    //this.sessionPage._setShowDaysCalendar(this._showdays)
  }

  /**
   * 
   * @param event 
   */
  _onEventShowCoutDown(event: boolean) {

    if(event) {
      this._showCoutnDown = event
    }


  }

}
