<ng-container *ngIf="_recetas && _recetas.length; else emptyRecetas">    
    <fieldset class="mt-4" >
        <div class="card card-body">
            <div class="card-title fw-bold text-primary">Receta registrada</div>
            <div class="mt-4">
                <div class="d-none d-lg-block">
                    <div class="card card-body" *ngFor="let receta of _recetas">
                        <span class="fw-bold"> {{receta.fecha}} </span>
                        <div class="d-flex justify-content-end">
                            <div class="hstack gap-1">
                                <button class="btn btn-primary" (click)="_generatePDF(receta)">Imprimir receta </button>
                                <button class="btn btn-secondary" (click)="_editarReceta(receta)">Editar receta </button>
                                <button class="btn btn-danger" (click)="_onDelete(receta)">Borrar receta </button>
                            </div>                                                
                        </div>
                        <div  class="table-responsive mt-2">
                            <div  class="table table-hover table-sm ">
                                <table class="table table-hover table-sm ">
                                    <thead class="bg-light text-center fw-bold text-uppercase">
                                        <th scope="col">Nombre producto</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Descripción aplicación  </th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let receta of receta.productos_recetados">
                                            <td class="text-wrap">{{receta.nombre_producto}} </td>
                                            <td class="text-wrap">{{receta.descripcion_producto}} </td>
                                            <td>{{receta.tipo}} </td>
                                            <td class="text-wrap">{{receta.descripcion_aplicacion}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-block d-lg-none">
                    <div class="row row-cols-xxl-3 row-cols-lg-2 row-cols-md-2 row-cols-1">
                        <div class="card card-body" *ngFor="let receta of _recetas">
                            <span class="fw-bold"> {{receta.fecha}} </span>
                            <div class="d-flex justify-content-end">
                                <div class="hstack gap-1">
                                    <button class="btn btn-primary" (click)="_generatePDF(receta)">Imprimir receta </button>
                                    <button class="btn btn-secondary" (click)="_editarReceta(receta)">Editar receta </button>
                                    <button class="btn btn-danger" (click)="_onDelete(receta)">Borrar receta </button>
                                </div>                                                
                            </div>
                            <div class="col" *ngFor="let receta of receta.productos_recetados">
                                <div class="card mt-2 shadow-lg p-3 rounded">
                                    <div class="card-body">
                                        <div class="card-title">
                                            <h5 class="text-primary">{{receta.nombre_producto}} </h5>
                                        </div>
                                        <div class="d-md-flex align-items-center">
                                            <div class="ms-lg-3 my-3 my-lg-0">
                                                <div class="m-2">                                                                        
                                                    <h5 class="fs-14 mb-2">Descripción</h5>
                                                    <p class="text-primary mb-0">{{receta.descripcion_producto}} </p>
                                                </div>
    
                                                <div class="m-2">
                                                    <h5 class="fs-14 mb-2">Tipo</h5>                                                                
                                                    <p class="text-primary mb-0">{{receta.tipo}} </p>
                                                </div>
                                                
                                                <div class="m-2">
                                                    <h5 class="fs-14 mb-2">Descripción aplicación</h5>
                                                    <p class="text-primary mb-0">{{receta.descripcion_aplicacion}} </p>
                                                </div>                                                              
                                            </div>
                                        </div>                                              
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>             
    </fieldset>
</ng-container>
<ng-template #emptyRecetas>
    
    <div class="container-fluid">   
            <div class="d-flex justify-content-center">
                <h4 class="text-muted ">No hay recetas registradas</h4>
            </div>
        <div class="py-0 m-4 p-2 d-flex justify-content-center">
            
            <img src="assets/images/empty.jpg" height="500">
        </div>
    </div>
</ng-template>


<ng-template #modalReceta let-modalReceta>
    <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close" (click)="modalReceta.dismiss('Cross click')"></button>
    </div>    
    <div class="modal-body text-center"> 
        <app-receta-medicamento 
        [_closeModal]="true" 
        [_loadRecetas]="true"
        [_receta]="_receta_" (eventCloseModal)="_onEventCloseModal2($event)" 
        (eventLoadMedicamentos)="_onEventLoadMedicamentos($event)" ></app-receta-medicamento>
    </div>
</ng-template>