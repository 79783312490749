<div class="card">
    <div class="card-body">      
        <ng-container *ngIf="!base64; else GalleryUploadTemplate">
            <div class="row gallery-wrapper" style="cursor: pointer">
                <div class="element-item col-xxl-3 col-xl-4 col-sm-6 "  *ngFor="let data of GalleryImage; let i=index">
                    <div class="gallery-box card">

                        <ng-container *ngIf="isPDF(data.filename); else _imgTemplate">
                            <div class="d-flex justify-content-center m-2 p-1">
                                <button class="btn btn-icon" (click)="openPDF(data)"> <i class="bx bx bxs-file-pdf display-5 text-danger"></i> </button>
                            </div>
                        </ng-container>

                        <ng-template #_imgTemplate>
                            <div class="gallery-container">
                                <a class="image-popup" >
                                    <img class="gallery-img img-fluid mx-auto" src="{{data.url}}" alt=""/>
                                    <div class="gallery-overlay" (click)="open(i)">   </div>
                                </a>
                            </div>
                        </ng-template>                    

                        <div class="box-content">
                            <div class="d-flex align-items-center mt-1">
                                <small class="text-muted"> IML. {{data.filename}} </small>   
                            </div>  
                        </div>
                        
                    </div>                       
                    <div *ngIf="data.comentario">
                        <textarea class="form-control" [value]="data.comentario" readonly disabled></textarea>
                    </div>
                    <div class="d-flex mt-4" *ngIf="_showDeleteBtn">
                        <button class="btn btn-icon" (click)="_onDelete(data)">
                            <i class="mdi mdi-trash-can-outline align-middle me-2 text-danger fs-2"></i> 
                        </button>
                    </div>   
                </div>
            </div>
        </ng-container>
        <ng-template #GalleryUploadTemplate>
            <div class="row gallery-wrapper" style="cursor: pointer">
                <div class="element-item col-xxl-3 col-xl-4 col-sm-6"   *ngFor="let data of GalleryBase64; let i=index">
                    <div class="gallery-box card">
                        <div class="gallery-container">
                            <a class="image-popup" >
                                <img class="gallery-img img-fluid mx-auto" src="{{data}}" alt=""/>
                                <div class="gallery-overlay" (click)="openBase64(i)">   </div>
                            </a>
                        </div>

                        <div class="box-content">
                            <div class="d-flex align-items-center mt-1">
                                <div class="flex-grow-1 text-muted"> <a href="javascript:void(0);" class="text-body text-truncate text-primary">IML</a></div>                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        
    </div><!-- end card-body -->
</div><!-- end card -->