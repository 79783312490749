import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionPageService } from '../../services/session-page.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  breadCrumbItems!: Array<{}>;
  _title: string = '';

  constructor (private route: ActivatedRoute, private sessionPage: SessionPageService) {}
  
  ngOnInit(): void {
    this.route.data.subscribe((resp: any) => {
      this._title = resp.title;
    })
    
    this.breadCrumbItems = [
      { label: 'Dashboards' },
      { label: 'JOB Dashboard', active: true }
    ]
  }
  
  _getTabId() {
    return this.sessionPage._getTabId()
  }

  onChange(event: Event) {
    const _val = (event.target as HTMLInputElement).outerText; //.srcElement.offsetParent.offsetParent.dataset;
    let _tab_id: number = 1;

    switch (_val) {

      case 'CALENDARIO':
        _tab_id = 1
        break;

      case 'TABLA':
        _tab_id  = 2;
        break;

      case 'PLANCHA' :
          _tab_id = 3
        break;
    
      default:
        _tab_id = 1;
        break;
    }
    this.sessionPage._setActiveTab(_tab_id);   
  }

}
