<div class="d-flex justify-content-center" >
    <div class="card border border-2 border-primary">
        <div class="card-body" *ngIf="signatureNeeded">
            <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="500" height="300"></canvas>    
        </div>
        <div class="card-body" *ngIf="!signatureNeeded" >
            <img src="{{ signatureImg }}" alt="" srcset="" />
        </div>
    </div>
</div>


<div class=" d-flex justify-content-center p-4">
    <button type="button" class="btn btn-danger m-2" (click)="clearPad()" >Limpiar</button> 
    <button type="button" class="btn btn-success m-2" (click)="savePad()" >Confirmar</button> 
    <!-- <button type="button" class="btn btn-primary m-2" >Cargar Firma</button> -->
</div>