import { Component, ElementRef, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-firma-paciente',
  templateUrl: './firma-paciente.component.html',
  styleUrls: ['./firma-paciente.component.scss']
})
export class FirmaPacienteComponent {

  @ViewChild('canvas-firma') canvasEl!: ElementRef;

  signatureNeeded: boolean = true
  signaturePad!: SignaturePad;
  
  signatureImg!: string;

  ngAfterViewInit(): void {
    this.initSigned()
  }

  private initSigned() {
    setTimeout(() => {      
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    }, 300);
    
  }

  clearPad() {
    this.signaturePad.clear();
    this.signatureNeeded = true
    this.initSigned()
    
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (!this.signatureNeeded) {
      this.signatureNeeded = false;
    }
  }
    
  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }



}
