import { dataInfo, dataObjectImages } from './../../layouts/models/user-data';
import { Injectable } from '@angular/core';

import { LoginResponse, User, currentUser } from '../models/auth.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

import { UserData} from 'src/app/layouts/models/user-data';
import { CredentialsService } from './credentials.service';
import { ProfileService } from 'src/app/pages/services/profile.service';
import { Router } from '@angular/router';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {

    private readonly apiUrl: string = environment.apiUrl;
    
    newProfileImg: dataObjectImages 

    user!: User;
    currentUserValue: any;
    private currentUserSubject: BehaviorSubject<User>;

    constructor(
        private http: HttpClient, 
        private credentials: CredentialsService,        
        private tokenS: TokenStorageService,
        public _profileService: ProfileService,
        private router: Router
    ) {        
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')!));
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
     async login(email: string, password: string) {
        return await firstValueFrom(this.http.post<LoginResponse>(`${this.apiUrl}users/token`,{email, password},httpOptions)).then(async (response) => { 
            
            return await firstValueFrom(this.http.get<UserData>(`${this.apiUrl}users/details`,{                    
                    headers: new HttpHeaders({ 'x-auth': `${response.data.token}` })
                })).then(async (resp) => {         

                    this.SetToken(resp.data.info, response)    
                    this.credentials._setCredential(resp.data.info)  
                    
                    const _PERFIL = resp.data.info.archivos?.Perfil;              
                    
                    if(_PERFIL) {
                        const _profileImg = _PERFIL[0];
                        this._profileService.setCurrentProfileImg(_profileImg)    
                    }

                    return response

                }).catch(error => {
                    console.warn({error});                    
                }) 
            })
    }

    /**
     * 
     * @param resp 
     * @param response 
     */
    private SetToken(resp: dataInfo, response: LoginResponse) {

        let UserData: currentUser = {
            username: resp.usuario,
            role: resp.roles,
            dev: resp.dev,
            token: response.data.token,
            expiration: response.data.vigencia
        }
        this.tokenS.saveToken(response.data.token)
        this.tokenS.saveUser(UserData)  
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        // return getFirebaseBackend()!.logout();
        this._profileService.setCurrentProfileImg(this.newProfileImg)
        this.credentials._setCredential()
        localStorage.clear()
        sessionStorage.clear()
        
        this.currentUserSubject.next(null!);
        this.router.navigateByUrl('/auth/login')
        //window.location.href = '/auth/login'
    }

   async _getUserDetail() {

    const token = this.tokenS.getToken()

        return await firstValueFrom(this.http.get<UserData>(`${this.apiUrl}users/details`,{                    
            headers: new HttpHeaders({ 'x-auth': `${token}` })
        }))

    }
  
}

