<div class="table-responsieve">
    <table class="table table-sm table-hover">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Paciente Id</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let candidato of _listadoCandidatos; let i = index">
                <th scope="row"> {{i + 1}} </th>
                <td>{{candidato.Name}} </td>
                <td>{{candidato.Paciente_Id}} </td>
                <td>
                    <div class="hstack gap-2">
                       
                        <div data-bs-toggle="tooltip" data-bs-placement="top" title="Convertir a Paciente">
                            <button class="btn btn-icon rounded-pill" (click)="openModal(modalPaciente, candidato)"> 
                                <i class="mdi mdi-account-arrow-down-outline align-middle me-1 fs-4 text-primary"></i>  
                            </button>
                        </div>

                      <!--   <button class="btn btn-icon"> <i class="mdi mdi-account-arrow-down-outline align-middle me-1 fs-4"></i>  </button>
                        <button class="btn btn-icon"> <i class="mdi mdi-account-arrow-down-outline align-middle me-1 fs-4"></i>  </button> -->
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #modalPaciente let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body text-center">          
            <app-create-patient (eventClickCancel)="onClickEvent($event)" [_candidato]="_candidato" [_reloadCanidates]="true" (eventReloadCandidates)="_onEventEmmiterReaload($event)"></app-create-patient>
        </div>
      </div><!-- /.modal-content -->
</ng-template>