export class Filters {
    inicio: string | null
    final: string  | null
    doctor: string 
}

export interface FilterCalendarObjs {
    inicio: string
    final: string
    doctor: string
}
