<div class="container-fluid">
    <div class="position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg profile-setting-img">
            <img src="assets/images/auth-2.jpg" class="profile-wid-img" alt="">
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xxl-3">
        <div class="card mt-n5">
            <div class="card-body p-4">
                <div class="text-center">
                    <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                        <img [src]="_previewProfile ? _previewProfile : 'assets/images/users/user-dummy-img.jpg'"
                            class="rounded-circle avatar-xl img-thumbnail user-profile-image" id="product-img"
                            alt="user-profile-image">
                        <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <input id="profile-img-file-input" type="file" class="profile-img-file-input"
                                accept="image/png, image/gif, image/jpeg" (change)="fileChange($event, 'profile')">
                            <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                                <span class="avatar-title rounded-circle bg-light text-body">
                                    <i class="ri-camera-fill"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                    <h5 class="fs-16 mb-1">{{userData?.nombre}}
                        {{userData?.area}}</h5>
                    <p class="text-muted mb-0">{{userData?.puesto}}</p>
                </div>
            </div>
        </div><!--end card-->
    </div>
    <div class="col-xxl-9" >
        <div class="card mt-xxl-n5">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="_getTabActiveId()"  class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="1" (click)="_setTabActiveId(1)"  class="nav-item" *ngIf="canLoadFirma()">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">                      
                            Firma virtual
                        </a>
                        <ng-template ngbNavContent>

                            <ng-container *ngIf="!_firmaLoaded; else firmaTemplate">
                                <fieldset class="mt-4" *ngIf="_previewImage && _previewImage.length">
                                    <div class="card">
                                     <div class="card-body">
                                         <div class="d-flex justify-content-center"  *ngFor="let item of _previewImage">
                                             <figure class="figure mb-0">
                                                 <img [src]="item.base64" class="figure-img img-fluid" max  alt="" width="300">
                                             </figure>                                        
                                         </div>                                    
                                     </div>
                                     <div class="card-footer">
                                         <div class="d-flex justify-content-center">
                                             <button class="btn btn-success" (click)="_onSaveIt()"> GUARDAR </button>
                                         </div>
                                     </div>
                                    </div>
                                 </fieldset>
     
                                 <fieldset class="text-center">                                
                                     <div class="card">
                                         <div class="card-body">                                        
                                             <legend class="text-muted fs-15 bold">Carge su firma Virtual</legend>
                                             <div class="position-relative d-inline-block">
                                                 <div class="position-absolute  bottom-0 end-0">
                                                     <label for="companylogo-image-input" class="mb-0" data-bs-toggle="tooltip"
                                                         data-bs-placement="right" ngbTooltip="Select Image">
                                                         <div class="avatar-xs cursor-pointer">
                                                             <div class="avatar-title bg-light border rounded-circle text-muted">
                                                                 <i class="ri-image-fill"></i>
                                                             </div>
                                                         </div>
                                                     </label>
                                                     <input class="form-control d-none" [multiple]="false" id="companylogo-image-input" type="file" accept="image/png, image/jpeg"  (change)="fileChange($event,'firma')">
                                                 </div>
                                                 <div class="avatar-lg p-1">
                                                     <div class="avatar-title bg-light rounded-circle">
                                                         <img src="assets/images/users/user-dummy-img.jpg" id="companylogo-img"
                                                             class="avatar-md h-auto rounded-circle object-fit-cover" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </fieldset>
                            </ng-container>

                            <ng-template #firmaTemplate>
                                <fieldset class="mt-4" >
                                    <div class="card" *ngFor="let item of _firmaLoaded" >
                                     <div class="card-body">
                                         <div class="d-flex justify-content-center"  >
                                             <figure class="figure mb-0">
                                                 <img [src]="item.url" class="figure-img img-fluid" max  alt="" width="300">
                                             </figure>                                        
                                         </div>                                    
                                     </div>
                                     <div class="card-footer">
                                         <div class="d-flex justify-content-center">
                                             <button class="btn btn-danger" (click)="_onDelete(item)"> Eliminar </button>
                                         </div>
                                     </div>
                                    </div>
                                 </fieldset>

                            </ng-template>

                            <ngx-spinner type="ball-scale-multiple" [fullScreen]="true"></ngx-spinner>

                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" (click)="_setTabActiveId(2)" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                          Bloqueo de horarios
                        </a>
                        <ng-template ngbNavContent >
                            <ng-template [ngTemplateOutlet]="_bloqueoHorarios" ></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" (click)="_setTabActiveId(3)" class="nav-item" *ngIf="canLoadFirma()" >
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            Cargar Cedula
                          </a>
                          <ng-template ngbNavContent>
                            <div *ngIf="_showCamera">
                                <ng-template [ngTemplateOutlet]="_cam_ine" ></ng-template>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="!_showCamera" >
                                <button class="btn btn-primary  text-uppercase " (click)="initWebCam()">Subir desde cámara</button>
                            </div>
                            <!--  -->

                            <div class="mt-4">
                             <div class="card card-body">
                                <file-pond
                                #myPond
                                [options]="pondOptions"
                                [files]="pondFiles"
                                (oninit)="pondHandleInit()"
                                (onaddfile)="pondHandleAddFile($event)"
                                (onremovefile)="pondRemoveFile($event)">
                                </file-pond>
                             </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #_bloqueoHorarios>
    <ul ngbNav #tabbloquos="ngbNav" class="nav nav-tabs dropdown-tabs nav-tabs-custo" role="tablist">
        <li [ngbNavItem]="1" class="nav-item">
            <a ngbNavLink class="nav-link text-uppercase" data-bs-toggle="tab" role="tab">Bloqueo recurrente</a>
            <ng-template ngbNavContent>
                <div class="mt-4">
                    <div class="card">
                        <div class="card-body">                                                   
                            <form [formGroup]="_formBloqueo" (ngSubmit)="_onSaveBloqueos()" >                       
                                <ng-container formArrayName="bloqueos">   
                                    <div class="row">                    
                                        <div class="col-lg-7 col-md-12" *ngIf="_role && !_role?.Doctor" >
                                            <div class="form-group">
                                                <label class="form-label">Doctor</label>
                                                <ng-select
                                                [items]="_Doctores"
                                                bindLabel="name"
                                                bindValue="id_salesforce"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="_onchage($event)"
                                                [(ngModel)]="_doctor"
                                                ></ng-select>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <button type="submit" class="btn btn-primary">Guardar</button>
                                            </div>                                                                          
                                        </div>
                                    </div>                      
                                    <div class="row g-2 mt-4"> 
                                        <div class="col-xl-3 col-lg-4 col-md-6" *ngFor="let bloq of bloqueos.controls; index as i"  [formGroupName]="i" >
                                            <div class="input-group">
                                                <input type="text" [disabled]="true" readonly formControlName="dia" class="form-control">
                                                <button class="btn btn-outline-success"  type="button"  (click)="_addControl(i)" > <i class="ri-add-line"></i>     </button>
                                            </div>
                                            <div formArrayName="horas">
                                                <div class="card mt-2">                 
                                                    <ngx-simplebar style="height: 40vh;">                                                           
                                                        <div class="card-body">
                                                            <div class="row g-2" *ngFor="let hora of horas(i).controls; let horaIndex = index" [formGroupName]="horaIndex">
                                                                <p class="mt-4"><small class="text-muted">Bloqueo de horario {{horaIndex + 1 }} </small></p>  
                                                                <div class="float-end">
                                                                    <button type="button" class="btn btn-icon" (click)="_removeControl(hora, horaIndex, i)" > 
                                                                        <i class="bx bx-trash text-danger fs-16"></i> 
                                                                    </button>
                                                                </div>                                                    
                                                                <div class="col-md-12 col-xl-6">
                                                                    <input 
                                                                    class="form-control flatpickr-input"
                                                                    type="text" 
                                                                    mwlFlatpickr                                                
                                                                    [noCalendar]="true" 
                                                                    [enableTime]="true" 
                                                                    placeholder="Inicio"
                                                                    formControlName="inicio"
                                                                    [dateFormat]="'H:i'"> 
                                                                </div>
                                                                <div class="col-md-12 col-xl-6">
                                                                    <input
                                                                    class="form-control flatpickr-input"
                                                                    type="text" 
                                                                    mwlFlatpickr
                                                                    [noCalendar]="true"                                               
                                                                    [enableTime]="true" 
                                                                    placeholder="Fin"
                                                                    formControlName="fin"
                                                                    [dateFormat]="'H:i'">    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ngx-simplebar>    
                                                </div>
                                            </div>                             
                                        </div>
                                    </div>                           
                                </ng-container>  
                                <div class="row">
                                    <div class="col d-flex justify-content-end">
                                        <div class="">
                                            <button type="submit" class="btn btn-primary">Guardar</button>
                                        </div>                  
                                    </div>
                                </div>   
                            </form>             
                        </div>
                    </div>
                </div>    
            </ng-template>
        </li>
        <li [ngbNavItem]="2" class="nav-item">
            <a ngbNavLink class="nav-link text-uppercase" data-bs-toggle="tab" role="tab">Bloqueo especifico</a>
            <ng-template ngbNavContent>
                <div class="mt-4">
                    <div class="row mt-4">                    
                        <div class="col-lg-7 col-md-12" *ngIf="_role && !_role?.Doctor" >
                            <div class="form-group">
                                <label class="form-label">Doctor</label>
                                <ng-select
                                [items]="_Doctores"
                                bindLabel="name"
                                bindValue="id_salesforce"
                                [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="_onchage($event)"
                                [(ngModel)]="_doctor"
                                ></ng-select>
                            </div>
                        </div>
                    </div>     
                    <div class="row mt-4">

                        <div class="col-lg-6 mt-4">             
                       <!--      <div class="float-start m-3">
                                <div class="form-check form-switch form-switch-lg" dir="ltr">
                                    <input type="checkbox" class="form-check-input" id="customSwitchsizelg" 
                                    [(ngModel)]="_showRangeDates" 
                                    [ngModelOptions]="{standalone: true}"  
                                    (ngModelChange)="onChange()"
                                    [checked]="_showRangeDates" [value]="_showRangeDates"  >
                                    <label class="form-check-label" for="customSwitchsizelg">Rango de fechas </label>
                                </div>
                            </div> -->
                            <div class="m-2">
                                <input
                                class="form-control flatpickr-input" 
                                type="text" 
                                mwlFlatpickr 
                                [altInput]="true" 
                                [(ngModel)]="_dateBloqueo"
                                [minDate]="_today"
                                [mode]="_showRangeDates ? 'range': 'single' "
                                [convertModelValue]="true" 
                                [inline]="true">   
                                <div class="mt-3">
                                    <div class="d-flex justify-content-center">
                                        <button 
                                        [disabled]="!_dateBloqueo"
                                        class="btn btn-primary text-uppercase" (click)="_addDates()">Agregar fecha </button>    
                                    </div>
                                </div>                                                               
                            </div>
                            <div class="mt-4" *ngIf="!_showRangeDates">
                                <div class="d-flex justify-content-center" >
                                    <button (click)="_onSaveItBloqueos()" class="btn btn-success w-100 text-uppercase">Guardar</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mt-4">

                            <ng-container *ngIf="!_showRangeDates; else _rangeDateTemplate">
                                <form [formGroup]="_formBloqueoDates">
                                
                                    <ng-container formArrayName="bloqueo_especifico">
                                        <ngx-simplebar style="max-height: calc(100vh - 70px)">
                                            <div class="row mt-4">
                                                <div class="col-lg-6" *ngFor="let bloq of bloqueoEsp.controls; index as i" [formGroupName]="i">
                                                    <div class="input-group">
                                                        <input type="text" [disabled]="true" readonly formControlName="fecha" class="form-control">
                                                        <button class="btn btn-outline-success" type="button"  (click)="_addControlHours(i)" > <i class="ri-add-line"></i>     </button>
                                                        <button class="btn btn-outline-danger"  type="button"  (click)="_removeCoontrolHour(i)" > <i class="bx bx-trash"></i>     </button>
                                                    </div>
                                                    <div formArrayName="horas">
                                                        <div class="card m2-2">
                                                            <ngx-simplebar style="height: 40vh;">
                                                                <div class="card-body">
                                                                    <div class="row g-2" *ngFor="let hora of horasEsp(i).controls; let horaIndex = index" [formGroupName]="horaIndex">
                                                                        <p class="mt-4"><small class="text-muted">Bloqueo de horario {{horaIndex + 1 }} </small></p>  
                                                                        <div class="float-end">
                                                                            <button type="button" class="btn btn-icon" (click)="_removeControlBloqueoEsp(hora, horaIndex, i)" > 
                                                                                <i class="bx bx-trash text-danger fs-16"></i> 
                                                                            </button>
                                                                        </div>
                                                                        <div class="col-md-12 col-xl-6">
                                                                            <input 
                                                                            class="form-control flatpickr-input"
                                                                            type="text" 
                                                                            mwlFlatpickr                                                
                                                                            [noCalendar]="true" 
                                                                            [enableTime]="true" 
                                                                            placeholder="Inicio"
                                                                            formControlName="inicio"
                                                                            [dateFormat]="'H:i'"> 
                                                                        </div>
                                                                        <div class="col-md-12 col-xl-6">
                                                                            <input
                                                                            class="form-control flatpickr-input"
                                                                            type="text" 
                                                                            mwlFlatpickr
                                                                            [noCalendar]="true"                                               
                                                                            [enableTime]="true" 
                                                                            placeholder="Fin"
                                                                            formControlName="fin"
                                                                            [dateFormat]="'H:i'">    
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ngx-simplebar>
                                                        </div>
                                                    </div>
                                                </div>                                
                                            </div>
                                        </ngx-simplebar>
                                    </ng-container>
                                 
                                </form>
                            </ng-container>
                            <ng-template #_rangeDateTemplate>
                                <div class="card card-body mt-4" *ngIf="_showform">
                                    <form [formGroup]="_formRangeDate">
                                        <div class="row">
                                            <div class="col-xxl-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Fecha inicio</label>
                                                    <input class="form-control" formControlName="startDate" readonly [disabled]="true">
                                                </div>                                                
                                            </div>
                                            <div class="col-xxl-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Fecha final</label>
                                                    <input class="form-control" formControlName="endDate" readonly [disabled]="true">
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xxl-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Hora de inicio</label>
                                                    <input 
                                                    class="form-control flatpickr-input"
                                                    type="text" 
                                                    mwlFlatpickr                                                
                                                    [noCalendar]="true" 
                                                    [enableTime]="true" 
                                                    placeholder="Inicio"
                                                    formControlName="inicio"
                                                    [dateFormat]="'H:i'"> 
                                                </div>
                                            </div>
                                            <div class="col-xxl-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Hora final</label>
                                                    <input 
                                                    class="form-control flatpickr-input"
                                                    type="text" 
                                                    mwlFlatpickr                                                
                                                    [noCalendar]="true" 
                                                    [enableTime]="true" 
                                                    placeholder="Inicio"
                                                    formControlName="fin"
                                                    [dateFormat]="'H:i'"> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <button type="button" class="btn btn-primary" (click)="_onSaveItFormDateRange()">Guardar</button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>   
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>   

    <div [ngbNavOutlet]="tabbloquos"></div>    

</ng-template>

<ng-template #_cam_ine>
    <div class="d-flex justify-content-center">
        <div class="p-3 m-4">
            <div class="card border card-border-primary"> 
                <div class="card-body border border-2 border-primary">
                   <!--  <button class="btn btn-primary" (click)="_permissions()">Acceder a camara</button> -->
                    <div class="d-flex justify-content-center">
                        <img id="photo" class="photo">                       
                        <webcam                   
                        [width]="350"
                        [height]="300"
                        [trigger]="triggerObservable" 
                        (imageCapture)="handleImage($event)" 
                        [allowCameraSwitch]="allowCameraSwitch" 
                        [switchCamera]="nextWebcamObservable"       
                        [mirrorImage]="'auto'"   
                        [imageQuality]="1"
                        [videoOptions]="videoOptions"
                        (cameraSwitched)="cameraWasSwitched($event)"
                        (initError)="handleInitError($event)"></webcam> 
                    </div>

                    <div class="container-fluid" *ngIf="webcamImage">
                        <img [src]="webcamImage.imageAsDataUrl"  class="figure-img img-fluid" max  alt="">
                    </div>
                     
                    <div class="d-flex justify-content-center" *ngIf="webcamImage">
                        <button class="btn btn-primary text-uppercase w-100" (click)="_uploadCedula()">Subir Cedula</button>
                    </div>

                </div>
               
            </div>
            <div class="d-flex justify-content-center">
                <div class="hstack gap-3">
                    <button
                    type="button" 
                    (click)="showNextWebcam(true);" 
                    [disabled]="!multipleWebcamsAvailable"
                    class="btn btn-soft-info waves-effect waves-light m-1"> 
                    <i class="ri-camera-switch-line align-bottom me-1"></i> Cambiar de camara </button>
    
                    <button 
                    type="button" 
                    (click)="triggerSnapshot();"
                    class="btn btn-soft-success waves-effect waves-light m-1">
                    <i class="ri-camera-3-line  align-bottom me-1"></i> Tomar fotografia </button>
    
                    <button 
                    type="button" 
                    (click)="toggleWebcam()"
                    class="btn btn-soft-danger waves-effect waves-light m-1">
                    <i class=" ri-camera-off-line align-bottom me-1"></i> Cerrar camara </button>
                </div>

          

            </div>
        </div>
        
    </div>
</ng-template>
