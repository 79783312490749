<ng-template #DetallesDoctor let-offcanvas>
    <div class="d-flex align-items-center bg-dark bg-gradient p-3 offcanvas-header">
        <h5 class="m-0 me-2 text-white text-uppercase">{{_doctor.name}}</h5>
        <button type="button" class="btn-close btn-close-white ms-auto " data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="_hideCanvas()"></button>
    </div>
    <div class="offcanvas-body profile-offcanvas p-0">
        <ngx-simplebar style="height: 90vh;">
            <div class="p-4">
                <h5 class="fs-15 mb-4">Detalles</h5>

                <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Nombre de empleado</h6>
                        <p class="mb-0">{{_doctor.name}} </p>
                    </div>                    
                </div>

                <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Tipo de empleado</h6>
                        <p class="mb-0">{{_doctor.tipo_empleado}} </p>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Especialidad</h6>
                        <p class="mb-0">{{_doctor.especialidad}} </p>
                    </div>
                </div>

                <div class="d-flex mb-3">

                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Telefono</h6>
                        <p class="mb-0">{{_doctor.telphone}} </p>
                    </div>

                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Celular</h6>
                        <p class="mb-0">{{_doctor.celular}} </p>
                    </div>
               
                </div>

                <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">correo</h6>
                        <p class="mb-0">{{_doctor.correo}} </p>
                    </div>
                </div>

                <div class="d-flex mb-3">
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Color de calendario</h6>
                        <p class="mb-0">{{_doctor.color_calendario}} </p>
                    </div>
                </div>

            </div>
        </ngx-simplebar>
    </div>
</ng-template>