export class PostCalendarData {
    cita?: string
    doctor?: string
    anestesiologo?: string | null
    ayudante?: string | null
    enfermera_quirurgica?:string
    enfermera_general?: string
    anestesia?: boolean
    biopsia?: boolean
        
}
