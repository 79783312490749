<app-breadcrumbs title="Listado de candidatos"></app-breadcrumbs>

<div class="mt-4 d-none d-lg-block">
    <div class="card card-body">
        <div class="table-responsive">
            <table class="table table-hover table-sm table-nowrap">
                <thead class="text-center bg-light text-uppercase" >
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellidos</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Información</th>
                    <th scope="col"></th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of _listadoCandidatos; let i = index">
                       <th scope="row"> {{i + 1}} </th>
                       <td>{{item.Datos_Candidato.relaciones.nombre}}</td>
                       <td>{{item.Datos_Candidato.relaciones.apellidos}} </td>
                       <td>{{item.Datos_Candidato.generales.correo}} </td>
                       <td>{{item.Datos_Candidato.generales.telefono | mask:'(000) 000 0000'}} </td>
                       <td>
                            <div class="table-responsive">
                                <table class="table table-hover table-sm table-nowrap">
                                    <thead class="bg-light">
                                        <th scope="col">Origen paciente</th>
                                        <th scope="col">Especialidad</th>
                                        <th scope="col">Método contactó</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{item.Datos_Candidato.informacion.origen_paciente}} </td>
                                            <td>{{item.Datos_Candidato.informacion.especialidad}} </td>
                                            <td>{{item.Datos_Candidato.informacion.metodo_contacto}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                       </td>
                       <td>
                            <div class="hstack gap-3">
                                <button class="btn btn-primary btn-sm text-uppercase" (click)="openModal(modalPaciente, item)">Convertir a paciente</button>
                                <button class="btn btn-secondary btn-sm" (click)="_onEdit(item)">Editar candidato</button>
                            </div>
                       </td>
                    </tr>
                </tbody>
            </table>
   
        </div>
    </div>
</div>

<div class="mt-4 d-block d-lg-none">
    <div class="row row-cols-xxl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
        <div class="col" *ngFor="let candidato of _listadoCandidatos">
            <div class="card mt-2 shadow-lg p-3 rounded">
                <div class="card-body">
                    <div class="d-md-flex align-items-center">   
                        <div class="ms-lg-3 my-3 my-lg-0">
                            <h5 class="fs-16 mb-2">{{candidato.Datos_Candidato.relaciones.nombre}} {{candidato.Datos_Candidato.relaciones.apellidos}} </h5>
                            <p class="fw-bold text-primarymb-0">{{candidato.Datos_Candidato.generales.correo}} </p>
                            <div class="mt-2"> 
                                <span> {{candidato.Datos_Candidato.generales.telefono | mask:'(000) 000 0000' }} </span>                               
                            </div>                            
                        </div>   
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap fw-bold"> Origen paciente</p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{candidato.Datos_Candidato.informacion.origen_paciente}} </span>                            
                        </div>                      
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap  fw-bold"> Especialidad </p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{candidato.Datos_Candidato.informacion.especialidad}} </span>                            
                        </div>                      
                    </div>
                    <div class="d-flex gap-4 mt-0 text-muted mx-auto fs-12">
                        <p class="text-wrap  fw-bold"> Método contactó </p>
                        <div class="d-flex mb-0 justify-content-center">
                            <span> {{candidato.Datos_Candidato.informacion.metodo_contacto}} </span>                            
                        </div>                      
                    </div>                 
                    <div class="d-flex mt-4 justify-content-center ">
                        <div class="hstack gap-3">
                            <button class="btn btn-primary btn-sm text-uppercase" (click)="openModal(modalPaciente, candidato)">Convertir a paciente</button>
                            <button class="btn btn-secondary btn-sm" (click)="_onEdit(candidato)">Editar candidato</button>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</div>


<ng-template #modalPaciente let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body text-center">          
            <app-create-patient 
            (eventClickCancel)="onClickEvent($event)" 
            [_candidato]="_candidato" 
            [_reloadCanidates]="true" 
            (eventReloadCandidates)="_onEventEmmiterReaload($event)"></app-create-patient>
        </div>
      </div><!-- /.modal-content -->
</ng-template>

<ng-template #modalCandidato let-mmodalCandiato>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close" (click)="mmodalCandiato.dismiss('Cross click')"></button>
        </div>

        <div class="modal-body text-center">    
            <app-create-candidate 
            [editable]="true" 
            [re]
            [_dataCandidato]="_candidato" 
            (eventCloseModal)="_onEventCloseModal($event)" ></app-create-candidate>
        </div>

    </div>
</ng-template>

<ngx-spinner [name]="_spinner" type="ball-scale-multiple" [fullScreen]="false"  ></ngx-spinner>