export class CreatePatients {
    Candidato_Id?: string
    name?: string
    paciente_id?: string    
    Salesforce_Id?: string
    
    generales   : Generales
    direccion   : Direccion
    relaciones  : Relaciones
    informacion : Informacion
    facturacion : Facturacion
    marketing   : Marketing

}

export interface Generales  {
    fecha_nacimiento: string // * Obligatorio
    edad?: number
    sexo: string // * Obligatorio
    correo?: string
    telefono?: string
    estado_civil?: string
    //correo: string
    sgmm?: boolean
    compañia_ssgm?: string    
}

export interface Direccion {
    pais?:string
    estado?:string
    municipio?:string
    codigo_postal?:number
    calle?:string
    colonia?:string
    num_exterior?:number
    num_interior?:number
    numero_exterior?: string | null
}

export interface Relaciones {
    candidato?: string
    odoo_api:{
        id_empresa: string
    },
    centro_medico: {
        Id: string,
        Name: string
    } // * Obligatorio
    nombre: string // * Obligatorio
    apellidos: string // * Obligatorio
    estado?: string
    ultima_actividad_reporte?: string
    referencia_numero_paciente?: string
    mes_ultima_cirugia?: string
    mes_penultima_cirugia?: string
    mes_antepenultima_cirugia?: string
    responsable_call_center?: string
    responsable_marketing?: string
    candidato_cirugia?: string
    objecion?: string
    cumple_hoy?: boolean
    seguro_antiguedad?: string
    responsable_redes_sociales?: string
    responsable_venta?: string
}

export interface Informacion {
    origen_paciente: string // * Obligatorio
    paciente_recomienda?: string
    origen_paciente_historico?: string
    motivo_consulta?: string
    especialidad: string // * Obligatorio
    antiguedad_mayor_2?: boolean
    metodo_contacto: string // * Obligatorio
    nombre_contacto_emergencias?: string
    telefono_contacto_emergencias?: string
    comentario?: string
}

export interface Facturacion {
    razon_social?: string
    correo_facturacion?: string
    rfc?: string
    motivo_consulta?: string
    misma_direccion_paciente?: boolean
    numero_tarjeta?: number
    direccion_facturacion?: string
    numero_poliza?: string
    deducible?: string
    coaseguro?: string
    comentario?: string
}

export interface Marketing {
    campaña?: string
    nombre_conjunto_anuncios?: string
    palabra_clave?: string
    costo_resultado?: string
    tasa_conversion?: string
    id_anuncio?: string
    id_cuenta?: string
}